import { useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { Box, Button, Grid, MenuItem } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import AppSelect from "../../components/AppSelect";
import Paragraph from "../../components/Paragraph";
import LanguageLevel from "../../models/LanguageLevel";
import CandidateLanguageList from "../../models/CandidateLanguageList";
import CandidateLanguage from "../../models/CandidateLanguage";
import LoadingModal from "../../components/LoadingModal";
import candidateService from "../../services/CandidateService";

interface ICandidateLanguageModalProps {
  close: () => void;
  languageLevels: LanguageLevel[];
  candidateLanguageList: CandidateLanguageList[];
  candidateLanguage: CandidateLanguage;
  onSave: () => void;
}

interface ICandidateLanguageModal {
  languageId: string;
  writeLevel: string;
  readLevel: string;
  learnLevel: string;
}
const validationSchema = Yup.object().shape({});

function CandidateLanguageModal({
  close,
  languageLevels,
  candidateLanguageList,
  candidateLanguage,
  onSave,
}: ICandidateLanguageModalProps) {
  const [initialValues, setInitialValues] = useState<ICandidateLanguageModal>({
    languageId: candidateLanguage.languageId.toString(),
    writeLevel: candidateLanguage.writeLevel?.toString() ?? "",
    readLevel: candidateLanguage.readLevel?.toString() ?? "",
    learnLevel: candidateLanguage.learnLevel?.toString() ?? "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function submit(values: ICandidateLanguageModal) {
    const cl: CandidateLanguage = {
      candidateLanguageId: candidateLanguage.candidateLanguageId,
      languageName: "",
      languageId:
        values.languageId != "0"
          ? Number(values.languageId)
          : candidateLanguage.languageId,
      learnLevel: Number(values.learnLevel),
      writeLevel: Number(values.writeLevel),
      readLevel: Number(values.readLevel),
    };
    try {
      setIsSaving(true);
      await candidateService.saveCandidateLanguage(cl);
      setIsSaving(false);
      onSave();
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {(props: FormikProps<ICandidateLanguageModal>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            isValid,
          } = props;

          return (
            <Form>
              <Grid container spacing={1}>
                {candidateLanguageList.length > 0 ? (
                  <Grid item lg={6} xs={12}>
                    <Paragraph title="Idioma" required />
                    <AppSelect
                      error={
                        errors.languageId && touched.languageId ? true : false
                      }
                      value={values.languageId}
                      name="languageId"
                      fullWidth
                      onChange={handleChange}
                    >
                      {candidateLanguageList.map((el) => (
                        <MenuItem key={el.languageId} value={el.languageId}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </AppSelect>
                  </Grid>
                ) : null}

                <Grid item lg={6} xs={12}>
                  <Paragraph title="Escritura" required />
                  <AppSelect
                    error={
                      errors.writeLevel && touched.writeLevel ? true : false
                    }
                    value={values.writeLevel}
                    name="writeLevel"
                    fullWidth
                    onChange={handleChange}
                  >
                    {languageLevels.map((el) => (
                      <MenuItem
                        key={el.languageLevelId}
                        value={el.languageLevelId.toString()}
                      >
                        {el.name}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Paragraph title="Lectura" required />
                  <AppSelect
                    error={errors.readLevel && touched.readLevel ? true : false}
                    name="readLevel"
                    fullWidth
                    value={values.readLevel}
                    onChange={handleChange}
                  >
                    {languageLevels.map((el) => (
                      <MenuItem
                        key={el.languageLevelId}
                        value={el.languageLevelId.toString()}
                      >
                        {el.name}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Paragraph title="Conversacion" required />
                  <AppSelect
                    error={
                      errors.learnLevel && touched.learnLevel ? true : false
                    }
                    name="learnLevel"
                    fullWidth
                    value={values.learnLevel}
                    onChange={handleChange}
                  >
                    {languageLevels.map((el) => (
                      <MenuItem
                        key={el.languageLevelId}
                        value={el.languageLevelId.toString()}
                      >
                        {el.name}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={close}
                    color="customGrey"
                    variant="contained"
                    sx={{ mr: "10px", color: "white" }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    onClick={() => {
                      if (!isValid) {
                        setIsValidForm(true);
                      }
                    }}
                    disabled={isSubmitting}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ bgcolor: "secondary.main" }}
                  >
                    {isSubmitting ? <>Guardando...</> : "Guardar"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default CandidateLanguageModal;
