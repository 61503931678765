import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { ChangeEvent, useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import AppSelect from "../../components/AppSelect";
import { CandidateTrip } from "../../models/CandidateTrip";
import LoadingModal from "../../components/LoadingModal";
import candidateService from "../../services/CandidateService";
import { GetDateYYYYMMDD } from "../../Utils";

interface EditTripModalProps {
  onClose: () => void;
  onSave: () => void;
  candidateTrip: CandidateTrip;
}
interface IEditTrip {
  flightDate: string;
  flightDateHour: string;
  flightDateAirline: string;
  flightDateReservationCode: string;
  flightDateAirPort: string;
  flightDateAirPortArrived: string;
  flightDateMinute: string;
}

const validationSchema = Yup.object().shape({
  flightDate: Yup.string().required("Requerido"),
  flightDateHour: Yup.string().required("Requerido"),
  flightDateMinute: Yup.string().required("Requerido"),
  flightDateAirline: Yup.string().required("Requerido"),
  flightDateReservationCode: Yup.string().required("Requerido"),
  flightDateAirPort: Yup.string().required("Requerido"),
  flightDateAirPortArrived: Yup.string().required("Requerido"),
});

function EditTripModal({ onClose, onSave, candidateTrip }: EditTripModalProps) {
  const [initialValues, setInitialValues] = useState<IEditTrip>({
    flightDate: "",
    flightDateHour: "",
    flightDateMinute: "",
    flightDateAirline: "",
    flightDateReservationCode: "",
    flightDateAirPort: "",
    flightDateAirPortArrived: "",
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [hours, setHours] = useState<string[]>([]);
  const [minutes, setMinutes] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    const hour: string[] = [];
    for (var h = 1; h < 24; h++) {
      if (h < 10) {
        hour.push("0" + h.toString());
      } else {
        hour.push(h.toString());
      }
    }
    hour.push("00");
    setHours(hour);

    const minute: string[] = [];
    for (var m = 0; m <= 59; m++) {
      if (m < 10) {
        minute.push("0" + m.toString());
      } else {
        minute.push(m.toString());
      }
    }

    setMinutes(minute);
    setInitialValues({
      ...candidateTrip,
      flightDate:
        candidateTrip.flightDate &&
        GetDateYYYYMMDD(new Date(candidateTrip.flightDate)),
    });
  }, []);

  async function submit(values: IEditTrip) {
    if (file == null) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Por favor agregar un archivo");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    const trip: CandidateTrip = {
      ...values,
      travelInfoID: candidateTrip.travelInfoID,
      isFlightTo: candidateTrip.isFlightTo,
      fileURL: "",
    };

    try {
      setIsSaving(true);
      await candidateService.saveCandidateTrip(trip, file);
      onSave();
      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {(props: FormikProps<IEditTrip>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            isValid,
          } = props;

          return (
            <Form>
              <Grid container spacing={1}>
                {/* Date */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Fecha de salida" required />
                  <AppTextField
                    type="date"
                    error={
                      errors.flightDate && touched.flightDate ? true : false
                    }
                    name="flightDate"
                    value={values.flightDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                {/* Hour */}
                <Grid item xs={12} lg={4}>
                  <Paragraph title="Hora" required />
                  <AppSelect
                    name="flightDateHour"
                    error={
                      errors.flightDateHour && touched.flightDateHour
                        ? true
                        : false
                    }
                    fullWidth
                    value={values.flightDateHour}
                    onChange={handleChange}
                  >
                    {hours.map((el, index) => (
                      <MenuItem key={index} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                {/* Minutes */}
                <Grid item xs={12} lg={4}>
                  <Paragraph title="Minutos" required />
                  <AppSelect
                    name="flightDateMinute"
                    error={
                      errors.flightDateMinute && touched.flightDateMinute
                        ? true
                        : false
                    }
                    fullWidth
                    value={values.flightDateMinute}
                    onChange={handleChange}
                  >
                    {minutes.map((el, index) => (
                      <MenuItem key={index} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                {/* Airline */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Aerolinea" required />
                  <AppTextField
                    error={
                      errors.flightDateAirline && touched.flightDateAirline
                        ? true
                        : false
                    }
                    name="flightDateAirline"
                    value={values.flightDateAirline}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                {/* Flight# */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Numero de vuelo" required />
                  <AppTextField
                    error={
                      errors.flightDateReservationCode &&
                      touched.flightDateReservationCode
                        ? true
                        : false
                    }
                    name="flightDateReservationCode"
                    value={values.flightDateReservationCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                {/* Airport */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Aeropuerto de salida" required />
                  <AppTextField
                    error={
                      errors.flightDateAirPort && touched.flightDateAirPort
                        ? true
                        : false
                    }
                    name="flightDateAirPort"
                    value={values.flightDateAirPort}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                {/* Airport Arrived*/}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Aeropuerto de entrada" required />
                  <AppTextField
                    error={
                      errors.flightDateAirPortArrived &&
                      touched.flightDateAirPortArrived
                        ? true
                        : false
                    }
                    name="flightDateAirPortArrived"
                    value={values.flightDateAirPortArrived}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item lg={8} xs={12}>
                  <Paragraph title="Archivo" required />
                  <AppTextField
                    inputProps={{ accept: "image/*, .doc, .docx, .pdf" }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files != null) {
                        const f = event.target.files[0];
                        setFile(f);
                      }
                    }}
                    type="file"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={onClose}
                    color="customGrey"
                    variant="contained"
                    sx={{ mr: "10px", color: "white" }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    onClick={() => {
                      if (!isValid) {
                        setIsValidForm(true);
                      }
                    }}
                    disabled={isSubmitting}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ bgcolor: "secondary.main" }}
                  >
                    {isSubmitting ? <>Guardando...</> : "Guardar"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}

export default EditTripModal;
