import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import Paragraph from "../../components/Paragraph";
import AppSelect from "../../components/AppSelect";
import AppTextField from "../../components/AppTextField";
import candidateService from "../../services/CandidateService";
import utilsService from "../../services/UtilsService";
import PassportInformation from "../../models/PassportInformation";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CandidateVisaModal from "./CandidateVisaModal";
import CandidateVisa from "../../models/CandidateVisa";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  GetDateDDMMYYYYY,
  GetDateYYYYMMDD,
  handleInputChangeToUppercase,
} from "../../Utils";
import CandidateCountryVisit from "../../models/CandidateCountryVisit";
import CandidateCountryVisitModal from "./CandidateCountryVisitModal";
import { Country } from "../../models/Country";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import CandidateParticipateProgram from "../../models/CandidateParticipateProgram";
import CandidateParticipateProgramModal from "./CandidateParticipateProgramModal";
import VisaType from "../../models/VisaType";
import LoadingModal from "../../components/LoadingModal";
import { CandidateApplicationContext } from "../../screens/CandidateApplicationScreen";
import { StyledTableCell } from "../../theme/GlobalTheme";
import { ApplicationState } from "../../models/ApplicationState";
import { useAppSelector } from "../../store/store";

interface ICandidatePassportForm {
  passportvisatypeid: string;
  passportId?: string;
  passportEmissionDate?: string | null;
  passportDueDate?: string | null;
  passportBirthday?: string | null;
  passportBirthPlace?: string;
  issuingAuthority?: string;
  passportReason?: string;
  usaVisaFlag: string;
  visaNegada: string;
  visaNegadaMotivo?: string;
  visaNegadaTipoVisa?: string;
  visaNegadaAno: string;
  outCountryLastMonthsFlag: string;
  participateProgramFlag: string;
  residencePetitionFlag: string;
  residencePetitionDate?: string;
  residencePetitionCategory?: string;
}
const candidatePassportFormValidationScheme = Yup.object().shape({
  passportvisatypeid: Yup.string().required("Requerido"),
  usaVisaFlag: Yup.string().required("Requerido"),
  visaNegada: Yup.string().required("Requerido"),
  outCountryLastMonthsFlag: Yup.string().required("Requerido"),
  participateProgramFlag: Yup.string().required("Requerido"),
  residencePetitionFlag: Yup.string().required("Requerido"),
  residencePetitionDate: Yup.string().when(
    "residencePetitionFlag",
    (residencePetitionFlag: string | string[], schema) => {
      if (residencePetitionFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  residencePetitionCategory: Yup.string().when(
    "residencePetitionFlag",
    (residencePetitionFlag: string | string[], schema) => {
      if (residencePetitionFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  passportId: Yup.string().when(
    "passportvisatypeid",
    (passportvisatypeid: string | string[], schema) => {
      if (passportvisatypeid == "2") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  passportEmissionDate: Yup.string().when(
    "passportvisatypeid",
    (passportvisatypeid: string | string[], schema) => {
      if (passportvisatypeid == "2") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  passportDueDate: Yup.string().when(
    "passportvisatypeid",
    (passportvisatypeid: string | string[], schema) => {
      if (passportvisatypeid == "2") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  passportBirthday: Yup.string().when(
    "passportvisatypeid",
    (passportvisatypeid: string | string[], schema) => {
      if (passportvisatypeid == "2") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  passportBirthPlace: Yup.string().when(
    "passportvisatypeid",
    (passportvisatypeid: string | string[], schema) => {
      if (passportvisatypeid == "2") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  issuingAuthority: Yup.string().when(
    "passportvisatypeid",
    (passportvisatypeid: string | string[], schema) => {
      if (passportvisatypeid == "2") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  passportReason: Yup.string().when(
    "passportvisatypeid",
    (passportvisatypeid: string | string[], schema) => {
      if (passportvisatypeid == "2") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  visaNegadaMotivo: Yup.string().when(
    "visaNegada",
    (visaNegada: string | string[], schema) => {
      if (visaNegada == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  visaNegadaTipoVisa: Yup.string().when(
    "visaNegada",
    (visaNegada: string | string[], schema) => {
      if (visaNegada == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  visaNegadaAno: Yup.string().when(
    "visaNegada",
    (visaNegada: string | string[], schema) => {
      if (visaNegada == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

function CandidatePassportForm() {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [candidateVisaModal, setCandidateVisaModal] = useState<boolean>(false);
  const [candidateCountryVisitModal, setCandidateCountryVisitModal] =
    useState<boolean>(false);
  const [candidateVisa, setCandidateVisa] = useState<CandidateVisa>({
    candidateVisaID: 0,
    visaFrom: "",
    visaNumber: "",
    visaTo: "",
    visaType: "",
  });
  const [candidateCountryVisit, setCandidateCountryVisit] =
    useState<CandidateCountryVisit>({
      candidateCountryVisitID: 0,
      countryID: 222,
      countryName: "",
      dateFrom: "",
      dateTo: "",
      tripMotivation: "",
    });
  const [countries, setCountries] = useState<Country[]>([]);
  const [candidateVisas, setCandidateVisas] = useState<CandidateVisa[]>([]);
  const [candidateParticipatePrograms, setCandidateParticipatePrograms] =
    useState<CandidateParticipateProgram[]>([]);
  const [candidateCountryVisits, setCandidateCountryVisits] = useState<
    CandidateCountryVisit[]
  >([]);
  const [visaTypes, setVisaTypes] = useState<VisaType[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [
    candidateParticipateProgramModal,
    setCandidateParticipateProgramModal,
  ] = useState<boolean>(false);

  const [candidateParticipateProgram, setCandidateParticipateProgram] =
    useState<CandidateParticipateProgram>({
      candidateParticipateProgramID: 0,
      participateAgencyNameProgram: "",
      participateYearProgram: "",
      participateStateProgram: "",
      participateWorkPlaceProgram: "",
      participateLastWorkPositionProgram: "",
      participateCityProgram: "",
      participateSponsorTraveledProgram: "",
    });

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [initialValues, setInitialValues] = useState<ICandidatePassportForm>({
    passportvisatypeid: "",
    passportId: "",
    passportEmissionDate: "",
    passportDueDate: "",
    passportBirthday: "",
    passportBirthPlace: "",
    issuingAuthority: "",
    passportReason: "",
    usaVisaFlag: "",
    visaNegada: "",
    visaNegadaTipoVisa: "",
    visaNegadaMotivo: "",
    visaNegadaAno: "",
    outCountryLastMonthsFlag: "",
    participateProgramFlag: "",
    residencePetitionFlag: "",
    residencePetitionCategory: "",
    residencePetitionDate: "",
  });
  const { step, setStep } = useContext(CandidateApplicationContext);
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  async function submit(values: ICandidatePassportForm) {
    if (
      (appState.applicationStateId != 23 && !appState.isAdmin) ||
      window.location.href.includes("profile")
    ) {
      setStep(step + 1);
      return;
    }
    const passportInformation: PassportInformation = {
      ...values,
      residencePetitionFlag: values.residencePetitionFlag == "SI",
      usaVisaFlag: values.usaVisaFlag == "SI",
      passportvisatypeid: Number(values.passportvisatypeid),
      passportDueDate:
        values.passportDueDate != "" ? values.passportDueDate : null,
      passportEmissionDate:
        values.passportEmissionDate != "" ? values.passportEmissionDate : null,
      passportBirthday:
        values.passportBirthday != "" ? values.passportBirthday : null,
      visaNegadaAno: values.visaNegadaAno != "" ? values.visaNegadaAno : null,
      visaNegadaTipoVisa:
        values.visaNegadaTipoVisa != "" ? values.visaNegadaTipoVisa : null,
      residencePetitionDate:
        values.residencePetitionDate != ""
          ? values.residencePetitionDate
          : null,
      residencePetitionCategory:
        values.residencePetitionCategory != ""
          ? values.residencePetitionCategory
          : null,
    };

    if (values.usaVisaFlag == "SI" && candidateVisas.length == 0) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Por favor agregar al menos una visa");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    if (
      values.outCountryLastMonthsFlag == "SI" &&
      candidateCountryVisits.length == 0
    ) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Por favor agregar al menos un viaje anterior");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    if (
      values.participateProgramFlag == "SI" &&
      candidateParticipatePrograms.length == 0
    ) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc(
        "Por favor agregar al menos un programa que hayas participado"
      );
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    setIsSaving(true);
    try {
      await candidateService.savePassportInformation(passportInformation);
      setIsSaving(false);
      setStep(step + 1);
    } catch (error) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initData() {
    const passportInformation: PassportInformation =
      await candidateService.getPassportInformation();
    const country = await utilsService.getCountries();
    const visas = await candidateService.getCandidateVisas();
    const visits = await candidateService.getCandidateCountryVisits();
    const programs = await candidateService.getCandidateParticipatePrograms();
    const visasTypes = await utilsService.getVisaTypes();
    setCountries(country);
    setCandidateVisas(visas);
    setCandidateCountryVisits(visits);
    setCandidateParticipatePrograms(programs);
    setVisaTypes(visasTypes);
    setIsLoading(false);

    setInitialValues({
      passportvisatypeid:
        passportInformation.passportvisatypeid != null
          ? passportInformation.passportvisatypeid.toString()
          : "0",
      passportId: passportInformation.passportId ?? "",
      passportEmissionDate:
        passportInformation.passportEmissionDate != null
          ? GetDateYYYYMMDD(new Date(passportInformation.passportEmissionDate))
          : "",
      passportDueDate:
        passportInformation.passportDueDate != null
          ? GetDateYYYYMMDD(new Date(passportInformation.passportDueDate))
          : "",
      passportBirthday:
        passportInformation.passportBirthday != null
          ? GetDateYYYYMMDD(new Date(passportInformation.passportBirthday))
          : "",
      passportBirthPlace: passportInformation.passportBirthPlace ?? "",
      issuingAuthority: passportInformation.issuingAuthority ?? "",
      passportReason: passportInformation.passportReason ?? "",
      usaVisaFlag: visas.length > 0 ? "SI" : "NO",
      visaNegada: passportInformation.visaNegada ?? "NO",
      visaNegadaMotivo: passportInformation.visaNegadaMotivo ?? "",
      visaNegadaTipoVisa: passportInformation.visaNegadaTipoVisa ?? "",
      visaNegadaAno: passportInformation.visaNegadaAno ?? "",
      outCountryLastMonthsFlag: visits.length > 0 ? "SI" : "NO",
      participateProgramFlag: programs.length > 0 ? "SI" : "NO",
      residencePetitionFlag: passportInformation.residencePetitionFlag
        ? "SI"
        : "NO",
      residencePetitionDate:
        passportInformation.residencePetitionDate != null
          ? GetDateYYYYMMDD(new Date(passportInformation.residencePetitionDate))
          : "",
      residencePetitionCategory:
        passportInformation.residencePetitionCategory ?? "",
    });
  }

  useEffect(() => {
    initData();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box textAlign="center">
            <Typography
              display="inline-block"
              sx={{
                mb: 3,
                backgroundColor: "#00166B",
                color: "white",
                padding: "10px 30px",
                borderRadius: "10px",
                textTransform: "uppercase",
              }}
              variant="h4"
            >
              DATOS SOBRE PASAPORTE, VISAS Y VIAJES
            </Typography>
          </Box>
          {/* <Typography sx={{ mb: 3 }} variant="h4">
            DATOS SOBRE PASAPORTE, VISAS Y VIAJES
          </Typography> */}

          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={
              !window.location.href.includes("profile")
                ? candidatePassportFormValidationScheme
                : undefined
            }
            validateOnMount={true}
          >
            {(props: FormikProps<ICandidatePassportForm>) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                setFieldValue,
                isValid,
              } = props;

              return (
                <Form>
                  <Grid container spacing={1}>
                    <Box
                      bgcolor="primary"
                      sx={{
                        width: "100%",
                        bgcolor: "primary.main",
                      }}
                    >
                      <Typography sx={{ color: "white", px: 2, py: 1 }}>
                        Datos sobre el pasaporte
                      </Typography>
                    </Box>

                    {/* Tienes pasaporte*/}
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="¿Tienes pasaporte?" required />
                      <AppSelect
                        name="passportvisatypeid"
                        error={
                          errors.passportvisatypeid &&
                          touched.passportvisatypeid
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.passportvisatypeid}
                        onChange={handleChange}
                      >
                        <MenuItem value="0">NO</MenuItem>
                        <MenuItem value="2">SI</MenuItem>
                      </AppSelect>
                    </Grid>
                    {/* Numero pasaporte */}
                    {values.passportvisatypeid == "2" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Número del pasaporte" required />
                        <AppTextField
                          error={
                            errors.passportId && touched.passportId
                              ? true
                              : false
                          }
                          name="passportId"
                          value={values.passportId}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                    ) : null}
                    {/* Fecha de emision */}
                    {values.passportvisatypeid == "2" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Fecha emisión" required />
                        <AppTextField
                          type="date"
                          error={
                            errors.passportEmissionDate &&
                            touched.passportEmissionDate
                              ? true
                              : false
                          }
                          name="passportEmissionDate"
                          value={values.passportEmissionDate}
                          onChange={handleChange}
                        />
                      </Grid>
                    ) : null}

                    {/* Fecha vencimiento */}
                    {values.passportvisatypeid == "2" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Fecha vencimiento" required />
                        <AppTextField
                          type="date"
                          error={
                            errors.passportDueDate && touched.passportDueDate
                              ? true
                              : false
                          }
                          name="passportDueDate"
                          value={values.passportDueDate}
                          onChange={handleChange}
                        />
                      </Grid>
                    ) : null}

                    {/* Fecha nacimiento (en pasaporte) */}
                    {values.passportvisatypeid == "2" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Fecha nacimiento (en pasaporte)"
                          required
                        />
                        <AppTextField
                          type="date"
                          error={
                            errors.passportBirthday && touched.passportBirthday
                              ? true
                              : false
                          }
                          name="passportBirthday"
                          value={values.passportBirthday}
                          onChange={handleChange}
                        />
                      </Grid>
                    ) : null}

                    {/* Ciudad nacimiento (pasaporte)*/}
                    {values.passportvisatypeid == "2" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Ciudad nacimiento (pasaporte)"
                          required
                        />
                        <AppTextField
                          error={
                            errors.passportBirthPlace &&
                            touched.passportBirthPlace
                              ? true
                              : false
                          }
                          name="passportBirthPlace"
                          value={values.passportBirthPlace}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                    ) : null}

                    {/* Autoridad emisora de su pasaporte */}
                    {values.passportvisatypeid == "2" ? (
                      <Grid item lg={6} xs={12}>
                        <Paragraph
                          title="Autoridad emisora de su pasaporte"
                          required
                        />
                        <AppTextField
                          error={
                            errors.issuingAuthority && touched.issuingAuthority
                              ? true
                              : false
                          }
                          name="issuingAuthority"
                          value={values.issuingAuthority}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                    ) : null}

                    {/* Porque sacó usted su pasaporte */}
                    {values.passportvisatypeid == "2" ? (
                      <Grid item lg={6} xs={12}>
                        <Paragraph
                          title="¿Por qué sacó usted su pasaporte?"
                          required
                        />
                        <AppTextField
                          error={
                            errors.passportReason && touched.passportReason
                              ? true
                              : false
                          }
                          name="passportReason"
                          value={values.passportReason}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                    ) : null}

                    <Box
                      bgcolor="primary"
                      sx={{
                        width: "100%",
                        bgcolor: "primary.main",
                      }}
                    >
                      <Typography sx={{ color: "white", px: 2, py: 1 }}>
                        Información sobre visado
                      </Typography>
                    </Box>

                    {/* Visas hacia estados unidos*/}
                    <Grid item xs={12}>
                      <Paragraph
                        title="¿Posee o ha tenido alguna visa hacia Estados Unidos? "
                        required
                      />
                      <AppSelect
                        name="usaVisaFlag"
                        error={
                          errors.usaVisaFlag && touched.usaVisaFlag
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.usaVisaFlag}
                        onChange={handleChange}
                      >
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="SI">SI</MenuItem>
                      </AppSelect>
                    </Grid>
                    {/* Visas estados unidos*/}
                    {values.usaVisaFlag == "SI" ? (
                      <Grid item xs={12}>
                        {appState.applicationStateId == 23 ||
                        appState.isAdmin ? (
                          <Button
                            sx={{ mb: 2 }}
                            variant="contained"
                            onClick={() => {
                              setCandidateVisa({
                                candidateVisaID: 0,
                                visaFrom: "",
                                visaNumber: "",
                                visaTo: "",
                                visaType: "",
                              });
                              setCandidateVisaModal(true);
                            }}
                          >
                            Agregar
                          </Button>
                        ) : null}

                        <TableContainer component={Paper}>
                          <Table
                            //sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left">
                                  Tipo de visa
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Numero de visa
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Desde
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Hasta
                                </StyledTableCell>
                                <StyledTableCell align="left"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {candidateVisas.map((row) => (
                                <StyledTableRow
                                  key={row.candidateVisaID}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <StyledTableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                  >
                                    {row.visaType}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {row.visaNumber}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {GetDateDDMMYYYYY(new Date(row.visaFrom))}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {GetDateDDMMYYYYY(new Date(row.visaTo))}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {appState.applicationStateId == 23 ||
                                    appState.isAdmin ? (
                                      <Box>
                                        <IconButton
                                          sx={{ mr: 1 }}
                                          color="error"
                                          onClick={async () => {
                                            await candidateService.deleteCandidateVisa(
                                              row.candidateVisaID
                                            );
                                            const visas =
                                              await candidateService.getCandidateVisas();
                                            setCandidateVisas(visas);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                          color="success"
                                          onClick={() => {
                                            var visa: CandidateVisa = {
                                              ...row,
                                            };
                                            visa.visaFrom = GetDateYYYYMMDD(
                                              new Date(row.visaFrom)
                                            );
                                            visa.visaTo = GetDateYYYYMMDD(
                                              new Date(row.visaTo)
                                            );
                                            setCandidateVisa(visa);
                                            setCandidateVisaModal(true);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Box>
                                    ) : null}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : null}

                    {/* Visas Negada*/}
                    <Grid item xs={12}>
                      <Paragraph
                        title="¿Alguna vez te han negado un visado hacia los Estados Unidos?"
                        required
                      />
                      <AppSelect
                        name="visaNegada"
                        error={
                          errors.visaNegada && touched.visaNegada ? true : false
                        }
                        fullWidth
                        value={values.visaNegada}
                        onChange={handleChange}
                      >
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="SI">SI</MenuItem>
                      </AppSelect>
                    </Grid>
                    {/* Visas Negada Tipo*/}
                    {values.visaNegada == "SI" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Tipo de visa" required />

                        <AppSelect
                          name="visaNegadaTipoVisa"
                          error={
                            errors.visaNegadaTipoVisa &&
                            touched.visaNegadaTipoVisa
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.visaNegadaTipoVisa}
                          onChange={handleChange}
                        >
                          {visaTypes.map((el) => (
                            <MenuItem key={el.visaTypeId} value={el.name}>
                              {el.name}
                            </MenuItem>
                          ))}
                        </AppSelect>
                      </Grid>
                    ) : null}
                    {/* Visas Negada motivo*/}
                    {values.visaNegada == "SI" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Motivo" required />
                        <AppTextField
                          error={
                            errors.visaNegadaMotivo && touched.visaNegadaMotivo
                              ? true
                              : false
                          }
                          name="visaNegadaMotivo"
                          value={values.visaNegadaMotivo}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                    ) : null}
                    {/* Visas Negada año*/}
                    {values.visaNegada == "SI" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Año" required />
                        <AppTextField
                          error={
                            errors.visaNegadaAno && touched.visaNegadaAno
                              ? true
                              : false
                          }
                          placeholder="2020"
                          type="number"
                          name="visaNegadaAno"
                          value={values.visaNegadaAno}
                          onChange={handleChange}
                        />
                      </Grid>
                    ) : null}

                    <Box
                      bgcolor="primary"
                      sx={{
                        width: "100%",
                        bgcolor: "primary.main",
                      }}
                    >
                      <Typography sx={{ color: "white", px: 2, py: 1 }}>
                        Viajes anteriores fuera del país
                      </Typography>
                    </Box>

                    {/* Viaje fuera del pais*/}
                    <Grid item xs={12}>
                      <Paragraph
                        title="¿Ha estado fuera del país anteriormente? "
                        required
                      />
                      <AppSelect
                        name="outCountryLastMonthsFlag"
                        error={
                          errors.outCountryLastMonthsFlag &&
                          touched.outCountryLastMonthsFlag
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.outCountryLastMonthsFlag}
                        onChange={handleChange}
                      >
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="SI">SI</MenuItem>
                      </AppSelect>
                    </Grid>

                    {values.outCountryLastMonthsFlag == "SI" ? (
                      <Grid item xs={12}>
                        {appState.applicationStateId == 23 ||
                        appState.isAdmin ? (
                          <Button
                            sx={{ mb: 2 }}
                            variant="contained"
                            onClick={() => {
                              setCandidateCountryVisit({
                                candidateCountryVisitID: 0,
                                countryID: 222,
                                countryName: "",
                                dateFrom: "",
                                dateTo: "",
                                tripMotivation: "",
                              });
                              setCandidateCountryVisitModal(true);
                            }}
                          >
                            Agregar
                          </Button>
                        ) : null}

                        <TableContainer component={Paper}>
                          <Table
                            //sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left">
                                  País
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Desde
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Hasta
                                </StyledTableCell>
                                <StyledTableCell align="left"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {candidateCountryVisits.map((el) => (
                                <StyledTableRow
                                  key={el.candidateCountryVisitID}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <StyledTableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                  >
                                    {el.countryName}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    {GetDateDDMMYYYYY(new Date(el.dateFrom))}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {GetDateDDMMYYYYY(new Date(el.dateTo))}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {appState.applicationStateId == 23 ||
                                    appState.isAdmin ? (
                                      <Box>
                                        <IconButton
                                          sx={{ mr: 1 }}
                                          color="error"
                                          onClick={async () => {
                                            try {
                                              await candidateService.deleteCandidateCountryVisit(
                                                el.candidateCountryVisitID
                                              );
                                              const visits: CandidateCountryVisit[] =
                                                await candidateService.getCandidateCountryVisits();
                                              setCandidateCountryVisits(visits);
                                            } catch {
                                              setOpenDialog(true);
                                              setDialogTitle("Error");
                                              setDialogDesc(
                                                "Ha ocurrido un error. Por favor intente mas tarde"
                                              );
                                              setDialogButton(
                                                <Button
                                                  onClick={() =>
                                                    setOpenDialog(false)
                                                  }
                                                >
                                                  Ok
                                                </Button>
                                              );
                                            }
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                          color="success"
                                          onClick={() => {
                                            const visit: CandidateCountryVisit =
                                              {
                                                ...el,
                                                dateFrom: GetDateYYYYMMDD(
                                                  new Date(el.dateFrom)
                                                ),
                                                dateTo: GetDateYYYYMMDD(
                                                  new Date(el.dateTo)
                                                ),
                                              };
                                            setCandidateCountryVisit(visit);
                                            setCandidateCountryVisitModal(true);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Box>
                                    ) : null}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Paragraph
                        title="Has participado en algún programa de intercambio cultural hacia los Estados Unidos (Summer Work, Summer Camp ó Internship Program)?"
                        required
                      />
                      <AppSelect
                        name="participateProgramFlag"
                        error={
                          errors.participateProgramFlag &&
                          touched.participateProgramFlag
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.participateProgramFlag}
                        onChange={handleChange}
                      >
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="SI">SI</MenuItem>
                      </AppSelect>
                    </Grid>
                    {/* Participate program */}
                    {values.participateProgramFlag == "SI" ? (
                      <Grid item xs={12}>
                        {appState.applicationStateId == 23 ||
                        appState.isAdmin ? (
                          <Button
                            sx={{ mb: 2 }}
                            variant="contained"
                            onClick={() => {
                              setCandidateParticipateProgram({
                                candidateParticipateProgramID: 0,
                                participateAgencyNameProgram: "",
                                participateYearProgram: "",
                                participateStateProgram: "",
                                participateWorkPlaceProgram: "",
                                participateLastWorkPositionProgram: "",
                                participateCityProgram: "",
                                participateSponsorTraveledProgram: "",
                              });
                              setCandidateParticipateProgramModal(true);
                            }}
                          >
                            Agregar
                          </Button>
                        ) : null}

                        <TableContainer component={Paper}>
                          <Table
                            //sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left">
                                  Agencia
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Año
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Estado
                                </StyledTableCell>
                                <StyledTableCell align="left"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {candidateParticipatePrograms.map((row) => (
                                <StyledTableRow
                                  key={row.candidateParticipateProgramID}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <StyledTableCell align="left">
                                    {row.participateAgencyNameProgram}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    {row.participateYearProgram}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    {row.participateStateProgram}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    {appState.applicationStateId == 23 ||
                                    appState.isAdmin ? (
                                      <Box>
                                        <IconButton
                                          sx={{ mr: 1 }}
                                          color="error"
                                          onClick={async () => {
                                            try {
                                              await candidateService.deleteCandidateParticipateProgram(
                                                row.candidateParticipateProgramID
                                              );
                                              var programs =
                                                await candidateService.getCandidateParticipatePrograms();
                                              setCandidateParticipatePrograms(
                                                programs
                                              );
                                              setCandidateParticipateProgramModal(
                                                false
                                              );
                                            } catch {
                                              setOpenDialog(true);
                                              setDialogTitle("Error");
                                              setDialogDesc(
                                                "Ha ocurrido un error. Por favor intente mas tarde"
                                              );
                                              setDialogButton(
                                                <Button
                                                  onClick={() =>
                                                    setOpenDialog(false)
                                                  }
                                                >
                                                  Ok
                                                </Button>
                                              );
                                            }
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                          color="success"
                                          onClick={() => {
                                            setCandidateParticipateProgram(row);
                                            setCandidateParticipateProgramModal(
                                              true
                                            );
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Box>
                                    ) : null}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : null}

                    <Box
                      bgcolor="primary"
                      sx={{
                        width: "100%",
                        bgcolor: "primary.main",
                      }}
                    >
                      <Typography sx={{ color: "white", px: 2, py: 1 }}>
                        Información sobre la residencia o ciudadanía en el
                        exterior
                      </Typography>
                    </Box>
                    {/* Residence */}
                    <Grid item xs={12}>
                      <Paragraph
                        title="¿Estás en proceso de petición para residencia en Estados Unidos?"
                        required
                      />
                      <AppSelect
                        name="residencePetitionFlag"
                        error={
                          errors.residencePetitionFlag &&
                          touched.residencePetitionFlag
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.residencePetitionFlag}
                        onChange={handleChange}
                      >
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="SI">SI</MenuItem>
                      </AppSelect>
                    </Grid>

                    {values.residencePetitionFlag == "SI" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Fecha de inicio de la petición"
                          required
                        />
                        <AppTextField
                          error={
                            errors.residencePetitionDate &&
                            touched.residencePetitionDate
                              ? true
                              : false
                          }
                          type="date"
                          name="residencePetitionDate"
                          value={values.residencePetitionDate}
                          onChange={handleChange}
                        />
                      </Grid>
                    ) : null}

                    {values.residencePetitionFlag == "SI" ? (
                      <Grid item lg={3} xs={12}>
                        <Paragraph title="Número de caso" required />
                        <AppTextField
                          error={
                            errors.residencePetitionCategory &&
                            touched.residencePetitionCategory
                              ? true
                              : false
                          }
                          name="residencePetitionCategory"
                          value={values.residencePetitionCategory}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                    ) : // <Grid item lg={4} xs={12}>
                    //   <Paragraph title="Categoría de la petición" required />
                    //   <AppTextField
                    //     error={
                    //       errors.residencePetitionCategory &&
                    //       touched.residencePetitionCategory
                    //         ? true
                    //         : false
                    //     }
                    //     name="residencePetitionCategory"
                    //     value={values.residencePetitionCategory}
                    //     onChange={handleChange}
                    //   />
                    // </Grid>
                    null}

                    {/* BUTTON */}
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          setStep(step - 1);
                        }}
                        color="customGrey"
                        variant="contained"
                        sx={{ mr: "10px", color: "white" }}
                      >
                        Atras
                      </Button>
                      <Button
                        onClick={() => {
                          if (!isValid) {
                            setIsValidForm(true);
                          }
                        }}
                        disabled={isSubmitting}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{ bgcolor: "secondary.main" }}
                      >
                        {isSubmitting ? <>Guardando...</> : "Siguiente"}
                      </Button>
                    </Grid>

                    <ConfirmationDialog
                      maxWidth="sm"
                      open={isValidForm}
                      title={"Error"}
                      description={"Llenar todos los requisitos en rojo"}
                      buttons={
                        <Button onClick={() => setIsValidForm(false)}>
                          Ok
                        </Button>
                      }
                    />
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          <Dialog fullWidth={true} maxWidth="md" open={candidateVisaModal}>
            <DialogTitle>Visa de Estados Unidos</DialogTitle>
            <DialogContent>
              <CandidateVisaModal
                candidateVisa={candidateVisa}
                onSave={async (visa: CandidateVisa) => {
                  const visas = await candidateService.getCandidateVisas();
                  setCandidateVisas(visas);
                  setCandidateVisaModal(false);
                }}
                close={() => {
                  setCandidateVisaModal(false);
                }}
              />
            </DialogContent>
          </Dialog>

          {/* Candidate Country Visit Modal */}
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={candidateCountryVisitModal}
          >
            <DialogTitle>Visita otro pais</DialogTitle>
            <DialogContent>
              <CandidateCountryVisitModal
                countries={countries}
                candidateCountryVisit={candidateCountryVisit}
                onSave={async (visit: CandidateCountryVisit) => {
                  const visits: CandidateCountryVisit[] =
                    await candidateService.getCandidateCountryVisits();
                  setCandidateCountryVisits(visits);
                  setCandidateCountryVisitModal(false);
                }}
                close={() => {
                  setCandidateCountryVisitModal(false);
                }}
              />
            </DialogContent>
          </Dialog>

          {/* Candidate Participate Program */}
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={candidateParticipateProgramModal}
          >
            <DialogTitle>Programa participado</DialogTitle>
            <DialogContent>
              <CandidateParticipateProgramModal
                close={() => {
                  setCandidateParticipateProgramModal(false);
                }}
                candidateParcitipateProgram={candidateParticipateProgram}
                onSave={async (
                  candidateParticipateProgram: CandidateParticipateProgram
                ) => {
                  var programs =
                    await candidateService.getCandidateParticipatePrograms();
                  setCandidateParticipatePrograms(programs);
                  setCandidateParticipateProgramModal(false);
                }}
              />
            </DialogContent>
          </Dialog>

          <ConfirmationDialog
            maxWidth="sm"
            open={openDialog}
            title={dialogTitle}
            description={dialogDesc}
            buttons={dialogButton}
          />
        </Box>
      )}
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default CandidatePassportForm;
