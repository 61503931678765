import { configureStore } from '@reduxjs/toolkit'
import appStateReducer from "./appStateReducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';



 const  store = configureStore({
  reducer: {
    appState: appStateReducer
  },
})

export const useAppDispatch: () => typeof store.dispatch=useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>>=useSelector
export default store;