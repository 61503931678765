import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import { useEffect, useState } from "react";
import candidateService from "../services/CandidateService";
import { AccountCandidate } from "../models/AccountCandidate";
import { ApplicationState } from "../models/ApplicationState";
import { useAppDispatch, useAppSelector } from "../store/store";
import ConfirmationDialog from "../components/ConfirmationDialog";
import LoadingModal from "../components/LoadingModal";
import AuthService from "../services/AuthService";
import { login, logout } from "../store/appStateReducer";
import { useNavigate } from "react-router-dom";

function ApplicationsScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [accountCandidates, setAccountCandidates] = useState<
    AccountCandidate[]
  >([]);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [cannotReApply, setCannotReApply] = useState(false);

  const appState: ApplicationState = useAppSelector((state) => state.appState);
  var dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const navigate = useNavigate();

  async function initialData() {
    const candidates = await candidateService.getAccountCandidates();
    const cn = await candidateService.cannotReApply();
    setCannotReApply(cn);
    setAccountCandidates(candidates);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Mis Solicitudes
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Mis Solicitudes
      </Typography> */}
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <TableContainer component={Paper}>
            <Table
              //sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Temporada</StyledTableCell>
                  <StyledTableCell align="left">Nombre</StyledTableCell>
                  <StyledTableCell align="left">Apellidos</StyledTableCell>
                  <StyledTableCell align="left">Tipo</StyledTableCell>
                  <StyledTableCell align="left">Estatus</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountCandidates.map((el, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="left" component="th" scope="row">
                      {el.seasonId}
                    </StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row">
                      {el.name}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.lastName}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.applicationDescription}
                    </StyledTableCell>
                    <StyledTableCell align="left">{el.status}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {appState.canReApply ? (
            <Button
              onClick={() => {
                setOpenDialog(true);
                setDialogTitle("Confirmar");
                setDialogDesc("¿Estas seguro de que deseas re-aplicar?");
                setDialogButton([
                  <Button onClick={() => setOpenDialog(false)}>
                    Cancelar
                  </Button>,
                  <Button
                    onClick={async () => {
                      setOpenDialog(false);
                      setIsSaving(true);
                      try {
                        const token = await candidateService.reApplySeason();
                        document.cookie =
                          "userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                        dispatch(logout());

                        // var date = new Date();
                        // date.setDate(date.getDate() + 1);
                        // // date.setHours(2, 0, 0);
                        // document.cookie = `userToken=${token};expires=${date.toUTCString()};`;

                        // var state: ApplicationState | null =
                        //   await AuthService.loadUserInfo();
                        // await dispatch(login({ applicationState: state! }));

                        navigate("/login");

                        setIsSaving(false);
                      } catch (error: any) {
                        if (error.response.status == 403) {
                          setIsSaving(false);
                          setDialogTitle("¡Lo sentimos!");
                          setDialogDesc(error.response.data);
                          setOpenDialog(true);
                          setDialogButton(
                            <Button onClick={() => setOpenDialog(false)}>
                              Ok
                            </Button>
                          );
                          return;
                        }
                        setIsSaving(false);
                        setDialogTitle("Lo Sentimos");
                        setDialogDesc(
                          "Ha ocurrido un error. Por favor intente mas tarde"
                        );
                        setOpenDialog(true);
                        setDialogButton(
                          <Button onClick={() => setOpenDialog(false)}>
                            Ok
                          </Button>
                        );
                      }
                    }}
                  >
                    Ok
                  </Button>,
                ]);
              }}
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ bgcolor: "secondary.main", mt: "10px" }}
            >
              Re-Aplicar
            </Button>
          ) : null}

          {cannotReApply ? (
            <Alert style={{ fontSize: 18, marginTop: 10 }} severity="warning">
              En este momento no es posible completar tu registro. Por favor,
              acércate a nuestras instalaciones para más asistencia.
            </Alert>
          ) : null}
        </Box>
      )}
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default ApplicationsScreen;
