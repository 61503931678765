import { Box, CircularProgress, Typography } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import StepNumber from "../components/StepNumber";

import CandidateFamilyForm from "../candidateApplication/CandidateFamily/CandidateFamilyForm";
import CandidateWorkForm from "../candidateApplication/CandidateWork/CandidateWorkForm";
import CandidateEducationForm from "../candidateApplication/CandidateEducation/CandidateEducationForm";
import CandidateBasicInfoForm from "../candidateApplication/CandidateBasicInfo/CandidateBasicInfoForm";
import CandidatePassportForm from "../candidateApplication/CandidatePassport/CandidatePassportForm";
import CandidateOtherFamilyForm from "../candidateApplication/CandidateOtherFamily/CandidateOtherFamilyForm";
import CandidateOtherInformationForm from "../candidateApplication/CandidateOtherInformation/CandidateOtherInformationForm";
import CandidateRepeatOtherInformation from "../candidateApplication/CandidateRepeatOtherInformation/CandidateRepeatOtherInformation";
import { useAppSelector } from "../store/store";
import { ApplicationState } from "../models/ApplicationState";

export interface ICandidateApplicationContext {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

const defaultValue = {
  step: 1,
  setStep: (step: number) => {},
} as ICandidateApplicationContext;

export const CandidateApplicationContext = createContext(defaultValue);

function CandidateApplicationScreen() {
  const [step, setStep] = useState<number>(1);
  const steps: number[] = [1, 2, 3, 4, 5, 6, 7];
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  if (appState.isRepeating) {
    steps.push(8);
  }

  return (
    <CandidateApplicationContext.Provider value={{ step, setStep }}>
      <Box sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 5,
          }}
        >
          {steps.map((el) => (
            <StepNumber key={el} currentStep={el} isActive={step == el} />
          ))}
        </Box>
        {step == 1 ? <CandidateBasicInfoForm /> : null}
        {step == 2 ? <CandidatePassportForm /> : null}
        {step == 3 ? <CandidateWorkForm /> : null}
        {step == 4 ? <CandidateEducationForm /> : null}
        {step == 5 ? <CandidateFamilyForm /> : null}
        {step == 6 ? <CandidateOtherFamilyForm /> : null}
        {step == 7 ? <CandidateOtherInformationForm /> : null}

        {step == 8 && appState.isRepeating ? (
          <CandidateRepeatOtherInformation />
        ) : null}
      </Box>
    </CandidateApplicationContext.Provider>
  );
}

export default CandidateApplicationScreen;
