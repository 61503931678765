import { useContext, useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { Form, Formik, FormikProps } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import candidateService from "../../services/CandidateService";
import Paragraph from "../../components/Paragraph";
import AppSelect from "../../components/AppSelect";
import AppTextField from "../../components/AppTextField";
import CloseFamilyModal from "./CloseFamilyModal";
import CandidateCloseFamily from "../../models/CandidateCloseFamily";
import CandidateFamilyInformation from "../../models/CandidateFamilyInformation";
import LoadingModal from "../../components/LoadingModal";
import { CandidateApplicationContext } from "../../screens/CandidateApplicationScreen";
import { handleInputChangeToUppercase } from "../../Utils";
import { ApplicationState } from "../../models/ApplicationState";
import { useAppSelector } from "../../store/store";

interface ICandidateFamilyForm {
  flagFather: string | null;
  flagFatherFalseJustification: string | null;
  flagMother: string | null;
  flagMotherJustification: string | null;
}

const validationSchema = Yup.object().shape({
  flagFather: Yup.string().required("Requerido"),
  flagFatherFalseJustification: Yup.string().when(
    "flagFather",
    (flagFather: string | string[], schema) => {
      if (flagFather == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  flagMother: Yup.string().required("Requerido"),
  flagMotherJustification: Yup.string().when(
    "flagMother",
    (flagMother: string | string[], schema) => {
      if (flagMother == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

function CandidateFamilyForm() {
  const [initialValues, setInitialValues] = useState<ICandidateFamilyForm>({
    flagFather: "NO",
    flagFatherFalseJustification: "",
    flagMother: "NO",
    flagMotherJustification: "",
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [motherInfo, setMotherInfo] = useState<CandidateCloseFamily | null>();
  const [fatherInfo, setFatherInfo] = useState<CandidateCloseFamily | null>();
  const [closeFamilyType, setCloseFamilyType] = useState<string>("");
  const [closeFamilyModal, setCloseFamilyModal] = useState<boolean>(false);
  const { step, setStep } = useContext(CandidateApplicationContext);
  const appState: ApplicationState = useAppSelector((state) => state.appState);
  async function initialData() {
    const familyInfo = await candidateService.getFamilyInformation();
    const mother = await candidateService.getCandidateRelatedFamily("MOTHER");
    const father = await candidateService.getCandidateRelatedFamily("FATHER");

    setInitialValues({
      flagFather: familyInfo.flagFather ? "SI" : "NO",
      flagMother: familyInfo.flagMother ? "SI" : "NO",
      flagMotherJustification: familyInfo.flagMotherJustification ?? "",
      flagFatherFalseJustification:
        familyInfo.flagFatherFalseJustification ?? "",
    });
    setMotherInfo(mother);
    setFatherInfo(father);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);

  async function submit(values: ICandidateFamilyForm) {
    if (
      (appState.applicationStateId != 23 && !appState.isAdmin) ||
      window.location.href.includes("profile")
    ) {
      setStep(step + 1);
      return;
    }
    const info: CandidateFamilyInformation = {
      ...values,
      flagFather: values.flagFather == "SI",
      flagMother: values.flagMother == "SI",
    };

    if (info.flagFather == true && fatherInfo == null) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Debes the agregar datos del padres");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    if (info.flagMother == true && motherInfo == null) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Debes the agregar datos de la madre");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    try {
      setIsSaving(true);
      await candidateService.saveCandidateFamilyInfo(info);
      setIsSaving(false);
      setStep(step + 1);
    } catch {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={
            !window.location.href.includes("profile") ? validationSchema : null
          }
          validateOnMount={true}
        >
          {(props: FormikProps<ICandidateFamilyForm>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Box textAlign="center">
                  <Typography
                    display="inline-block"
                    sx={{
                      mb: 3,
                      backgroundColor: "#00166B",
                      color: "white",
                      padding: "10px 30px",
                      borderRadius: "10px",
                      textTransform: "uppercase",
                    }}
                    variant="h4"
                  >
                    DATOS FAMILIARES
                  </Typography>
                </Box>
                {/* <Typography sx={{ mb: 3 }} variant="h4">
                  DATOS FAMILIARES
                </Typography> */}
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Paragraph title="¿Agregar datos del padre? " required />
                    <AppSelect
                      name="flagFather"
                      error={
                        errors.flagFather && touched.flagFather ? true : false
                      }
                      fullWidth
                      value={values.flagFather}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>

                  {values.flagFather == "NO" ? (
                    <Grid item xs={12}>
                      <Paragraph
                        title="¿Por qué no deseas agregar los datos de tu padre?"
                        required
                      />
                      <AppTextField
                        error={
                          errors.flagFatherFalseJustification &&
                          touched.flagFatherFalseJustification
                            ? true
                            : false
                        }
                        name="flagFatherFalseJustification"
                        value={values.flagFatherFalseJustification}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {values.flagFather == "SI" ? (
                    <Grid item xs={12}>
                      {fatherInfo != null ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography>
                            {fatherInfo.name} {fatherInfo.lastName}
                          </Typography>
                          <Button
                            sx={{ fontWeight: "600" }}
                            color="secondary"
                            onClick={() => {
                              setCloseFamilyType("FATHER");
                              setCloseFamilyModal(true);
                            }}
                          >
                            Editiar
                          </Button>
                        </Box>
                      ) : appState.applicationStateId == 23 ||
                        appState.isAdmin ? (
                        <Button
                          variant="contained"
                          sx={{ fontWeight: "600" }}
                          onClick={() => {
                            setCloseFamilyType("FATHER");
                            setCloseFamilyModal(true);
                          }}
                        >
                          Agregar
                        </Button>
                      ) : null}
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Paragraph title="¿Agregar datos de la madre?" required />
                    <AppSelect
                      name="flagMother"
                      error={
                        errors.flagMother && touched.flagMother ? true : false
                      }
                      fullWidth
                      value={values.flagMother}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>

                  {values.flagMother == "NO" ? (
                    <Grid item xs={12}>
                      <Paragraph
                        title="¿Por qué no deseas agregar los datos de tu madre?"
                        required
                      />
                      <AppTextField
                        error={
                          errors.flagMotherJustification &&
                          touched.flagMotherJustification
                            ? true
                            : false
                        }
                        name="flagMotherJustification"
                        value={values.flagMotherJustification}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {values.flagMother == "SI" ? (
                    <Grid item xs={12}>
                      {motherInfo != null ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography>
                            {motherInfo.name} {motherInfo.lastName}
                          </Typography>
                          <Button
                            sx={{ fontWeight: "600" }}
                            color="secondary"
                            onClick={() => {
                              setCloseFamilyType("MOTHER");
                              setCloseFamilyModal(true);
                            }}
                          >
                            Editiar
                          </Button>
                        </Box>
                      ) : appState.applicationStateId == 23 ||
                        appState.isAdmin ? (
                        <Button
                          variant="contained"
                          sx={{ fontWeight: "600" }}
                          onClick={() => {
                            setCloseFamilyType("MOTHER");
                            setCloseFamilyModal(true);
                          }}
                        >
                          Agregar
                        </Button>
                      ) : null}
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setStep(step - 1);
                      }}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Atras
                    </Button>
                    <Button
                      onClick={() => {
                        if (!isValid) {
                          setIsValidForm(true);
                        }
                      }}
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Siguiente"}
                    </Button>
                  </Grid>

                  <ConfirmationDialog
                    maxWidth="sm"
                    open={isValidForm}
                    title={"Error"}
                    description={"Llenar todos los requisitos en rojo"}
                    buttons={
                      <Button onClick={() => setIsValidForm(false)}>Ok</Button>
                    }
                  />

                  <ConfirmationDialog
                    maxWidth="sm"
                    open={openDialog}
                    title={dialogTitle}
                    description={dialogDesc}
                    buttons={dialogButton}
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}

      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />

      <Dialog fullWidth={true} maxWidth="md" open={closeFamilyModal}>
        <DialogTitle>
          Agregar/Editar {closeFamilyType == "FATHER" ? "Padre" : "Madre"}
        </DialogTitle>
        <DialogContent>
          <CloseFamilyModal
            type={closeFamilyType}
            onSave={async () => {
              if (closeFamilyType == "FATHER") {
                const father = await candidateService.getCandidateRelatedFamily(
                  "FATHER"
                );
                setFatherInfo(father);
              }
              if (closeFamilyType == "MOTHER") {
                const mother = await candidateService.getCandidateRelatedFamily(
                  "MOTHER"
                );
                setMotherInfo(mother);
              }
              setCloseFamilyModal(false);
            }}
            close={() => {
              setCloseFamilyModal(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default CandidateFamilyForm;
