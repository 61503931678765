import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CandidateService from "../services/CandidateService";
import { CandidateJobAvailable } from "../models/CandidateJobAvailable";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import { GetDateDDMMYYYYY, currencyFormatter } from "../Utils";
import { Form, Formik, FormikProps } from "formik";
import Paragraph from "../components/Paragraph";
import AppSelect from "../components/AppSelect";
import LoadingModal from "../components/LoadingModal";
import CandidateJob from "../models/CandidateJob";
import personajeAvionPose2 from "../assets/images/personaje-avion-pose2.png";
import ConfirmationDialog from "../components/ConfirmationDialog";
import CandidateWorkRefused from "../models/CandidateWorkRefused";
import IOption from "../interfaces/IOption";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface IJobFilter {
  potision?: string;
  city?: string;
  company?: string;
}

function JobBoardScreen() {
  const [candidateJobs, setCandidateJobs] = useState<CandidateJobAvailable[]>(
    []
  );
  const [allJobs, setAllJobs] = useState<CandidateJobAvailable[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<IJobFilter>({
    city: "todos",
    company: "todos",
    potision: "todos",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [positions, setPositions] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(7);
  const [workPlaceItemId, setWorkPlaceItemId] = useState<number>(0);
  const [candidateJob, setCandidateJob] = useState<CandidateJob | null>();
  const [workRefuseds, setWorkRefuseds] = useState<CandidateWorkRefused[]>([]);
  const [confirmation2, setConfirmation2] = useState<boolean>(false);
  const [confirmation3, setConfirmation3] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<CandidateJobAvailable>();
  const [isTermOpen, setIsTermOpen] = useState<boolean>(false);

  async function initialData() {
    const jobs = await CandidateService.getCandidateJobsAvailable();
    const job = await CandidateService.getCandidateJob();
    const wfs = await CandidateService.getCandidateWorkRefuseds();

    const pos: string[] = [];
    const cits: string[] = [];
    const comps: string[] = [];

    setIsLoading(false);
    setCandidateJobs(jobs);
    setAllJobs(jobs);
    setCandidateJob(job);
    setWorkRefuseds(wfs);

    jobs
      .map((el) => el.positionName)
      .forEach((el) => {
        if (!pos.includes(el)) {
          pos.push(el);
        }
      });
    jobs
      .map((el) => el.city)
      .forEach((el) => {
        if (!cits.includes(el)) {
          cits.push(el);
        }
      });
    jobs
      .map((el) => el.companyName)
      .forEach((el) => {
        if (!comps.includes(el)) {
          comps.push(el);
        }
      });

    setPositions(pos);
    setCompanies(comps);
    setCities(cits);
  }

  async function submit(values: IJobFilter) {
    let filterJobs = [...allJobs];
    if (values.city != "todos") {
      filterJobs = filterJobs.filter((el) => el.city == values.city);
    }
    if (values.company != "todos") {
      filterJobs = filterJobs.filter((el) => el.companyName == values.company);
    }
    if (values.potision != "todos") {
      filterJobs = filterJobs.filter(
        (el) => el.positionName == values.potision
      );
    }
    setCandidateJobs(filterJobs);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  async function applyForJob() {
    try {
      setIsSaving(true);
      await CandidateService.applyForJob(workPlaceItemId);
      const job = await CandidateService.getCandidateJob();
      setCandidateJob(job);
      setIsSaving(false);
      setConfirmation3(false);
      setConfirmation2(false);
      setIsOpen(false);
    } catch (error: any) {
      setIsSaving(false);

      if (error.response.status == 403) {
        setIsSaving(false);
        setOpenDialog(true);
        setConfirmation3(false);
        setConfirmation2(false);
        setIsOpen(false);
        setDialogTitle("Error");
        setDialogDesc("Lo sentimos, ya este puesto ha sido seleccionado");
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
        return;
      }

      setIsSaving(false);
      setConfirmation3(false);
      setConfirmation2(false);
      setIsOpen(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Job Board
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Job Board
      </Typography> */}

      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          {candidateJob == null ? (
            <Box>
              <Alert severity="warning">
                Estimado participante, te invitamos a leer los detalles de la
                plaza antes de seleccionarla puesto que una vez seleccionada la
                plaza la misma no puede ser cambiada. Favor elegir una plaza
                SOLO SI ESTÁS SEGURO (A) DE LA MISMA.
              </Alert>

              <Box>
                <Formik
                  initialValues={initialValues}
                  onSubmit={submit}
                  validateOnMount={true}
                >
                  {(props: FormikProps<IJobFilter>) => {
                    const { values, touched, errors, handleChange } = props;

                    return (
                      <Form>
                        <Grid container spacing={1}>
                          <Grid item xs={12} lg={3}>
                            <Paragraph title="Posición" />
                            <AppSelect
                              name="potision"
                              error={
                                errors.potision && touched.potision
                                  ? true
                                  : false
                              }
                              fullWidth
                              value={values.potision}
                              onChange={handleChange}
                              isSearchable={true}
                              options={positions.map((el) => {
                                var option: IOption = {
                                  label: el,
                                  value: el,
                                };
                                return option;
                              })}
                            >
                              {/* <MenuItem value="todos">Todos</MenuItem>
                              {positions.map((el, index) => (
                                <MenuItem key={index} value={el}>
                                  {el}
                                </MenuItem>
                              ))} */}
                            </AppSelect>
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <Paragraph title="Ciudad" />
                            <AppSelect
                              name="city"
                              error={errors.city && touched.city ? true : false}
                              fullWidth
                              value={values.city}
                              onChange={handleChange}
                              isSearchable={true}
                              options={cities.map((el) => {
                                var option: IOption = {
                                  label: el,
                                  value: el,
                                };
                                return option;
                              })}
                            >
                              {/* <MenuItem value="todos">Todos</MenuItem>
                              {cities.map((el, index) => (
                                <MenuItem key={index} value={el}>
                                  {el}
                                </MenuItem>
                              ))} */}
                            </AppSelect>
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <Paragraph title="Compañía" />
                            <AppSelect
                              name="company"
                              error={
                                errors.company && touched.company ? true : false
                              }
                              fullWidth
                              value={values.company}
                              onChange={handleChange}
                              isSearchable={true}
                              options={companies.map((el) => {
                                var option: IOption = {
                                  label: el,
                                  value: el,
                                };
                                return option;
                              })}
                            >
                              {/* <MenuItem value="todos">Todos</MenuItem>
                              {companies.map((el, index) => (
                                <MenuItem key={index} value={el}>
                                  {el}
                                </MenuItem>
                              ))} */}
                            </AppSelect>
                          </Grid>
                          <Grid
                            display="flex"
                            alignItems="end"
                            item
                            xs={12}
                            lg={3}
                          >
                            <Button
                              sx={{ mb: "5px" }}
                              variant="contained"
                              type="submit"
                            >
                              Filtrar
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>

              <TableContainer component={Paper}>
                <Table
                  sx={{ width: 1200 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        Descripción
                      </StyledTableCell>
                      <StyledTableCell align="left">Sponsor</StyledTableCell>
                      <StyledTableCell align="left">Compañía</StyledTableCell>
                      <StyledTableCell align="left">Posición</StyledTableCell>
                      <StyledTableCell align="left">Lugar</StyledTableCell>
                      <StyledTableCell align="left">
                        Cantidad Disponible
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Incluye housing
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Pago x Hora
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Pago x Hora Extra
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Fecha Entrevista
                      </StyledTableCell>
                      <StyledTableCell align="left">Modo</StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidateJobs
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((el, index) => (
                        <StyledTableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell align="left">
                            {el.link && (
                              <Button target="_blank" href={el.link}>
                                Ver PDF
                              </Button>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {el.sponsorName}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {el.companyName}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {el.positionName}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {el.city}, {el.state}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {el.cantidad}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {el.housingIsIncluded ? (
                              <CheckCircleIcon
                                sx={{ fontSize: "25px", color: "green" }}
                              />
                            ) : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {/* {currencyFormatter.format(el.paymentByHour)} */}
                            {el.paymentByHourNuevo}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {/* {currencyFormatter.format(el.paymentByHourExtra)} */}
                            {el.paymentByHourExtraNuevo}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {el.interviewDateTime &&
                              GetDateDDMMYYYYY(new Date(el.interviewDateTime))}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {el.interviewMode && el.interviewMode}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Button
                              onClick={() => {
                                setSelectedJob(el);

                                if (el.termsAndCodition.trim() !== "") {
                                  setSelectedJob(el);
                                  setIsTermOpen(true);
                                } else {
                                  setIsOpen(true);
                                  setWorkPlaceItemId(el.workPlaceItemID);
                                }
                              }}
                              sx={{ fontSize: "12px" }}
                              variant="contained"
                            >
                              Aplicar a esta plaza
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={candidateJobs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Typography sx={{ mb: 3 }} variant="h4">
                Empleos Inactivos
              </Typography>

              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Compañía</StyledTableCell>
                      <StyledTableCell align="left">Posición</StyledTableCell>
                      <StyledTableCell align="left">Ciudad</StyledTableCell>
                      <StyledTableCell align="left">Estado</StyledTableCell>
                      <StyledTableCell align="left">Sponsor</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workRefuseds.map((el, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <StyledTableCell align="left">
                          {el.company}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {el.position}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {el.city}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {el.state}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {el.sponsor}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box>
              <Grid container>
                <Grid item lg={4} xs={12} display={{ lg: "block", xs: "none" }}>
                  <img
                    style={{
                      height: "100%",
                      maxHeight: "500px",
                      objectFit: "contain",
                      display: "block",
                      margin: "auto",
                    }}
                    src={personajeAvionPose2}
                  />
                </Grid>

                <Grid item lg={8} xs={12}>
                  <Box
                    borderRadius={4}
                    p={3}
                    color="white"
                    bgcolor="primary.main"
                  >
                    <Typography textAlign="center" mb={1} variant="h6">
                      Detalles del empleo
                    </Typography>

                    <Typography textAlign="center" mb={1} variant="h5">
                      {candidateJob.companyName}
                    </Typography>

                    <Box mb={1} display="flex">
                      <Typography mr={1} variant="body1">
                        Posición:
                      </Typography>
                      <Typography>{candidateJob.workPosition}</Typography>
                    </Box>
                    <Box mb={1} display="flex">
                      <Typography mr={1} variant="body1">
                        Sponsor:
                      </Typography>
                      <Typography>{candidateJob.sponsorName}</Typography>
                    </Box>
                    <Box mb={1} display="flex">
                      <Typography mr={1} variant="body1">
                        Pago x Hora:
                      </Typography>
                      <Typography>
                        {/* {currencyFormatter.format(candidateJob.paymentByHour)} */}
                        {candidateJob.paymentByHourNuevo}
                      </Typography>
                    </Box>
                    <Box mb={1} display="flex">
                      <Typography mr={1} variant="body1">
                        Pago x Hora Extra:
                      </Typography>
                      <Typography>
                        {/* {currencyFormatter.format(
                          candidateJob.paymentByHourExtra
                        )} */}
                        {candidateJob.paymentByHourExtraNuevo}
                      </Typography>
                    </Box>
                    <Box mb={1} display="flex">
                      <Typography mr={1} variant="body1">
                        Estatus:
                      </Typography>
                      <Typography>{candidateJob.status}</Typography>
                    </Box>

                    {candidateJob.interviewDate != null ? (
                      <Box>
                        <Box mb={1} display="flex">
                          <Typography mr={1} variant="body1">
                            Fecha de Entrevista:
                          </Typography>
                          <Typography>
                            {GetDateDDMMYYYYY(
                              new Date(candidateJob.interviewDate)
                            )}
                          </Typography>
                        </Box>

                        <Box mb={1} display="flex">
                          <Typography mr={1} variant="body1">
                            Modo:
                          </Typography>
                          <Typography>{candidateJob.interviewMode}</Typography>
                        </Box>
                      </Box>
                    ) : null}

                    {candidateJob.startDate && (
                      <Box mb={1} display="flex">
                        <Typography mr={1} variant="body1">
                          Fecha de inicio:
                        </Typography>
                        <Typography>
                          {GetDateDDMMYYYYY(new Date(candidateJob.startDate))}
                        </Typography>
                      </Box>
                    )}
                    {candidateJob.endDate && (
                      <Box mb={1} display="flex">
                        <Typography mr={1} variant="body1">
                          Fecha final:
                        </Typography>
                        <Typography>
                          {GetDateDDMMYYYYY(new Date(candidateJob.endDate))}
                        </Typography>
                      </Box>
                    )}

                    {candidateJob.jobDescriptionFile && (
                      <Box>
                        <Button
                          fullWidth
                          color="secondary"
                          variant="contained"
                          href={candidateJob.jobDescriptionFile}
                          target="_blank"
                        >
                          Ver Descripción
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      )}
      {isTermOpen && (
        <Dialog
          open={isTermOpen}
          onClose={() => {
            setIsTermOpen(false);
          }}
        >
          <DialogTitle>Terminos y Condiciones</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedJob!.termsAndCodition,
                }}
              ></div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsTermOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={async () => {
                setIsTermOpen(false);
                setIsOpen(true);
                setWorkPlaceItemId(selectedJob!.workPlaceItemID);
              }}
            >
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmación de Aplicación a la Plaza: has seleccionado la opción de
            aplicar a esta plaza de trabajo. Una vez confirmada tu selección, no
            será posible realizar cambios. Al proceder, aceptas los términos y
            condiciones establecidos por el sponsor, disponibles en la sección
            de recursos de tu plataforma. ¿Deseas continuar con la aplicación,
            teniendo esto en cuenta?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              //applyForJob();
              setIsOpen(false);
              setConfirmation2(true);
            }}
            autoFocus
          >
            Applicar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmation2}
        onClose={() => {
          setConfirmation2(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmación de Lectura de Información: confirmas haber leído y
            comprendido toda la información relacionada con esta plaza de
            trabajo. Al aplicar, aceptas los términos y condiciones, y reconoces
            que la plaza no podrá ser cambiada posteriormente, salvo
            actualizaciones o modificaciones que el sponsor considere
            necesarias.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmation2(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              //applyForJob();
              setConfirmation2(false);
              setConfirmation3(true);
            }}
            autoFocus
          >
            Applicar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmation3}
        onClose={() => {
          setConfirmation3(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmación Final de Aplicación: ¿estás seguro de que deseas
            aplicar a esta plaza? Al confirmar, declaras haber leído y aceptado
            los términos y condiciones, y estar de acuerdo con los mismos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmation3(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              applyForJob();
            }}
            autoFocus
          >
            Applicar
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}

export default JobBoardScreen;
