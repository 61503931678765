import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CandidateResource from "../models/CandidateResource";
import CandidateService from "../services/CandidateService";

interface ResourceLink {
  link: string;
  name: string;
}

function ResourcesScreen() {
  const [candidateResources, setCandidateResources] = useState<
    CandidateResource[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function initialData() {
    const resources = await CandidateService.getCandidateResources();
    setCandidateResources(resources);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);

  const resourceLinks: ResourceLink[] = [
    {
      link: "https://drive.google.com/file/d/1ta_YBmm8pTmKzIS6RP0oldrlb00VmHhd/view?usp=sharing",
      name: "CONTRATO PARTICIPACION - ACE 2024",
    },
    {
      link: "https://drive.google.com/file/d/1C5SbaSTtSz6eg98NfA2HvFu1rrqxE8z7/view",
      name: "Wlberforce",
    },
    {
      link: "https://drive.google.com/file/d/17T7MzajSRSAw0hCMxa1wboOgw5UwTYnc/view",
      name: "FOLLETO INFORMATIVO PROCESO CONSULAR",
    },
    {
      link: "https://drive.google.com/file/d/17STH1YOG8F7g062KOWBOM9isoEeOyn7R/view",
      name: "Link Estudiantes de Greenheart",
    },
    {
      link: "https://drive.google.com/file/d/1FFfvFdcc9emGtdIIdch2otMi5mDVIded/view",
      name: "Contrato de participación Ace International",
    },
    {
      link: "https://drive.google.com/file/d/1t3xkiKuND672JnRijEjkouQeBZhZKluT/view",
      name: "AAG-PreDepartureOrientation-Partner Summer",
    },
    {
      link: "https://drive.google.com/file/d/1JgiNmYbJjdue_C7FL9Opq62cltkcAG7J/view?usp=sharing",
      name: "Cuenta Dolares",
    },
    {
      link: "https://drive.google.com/file/d/151JgnLldTzGQjDUGFQSsE4FycivEFbK_/view",
      name: "Instructivo Registro de Voucher",
    },
    {
      link: "https://drive.google.com/file/d/1LE4DxHc4mD7CN8XVGVqn7UJaasvo-PLx/view",
      name: "Intructivo Descargo de Recursos y Carga de Registro de Documentos",
    },
    {
      link: "https://drive.google.com/file/d/1pexn1_Hch57aeakt5ZkmRA77FwE2V8cY/view",
      name: "Formulario Garantes",
    },
    {
      link: "https://docs.google.com/document/d/1LWJf4XxpqQVZwF3kV3RUUUE7P3E6c-DM/edit",
      name: "Modelo de Curriculum Vitae",
    },
    {
      link: "https://drive.google.com/file/d/1m26mKhiHcTPg8Fv3LOJCHh6xjuYrhWj9/view",
      name: "Requisitos del garante",
    },
  ];
  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Recursos
        </Typography>
      </Box>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <List>
          {candidateResources.map((el, index) => (
            <ListItem sx={{ mb: 2 }} key={index} disablePadding>
              <ListItemButton
                sx={{ backgroundColor: "#0000000a" }}
                href={el.url}
                target="_blank"
              >
                <ListItemText primary={el.name.toUpperCase()} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}

export default ResourcesScreen;
