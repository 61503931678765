import { Button, Grid } from "@mui/material";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import { useEffect, useState } from "react";
import CandidateVisa from "../../models/CandidateVisa";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import candidateService from "../../services/CandidateService";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import { handleInputChangeToUppercase } from "../../Utils";

interface CandidateVisaProps {
  close: () => void;
  candidateVisa: CandidateVisa;
  onSave: (candidateVisa: CandidateVisa) => void;
}

const candidateVisaValidationScheme = Yup.object().shape({
  visaFrom: Yup.string().required("Requerido"),
  visaNumber: Yup.string().required("Requerido"),
  visaTo: Yup.string().required("Requerido"),
  visaType: Yup.string().required("Requerido"),
});

function CandidateVisaModal({
  close,
  candidateVisa,
  onSave,
}: CandidateVisaProps) {
  const [initialValues, setInitialValues] =
    useState<CandidateVisa>(candidateVisa);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setInitialValues(candidateVisa);
  }, []);

  async function submit(values: CandidateVisa) {
    const visa: CandidateVisa = {
      ...values,
    };

    try {
      setIsSaving(true);
      const returnVisa = await candidateService.saveCandidateVisa(visa);
      onSave(returnVisa);
      setIsSaving(false);
    } catch {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={candidateVisaValidationScheme}
      validateOnMount={true}
    >
      {(props: FormikProps<CandidateVisa>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          isValid,
        } = props;

        return (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Paragraph title="Tipo de Visa" required />
                <AppTextField
                  error={errors.visaType && touched.visaType ? true : false}
                  name="visaType"
                  value={values.visaType}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <Paragraph title="No. de visa" required />
                <AppTextField
                  error={errors.visaNumber && touched.visaNumber ? true : false}
                  name="visaNumber"
                  value={values.visaNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <Paragraph title="Fecha Emisión" required />
                <AppTextField
                  type="date"
                  error={errors.visaFrom && touched.visaFrom ? true : false}
                  name="visaFrom"
                  value={values.visaFrom}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <Paragraph title="Fecha de vencimiento" required />
                <AppTextField
                  type="date"
                  error={errors.visaTo && touched.visaTo ? true : false}
                  name="visaTo"
                  value={values.visaTo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={close}
                  color="customGrey"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Cerrar
                </Button>
                <Button
                  onClick={() => {
                    if (!isValid) {
                      setIsValidForm(true);
                    }
                  }}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ bgcolor: "secondary.main" }}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>

            <ConfirmationDialog
              maxWidth="sm"
              open={isValidForm}
              title={"Error"}
              description={"Llenar todos los requisitos en rojo"}
              buttons={
                <Button onClick={() => setIsValidForm(false)}>Ok</Button>
              }
            />
            <ConfirmationDialog
              maxWidth="sm"
              open={openDialog}
              title={dialogTitle}
              description={dialogDesc}
              buttons={dialogButton}
            />
            <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
          </Form>
        );
      }}
    </Formik>
  );
}
export default CandidateVisaModal;
