import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import CandidateService from "../services/CandidateService";
import CandidatePreDepartureDocument from "../models/CandidatePreDepartureDocument";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import { GetDateDDMMYYYYY } from "../Utils";
import LoadingModal from "../components/LoadingModal";
import AppTextField from "../components/AppTextField";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Paragraph from "../components/Paragraph";
import SignCandidateDocument from "../models/SignCandidateDocument";

function PreDepartureScreen() {
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [preDeparture, setPreDeparture] =
    useState<CandidatePreDepartureDocument>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [preDepartureDocuments, setPreDepartureDocuments] = useState<
    CandidatePreDepartureDocument[]
  >([]);
  const [seconds, setSeconds] = useState(60);
  const [code, setCode] = useState<string>("");
  const [isCodeGenerated, setIsCodeGenerated] = useState<boolean>(false);

  async function initialData() {
    const docs = await CandidateService.getCandidatePreDepartureDocuments();
    setPreDepartureDocuments(docs);
    console.log(docs);
    setIsLoading(false);
  }

  async function GenerateCode() {
    try {
      setLoadingModal(true);
      await CandidateService.sendAndGenerateDocumentCode(
        preDeparture!.candidateDocumentId
      );
      setLoadingModal(false);
      setIsCodeGenerated(true);

      setOpenDialog(true);
      setDialogTitle("Confirmación");
      setDialogDesc(
        "Se le ha enviado un correo con el codigo de confirmación."
      );
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    } catch (error: any) {
      setLoadingModal(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function SignDocument() {
    if (code.trim().length == 0) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Debe de entrar el codigo.");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    try {
      setLoadingModal(true);

      const sign: SignCandidateDocument = {
        documentId: preDeparture!.documentId,
        candidateDocumentId: preDeparture!.candidateDocumentId,
        code: code,
      };

      await CandidateService.signCandidateDocument(sign);
      const docs = await CandidateService.getCandidatePreDepartureDocuments();
      setPreDepartureDocuments(docs);
      setLoadingModal(false);
      setIsOpenModal(false);
      setIsCodeGenerated(false);
      setCode("");
    } catch (error: any) {
      setLoadingModal(false);
      if (error.response.status == 403) {
        setOpenDialog(true);
        setDialogTitle("Error");
        setDialogDesc("El codigo no es valido");
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
        return;
      }
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Pre Departure
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Pre Departure
      </Typography> */}
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Documento</StyledTableCell>
                  <StyledTableCell align="left">Fecha Firma</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preDepartureDocuments.map((el, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="left">
                      {el.documentName}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.signDate != null
                        ? GetDateDDMMYYYYY(new Date(el.signDate))
                        : null}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {el.documentSignURL != null &&
                      el.documentSignURL != "" ? (
                        <Button href={el.documentSignURL} target="_blank">
                          {" "}
                          Ver Documento
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            if (el.signDate == null) {
                              setSeconds(60);
                            }
                            setPreDeparture(el);
                            setIsOpenModal(true);
                          }}
                        >
                          Firmar Documento
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Dialog fullWidth={true} maxWidth="lg" open={isOpenModal}>
        <DialogTitle>
          Firmar Documento{" "}
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpenModal(false);
              setIsCodeGenerated(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <iframe
            style={{
              width: "100%",
              //maxWidth: "400px",
              height: "500px",
              maxHeight: "900px",
            }}
            src={`${process.env.REACT_APP_API_BASEURL}/Documents/${preDeparture?.documentId}?candidateDocumentId=${preDeparture?.candidateDocumentId}`}
          />

          {preDeparture?.signDate == null ? (
            <Box mt={2} textAlign="center">
              {isCodeGenerated ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box sx={{ maxWidth: 400, width: "100%" }}>
                    <Paragraph title="Codigo" required />
                    <AppTextField
                      value={code}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setCode(event.target.value);
                      }}
                    />
                  </Box>
                  <Box sx={{ maxWidth: 400, width: "100%" }}>
                    {" "}
                    <Button
                      fullWidth
                      onClick={SignDocument}
                      variant="contained"
                    >
                      Firmar Documento
                    </Button>
                  </Box>
                </Box>
              ) : null}

              {seconds > 0 && !isCodeGenerated ? (
                <Typography>Generar codigo en {seconds} segundos</Typography>
              ) : null}

              {seconds == 0 && !isCodeGenerated ? (
                <Button onClick={GenerateCode} variant="contained">
                  Generar Codigo
                </Button>
              ) : null}
            </Box>
          ) : null}
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={loadingModal} loadingMessage="Guardando" />
    </Box>
  );
}
export default PreDepartureScreen;
