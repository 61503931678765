import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../theme/GlobalTheme";
import { useEffect, useState } from "react";
import LoadingModal from "../../components/LoadingModal";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import AddVoucherModal from "./AddVoucherModal";
import CandidateVoucher from "../../models/CandidateVoucher";
import candidateService from "../../services/CandidateService";
import { currencyFormatter } from "../../Utils";

function VouchersScreen() {
  const [modal, setModal] = useState<boolean>(false);
  const [vouchers, setVouchers] = useState<CandidateVoucher[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function initialData() {
    const voucher = await candidateService.getCandidateVouchers();
    setVouchers(voucher);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Vouchers
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Vouchers
      </Typography> */}

      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Button
            sx={{ mb: 2 }}
            onClick={() => {
              setModal(true);
            }}
            variant="contained"
          >
            Agregar Voucher
          </Button>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Banco</StyledTableCell>

                  <StyledTableCell align="left">
                    No. Transacción
                  </StyledTableCell>
                  <StyledTableCell align="left">Monto</StyledTableCell>
                  <StyledTableCell align="left">Tipo</StyledTableCell>
                  <StyledTableCell align="left">Estatus</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vouchers.map((el) => (
                  <StyledTableRow
                    key={el.transactionNumber}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="left">{el.name}</StyledTableCell>

                    <StyledTableCell align="left">
                      {el.transactionNumber}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currencyFormatter.format(el.transactionAmount)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.transactionTypeCode}
                    </StyledTableCell>
                    <StyledTableCell align="left">{el.status}</StyledTableCell>
                    <StyledTableCell align="left">
                      <Button
                        color="success"
                        variant="contained"
                        sx={{
                          fontSize: "10px",
                          mr: { xs: 0, lg: 1 },
                          mb: { lg: 0, xs: 1 },
                        }}
                        href={el.fileURL!}
                        target="_blank"
                      >
                        Ver
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Dialog fullWidth={true} maxWidth="md" open={modal}>
        <DialogTitle>Agregar Voucher</DialogTitle>
        <DialogContent>
          <AddVoucherModal
            onSave={async () => {
              initialData();
              setModal(false);
            }}
            close={() => {
              setModal(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default VouchersScreen;
