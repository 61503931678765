import axios from "axios";
import IRegisterForm from "../interfaces/IRegisterForm"
import { AuthEndPoints } from "../Settings/EndPoints";
import { ApplicationState } from "../models/ApplicationState";
import ILoginForm from "../interfaces/ILoginForm";
import { getCookie } from "../Utils";
import ResetPassword from "../models/ResetPassword";



class AuthService{
    async login(userLogin: ILoginForm): Promise<string | undefined>{
        var ret = undefined;

        var resp = await axios.post(AuthEndPoints.Login,userLogin);
        ret = resp.data;
        return  ret;

    }
    async register(form: IRegisterForm): Promise<boolean>{

        var resp = await axios.post(AuthEndPoints.Register,form);
        if(resp.status == 500)
        {
            return false
        }

        return true;
    }

    async activateAccount(code: string): Promise<boolean>{

        var resp = await axios.post(AuthEndPoints.ActivationCode + `?code=${code}`);
        if(resp.status == 422)
        {
            return false
        }
        return true;
    }

    async resendConfirmationEmail(email: string){
        var resp = await axios.post(AuthEndPoints.ResendConfirmationEmail + `?email=${email}`);
    }

    async loadUserInfo(): Promise<ApplicationState | null>
    {
        var token: string | null = getCookie("userToken");
        if(token == null || token.length == 0){
            return null;
        }

        var resp = await axios.get(AuthEndPoints.LoadUser)
        var data: ApplicationState = resp.data

        return data;
    }

    async forgotPassword(email: string, token: string | null) {
        var body = {
            email: email,
            token: token ?? ""
        }
        await axios.post(AuthEndPoints.ForgotPassword,body);
    }

    async resetPassword(resetPassword: ResetPassword) {
        await axios.post(AuthEndPoints.ResetPassword, resetPassword );
    }

    hasToken(): boolean{
        var ret:boolean = false;
        if(getCookie("userToken") != null){
            ret = true;
        }

        return ret;
    }
}

export default new AuthService();