import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import candidateService from "../../services/CandidateService";
import utilsService from "../../services/UtilsService";
import BankAccount from "../../models/BankAccount";
import AppSelect from "../../components/AppSelect";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import {
  DateTimePicker,
  DateTimeValidationError,
  MobileDateTimePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types";
import dayjs, { Dayjs } from "dayjs";
import CandidateVoucher from "../../models/CandidateVoucher";
import { handleInputChangeToUppercase } from "../../Utils";

const validationSchema = Yup.object().shape({
  bankAccountId: Yup.string().required("Requerido"),
  transactionTypeCode: Yup.string().required("Requerido"),
  transactionDateTime: Yup.string().required("Requerido"),
  transactionAmount: Yup.string().required("Requerido"),
  comments: Yup.string().required("Requerido"),
});

interface AddVoucherModalProps {
  close: () => void;
  onSave: () => void;
}
interface IAddVoucher {
  bankAccountId: string;
  transactionTypeCode: string;
  transactionDateTime: string;
  exchangeType: string;
  transactionAmount: string;
  transactionOffice: string;
  transactionCashier: string;
  time: Dayjs;
  comments: string;
  transactionNumber: string;
}

function AddVoucherModal({ close, onSave }: AddVoucherModalProps) {
  const [initialValues, setInitialValues] = useState<IAddVoucher>({
    bankAccountId: "",
    transactionTypeCode: "",
    transactionDateTime: "",
    exchangeType: "",
    transactionAmount: "",
    transactionOffice: "",
    transactionCashier: "",
    time: dayjs(Date.now()),
    comments: "",
    transactionNumber: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [bankAccount, setBankAccount] = useState<BankAccount>();
  const [isSimpleForm, setIsSimpleForm] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  async function submit(values: IAddVoucher) {
    if (values.transactionCashier.trim() == "" && !isSimpleForm) {
      alert("Agregar cajero");
      return;
    }
    if (values.transactionNumber.trim() == "" && isSimpleForm) {
      alert("Agregar numero de referencia");
      return;
    }
    if (values.transactionOffice.trim() == "" && !isSimpleForm) {
      alert("Agregar oficina");
      return;
    }

    if (file == undefined) {
      alert("Agregar un archivo");
      return;
    }

    const voucher: CandidateVoucher = {
      bankAccountId: bankAccount?.bankAccountId!,
      transactionTypeCode: values.transactionTypeCode,
      transactionDateTime: values.transactionDateTime,
      exchangeRateId: bankAccount?.exchangeRateId!,
      transactionAmount: Number(values.transactionAmount),
      transactionCashier: !isSimpleForm ? values.transactionCashier : "",
      transactionOffice: !isSimpleForm ? values.transactionOffice : "",
      transactionTime: !isSimpleForm ? values.time.toString() : null,
      comment: values.comments,
      transactionNumber: isSimpleForm ? values.transactionNumber : "",
    };

    //TransactionOffice.Text + "-" + TransactionCashier.Text + " " + TransactionTimeHH.Text + ":" + TransactionTimeMM.Text + ":" + TransactionTimeSS.Text;
    if (!isSimpleForm) {
      voucher.transactionNumber = `${values.transactionOffice}-${
        values.transactionCashier
      }-${values.time.hour() < 10 ? "0" : ""}${values.time.hour()}-${
        values.time.minute() < 10 ? "0" : ""
      }${values.time.minute()}-${
        values.time.second() < 10 ? "0" : ""
      }${values.time.second()}`;
    }

    try {
      setIsSaving(true);
      await candidateService.saveCandidateVoucher(voucher, file);
      setIsSaving(false);
      onSave();
    } catch (error: any) {
      if (error.response.status == 403) {
        setIsSaving(false);
        setOpenDialog(true);
        setDialogTitle("Lo sentimos");
        setDialogDesc(
          "Este voucher ya ha sido registrado. Por favor agrege otro."
        );
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
        return;
      }
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    const accounts = await utilsService.getBankAccounts();
    setBankAccounts(accounts);
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {(props: FormikProps<IAddVoucher>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            isValid,
          } = props;

          return (
            <Form>
              <Grid container spacing={1}>
                {/* Cuenta */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Cuenta" required />
                  <AppSelect
                    error={
                      errors.bankAccountId && touched.bankAccountId
                        ? true
                        : false
                    }
                    name="bankAccountId"
                    fullWidth
                    value={values.bankAccountId}
                    onChange={async (event, child) => {
                      setFieldValue("bankAccountId", event.target.value);
                      const account: BankAccount = bankAccounts.filter(
                        (el) => el.bankAccountId == event.target.value
                      )[0];
                      setBankAccount(account);
                      setFieldValue("exchangeType", account.exchangeRate);
                      setFieldValue("transactionType", "");
                    }}
                  >
                    {bankAccounts.map((el) => (
                      <MenuItem
                        key={el.bankAccountId}
                        value={el.bankAccountId.toString()}
                      >
                        {el.bankAccountName}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                {/* Tipo de transacion */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Tipo transacción" required />
                  <AppSelect
                    error={
                      errors.transactionTypeCode && touched.transactionTypeCode
                        ? true
                        : false
                    }
                    name="transactionTypeCode"
                    fullWidth
                    value={values.transactionTypeCode}
                    onChange={async (event, child) => {
                      setFieldValue("transactionTypeCode", event.target.value);
                      if (bankAccount != null) {
                        if (
                          event.target.value == "TRANSFERENCIA" &&
                          bankAccount.isSimpleTransactionForTransfer
                        ) {
                          setIsSimpleForm(true);
                        } else if (
                          event.target.value == "DEPOSITO" &&
                          bankAccount.isSimpleTransactionForDeposit
                        ) {
                          setIsSimpleForm(true);
                        } else {
                          setIsSimpleForm(false);
                        }
                      }
                    }}
                  >
                    <MenuItem value="TRANSFERENCIA">TRANSFERENCIA</MenuItem>
                    <MenuItem value="DEPÓSITO">DEPOSITO</MenuItem>
                  </AppSelect>
                </Grid>
                {/* Fecha del deposito */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Fecha del depósito" required />
                  <AppTextField
                    type="date"
                    error={
                      errors.transactionDateTime && touched.transactionDateTime
                        ? true
                        : false
                    }
                    name="transactionDateTime"
                    value={values.transactionDateTime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                {/* Tipo de Moneda */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Tipo de moneda" />
                  <AppTextField
                    disabled
                    name="exchangeType"
                    value={values.exchangeType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                {/* Amount */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Monto pagado" required />
                  <AppTextField
                    type="number"
                    error={
                      errors.transactionAmount && touched.transactionAmount
                        ? true
                        : false
                    }
                    name="transactionAmount"
                    value={values.transactionAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                {/* Office */}
                {!isSimpleForm ? (
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Oficina" required />
                    <AppTextField
                      inputProps={{ maxLength: 5 }}
                      error={
                        errors.transactionOffice && touched.transactionOffice
                          ? true
                          : false
                      }
                      name="transactionOffice"
                      value={values.transactionOffice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                ) : null}

                {/* Cajero */}
                {!isSimpleForm ? (
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Cajero" required />
                    <AppTextField
                      inputProps={{ maxLength: 5 }}
                      error={
                        errors.transactionCashier && touched.transactionCashier
                          ? true
                          : false
                      }
                      name="transactionCashier"
                      value={values.transactionCashier}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                ) : null}
                {/* Date */}
                {!isSimpleForm ? (
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Hora" required />
                    <MobileTimePicker
                      sx={{
                        width: "100%",
                        marginBottom: "5px",
                        bgcolor: "white",
                        borderRadius: "4px",
                        padding: "0px !important",
                        "& .MuiInputBase-input": {
                          padding: "8.4px 14px",
                        },
                      }}
                      views={["hours", "minutes", "seconds"]}
                      defaultValue={values.time}
                      onChange={(
                        value: Dayjs | null,
                        context: PickerChangeHandlerContext<DateTimeValidationError | null>
                      ) => {
                        setFieldValue("time", value);
                      }}
                    />
                  </Grid>
                ) : null}

                {/* Referencia */}
                {isSimpleForm ? (
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="No. de referencia" required />
                    <AppTextField
                      name="transactionNumber"
                      error={
                        errors.transactionNumber && touched.transactionNumber
                          ? true
                          : false
                      }
                      value={values.transactionNumber}
                      onChange={handleChange}
                    />
                  </Grid>
                ) : null}

                {/* Archivo */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Archivo" required />
                  <AppTextField
                    inputProps={{ accept: "image/*, .doc, .docx, .pdf" }}
                    name="file"
                    type="file"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files != null) {
                        const f = event.target.files[0];
                        setFile(f);
                      }
                    }}
                  />
                </Grid>
                {/* Comentario */}
                <Grid item xs={12}>
                  <Paragraph title="Comentario" required />
                  <AppTextField
                    name="comments"
                    error={errors.comments && touched.comments ? true : false}
                    value={values.comments}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChangeToUppercase(event, setFieldValue)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={close}
                    color="customGrey"
                    variant="contained"
                    sx={{ mr: "10px", color: "white" }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    onClick={() => {
                      if (!isValid) {
                        setIsValidForm(true);
                      }
                    }}
                    disabled={isSubmitting}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ bgcolor: "secondary.main" }}
                  >
                    {isSubmitting ? <>Guardando...</> : "Guardar"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default AddVoucherModal;
