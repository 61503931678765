import {
  Input,
  InputBaseComponentProps,
  TextField,
  TextFieldVariants,
} from "@mui/material";
import React from "react";
import { ApplicationState } from "../models/ApplicationState";
import { useAppSelector } from "../store/store";

interface AppTextFieldProps {
  variant?: TextFieldVariants;
  label?: React.ReactNode;
  required?: boolean;
  style?: React.CSSProperties;
  name?: string;
  value?: unknown;
  helperText?: React.ReactNode;
  error?: boolean | undefined;
  type?: React.HTMLInputTypeAttribute | undefined;
  inputProps?: InputBaseComponentProps | undefined;
  disabled?: boolean;
  placeholder?: string;
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
}

function AppTextField({
  variant = "outlined",
  label,
  required,
  style,
  name,
  value,
  onChange,
  onBlur,
  helperText,
  error,
  type,
  inputProps,
  disabled,
  placeholder,
}: AppTextFieldProps) {
  const appState: ApplicationState = useAppSelector((state) => state.appState);
  return (
    <TextField
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      size="small"
      variant={variant}
      label={label}
      required={required}
      style={style}
      fullWidth
      type={type}
      inputProps={inputProps}
      disabled={
        (disabled == undefined &&
          appState.applicationStateId != 24 &&
          appState.applicationStateId != 23 &&
          !appState.isAdmin &&
          (window.location.href.includes("candidateapplication") ||
            window.location.href.includes("profile"))) ||
        (window.location.href.includes("ds160") &&
          appState.dS160FileUrl != null &&
          appState.dS160FileUrl.length > 0)
          ? true
          : disabled
      }
      sx={{
        marginBottom: "5px",
        bgcolor: "white",
        borderRadius: "4px",
      }}
    ></TextField>
  );
}

export default AppTextField;
