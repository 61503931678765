import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { ChangeEvent, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import CandidateReference from "../../models/CandidateReference";
import AppTextField from "../../components/AppTextField";
import Paragraph from "../../components/Paragraph";
import { handleInputChangeToUppercase, phoneFormatter } from "../../Utils";
import AppSelect from "../../components/AppSelect";
import FamilyRelationship from "../../models/FamilyRelationship";
import candidateService from "../../services/CandidateService";
import { Country } from "../../models/Country";
import IOption from "../../interfaces/IOption";

interface ICandidateOtherRefenreceModal {
  name: string;
  secondName: string | null;
  lastName: string;
  secondLastName: string | null;
  relationText: string;
  phonePrincipal: string;
  phoneSecondary: string | null;
  email: string | null;
  countryOfResidence: string;
}

interface CandidateOtherRefenreceModalProps {
  close: () => void;
  onSave: () => void;
  candidataReference: CandidateReference;
  familyRelationships: FamilyRelationship[];
  countries: Country[];
}

const validationScheme = Yup.object().shape({
  name: Yup.string().required("Requerido"),
  lastName: Yup.string().required("Requerido"),
  relationText: Yup.string().required("Requerido"),
  phonePrincipal: Yup.string().required("Requerido"),
  countryOfResidence: Yup.string().required("Requerido"),
});

function CandidateOtherRefenreceModal({
  close,
  onSave,
  candidataReference,
  familyRelationships,
  countries,
}: CandidateOtherRefenreceModalProps) {
  const [initialValues, setInitialValues] =
    useState<ICandidateOtherRefenreceModal>({
      name: candidataReference.name,
      secondName: candidataReference.secondName,
      lastName: candidataReference.lastName,
      secondLastName: candidataReference.secondLastName,
      relationText: candidataReference.relationText,
      email: candidataReference.email,
      phonePrincipal: candidataReference.phonePrincipal,
      phoneSecondary: candidataReference.phoneSecondary,
      countryOfResidence:
        candidataReference.countryOfResidence != null &&
        candidataReference.countryOfResidence > 0
          ? candidataReference.countryOfResidence.toString()
          : "",
    });

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  async function submit(values: ICandidateOtherRefenreceModal) {
    const reference: CandidateReference = {
      candidateRelatedID: candidataReference.candidateRelatedID,
      ...values,
      countryOfResidence: Number(values.countryOfResidence),
    };

    try {
      setIsSaving(true);
      await candidateService.saveCandidateReference(reference);
      setIsSaving(false);
      onSave();
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationScheme}
        validateOnMount={true}
      >
        {(props: FormikProps<ICandidateOtherRefenreceModal>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            isValid,
          } = props;

          return (
            <Form>
              <Grid container spacing={1}>
                {/* Primer Nombre */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Primer nombre" required />
                  <AppTextField
                    error={errors.name && touched.name ? true : false}
                    name="name"
                    value={values.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChangeToUppercase(event, setFieldValue)
                    }
                  />
                </Grid>
                {/* Segundo Nombre */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Segundo nombre" />
                  <AppTextField
                    name="secondName"
                    value={values.secondName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChangeToUppercase(event, setFieldValue)
                    }
                  />
                </Grid>
                {/* Primer apellido */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Primer apellido" required />
                  <AppTextField
                    error={errors.lastName && touched.lastName ? true : false}
                    name="lastName"
                    value={values.lastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChangeToUppercase(event, setFieldValue)
                    }
                  />
                </Grid>
                {/* Segundo apellido */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Segundo apellido" />
                  <AppTextField
                    name="secondLastName"
                    value={values.secondLastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChangeToUppercase(event, setFieldValue)
                    }
                  />
                </Grid>

                {/* Parentezco */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Parentesco" required />
                  <AppSelect
                    name="relationText"
                    error={
                      errors.relationText && touched.relationText ? true : false
                    }
                    fullWidth
                    value={values.relationText}
                    onChange={handleChange}
                  >
                    {familyRelationships.map((el) => (
                      <MenuItem key={el.familyRelationshipId} value={el.name}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>

                {/* Numero de celular */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Número celular" required />
                  <AppTextField
                    error={
                      errors.phonePrincipal && touched.phonePrincipal
                        ? true
                        : false
                    }
                    name="phonePrincipal"
                    value={values.phonePrincipal}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const phoneRegex = /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                      const { value } = event.target;
                      if (phoneRegex.test(value)) {
                        const formattedPhone = phoneFormatter(value);
                        setFieldValue("phonePrincipal", formattedPhone);
                      }
                    }}
                  />
                </Grid>
                {/* Otro Numero de celular */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Otro teléfono" />
                  <AppTextField
                    name="phoneSecondary"
                    value={values.phoneSecondary}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const phoneRegex = /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                      const { value } = event.target;
                      if (phoneRegex.test(value)) {
                        const formattedPhone = phoneFormatter(value);
                        setFieldValue("phoneSecondary", formattedPhone);
                      }
                    }}
                  />
                </Grid>

                {/* Email */}
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Email" />
                  <AppTextField
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item lg={4} xs={12}>
                  <Paragraph title="País" required />
                  <AppSelect
                    name="countryOfResidence"
                    error={
                      errors.countryOfResidence && touched.countryOfResidence
                        ? true
                        : false
                    }
                    fullWidth
                    value={values.countryOfResidence}
                    onChange={handleChange}
                    isSearchable={true}
                    options={countries.map((el) => {
                      var option: IOption = {
                        label: el.name!,
                        value: el.countryId.toString(),
                      };
                      return option;
                    })}
                  >
                    {/* {countries.map((el) => (
                      <MenuItem key={el.countryId} value={el.countryId}>
                        {el.name}
                      </MenuItem>
                    ))} */}
                  </AppSelect>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={close}
                    color="customGrey"
                    variant="contained"
                    sx={{ mr: "10px", color: "white" }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    onClick={() => {
                      if (!isValid) {
                        setIsValidForm(true);
                      }
                    }}
                    disabled={isSubmitting}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ bgcolor: "secondary.main" }}
                  >
                    {isSubmitting ? <>Guardando...</> : "Guardar"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default CandidateOtherRefenreceModal;
