import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CandidateService from "../services/CandidateService";
import CandidatePaymentDone from "../models/CandidatePaymentDone";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import { GetDateDDMMYYYYY, currencyFormatter } from "../Utils";
import CandidatePaymentAccount from "../models/CandidatePaymentAccount";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

function AccountBalanceScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [candidatePaymentsDone, setCandidatePaymentsDone] = useState<
    CandidatePaymentDone[]
  >([]);
  const [paymentAccounts, setPaymentAccounts] = useState<
    CandidatePaymentAccount[]
  >([]);
  const [pendingUSDollar, setPendingUSDollar] = useState<number>(0);
  const [pendingDODollar, setPendingDODollar] = useState<number>(0);
  async function initialData() {
    const payments = await CandidateService.getCandidatePaymentDone();
    const accounts = await CandidateService.getCandidatePaymentAccounts();
    setIsLoading(false);
    setCandidatePaymentsDone(payments);
    setPaymentAccounts(accounts);

    if (accounts.length > 0) {
      const pendingPesos = accounts
        .filter((el) => el.currencyId == 1)
        .reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.amount - currentValue.amountPaid);
        }, 0);

      const pendingDollar = accounts
        .filter((el) => el.currencyId == 2)
        .reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.amount - currentValue.amountPaid);
        }, 0);

      setPendingDODollar(pendingPesos);
      setPendingUSDollar(pendingDollar);
    }
  }
  useEffect(() => {
    initialData();
  }, []);
  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Estado de Cuentas
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Box
            color="white"
            borderRadius={2}
            height={100}
            sx={{ backgroundColor: "#e17055", display: "flex" }}
          >
            <Box
              p={2}
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <MonetizationOnIcon sx={{ fontSize: "30px" }} />
              <Box
                textAlign="right"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Typography sx={{ fontSize: "30px" }}>
                  {currencyFormatter.format(pendingDODollar)}
                </Typography>
                <Typography sx={{ fontSize: "20px" }}>
                  Total adeudado en pesos
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            color="white"
            borderRadius={2}
            height={100}
            sx={{ backgroundColor: "#74b9ff", display: "flex" }}
          >
            <Box
              p={2}
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <MonetizationOnIcon sx={{ fontSize: "30px" }} />
              <Box
                textAlign="right"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Typography sx={{ fontSize: "30px" }}>
                  {currencyFormatter.format(pendingUSDollar)}
                </Typography>
                <Typography sx={{ fontSize: "20px" }}>
                  Total adeudado en dolares
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ mb: 2 }} variant="h4">
            Pagos Realizados
          </Typography>
          <TableContainer sx={{ mb: 2 }} component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Tipo de Pago</StyledTableCell>
                  <StyledTableCell align="left">Banco</StyledTableCell>
                  <StyledTableCell align="left">Moneda</StyledTableCell>
                  <StyledTableCell align="left">Monto</StyledTableCell>
                  <StyledTableCell align="left">Fecha</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {candidatePaymentsDone.map((el, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="left" component="th" scope="row">
                      {el.transactionTypeCode}
                    </StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row">
                      {el.bankAccount}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.exchangeRat}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currencyFormatter.format(el.transactionAmount)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {GetDateDDMMYYYYY(new Date(el.paymentDate))}
                    </StyledTableCell>
                    <StyledTableCell align="left">view</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ mb: 2 }} variant="h4">
            Estado de Cuentas por Pagar
          </Typography>
          <TableContainer sx={{ mb: 2 }} component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Concepto</StyledTableCell>
                  <StyledTableCell align="left">Monto</StyledTableCell>
                  <StyledTableCell align="left">Monto Pagado</StyledTableCell>
                  <StyledTableCell align="left">
                    Monto Pendiente
                  </StyledTableCell>
                  <StyledTableCell align="left">Fecha Limite</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentAccounts.map((el, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      backgroundColor:
                        el.amount - el.amountPaid <= 0
                          ? "#bce7bc !important"
                          : "",
                    }}
                  >
                    <StyledTableCell align="left" component="th" scope="row">
                      {el.description}
                    </StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row">
                      {el.currency} {currencyFormatter.format(el.amount)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currencyFormatter.format(el.amountPaid)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currencyFormatter.format(el.amount - el.amountPaid)}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {GetDateDDMMYYYYY(new Date(el.limitDate))}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
export default AccountBalanceScreen;
