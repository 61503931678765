import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Paragraph from "../../components/Paragraph";
import AppSelect from "../../components/AppSelect";
import { ChangeEvent, useContext, useState } from "react";
import CandidateWork from "../../models/CandidateWork";
import AppTextField from "../../components/AppTextField";
import utilsService from "../../services/UtilsService";
import candidateService from "../../services/CandidateService";
import Province from "../../models/Province";
import City from "../../models/City";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CandidatePreviousJobModal from "./CandidatePreviousJobModal";
import LoadingModal from "../../components/LoadingModal";
import { handleInputChangeToUppercase, phoneFormatter } from "../../Utils";
import { CandidateApplicationContext } from "../../screens/CandidateApplicationScreen";
import { StyledTableCell, StyledTableRow } from "../../theme/GlobalTheme";
import { ApplicationState } from "../../models/ApplicationState";
import { useAppSelector } from "../../store/store";

interface ICandidateWorkForm {
  isCurrentlyWorking: string;
  candidateWorkId: number;
  recentWork: string | null;
  position: string | null;
  salary: string;
  companyDirection: string | null;
  provinceId: string | null;
  cityId: string | null;
  companyNeighborhood: string | null;
  workTime: string | null;
  companyPhone: string | null;
  supervisorName: string | null;
  actualJOB: boolean | null;
  hasPreviousJobs: string;
  workFunctionality: string;
}

const candidateWorkFormValidationScheme = Yup.object().shape({
  isCurrentlyWorking: Yup.string().required("Requerido"),
  recentWork: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  position: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  companyDirection: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  provinceId: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  cityId: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  salary: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  workTime: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  companyPhone: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  workFunctionality: Yup.string().when(
    "isCurrentlyWorking",
    (isCurrentlyWorking: string | string[], schema) => {
      if (isCurrentlyWorking == "true") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

function CandidateWorkForm() {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [candidatePreviousJobModal, setCandidatePreviousJobModal] =
    useState<boolean>(false);
  const [candidateWorks, setCandidateWorks] = useState<CandidateWork[]>([]);
  const [candidateWork, setCandidateWork] = useState<CandidateWork>({
    isCurrentlyWorking: false,
    candidateWorkId: 0,
    recentWork: "",
    position: "",
    salary: 0,
    companyDirection: "",
    provinceId: 0,
    cityId: 0,
    companyNeighborhood: "",
    workTime: "",
    companyPhone: "",
    supervisorName: "",
    actualJOB: false,
  });
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [cities, setCities] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [initialValues, setInitialValues] = useState<ICandidateWorkForm>({
    isCurrentlyWorking: "false",
    candidateWorkId: 0,
    recentWork: "",
    position: "",
    salary: "",
    companyDirection: "",
    provinceId: "",
    cityId: "",
    companyNeighborhood: "",
    workTime: "",
    companyPhone: "",
    supervisorName: "",
    actualJOB: false,
    hasPreviousJobs: "NO",
    workFunctionality: "",
  });
  const { step, setStep } = useContext(CandidateApplicationContext);
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  async function initialData() {
    const province = await utilsService.getProvinces();
    const works: CandidateWork[] = await candidateService.getCandidateWorks();
    const currentWork = await candidateService.getCandidateCurrentWork();
    if (currentWork.provinceId != null && currentWork.provinceId > 0) {
      const cits = await utilsService.getCities(
        currentWork.provinceId?.toString()
      );
      setCities(cits);
    }
    setInitialValues({
      isCurrentlyWorking: currentWork.isCurrentlyWorking ? "true" : "false",
      candidateWorkId: currentWork.candidateWorkId,
      recentWork: currentWork.recentWork ?? "",
      position: currentWork.position ?? "",
      salary: currentWork.salary > 0 ? currentWork.salary.toString() : "",
      companyDirection: currentWork.companyDirection ?? "",
      provinceId:
        currentWork.provinceId != null && currentWork.provinceId > 0
          ? currentWork.provinceId.toString()
          : "",
      cityId:
        currentWork.cityId != null && currentWork.cityId > 0
          ? currentWork.cityId.toString()
          : "",
      companyNeighborhood: currentWork.companyNeighborhood ?? "",
      workTime: currentWork.workTime ?? "",
      companyPhone: currentWork.companyPhone ?? "",
      supervisorName: currentWork.supervisorName ?? "",
      actualJOB: true,
      hasPreviousJobs: works.length > 0 ? "SI" : "NO",
      workFunctionality: currentWork.workFunctionality ?? "",
    });

    setProvinces(province);
    setCandidateWorks(works);
    setIsLoading(false);
  }

  async function submit(values: ICandidateWorkForm) {
    if (
      (appState.applicationStateId != 23 && !appState.isAdmin) ||
      window.location.href.includes("profile")
    ) {
      setStep(step + 1);
      return;
    }
    const candidateWork: CandidateWork = {
      ...values,
      isCurrentlyWorking: values.isCurrentlyWorking == "true" ? true : false,
      cityId: values.cityId != "" ? Number(values.cityId) : 0,
      provinceId: values.provinceId != "" ? Number(values.provinceId) : 0,
      salary: values.salary != "" ? Number(values.salary) : 0,
    };

    if (values.hasPreviousJobs == "SI" && candidateWorks.length == 0) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Por favor agregar trabajo anterior");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    try {
      setIsSaving(true);
      const work = await candidateService.saveCandidateWork(candidateWork);
      setIsSaving(false);
      setStep(step + 1);
    } catch {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  useState(() => {
    initialData();
  });

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={
            !window.location.href.includes("profile")
              ? candidateWorkFormValidationScheme
              : undefined
          }
          validateOnMount={true}
        >
          {(props: FormikProps<ICandidateWorkForm>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Box textAlign="center">
                  <Typography
                    display="inline-block"
                    sx={{
                      mb: 3,
                      backgroundColor: "#00166B",
                      color: "white",
                      padding: "10px 30px",
                      borderRadius: "10px",
                      textTransform: "uppercase",
                    }}
                    variant="h4"
                  >
                    DATOS LABORABLES
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  {/* <Typography sx={{ mb: 3 }} variant="h4">
                    DATOS LABORABLES
                  </Typography> */}
                  <Box
                    bgcolor="primary"
                    sx={{
                      width: "100%",
                      bgcolor: "primary.main",
                    }}
                  >
                    <Typography sx={{ color: "white", px: 2, py: 1 }}>
                      Información del trabajo actual
                    </Typography>
                  </Box>

                  {/* Current Job*/}
                  <Grid item xs={12}>
                    <Paragraph title="¿Trabaja actualmente?" required />
                    <AppSelect
                      name="isCurrentlyWorking"
                      error={
                        errors.isCurrentlyWorking && touched.isCurrentlyWorking
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.isCurrentlyWorking}
                      onChange={handleChange}
                    >
                      <MenuItem value="false">NO</MenuItem>
                      <MenuItem value="true">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Company Name*/}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph
                        title="Nombre completo de la empresa"
                        required
                      />
                      <AppTextField
                        error={
                          errors.recentWork && touched.recentWork ? true : false
                        }
                        name="recentWork"
                        value={values.recentWork}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Position*/}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Posición" required />
                      <AppTextField
                        error={
                          errors.position && touched.position ? true : false
                        }
                        name="position"
                        value={values.position}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* Salario*/}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Salario mensual (RD$)" required />
                      <AppTextField
                        type="number"
                        error={errors.salary && touched.salary ? true : false}
                        name="salary"
                        value={values.salary}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : null}
                  {/* Address*/}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Dirección completa" required />
                      <AppTextField
                        error={
                          errors.companyDirection && touched.companyDirection
                            ? true
                            : false
                        }
                        name="companyDirection"
                        value={values.companyDirection}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* TODO: Add Country  */}

                  {/* Providences*/}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Provincia" required />
                      <AppSelect
                        name="provinceId"
                        error={
                          errors.provinceId && touched.provinceId ? true : false
                        }
                        fullWidth
                        value={values.provinceId}
                        onChange={async (event, child) => {
                          setFieldValue("provinceId", event.target.value);
                          var newCities = await utilsService.getCities(
                            event.target.value
                          );
                          setFieldValue("cityId", "");
                          setCities(newCities);
                        }}
                      >
                        {provinces.map((el) => (
                          <MenuItem key={el.provinceId} value={el.provinceId}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                  ) : null}

                  {/* City */}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Municipio" required />
                      <AppSelect
                        name="cityId"
                        error={errors.cityId && touched.cityId ? true : false}
                        fullWidth
                        value={values.cityId}
                        onChange={handleChange}
                      >
                        {cities.map((el) => (
                          <MenuItem key={el.cityId} value={el.cityId}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                  ) : null}
                  {/* Codigo postal */}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Código postal" />
                      <AppTextField
                        name="companyNeighborhood"
                        value={values.companyNeighborhood}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* Fecha */}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Fecha de inicio" required />
                      <AppTextField
                        type="date"
                        error={
                          errors.workTime && touched.workTime ? true : false
                        }
                        name="workTime"
                        value={values.workTime}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : null}

                  {/* Telefono */}
                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Teléfono" required />
                      <AppTextField
                        error={
                          errors.companyPhone && touched.companyPhone
                            ? true
                            : false
                        }
                        name="companyPhone"
                        value={values.companyPhone}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue("companyPhone", formattedPhone);
                          }
                        }}
                      />
                    </Grid>
                  ) : null}

                  {/* Describe work functionality */}

                  {values.isCurrentlyWorking == "true" ? (
                    <Grid item xs={12}>
                      <Paragraph
                        title="Describa brevemente sus funciones "
                        required
                      />
                      <AppTextField
                        error={
                          errors.workFunctionality && touched.workFunctionality
                            ? true
                            : false
                        }
                        name="workFunctionality"
                        value={values.workFunctionality}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* Previous Job*/}
                  <Grid item xs={12}>
                    <Paragraph title="¿Ha trabajado anteriormente?" required />
                    <AppSelect
                      name="hasPreviousJobs"
                      error={
                        errors.hasPreviousJobs && touched.hasPreviousJobs
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.hasPreviousJobs}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>

                  {values.hasPreviousJobs == "SI" ? (
                    <Grid item xs={12}>
                      {appState.applicationStateId == 23 || appState.isAdmin ? (
                        <Button
                          sx={{ mb: 2 }}
                          variant="contained"
                          onClick={() => {
                            setCandidateWork({
                              isCurrentlyWorking: false,
                              candidateWorkId: 0,
                              recentWork: "",
                              position: "",
                              salary: 0,
                              companyDirection: "",
                              provinceId: 1,
                              cityId: 153,
                              companyNeighborhood: "",
                              workTime: "",
                              companyPhone: "",
                              supervisorName: "",
                              actualJOB: false,
                            });
                            setCandidatePreviousJobModal(true);
                          }}
                        >
                          Agregar
                        </Button>
                      ) : null}

                      <TableContainer component={Paper}>
                        <Table
                          //sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                Empresa
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                Posicion
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                Salario
                              </StyledTableCell>
                              <StyledTableCell align="left"></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {candidateWorks.map((el) => (
                              <StyledTableRow
                                key={el.candidateWorkId}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell align="left">
                                  {el.recentWork}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  {el.position}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  {el.salary}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  {appState.applicationStateId == 23 ||
                                  appState.isAdmin ? (
                                    <Box>
                                      <IconButton
                                        sx={{ mr: 1 }}
                                        color="error"
                                        onClick={async () => {
                                          try {
                                            await candidateService.deleteCandidateWork(
                                              el.candidateWorkId
                                            );
                                            var works =
                                              await candidateService.getCandidateWorks();
                                            setCandidateWorks(works);
                                          } catch {
                                            setOpenDialog(true);
                                            setDialogTitle("Error");
                                            setDialogDesc(
                                              "Ha ocurrido un error. Por favor intente mas tarde"
                                            );
                                            setDialogButton(
                                              <Button
                                                onClick={() =>
                                                  setOpenDialog(false)
                                                }
                                              >
                                                Ok
                                              </Button>
                                            );
                                          }
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                      <IconButton
                                        color="success"
                                        onClick={async () => {
                                          setCandidateWork(el);
                                          setCandidatePreviousJobModal(true);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Box>
                                  ) : null}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : null}

                  {/* BUTTON */}
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setStep(step - 1);
                      }}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Atras
                    </Button>
                    <Button
                      onClick={() => {
                        if (!isValid) {
                          setIsValidForm(true);
                        }
                      }}
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Siguiente"}
                    </Button>
                  </Grid>

                  <ConfirmationDialog
                    maxWidth="sm"
                    open={isValidForm}
                    title={"Error"}
                    description={"Llenar todos los requisitos en rojo"}
                    buttons={
                      <Button onClick={() => setIsValidForm(false)}>Ok</Button>
                    }
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />

      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <Dialog fullWidth={true} maxWidth="md" open={candidatePreviousJobModal}>
        <DialogTitle>Trabajo anterior</DialogTitle>
        <DialogContent>
          <CandidatePreviousJobModal
            // city={candidatePreviousJobCity}
            // providences={providences}
            candidateWork={candidateWork}
            onSave={async (work: CandidateWork) => {
              const works = await candidateService.getCandidateWorks();
              setCandidateWorks(works);
              setCandidatePreviousJobModal(false);
            }}
            close={() => {
              setCandidatePreviousJobModal(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default CandidateWorkForm;
