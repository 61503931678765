import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import candidateService from "../services/CandidateService";
import CandidateDocument from "../models/CandidateDocument";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import AppTextField from "../components/AppTextField";
import LoadingModal from "../components/LoadingModal";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { ApplicationState } from "../models/ApplicationState";
import { useAppSelector } from "../store/store";

function DocumentsScreen() {
  const [documents, setDocuments] = useState<CandidateDocument[]>([]);
  const [employerDocs, setEmployerDocs] = useState<CandidateDocument[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modal, setModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>();
  const [doc, setDoc] = useState<CandidateDocument | null>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  async function initialData() {
    const docs = await candidateService.getCandidateDocuments();

    setIsLoading(false);
    setDocuments(docs.filter((el) => !el.isOnlyForSponsor));
    setEmployerDocs(docs.filter((el) => el.isOnlyForSponsor));
  }

  async function uploadFile() {
    if (file != null && doc != null) {
      try {
        setIsSaving(true);
        await candidateService.uploadCandidateDocument(
          doc.candidateDocumentId,
          file
        );

        setIsSaving(false);
        setModal(false);
        initialData();
      } catch (error: any) {
        setIsSaving(false);
        setOpenDialog(true);
        setDialogTitle("Error");
        setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
      }
    } else {
      alert("Por favor agregar un archivo");
    }
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Mis Documentos
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Mis Documentos
      </Typography> */}
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          {employerDocs.length > 0 ? (
            <Box>
              <Typography sx={{ mb: 2 }} variant="h4">
                Documentos Empleos
              </Typography>
              <TableContainer sx={{ mb: 2 }} component={Paper}>
                <Table
                  //sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Documento</StyledTableCell>
                      <StyledTableCell align="left">Estado</StyledTableCell>
                      <StyledTableCell align="left">Comentario</StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employerDocs.map((el) => (
                      <StyledTableRow
                        key={el.candidateDocumentId}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <StyledTableCell align="left">
                          {el.documentName}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {el.status}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {el.rejectedComment}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {el.documentUrl != "" ? (
                            <Box>
                              <Button
                                color="success"
                                variant="contained"
                                sx={{
                                  fontSize: "10px",
                                  mr: { xs: 0, lg: 1 },
                                  mb: { lg: 0, xs: 1 },
                                }}
                                href={el.documentUrl.replace(
                                  "download",
                                  "view"
                                )}
                                target="_blank"
                              >
                                Ver
                              </Button>
                              {appState.editForm ? (
                                <IconButton
                                  sx={{
                                    fontSize: "10px",
                                  }}
                                  color="error"
                                  onClick={async () => {
                                    setOpenDialog(true);
                                    setDialogTitle("Confirmar");
                                    setDialogDesc(
                                      "¿Estas seguro de que quieres eliminar el archivo?"
                                    );
                                    setDialogButton([
                                      <Button
                                        onClick={() => setOpenDialog(false)}
                                      >
                                        NO
                                      </Button>,
                                      <Button
                                        onClick={async () => {
                                          setIsSaving(true);
                                          await candidateService.updateCandidateDocument(
                                            el.candidateDocumentId
                                          );
                                          setOpenDialog(false);
                                          setIsSaving(false);
                                          initialData();
                                        }}
                                      >
                                        SI
                                      </Button>,
                                    ]);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          ) : (
                            <Button
                              sx={{
                                fontSize: "10px",
                              }}
                              onClick={() => {
                                setDoc(el);
                                setModal(true);
                              }}
                              variant="contained"
                              component="label"
                            >
                              Subir
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}

          <Typography sx={{ mb: 2 }} variant="h4">
            Documentos del Programa
          </Typography>
          <TableContainer sx={{ mb: 2 }} component={Paper}>
            <Table
              //sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Documento</StyledTableCell>
                  <StyledTableCell align="left">Estado</StyledTableCell>
                  <StyledTableCell align="left">Comentario</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((el) => (
                  <StyledTableRow
                    key={el.candidateDocumentId}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="left">
                      {el.documentName}
                    </StyledTableCell>

                    <StyledTableCell align="left">{el.status}</StyledTableCell>

                    <StyledTableCell align="left">
                      {el.rejectedComment}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.documentUrl != "" ? (
                        <Box>
                          <Button
                            color="success"
                            variant="contained"
                            sx={{
                              fontSize: "10px",
                              mr: { xs: 0, lg: 1 },
                              mb: { lg: 0, xs: 1 },
                            }}
                            href={el.documentUrl.replace("download", "view")}
                            target="_blank"
                          >
                            Ver
                          </Button>
                          {appState.editForm ? (
                            <IconButton
                              sx={{
                                fontSize: "10px",
                              }}
                              color="error"
                              onClick={async () => {
                                setOpenDialog(true);
                                setDialogTitle("Confirmar");
                                setDialogDesc(
                                  "¿Estas seguro de que quieres eliminar el archivo?"
                                );
                                setDialogButton([
                                  <Button onClick={() => setOpenDialog(false)}>
                                    NO
                                  </Button>,
                                  <Button
                                    onClick={async () => {
                                      setIsSaving(true);
                                      await candidateService.updateCandidateDocument(
                                        el.candidateDocumentId
                                      );
                                      setOpenDialog(false);
                                      setIsSaving(false);
                                      initialData();
                                    }}
                                  >
                                    SI
                                  </Button>,
                                ]);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      ) : (
                        <Button
                          sx={{
                            fontSize: "10px",
                          }}
                          onClick={() => {
                            setDoc(el);
                            setModal(true);
                          }}
                          variant="contained"
                          component="label"
                        >
                          Subir
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Dialog fullWidth={true} maxWidth="sm" open={modal}>
        <DialogTitle>Subir Documento</DialogTitle>
        <DialogContent>
          <AppTextField
            inputProps={{ accept: "image/*, .doc, .docx, .pdf" }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.files != null) {
                const f = event.target.files[0];
                setFile(f);
              }
            }}
            type="file"
          />
          <Button
            onClick={() => {
              setModal(false);
              setFile(null);
              setDoc(null);
            }}
            sx={{ mr: 1 }}
            color="customGrey"
            variant="contained"
          >
            Cerrar
          </Button>
          <Button
            onClick={() => {
              uploadFile();
            }}
            color="secondary"
            variant="contained"
          >
            Subir Archivo
          </Button>
        </DialogContent>
      </Dialog>
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
    </Box>
  );
}
export default DocumentsScreen;
