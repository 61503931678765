import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ApplicationState } from '../models/ApplicationState'
import { getCookie } from '../Utils';
import CandidateInterviewUpdate from '../models/CandidateInterviewUpdate';

const defaultState : ApplicationState = {
    isSignedIn: false,
    accountId: 0,
    candidateId: 0,
    fullName: '',
    profileId: 0,
    profileName: '',
    subsidiaryId: 0,
    isValidForDocument: false,
    editForm: false,
    mustChangePassword: false,
    applicationStateId: 0,
    applicationPhaseId: 0,
    isValidForDS160: false,
    haveToPay: false,
    isSponsor: false,
    viewGuarantor: false,
    tripDetail: false,
    tripDetailCandidateID: 0,
    jobBoard: false,
    isQRValid: false,
    subsidiary: undefined,
    interviewDate: null,
    interviewPlaceAddress: null,
    interviewType: null,
    isAdmin : false,
    isRepeating: false,
    email: "",
    profilePicture: "",
    canReApply: false,
    welcomeCallDateTime: null,
    welcomeCallNeeded : false,
    welcomeCallCompleted: false

}
const initialState: ApplicationState ={...defaultState}



export const appState = createSlice({
    name: 'appState',
    initialState: initialState,
    reducers: {
        login: (state: ApplicationState, actions: PayloadAction<{applicationState: ApplicationState}>) => {

            state.isSignedIn = true;
            state.accountId = actions.payload.applicationState.accountId;
            state.candidateId = actions.payload.applicationState.candidateId;
            state.applicationPhaseId = actions.payload.applicationState.applicationPhaseId;
            state.applicationStateId = actions.payload.applicationState.applicationStateId;
            state.editForm = actions.payload.applicationState.editForm;
            state.fullName = actions.payload.applicationState.fullName;
            state.haveToPay = actions.payload.applicationState.haveToPay;
            state.isQRValid = actions.payload.applicationState.isQRValid;
            state.isSponsor = actions.payload.applicationState.isSponsor;
            state.isValidForDS160 = actions.payload.applicationState.isValidForDS160;
            state.isValidForDocument = actions.payload.applicationState.isValidForDocument;
            state.jobBoard = actions.payload.applicationState.jobBoard;
            state.viewGuarantor = actions.payload.applicationState.viewGuarantor;
            state.tripDetailCandidateID = actions.payload.applicationState.tripDetailCandidateID;
            state.tripDetail = actions.payload.applicationState.tripDetail;
            state.subsidiaryId = actions.payload.applicationState.subsidiaryId;
            state.profileName = actions.payload.applicationState.profileName;
            state.profileId = actions.payload.applicationState.profileId;
            state.mustChangePassword = actions.payload.applicationState.mustChangePassword
            state.subsidiary = actions.payload.applicationState.subsidiary;
            state.interviewDate = actions.payload.applicationState.interviewDate;
            state.interviewPlaceAddress = actions.payload.applicationState.interviewPlaceAddress;
            state.interviewType = actions.payload.applicationState.interviewType
            state.interviewStatus = actions.payload.applicationState.interviewStatus;
            state.candidateStatusMessage =actions.payload.applicationState.candidateStatusMessage;
            state.guarantorStatus = actions.payload.applicationState.guarantorStatus;
            state.guarantorPercentage = actions.payload.applicationState.guarantorPercentage;
            state.pendingAmountDollar = actions.payload.applicationState.pendingAmountDollar;
            state.pendingAmountPesos = actions.payload.applicationState.pendingAmountPesos;
            state.returnSingatureDate = actions.payload.applicationState.returnSingatureDate;
            state.isAdmin = actions.payload.applicationState.isAdmin
            state.isRepeating = actions.payload.applicationState.isRepeating;
            state.email = actions.payload.applicationState.email;
            state.dS160FileUrl = actions.payload.applicationState.dS160FileUrl;
            state.profilePicture = actions.payload.applicationState.profilePicture;
            state.canReApply = actions.payload.applicationState.canReApply;
            state.welcomeCallDateTime = actions.payload.applicationState.welcomeCallDateTime;
            state.welcomeCallNeeded = actions.payload.applicationState.welcomeCallNeeded;
            state.welcomeCallCompleted = actions.payload.applicationState.welcomeCallCompleted;
        },

        logout: (state: ApplicationState) =>{

            state.isSignedIn = false;
            state.accountId = 0;
            state.candidateId = 0;
            state.fullName = '';
            state.profileId = 0;
            state.profileName = '';
            state.subsidiaryId = 0;
            state.isValidForDocument = false;
            state.editForm = false;
            state.mustChangePassword = false;
            state.applicationStateId = 0;
            state.applicationPhaseId = 0;
            state.isValidForDS160 = false;
            state.haveToPay = false;
            state.isSponsor = false;
            state.viewGuarantor= false;
            state.tripDetail = false;
            state.tripDetailCandidateID = 0;
            state.jobBoard = false;
            state.isQRValid = false;
            state.candidateStatusMessage = "";
            state.guarantorStatus = "";
            state.guarantorPercentage = 0;
            state.pendingAmountDollar = 0;
            state.pendingAmountPesos = 0;
            state.returnSingatureDate = ""
            state.isRepeating = false;
            state.email = "";
            state.dS160FileUrl = "";
            state.profilePicture = "";
            state.canReApply = false;
            state.welcomeCallDateTime = null;
            state.welcomeCallNeeded = false;
            state.welcomeCallCompleted = false;
            document.cookie = "userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        },
        changeInterviewDate(state: ApplicationState, actions: PayloadAction<CandidateInterviewUpdate>){

              const date: Date  = new Date(actions.payload.date)

              function setHours(dt: Date, h:string) {
                var s = /(\d+):(\d+)(.+)/.exec(h);
                dt.setHours(s![3] === "pm" ?
                  12 + parseInt(s![1], 10) :
                  parseInt(s![1], 10));
                dt.setMinutes(parseInt(s![2],10));
              }
              setHours(date, actions.payload.hour)


            state.interviewDate = date;
        }
    },
  })

  export const { login, logout, changeInterviewDate } = appState.actions

  export default appState.reducer