import { Box, CircularProgress } from "@mui/material";
import logo from "../assets/images/ace_logo_big.png";

function LoadingScreen() {
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          textAlign: "center",
          width: "100%",
        }}
      >
        <Box sx={{ mb: 5 }}>
          <img style={{ width: "100%", maxWidth: "400px" }} src={logo} />
        </Box>

        <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
      </Box>
    </Box>
  );
}

export default LoadingScreen;
