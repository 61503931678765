import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import City from "../../models/City";
import Province from "../../models/Province";
import CandidateWork from "../../models/CandidateWork";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import AppSelect from "../../components/AppSelect";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import utilsService from "../../services/UtilsService";
import candidateService from "../../services/CandidateService";
import LoadingModal from "../../components/LoadingModal";
import { handleInputChangeToUppercase, phoneFormatter } from "../../Utils";

const validationScheme = Yup.object().shape({
  recentWork: Yup.string().required("Requerido"),
  position: Yup.string().required("Requerido"),
  companyDirection: Yup.string().required("Requerido"),
  provinceId: Yup.string().required("Requerido"),
  cityId: Yup.string().required("Requerido"),
  salary: Yup.string().required("Requerido"),
  workTime: Yup.string().required("Requerido"),
  workTimeOut: Yup.string().required("Requerido"),
  companyPhone: Yup.string().required("Requerido"),
  workFunctionality: Yup.string().required("Requerido"),
});

interface CandidatePreviousJobModalProps {
  candidateWork: CandidateWork;
  onSave: (candidateWork: CandidateWork) => void;
  close: () => void;
}

function CandidatePreviousJobModal({
  candidateWork,
  onSave,
  close,
}: // city,
CandidatePreviousJobModalProps) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [cities, setCities] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [initialValues, setInitialValues] = useState<CandidateWork>({
    isCurrentlyWorking: false,
    candidateWorkId: 0,
    recentWork: "",
    position: "",
    salary: 0,
    companyDirection: "",
    provinceId: 1,
    cityId: 153,
    companyNeighborhood: "",
    workTime: "",
    companyPhone: "",
    supervisorName: "",
    actualJOB: false,
    workFunctionality: "",
  });

  async function submit(values: CandidateWork) {
    const candidateWork: CandidateWork = {
      ...values,
      isCurrentlyWorking: false,
      actualJOB: false,
    };

    try {
      setIsSaving(true);
      const work = await candidateService.saveCandidateWork(candidateWork);
      onSave(work);
      setIsSaving(false);
    } catch {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    const provs = await utilsService.getProvinces();
    const cit = await utilsService.getCities(
      candidateWork.provinceId!.toString()
    );

    setInitialValues({
      ...candidateWork,
      workTimeOut:
        candidateWork.workTimeOut != null ? candidateWork.workTimeOut : "",
      workTime: candidateWork.workTime != null ? candidateWork.workTime : "",
      workFunctionality: candidateWork.workFunctionality ?? "",
    });

    setProvinces(provs);
    setCities(cit);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box position="relative">
      {isLoading ? (
        <Box display="block" margin="auto" textAlign="center">
          <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
          <Typography variant="body1">Cargando...</Typography>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationScheme}
          validateOnMount={true}
        >
          {(props: FormikProps<CandidateWork>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Grid container spacing={1}>
                  {/* Company Name*/}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Nombre completo de la empresa" required />
                    <AppTextField
                      error={
                        errors.recentWork && touched.recentWork ? true : false
                      }
                      name="recentWork"
                      value={values.recentWork}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>

                  {/* Position*/}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Posición" required />
                    <AppTextField
                      error={errors.position && touched.position ? true : false}
                      name="position"
                      value={values.position}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>

                  {/* Salario*/}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Salario mensual (RD$)" required />
                    <AppTextField
                      type="number"
                      error={errors.salary && touched.salary ? true : false}
                      name="salary"
                      value={values.salary}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Address*/}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Dirección completa" required />
                    <AppTextField
                      error={
                        errors.companyDirection && touched.companyDirection
                          ? true
                          : false
                      }
                      name="companyDirection"
                      value={values.companyDirection}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* TODO: Add Country  */}

                  {/* Provincia*/}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Provincia" required />
                    <AppSelect
                      name="provinceId"
                      error={
                        errors.provinceId && touched.provinceId ? true : false
                      }
                      fullWidth
                      value={values.provinceId}
                      onChange={async (event, child) => {
                        setFieldValue("provinceId", event.target.value);
                        var newCities = await utilsService.getCities(
                          event.target.value
                        );
                        setCities(newCities);
                        setFieldValue("cityId", "");
                      }}
                    >
                      {provinces.map((el) => (
                        <MenuItem key={el.provinceId} value={el.provinceId}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </AppSelect>
                  </Grid>

                  {/* City */}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Municipio" required />
                    <AppSelect
                      name="cityId"
                      error={errors.cityId && touched.cityId ? true : false}
                      fullWidth
                      value={values.cityId}
                      onChange={handleChange}
                    >
                      {cities.map((el) => (
                        <MenuItem key={el.cityId} value={el.cityId}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </AppSelect>
                  </Grid>

                  {/* Codigo postal */}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Código postal (opcional)" />
                    <AppTextField
                      name="companyNeighborhood"
                      value={values.companyNeighborhood}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>

                  {/* Fecha inicio */}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Fecha de inicio" required />
                    <AppTextField
                      type="date"
                      error={errors.workTime && touched.workTime ? true : false}
                      name="workTime"
                      value={values.workTime}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Fecha salida */}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Fecha de salida" required />
                    <AppTextField
                      type="date"
                      error={
                        errors.workTimeOut && touched.workTimeOut ? true : false
                      }
                      name="workTimeOut"
                      value={values.workTimeOut}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Telefono */}

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Teléfono" required />
                    <AppTextField
                      error={
                        errors.companyPhone && touched.companyPhone
                          ? true
                          : false
                      }
                      name="companyPhone"
                      value={values.companyPhone}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const phoneRegex = /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                        const { value } = event.target;
                        if (phoneRegex.test(value)) {
                          const formattedPhone = phoneFormatter(value);
                          setFieldValue("companyPhone", formattedPhone);
                        }
                      }}
                    />
                  </Grid>

                  {/* Describe work functionality */}

                  <Grid item lg={8} xs={12}>
                    <Paragraph
                      title="Describa brevemente sus funciones "
                      required
                    />
                    <AppTextField
                      error={
                        errors.workFunctionality && touched.workFunctionality
                          ? true
                          : false
                      }
                      name="workFunctionality"
                      value={values.workFunctionality}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* BUTTON */}
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Button
                        onClick={close}
                        color="customGrey"
                        variant="contained"
                        sx={{ mr: "10px", color: "white" }}
                      >
                        Cerrar
                      </Button>
                      <Button
                        onClick={() => {
                          if (!isValid) {
                            setIsValidForm(true);
                          }
                        }}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{ bgcolor: "secondary.main" }}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>

                  <ConfirmationDialog
                    maxWidth="sm"
                    open={isValidForm}
                    title={"Error"}
                    description={"Llenar todos los requisitos en rojo"}
                    buttons={
                      <Button onClick={() => setIsValidForm(false)}>Ok</Button>
                    }
                  />

                  <LoadingModal
                    isLoading={isSaving}
                    loadingMessage="Guardando"
                  />

                  <ConfirmationDialog
                    maxWidth="sm"
                    open={openDialog}
                    title={dialogTitle}
                    description={dialogDesc}
                    buttons={dialogButton}
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
}

export default CandidatePreviousJobModal;
