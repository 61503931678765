import { Form, Formik, FormikProps } from "formik";
import { CandidateEducationInterruption } from "../../models/CandidateEducationInterruption";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import candidateService from "../../services/CandidateService";
import LoadingModal from "../../components/LoadingModal";
import { handleInputChangeToUppercase } from "../../Utils";

interface EducationInterruptionModalProps {
  interruption: CandidateEducationInterruption;
  onSave: (interruption: CandidateEducationInterruption) => void;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  dateFrom: Yup.string().required("Requerido"),
  dateTo: Yup.string().required("Requerido"),
  justification: Yup.string().required("Requerido"),
});

function EducationInterruptionModal({
  interruption,
  onSave,
  close,
}: EducationInterruptionModalProps) {
  const [initialValues, setInitialValues] =
    useState<CandidateEducationInterruption>(interruption);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();

  async function submit(values: CandidateEducationInterruption) {
    const interrupt: CandidateEducationInterruption = {
      ...values,
    };

    try {
      setIsSaving(true);
      const saveInterrupt =
        await candidateService.saveCandidateEducationInterruption(interrupt);
      onSave(saveInterrupt);
      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {(props: FormikProps<CandidateEducationInterruption>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          isValid,
        } = props;

        return (
          <Form>
            <Grid container spacing={1}>
              <Grid item lg={6} xs={12}>
                <Paragraph title="Fecha desde" required />
                <AppTextField
                  type="date"
                  error={errors.dateFrom && touched.dateFrom ? true : false}
                  name="dateFrom"
                  value={values.dateFrom}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Paragraph title="Fecha hasta" required />
                <AppTextField
                  type="date"
                  error={errors.dateTo && touched.dateTo ? true : false}
                  name="dateTo"
                  value={values.dateTo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Paragraph
                  title="¿Cual fue el motivo de la interrupción?"
                  required
                />
                <AppTextField
                  error={
                    errors.justification && touched.justification ? true : false
                  }
                  name="justification"
                  value={values.justification}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={close}
                  color="customGrey"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Cerrar
                </Button>
                <Button
                  onClick={() => {
                    if (!isValid) {
                      setIsValidForm(true);
                    }
                  }}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ bgcolor: "secondary.main" }}
                >
                  Guardar
                </Button>
              </Grid>

              <ConfirmationDialog
                maxWidth="sm"
                open={isValidForm}
                title={"Error"}
                description={"Llenar todos los requisitos en rojo"}
                buttons={
                  <Button onClick={() => setIsValidForm(false)}>Ok</Button>
                }
              />
              <ConfirmationDialog
                maxWidth="sm"
                open={openDialog}
                title={dialogTitle}
                description={dialogDesc}
                buttons={dialogButton}
              />
              <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
export default EducationInterruptionModal;
