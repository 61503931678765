import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import animation404 from "../assets/lottie/404.json";

interface ErrorScreenProps {
  title: string | JSX.Element;
}

function ErrorScreen({ title }: ErrorScreenProps) {
  return (
    <Box textAlign="center">
      <Box mb={10} style={{ marginTop: "20px" }}>
        {title}
      </Box>
      <Lottie
        style={{ maxWidth: 500, width: "100%", display: "inline-block" }}
        animationData={animation404}
      />
    </Box>
  );
}
export default ErrorScreen;
