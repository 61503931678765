import { Button, Grid } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import CandidateParticipateProgram from "../../models/CandidateParticipateProgram";
import { useState } from "react";
import * as Yup from "yup";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import candidateService from "../../services/CandidateService";
import LoadingModal from "../../components/LoadingModal";
import { handleInputChangeToUppercase } from "../../Utils";

interface CandidateParticipateProgramModalProps {
  close: () => void;
  candidateParcitipateProgram: CandidateParticipateProgram;
  onSave: (candidateParcitipateProgram: CandidateParticipateProgram) => void;
}

const candidateParcitipateProgramValidationScheme = Yup.object().shape({
  participateAgencyNameProgram: Yup.string().required("Requerido"),
  participateYearProgram: Yup.string().required("Requerido"),
  participateStateProgram: Yup.string().required("Requerido"),
  participateWorkPlaceProgram: Yup.string().required("Requerido"),
  participateLastWorkPositionProgram: Yup.string().required("Requerido"),
  participateCityProgram: Yup.string().required("Requerido"),
  participateSponsorTraveledProgram: Yup.string().required("Requerido"),
});

function CandidateParticipateProgramModal({
  close,
  candidateParcitipateProgram,
  onSave,
}: CandidateParticipateProgramModalProps) {
  const [initialValues, setInitialValues] =
    useState<CandidateParticipateProgram>(candidateParcitipateProgram);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function submit(values: CandidateParticipateProgram) {
    const program: CandidateParticipateProgram = {
      ...values,
    };

    try {
      setIsSaving(true);
      const returnProgram =
        await candidateService.saveCandidateParticipateProgram(program);

      onSave(returnProgram);
      setIsSaving(false);
    } catch {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={candidateParcitipateProgramValidationScheme}
    >
      {(props: FormikProps<CandidateParticipateProgram>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          setFieldValue,
        } = props;

        return (
          <Form>
            <Grid container spacing={1}>
              <Grid item lg={4} xs={12}>
                <Paragraph title="Agencia con la que participó" required />
                <AppTextField
                  error={
                    errors.participateAgencyNameProgram &&
                    touched.participateAgencyNameProgram
                      ? true
                      : false
                  }
                  name="participateAgencyNameProgram"
                  value={values.participateAgencyNameProgram}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <Paragraph title="Año en el que participó" required />
                <AppTextField
                  placeholder="2020"
                  error={
                    errors.participateYearProgram &&
                    touched.participateYearProgram
                      ? true
                      : false
                  }
                  name="participateYearProgram"
                  value={values.participateYearProgram}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <Paragraph title="Estado donde trabajó" required />
                <AppTextField
                  error={
                    errors.participateStateProgram &&
                    touched.participateStateProgram
                      ? true
                      : false
                  }
                  name="participateStateProgram"
                  value={values.participateStateProgram}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <Paragraph title="Nombre de la empresa donde laboró" required />
                <AppTextField
                  error={
                    errors.participateWorkPlaceProgram &&
                    touched.participateWorkPlaceProgram
                      ? true
                      : false
                  }
                  name="participateWorkPlaceProgram"
                  value={values.participateWorkPlaceProgram}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <Paragraph title="Posición" required />
                <AppTextField
                  error={
                    errors.participateLastWorkPositionProgram &&
                    touched.participateLastWorkPositionProgram
                      ? true
                      : false
                  }
                  name="participateLastWorkPositionProgram"
                  value={values.participateLastWorkPositionProgram}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <Paragraph title="Ciudad donde trabajó" required />
                <AppTextField
                  error={
                    errors.participateCityProgram &&
                    touched.participateCityProgram
                      ? true
                      : false
                  }
                  name="participateCityProgram"
                  value={values.participateCityProgram}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <Paragraph title="Sponsor con el cuál viajó" required />
                <AppTextField
                  error={
                    errors.participateSponsorTraveledProgram &&
                    touched.participateSponsorTraveledProgram
                      ? true
                      : false
                  }
                  name="participateSponsorTraveledProgram"
                  value={values.participateSponsorTraveledProgram}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={close}
                  color="customGrey"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Cerrar
                </Button>
                <Button
                  onClick={() => {
                    if (!isValid) {
                      setIsValidForm(true);
                    }
                  }}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ bgcolor: "secondary.main" }}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
            <ConfirmationDialog
              maxWidth="sm"
              open={isValidForm}
              title={"Error"}
              description={"Llenar todos los requisitos en rojo"}
              buttons={
                <Button onClick={() => setIsValidForm(false)}>Ok</Button>
              }
            />
            <ConfirmationDialog
              maxWidth="sm"
              open={openDialog}
              title={dialogTitle}
              description={dialogDesc}
              buttons={dialogButton}
            />
            <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
          </Form>
        );
      }}
    </Formik>
  );
}

export default CandidateParticipateProgramModal;
