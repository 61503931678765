import Button from "@mui/material/Button";
import {
  Container,
  Typography,
  Link,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import AppTextField from "../components/AppTextField";
import logo from "../assets/images/ace_logo_big.png";
import logoBlue from "../assets/images/ace-logo-blue.jpg";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import ErrorText from "../components/ErrorText";
import ILoginForm from "../interfaces/ILoginForm";
import { useState } from "react";
import ConfirmationDialog from "../components/ConfirmationDialog";
import authService from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { ApplicationState } from "../models/ApplicationState";
import { login } from "../store/appStateReducer";
import { useAppDispatch } from "../store/store";
import LoadingModal from "../components/LoadingModal";
import { setBodyColor } from "../Utils";
import Paragraph from "../components/Paragraph";

const loginScheme = Yup.object().shape({
  password: Yup.string().min(5).required("Requerido"),
  email: Yup.string().email("Email invalido").required("Requerido"),
});

function LoginScreen() {
  var [isLoading, setIsLoading] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  var [modalTitle, setModalTitle] = useState<string>("Entrando...");

  var dispatch = useAppDispatch();

  var navigate = useNavigate();

  async function submit(values: ILoginForm) {
    setIsLoading(true);

    try {
      setModalTitle("Entrando...");
      var token: string | undefined = await authService.login(values);
      var date = new Date();
      date.setDate(date.getDate() + 1);
      // date.setHours(2, 0, 0);
      document.cookie = `userToken=${token};expires=${date.toUTCString()};`;

      var applicationState: ApplicationState | null =
        await authService.loadUserInfo();

      if (applicationState != null) {
        await dispatch(login({ applicationState: applicationState }));

        if (applicationState.applicationStateId == 23) {
          navigate("/candidateapplication");
        } else {
          navigate("/");
        }

        setBodyColor("#FFFFFF");
        document.body.classList.remove("body-custom-bg");
      }
    } catch (error: any) {
      setIsLoading(false);

      if (error.response == undefined) {
        setDialogTitle("¡Lo sentimos!");
        setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
        setOpenDialog(true);
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
      }
      if (error.response.status == 401) {
        setDialogTitle("¡Lo sentimos!");
        setDialogDesc("Email o contraseña inválido, favor intente nuevamente.");
        setOpenDialog(true);
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
      } else if (error.response.status == 403) {
        setDialogTitle("¡Lo sentimos!");
        setDialogDesc("Debe Activar su cuenta");
        setOpenDialog(true);
        setDialogButton([
          <Button
            key={1}
            onClick={async () => {
              setOpenDialog(false);
              setIsLoading(true);
              setModalTitle("Enviando...");

              try {
                await authService.resendConfirmationEmail(values.email);

                setIsLoading(false);
                setDialogTitle("Confirmación");
                setDialogDesc("La confirmación ha sido enviada a su correo");
                setOpenDialog(true);
                setDialogButton(
                  <Button onClick={() => setOpenDialog(false)}>Ok</Button>
                );
              } catch (error) {
                setIsLoading(false);
                setDialogTitle("¡Lo sentimos!");
                setDialogDesc(
                  "Ha ocurrido un error. Por favor intente mas tarde"
                );
                setOpenDialog(true);
                setDialogButton(
                  <Button onClick={() => setOpenDialog(false)}>Ok</Button>
                );
              }
            }}
          >
            Enviar Confirmación
          </Button>,
          <Button key={2} onClick={() => setOpenDialog(false)}>
            Cerrar
          </Button>,
        ]);
      } else {
        setDialogTitle("Lo Sentimos");
        setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
        setOpenDialog(true);
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
      }
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        maxWidth: { lg: "570px", xs: "100%" },
        margin: "auto",
        width: "100%",
        backgroundColor: "white",
        padding: 2,
        borderRadius: 5,
        position: { lg: "absolute" },
        top: { lg: "50%" },
        left: { lg: "50%" },
        transform: { lg: "translate(-50%,-50%)" },
      }}
    >
      <Box sx={{ mb: 5 }}>
        <img style={{ width: "100%", maxWidth: "400px" }} src={logoBlue} />
      </Box>

      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={submit}
        validationSchema={loginScheme}
      >
        {(props: FormikProps<ILoginForm>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;
          return (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "450px",
                width: "100%",
              }}
            >
              <Paragraph title="Email" />
              <AppTextField
                error={errors.email && touched.email ? true : false}
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <ErrorText text={errors.email} />
              ) : null}

              <Paragraph title="Contraseña" />
              <AppTextField
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                style={{ marginBottom: "15px" }}
                error={errors.password && touched.password ? true : false}
              />
              {errors.password && touched.password ? (
                <ErrorText text={errors.password} />
              ) : null}

              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ bgcolor: "secondary.main" }}
              >
                Ingresar
              </Button>

              <Link
                sx={{ ml: "5px", textAlign: "right", mt: "10px" }}
                color={"secondary"}
                href="/forgotpassword"
                underline="none"
              >
                ¿Olvidó contraseña ?
              </Link>

              <Box
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ my: 2 }}>¿No tienes cuenta?</Typography>
                <Button
                  variant="contained"
                  sx={{ ml: "5px", display: "inline-block", width: "100%" }}
                  onClick={() => {
                    navigate("/register");
                    // setDialogTitle("");
                    // setDialogDesc(
                    //   "¿Ha participado anteriormente en el programa Summer Work con algunas de estas agencias: ITEX, GLOEX, SHANCE, ALLIANCE?"
                    // );
                    // setOpenDialog(true);
                    // setDialogButton([
                    //   <Button onClick={() => navigate("/register")}>No</Button>,
                    //   <Button
                    //     onClick={() => {
                    //       setDialogTitle("");
                    //       setDialogDesc(
                    //         "Estimado participante por políticas internas de las empresas que forman parte del grupo ICEAA, no podemos admitir su participación, favor realizar el proceso en la empresa con la que ha participado anteriormente."
                    //       );
                    //       setDialogButton(
                    //         <Button onClick={() => setOpenDialog(false)}>
                    //           Ok
                    //         </Button>
                    //       );
                    //     }}
                    //   >
                    //     Si
                    //   </Button>,
                    // ]);
                  }}
                  // underline="none"
                >
                  Registrarse
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>

      <LoadingModal isLoading={isLoading} loadingMessage={modalTitle} />

      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
    </Box>
  );
}

export default LoginScreen;
