import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import personajeAvionPose2 from "../../assets/images/personaje-avion-pose2.png";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { ChangeEvent, useEffect, useState } from "react";
import EditTripModal from "./EditTripModal";
import { CandidateTrip } from "../../models/CandidateTrip";
import candidateService from "../../services/CandidateService";
import { GetDateDDMMYYYYY } from "../../Utils";
import Candidate from "../../models/Candidate";
import CandidateTripGenerateQR from "../../models/CandidateTripGenerateQR";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import AppSelect from "../../components/AppSelect";
import AppTextField from "../../components/AppTextField";
import LoadingModal from "../../components/LoadingModal";
import Paragraph from "../../components/Paragraph";
import ArticleIcon from "@mui/icons-material/Article";
import ValidHourForDate from "../../models/ValidHourForDate";
import UtilsService from "../../services/UtilsService";
import { useAppSelector } from "../../store/store";
import QRCode from "react-qr-code";

function TripScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editTripModal, setEditTripModal] = useState<boolean>(false);
  const [candidateTripOut, setCandidateTripOut] = useState<CandidateTrip>({
    flightDate: "",
    flightDateHour: "",
    flightDateMinute: "",
    flightDateAirline: "",
    flightDateReservationCode: "",
    flightDateAirPort: "",
    flightDateAirPortArrived: "",
    isFlightTo: false,
    travelInfoID: 0,
    fileURL: "",
  });
  const [candidate, setCandidate] = useState<Candidate | null>(null);
  const [candidateTripArrived, setCandidateTripArrived] =
    useState<CandidateTrip>({
      flightDate: "",
      flightDateHour: "",
      flightDateMinute: "",
      flightDateAirline: "",
      flightDateReservationCode: "",
      flightDateAirPort: "",
      flightDateAirPortArrived: "",
      isFlightTo: true,
      travelInfoID: 0,
      fileURL: "",
    });
  const [candidateTripModal, setCandidateTripModal] =
    useState<CandidateTrip>(candidateTripOut);

  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Requerido"),
    hour: Yup.string().required("Requerido"),
  });

  const [initialValues, setInitialValues] = useState<CandidateTripGenerateQR>({
    date: "",
    hour: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  var [validHourForDate, setValidHourForDate] = useState<ValidHourForDate[]>();
  var appState = useAppSelector((state) => state.appState);

  async function submit(values: CandidateTripGenerateQR) {
    try {
      setIsSaving(true);
      await candidateService.generateCandidateTripQRCode(values);
      const can = await candidateService.getCandidate(true);
      setCandidate(can);

      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);

      if (error.response.status == 403) {
        setOpenDialog(true);
        setDialogTitle("Error");
        setDialogDesc(
          "La fecha de firma del libro de retorno debe ser mayor a su fecha de vuelo de regreso."
        );
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
        return;
      }

      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    const tripFrom = await candidateService.getCandidateTrip(false);
    const tripTo = await candidateService.getCandidateTrip(true);
    const can = await candidateService.getCandidate(true);

    if (tripFrom != null) {
      setCandidateTripOut(tripFrom);
    }
    if (tripTo != null) {
      setCandidateTripArrived(tripTo);
    }

    setIsLoading(false);
    setCandidate(can);
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Viaje
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Viaje
      </Typography> */}

      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid item xs={12} lg={6}>
            {/* Viaje de salida */}
            <Typography sx={{ mb: 3 }} variant="h5">
              Vuelo de Salida
            </Typography>
            <Card sx={{ minWidth: 275, mb: 3 }}>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography fontSize={20}>Fecha</Typography>
                    <Typography
                      fontSize={18}
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                    >
                      {candidateTripOut.flightDate &&
                        GetDateDDMMYYYYY(new Date(candidateTripOut.flightDate))}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={20}>Aerolinea</Typography>
                    <Typography
                      fontSize={18}
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                    >
                      {candidateTripOut.flightDateAirline &&
                        candidateTripOut.flightDateAirline}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={20}># de vuelo</Typography>
                    <Typography
                      fontSize={18}
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                    >
                      {candidateTripOut.flightDateReservationCode &&
                        candidateTripOut.flightDateReservationCode}
                    </Typography>
                  </Box>
                </Box>
                {candidateTripOut.flightDate == "" ? (
                  <Box mb={2} textAlign="center">
                    <Typography>
                      No se ha registrado vuelo de entrada
                    </Typography>
                  </Box>
                ) : null}

                <Button
                  onClick={() => {
                    setCandidateTripModal(candidateTripOut);
                    setEditTripModal(true);
                  }}
                  startIcon={<EditCalendarIcon />}
                  variant="contained"
                >
                  Editar
                </Button>
                {candidateTripOut.fileURL != null &&
                candidateTripOut.fileURL != "" ? (
                  <Button
                    color="success"
                    href={candidateTripOut.fileURL}
                    target="_blank"
                    sx={{ mx: 2 }}
                    startIcon={<ArticleIcon />}
                    variant="contained"
                  >
                    Ver Documento
                  </Button>
                ) : null}
              </CardContent>
            </Card>
            {/* Viaje de entrada */}
            <Typography sx={{ mb: 3 }} variant="h5">
              Vuelo de Retorno
            </Typography>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography fontSize={20}>Fecha</Typography>
                    <Typography
                      fontSize={18}
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                    >
                      {candidateTripArrived.flightDate &&
                        GetDateDDMMYYYYY(
                          new Date(candidateTripArrived.flightDate)
                        )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={20}>Aerolinea</Typography>
                    <Typography
                      fontSize={18}
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                    >
                      {candidateTripArrived.flightDateAirline &&
                        candidateTripArrived.flightDateAirline}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={20}># de vuelo</Typography>
                    <Typography
                      fontSize={18}
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                    >
                      {candidateTripArrived.flightDateReservationCode &&
                        candidateTripArrived.flightDateReservationCode}
                    </Typography>
                  </Box>
                </Box>
                {candidateTripArrived.flightDate == "" ? (
                  <Box mb={2} textAlign="center">
                    <Typography>
                      No se ha registrado vuelo de entrada
                    </Typography>
                  </Box>
                ) : null}

                <Button
                  onClick={() => {
                    setCandidateTripModal(candidateTripArrived);
                    setEditTripModal(true);
                  }}
                  startIcon={<EditCalendarIcon />}
                  variant="contained"
                >
                  Editar
                </Button>
                {candidateTripArrived.fileURL != null &&
                candidateTripArrived.fileURL != "" ? (
                  <Button
                    color="success"
                    href={candidateTripArrived.fileURL}
                    target="_blank"
                    sx={{ mx: 2 }}
                    startIcon={<ArticleIcon />}
                    variant="contained"
                  >
                    Ver Documento
                  </Button>
                ) : null}
              </CardContent>
            </Card>

            {candidateTripArrived.flightDate != "" &&
            candidate != null &&
            candidate.returnQRCodeGenerated == null ? (
              <Box mt={2}>
                <Card>
                  <CardContent>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={submit}
                      validationSchema={validationSchema}
                      validateOnMount={true}
                    >
                      {(props: FormikProps<CandidateTripGenerateQR>) => {
                        const {
                          values,
                          touched,
                          errors,
                          handleBlur,
                          handleChange,
                          isSubmitting,
                          setFieldValue,
                          isValid,
                        } = props;

                        return (
                          <Form>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Paragraph
                                  title="Returno firma del libro "
                                  variant="h5"
                                />
                                <Alert severity="info">
                                  Estaremos recibiendo firmas a partir del día
                                  09 hasta el 16 de septiembre desde las 9:00 AM
                                  a hasta las 11:30 AM y desde 1:00 PM hasta las
                                  4:30 PM .{" "}
                                </Alert>
                                <Paragraph title="Fecha" required />
                                <AppTextField
                                  type="date"
                                  error={
                                    errors.date && touched.date ? true : false
                                  }
                                  name="date"
                                  value={values.date}
                                  onBlur={handleBlur}
                                  onChange={async (
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (event.target.value === "2024-09-11") {
                                      setOpenDialog(true);
                                      setDialogTitle("Lo sentimos");
                                      setDialogDesc(
                                        "La fecha seleccionada no está disponible. Por favor, elija otra opción."
                                      );
                                      setDialogButton(
                                        <Button
                                          onClick={() => setOpenDialog(false)}
                                        >
                                          Ok
                                        </Button>
                                      );
                                      return;
                                    }

                                    setFieldValue("date", event.target.value);
                                    var validHours =
                                      await UtilsService.getValidHourForDate(
                                        event.target.value
                                      );
                                    setValidHourForDate(validHours);
                                  }}
                                />
                                <Paragraph title="Hora" required />
                                <AppSelect
                                  name="hour"
                                  error={
                                    errors.hour && touched.hour ? true : false
                                  }
                                  fullWidth
                                  value={values.hour}
                                  onChange={handleChange}
                                >
                                  {validHourForDate?.map((el) => (
                                    <MenuItem
                                      key={el.interviewTime}
                                      value={el.interviewTime}
                                    >
                                      {el.interviewTime}
                                    </MenuItem>
                                  ))}
                                </AppSelect>
                              </Grid>

                              <Grid item xs={12}>
                                <Button
                                  onClick={() => {
                                    if (!isValid) {
                                      setIsValidForm(true);
                                    }
                                  }}
                                  disabled={isSubmitting}
                                  type="submit"
                                  color="secondary"
                                  variant="contained"
                                  sx={{
                                    bgcolor: "secondary.main",
                                    mt: "10px",
                                  }}
                                >
                                  {isSubmitting ? (
                                    <>Guardando...</>
                                  ) : (
                                    "Generar QR"
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        );
                      }}
                    </Formik>
                  </CardContent>
                </Card>
              </Box>
            ) : null}
          </Grid>
          <Grid
            // sx={{ display: { lg: "block", xs: "none" } }}
            item
            xs={12}
            lg={6}
          >
            {candidate?.returnQRCodeGenerated == null ? (
              <img
                style={{
                  height: "100%",
                  maxHeight: "500px",
                  objectFit: "contain",
                  display: "block",
                  margin: "auto",
                }}
                src={personajeAvionPose2}
              />
            ) : candidate?.returnQRFileName.includes("QRFile") ? (
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Box
                    borderRadius={4}
                    p={3}
                    color="white"
                    bgcolor="primary.main"
                  >
                    <Typography sx={{ mb: 2 }}>
                      Por favor contactar a Ace International para ver codigo QR
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Box display="flex" justifyContent="center" mt={2}>
                <QRCode
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "300px",
                  }}
                  value={`https://admin.aceenlinea.com/DescrgacaPrograma.aspx?token=${candidate?.retunQRCode}`}
                />
              </Box>
              // <img
              //   style={{
              //     maxHeight: "400px",
              //     maxWidth: "400px",
              //     width: "100%",
              //     objectFit: "contain",
              //     display: "block",
              //     marginLeft: "auto",
              //     marginRight: "auto",
              //     marginTop: 4,
              //   }}
              //   alt="Codigo QR"
              //   //src={`${process.env.REACT_APP_API_BASEURL}/Documents/qrcode?candidateId=${appState.candidateId}&isTrip=true`}
              //   src={candidate?.returnQRFileName
              //     .replace("/uc?", "/thumbnail?")
              //     .replace("&export=VIEW", "")}
              // />
            )}
          </Grid>
        </Grid>
      )}

      <Dialog fullWidth={true} maxWidth="md" open={editTripModal}>
        <DialogTitle>Agregar/Editar Viaje</DialogTitle>
        <DialogContent>
          <EditTripModal
            candidateTrip={candidateTripModal}
            onClose={() => {
              setEditTripModal(false);
            }}
            onSave={async () => {
              setEditTripModal(false);
              const tripFrom = await candidateService.getCandidateTrip(false);
              const tripTo = await candidateService.getCandidateTrip(true);

              if (tripFrom != null) {
                setCandidateTripOut(tripFrom);
              }
              if (tripTo != null) {
                setCandidateTripArrived(tripTo);
              }
            }}
          />
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default TripScreen;
