import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { ChangeEvent, Ref, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import AppSelect from "../../components/AppSelect";
import { Country } from "../../models/Country";
import utilsService from "../../services/UtilsService";
import Province from "../../models/Province";
import City from "../../models/City";
import {
  GetDateYYYYMMDD,
  handleInputChangeToUppercase,
  phoneFormatter,
} from "../../Utils";
import VisaType from "../../models/VisaType";
import MigratoryState from "../../models/MigratoryState";
import CandidateCloseFamily from "../../models/CandidateCloseFamily";
import candidateService from "../../services/CandidateService";
import LoadingModal from "../../components/LoadingModal";
import IOption from "../../interfaces/IOption";
import { ApplicationState } from "../../models/ApplicationState";
import { useAppSelector } from "../../store/store";

export interface CloseFamilyModalForm {
  candidateRelatedID: number;
  name: string;
  secondName: string;
  lastName: string;
  secondLastName: string;
  relatedDOB: string;
  phonePrincipal: string;
  phoneSecondary: string;
  email: string;
  liveTogether: string;
  addressStreetName: string;
  addressCountry2: string;
  addressProvinceId: string;
  addressCityId: string;
  addressNeighborhood: string;
  pagaraSuPrograma: string;
  workFlag: string;
  workCompanyName: string;
  workPosition: string;
  workAddressStreetName: string;
  workSalary: string;
  workAddressProvinceId: string;
  workAddressCityId: string;
  workAddressNeighborhood: string;
  workCompanyPhone: string;
  visaUSAFlag: string;
  visaUSATypeId: string;
  isUSAResident: string;
  outofCountryStateText: string;
  outofCountryMigratoryStateId: string;
  outofCountryPeriodValue: string;
  noWorkExplain: string;
  workSupervisor: string;
  addressStateText: string;
  addressCityText: string;
}

const validationScheme = Yup.object().shape({
  name: Yup.string().required("Requerido"),
  lastName: Yup.string().required("Requerido"),
  relatedDOB: Yup.string().required("Requerido"),
  phonePrincipal: Yup.string().required("Requerido"),
  liveTogether: Yup.string().required("Requerido"),
  addressStreetName: Yup.string().when(
    "liveTogether",
    (liveTogether: string | string[], schema) => {
      if (liveTogether == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  addressCountry2: Yup.string().when(
    "liveTogether",
    (liveTogether: string | string[], schema) => {
      if (liveTogether == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  addressProvinceId: Yup.string().when(
    ["liveTogether", "addressCountry2"],
    (liveTogether: string | string[], schema) => {
      if (liveTogether[0] == "NO" && liveTogether[1] == "1") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  addressCityId: Yup.string().when(
    ["liveTogether", "addressCountry2"],
    (liveTogether: string | string[], schema) => {
      if (liveTogether[0] == "NO" && liveTogether[1] == "1") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  addressCityText: Yup.string().when(
    ["liveTogether", "addressCountry2"],
    (liveTogether: string | string[], schema) => {
      if (liveTogether[0] == "NO" && liveTogether[1] != "1") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  addressStateText: Yup.string().when(
    ["liveTogether", "addressCountry2"],
    (liveTogether: string | string[], schema) => {
      if (liveTogether[0] == "NO" && liveTogether[1] != "1") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  workFlag: Yup.string().required("Requerido"),
  noWorkExplain: Yup.string().when(
    "workFlag",
    (workFlag: string | string[], schema) => {
      if (workFlag == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  workCompanyName: Yup.string().when(
    "workFlag",
    (workFlag: string | string[], schema) => {
      if (workFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  workPosition: Yup.string().when(
    "workFlag",
    (workFlag: string | string[], schema) => {
      if (workFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  workAddressStreetName: Yup.string().when(
    "workFlag",
    (workFlag: string | string[], schema) => {
      if (workFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  workSalary: Yup.string().when(
    "workFlag",
    (workFlag: string | string[], schema) => {
      if (workFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  workAddressCityId: Yup.string().when(
    "workFlag",
    (workFlag: string | string[], schema) => {
      if (workFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  workAddressProvinceId: Yup.string().when(
    "workFlag",
    (workFlag: string | string[], schema) => {
      if (workFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  visaUSAFlag: Yup.string().required("Requerido"),
  visaUSATypeId: Yup.string().when(
    "visaUSAFlag",
    (visaUSAFlag: string | string[], schema) => {
      if (visaUSAFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  isUSAResident: Yup.string().required("Requerido"),
  outofCountryStateText: Yup.string().when(
    "isUSAResident",
    (isUSAResident: string | string[], schema) => {
      if (isUSAResident == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  outofCountryMigratoryStateId: Yup.string().when(
    "isUSAResident",
    (isUSAResident: string | string[], schema) => {
      if (isUSAResident == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  outofCountryPeriodValue: Yup.string().when(
    "isUSAResident",
    (isUSAResident: string | string[], schema) => {
      if (isUSAResident == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

interface ICloseFamilyModal {
  close: () => void;
  onSave: () => void;
  type: string;
}

function CloseFamilyModal({ close, type, onSave }: ICloseFamilyModal) {
  const [initialValues, setInitialValues] = useState<CloseFamilyModalForm>({
    candidateRelatedID: 0,
    name: "",
    secondName: "",
    lastName: "",
    secondLastName: "",
    relatedDOB: "",
    phonePrincipal: "",
    phoneSecondary: "",
    email: "",
    liveTogether: "NO",
    addressStreetName: "",
    addressCountry2: "",
    addressProvinceId: "",
    addressCityId: "",
    addressNeighborhood: "",
    pagaraSuPrograma: "NO",
    workFlag: "NO",
    workCompanyName: "",
    workPosition: "",
    workAddressStreetName: "",
    workSalary: "",
    workAddressProvinceId: "",
    workAddressCityId: "",
    workAddressNeighborhood: "",
    workCompanyPhone: "",
    visaUSAFlag: "",
    visaUSATypeId: "",
    isUSAResident: "",
    outofCountryStateText: "",
    outofCountryMigratoryStateId: "",
    outofCountryPeriodValue: "",
    noWorkExplain: "",
    workSupervisor: "",
    addressCityText: "",
    addressStateText: "",
  });
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [workCities, setWorkCities] = useState<City[]>([]);
  const [visaTypes, setVisaTypes] = useState<VisaType[]>([]);
  const [migratoryStates, setMigratoryStates] = useState<MigratoryState[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  async function submit(values: CloseFamilyModalForm) {
    if (appState.applicationStateId != 23 && !appState.isAdmin) {
      onSave();
      return;
    }

    const family: CandidateCloseFamily = {
      ...values,
      candidateRelatedID: initialValues.candidateRelatedID ?? 0,
      addressCountry2:
        values.addressCountry2 != "" ? Number(values.addressCountry2) : null,
      addressProvinceId:
        values.addressProvinceId != ""
          ? Number(values.addressProvinceId)
          : null,
      outofCountryMigratoryStateId:
        values.outofCountryMigratoryStateId != ""
          ? Number(values.outofCountryMigratoryStateId)
          : null,

      liveTogether: values.liveTogether == "SI",
      addressCityId:
        values.addressCityId != "" ? Number(values.addressCityId) : null,
      workFlag: values.workFlag == "SI",
      workSalary: values.workFlag == "SI" ? Number(values.workSalary) : null,
      workAddressCityId:
        values.workFlag == "SI" ? Number(values.workAddressCityId) : null,
      workAddressProvinceId:
        values.workFlag == "SI" ? Number(values.workAddressProvinceId) : null,
      visaUSAFlag: values.visaUSAFlag == "SI",
      visaUSATypeId:
        values.visaUSAFlag == "SI" ? Number(values.visaUSATypeId) : null,
      isUSAResident: values.isUSAResident == "SI",
      type: type,
      workSupervisor: values.workSupervisor ?? null,
      addressCityText: values.addressCityText ?? null,
      addressStateText: values.addressStateText ?? null,
    };

    try {
      setIsSaving(true);
      await candidateService.saveCandidateFamilyRelated(family);
      setIsSaving(false);
      onSave();
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    const country = await utilsService.getCountries();
    const province = await utilsService.getProvinces();
    const visasTypes = await utilsService.getVisaTypes();
    const migratory = await utilsService.getMigratoryStates();
    const relatedF = await candidateService.getCandidateRelatedFamily(type);

    if (
      relatedF != null &&
      relatedF.addressProvinceId != null &&
      relatedF.addressProvinceId > 0
    ) {
      const city = await utilsService.getCities(
        relatedF.addressProvinceId.toString()
      );
      setCities(city);
    }

    if (
      relatedF != null &&
      relatedF.workAddressProvinceId != null &&
      relatedF.workAddressProvinceId > 0
    ) {
      const city = await utilsService.getCities(
        relatedF.workAddressProvinceId.toString()
      );
      setWorkCities(city);
    }

    setMigratoryStates(migratory);
    setVisaTypes(visasTypes);
    setProvinces(province);
    setCountries(country);
    setIsLoading(false);

    if (relatedF != null) {
      setInitialValues({
        ...relatedF,
        relatedDOB:
          relatedF.relatedDOB != null
            ? GetDateYYYYMMDD(new Date(relatedF.relatedDOB))
            : "",
        secondName: relatedF.secondName ?? "",
        secondLastName: relatedF.secondLastName ?? "",
        phoneSecondary: relatedF.phoneSecondary ?? "",
        email: relatedF.email ?? "",
        liveTogether: relatedF.liveTogether ? "SI" : "NO",
        addressStreetName: relatedF.addressStreetName ?? "",
        addressCountry2:
          relatedF.addressCountry2 != null
            ? relatedF.addressCountry2?.toString()
            : "",
        addressProvinceId:
          relatedF.addressProvinceId != null
            ? relatedF.addressProvinceId?.toString()
            : "",
        addressCityId:
          relatedF.addressCityId != null
            ? relatedF.addressCityId?.toString()
            : "",

        addressNeighborhood: relatedF.addressNeighborhood ?? "",
        pagaraSuPrograma: relatedF.pagaraSuPrograma ?? "",
        workFlag: relatedF.workFlag ? "SI" : "NO",
        workCompanyName: relatedF.workCompanyName ?? "",
        workPosition: relatedF.workPosition ?? "",
        workAddressStreetName: relatedF.workAddressStreetName ?? "",
        workSalary:
          relatedF.workSalary != null ? relatedF.workSalary?.toString() : "",
        workAddressProvinceId:
          relatedF.workAddressProvinceId != null
            ? relatedF.workAddressProvinceId.toString()
            : "",
        workAddressCityId:
          relatedF.workAddressCityId != null
            ? relatedF.workAddressCityId.toString()
            : "",
        workAddressNeighborhood: relatedF.workAddressNeighborhood ?? "",
        workCompanyPhone: relatedF.workCompanyPhone ?? "",
        visaUSAFlag:
          relatedF.visaUSAFlag != null && relatedF.visaUSAFlag == true
            ? "SI"
            : "NO",
        visaUSATypeId:
          relatedF.visaUSATypeId != null
            ? relatedF.visaUSATypeId.toString()
            : "",
        isUSAResident:
          relatedF.isUSAResident != null && relatedF.isUSAResident
            ? "SI"
            : "NO",
        outofCountryMigratoryStateId:
          relatedF.outofCountryMigratoryStateId != null
            ? relatedF.outofCountryMigratoryStateId.toString()
            : "",
        outofCountryStateText: relatedF.outofCountryStateText ?? "",
        outofCountryPeriodValue: relatedF.outofCountryPeriodValue ?? "",
        noWorkExplain: relatedF.noWorkExplain ?? "",

        workSupervisor: relatedF.workSupervisor ?? "",
        addressCityText: relatedF.addressCityText ?? "",
        addressStateText: relatedF.addressStateText ?? "",
      });
    }
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      {isLoading ? (
        <Box display="block" margin="auto" textAlign="center">
          <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
          <Typography variant="body1">Cargando...</Typography>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationScheme}
          validateOnMount={true}
        >
          {(props: FormikProps<CloseFamilyModalForm>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Grid container spacing={1}>
                  {/* Primer Nombre */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Primer nombre" required />
                    <AppTextField
                      error={errors.name && touched.name ? true : false}
                      name="name"
                      value={values.name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Segundo Nombre */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Segundo nombre" />
                    <AppTextField
                      name="secondName"
                      value={values.secondName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Primer apellido */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Primer apellido" required />
                    <AppTextField
                      error={errors.lastName && touched.lastName ? true : false}
                      name="lastName"
                      value={values.lastName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Segundo apellido */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Segundo apellido" />
                    <AppTextField
                      name="secondLastName"
                      value={values.secondLastName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Fecha de nacimiento */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Fecha de nacimiento" required />
                    <AppTextField
                      type="date"
                      error={
                        errors.relatedDOB && touched.relatedDOB ? true : false
                      }
                      name="relatedDOB"
                      value={values.relatedDOB}
                      onChange={handleChange}
                    />
                  </Grid>
                  {/* Numero de celular */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Número celular" required />
                    <AppTextField
                      error={
                        errors.phonePrincipal && touched.phonePrincipal
                          ? true
                          : false
                      }
                      name="phonePrincipal"
                      value={values.phonePrincipal}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const phoneRegex = /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                        const { value } = event.target;
                        if (phoneRegex.test(value)) {
                          const formattedPhone = phoneFormatter(value);
                          setFieldValue("phonePrincipal", formattedPhone);
                        }
                      }}
                    />
                  </Grid>
                  {/* Otro numero */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Otro número" />
                    <AppTextField
                      name="phoneSecondary"
                      value={values.phoneSecondary}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const phoneRegex = /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                        const { value } = event.target;
                        if (phoneRegex.test(value)) {
                          const formattedPhone = phoneFormatter(value);
                          setFieldValue("phoneSecondary", formattedPhone);
                        }
                      }}
                    />
                  </Grid>
                  {/* Email */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Correo electrónico" />
                    <AppTextField
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  {/* Viven junto */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="¿Viven junto?" required />
                    <AppSelect
                      name="liveTogether"
                      error={
                        errors.liveTogether && touched.liveTogether
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.liveTogether}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Direccion complete */}
                  {values.liveTogether == "NO" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Dirección completa" required />
                      <AppTextField
                        error={
                          errors.addressStreetName && touched.addressStreetName
                            ? true
                            : false
                        }
                        name="addressStreetName"
                        value={values.addressStreetName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Pais */}
                  {values.liveTogether == "NO" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Pais" required />
                      <AppSelect
                        name="addressCountry2"
                        error={
                          errors.addressCountry2 && touched.addressCountry2
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.addressCountry2}
                        onChange={handleChange}
                        isSearchable={true}
                        options={countries.map((el) => {
                          var option: IOption = {
                            label: el.name!,
                            value: el.countryId.toString(),
                          };
                          return option;
                        })}
                      >
                        {/* {countries.map((el) => (
                          <MenuItem
                            key={el.countryId}
                            value={el.countryId.toString()}
                          >
                            {el.name}
                          </MenuItem>
                        ))} */}
                      </AppSelect>
                    </Grid>
                  ) : null}
                  {/* Provincia */}
                  {values.liveTogether == "NO" ? (
                    values.addressCountry2 == "1" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Provincia" required />
                        <AppSelect
                          name="addressProvinceId"
                          error={
                            errors.addressProvinceId &&
                            touched.addressProvinceId
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.addressProvinceId}
                          onChange={async (event, child) => {
                            setFieldValue(
                              "addressProvinceId",
                              event.target.value
                            );
                            var newCities = await utilsService.getCities(
                              event.target.value
                            );
                            setFieldValue("addressCityId", "");
                            setCities(newCities);
                          }}
                        >
                          {provinces.map((el) => (
                            <MenuItem
                              key={el.provinceId}
                              value={el.provinceId.toString()}
                            >
                              {el.name}
                            </MenuItem>
                          ))}
                        </AppSelect>
                      </Grid>
                    ) : (
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Estado" required />
                        <AppTextField
                          error={
                            errors.addressStateText && touched.addressStateText
                              ? true
                              : false
                          }
                          name="addressStateText"
                          value={values.addressStateText}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                    )
                  ) : null}
                  {/* Municipio */}
                  {values.liveTogether == "NO" ? (
                    values.addressCountry2 == "1" ? (
                      <Grid item lg={6} xs={12}>
                        <Paragraph title="Municipio" required />
                        <AppSelect
                          name="addressCityId"
                          error={
                            errors.addressCityId && touched.addressCityId
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.addressCityId}
                          onChange={handleChange}
                        >
                          {cities.map((el) => (
                            <MenuItem
                              key={el.cityId}
                              value={el.cityId.toString()}
                            >
                              {el.name}
                            </MenuItem>
                          ))}
                        </AppSelect>
                      </Grid>
                    ) : (
                      <Grid item lg={6} xs={12}>
                        <Paragraph title="Ciudad" required />
                        <AppTextField
                          error={
                            errors.addressCityText && touched.addressCityText
                              ? true
                              : false
                          }
                          name="addressCityText"
                          value={values.addressCityText}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                    )
                  ) : null}
                  {/* Codigo postal */}
                  {values.liveTogether == "NO" ? (
                    <Grid item lg={6} xs={12}>
                      <Paragraph title="Código postal" />
                      <AppTextField
                        name="addressNeighborhood"
                        value={values.addressNeighborhood}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Pagara programa */}
                  <Grid item lg={6} xs={12}>
                    <Paragraph
                      title="¿Esta es una de las personas que pagará su programa?"
                      required
                    />
                    <AppSelect
                      name="pagaraSuPrograma"
                      error={
                        errors.pagaraSuPrograma && touched.pagaraSuPrograma
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.pagaraSuPrograma}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <Paragraph title="¿trabaja actualmente?" required />
                    <AppSelect
                      name="workFlag"
                      error={errors.workFlag && touched.workFlag ? true : false}
                      fullWidth
                      value={values.workFlag}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>

                  {values.workFlag == "NO" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph
                        title="¿A que se dedica para mantenerse?"
                        required
                      />
                      <AppTextField
                        error={
                          errors.noWorkExplain && touched.noWorkExplain
                            ? true
                            : false
                        }
                        name="noWorkExplain"
                        value={values.noWorkExplain}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* Nombre de la empresa */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph
                        title="Nombre completo de la empresa"
                        required
                      />
                      <AppTextField
                        error={
                          errors.workCompanyName && touched.workCompanyName
                            ? true
                            : false
                        }
                        name="workCompanyName"
                        value={values.workCompanyName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* posicion */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Posición" required />
                      <AppTextField
                        error={
                          errors.workPosition && touched.workPosition
                            ? true
                            : false
                        }
                        name="workPosition"
                        value={values.workPosition}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Salario */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Ingreso mensual (RD$)" required />
                      <AppTextField
                        type="number"
                        error={
                          errors.workSalary && touched.workSalary ? true : false
                        }
                        name="workSalary"
                        value={values.workSalary}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : null}
                  {/* Direccion */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Dirección completa" required />
                      <AppTextField
                        error={
                          errors.workAddressStreetName &&
                          touched.workAddressStreetName
                            ? true
                            : false
                        }
                        name="workAddressStreetName"
                        value={values.workAddressStreetName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Work Provincia */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Provincia" required />
                      <AppSelect
                        name="workAddressProvinceId"
                        error={
                          errors.workAddressProvinceId &&
                          touched.workAddressProvinceId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.workAddressProvinceId}
                        onChange={async (event, child) => {
                          setFieldValue(
                            "workAddressProvinceId",
                            event.target.value
                          );
                          var newCities = await utilsService.getCities(
                            event.target.value
                          );
                          setFieldValue("workAddressCityId", "");
                          setWorkCities(newCities);
                        }}
                      >
                        {provinces.map((el) => (
                          <MenuItem
                            key={el.provinceId}
                            value={el.provinceId.toString()}
                          >
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                  ) : null}
                  {/* Work Municipio */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Municipio" required />
                      <AppSelect
                        name="workAddressCityId"
                        error={
                          errors.workAddressCityId && touched.workAddressCityId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.workAddressCityId}
                        onChange={handleChange}
                      >
                        {workCities.map((el) => (
                          <MenuItem
                            key={el.cityId}
                            value={el.cityId.toString()}
                          >
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                  ) : null}
                  {/* TODO: Add Country for work DB */}

                  {/* Postal */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Código postal" />
                      <AppTextField
                        name="workAddressNeighborhood"
                        value={values.workAddressNeighborhood}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Company phone */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Teléfono" />
                      <AppTextField
                        name="workCompanyPhone"
                        value={values.workCompanyPhone}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue("workCompanyPhone", formattedPhone);
                          }
                        }}
                      />
                    </Grid>
                  ) : null}

                  {/* Supervisor */}
                  {values.workFlag == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Nombre Supervisor" />
                      <AppTextField
                        name="workSupervisor"
                        value={values.workSupervisor}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Posee visado */}
                  <Grid item lg={6} xs={12}>
                    <Paragraph
                      title="Posee visado de Estados Unidos?"
                      required
                    />
                    <AppSelect
                      name="visaUSAFlag"
                      error={
                        errors.visaUSAFlag && touched.visaUSAFlag ? true : false
                      }
                      fullWidth
                      value={values.visaUSAFlag}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Visa type */}
                  {values.visaUSAFlag == "SI" ? (
                    <Grid item lg={6} xs={12}>
                      <Paragraph title="Tipo de visa" required />
                      <AppSelect
                        name="visaUSATypeId"
                        error={
                          errors.visaUSATypeId && touched.visaUSATypeId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.visaUSATypeId}
                        onChange={handleChange}
                      >
                        {visaTypes.map((el) => (
                          <MenuItem key={el.visaTypeId} value={el.visaTypeId}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                  ) : null}
                  {/* Residente */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="¿Reside en Estados Unidos? " required />
                    <AppSelect
                      name="isUSAResident"
                      error={
                        errors.isUSAResident && touched.isUSAResident
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.isUSAResident}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Resident State */}
                  {values.isUSAResident == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Estado donde reside" required />
                      <AppTextField
                        error={
                          errors.outofCountryStateText &&
                          touched.outofCountryStateText
                            ? true
                            : false
                        }
                        name="outofCountryStateText"
                        value={values.outofCountryStateText}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* Migratory State */}
                  {values.isUSAResident == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Condición migratoria" required />
                      <AppSelect
                        name="outofCountryMigratoryStateId"
                        error={
                          errors.outofCountryMigratoryStateId &&
                          touched.outofCountryMigratoryStateId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.outofCountryMigratoryStateId}
                        onChange={handleChange}
                      >
                        {migratoryStates.map((el) => (
                          <MenuItem
                            key={el.migratoryStateId}
                            value={el.migratoryStateId.toString()}
                          >
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                  ) : null}

                  {/* Company phone */}
                  {values.isUSAResident == "SI" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Tiempo en USA" required />
                      <AppTextField
                        error={
                          errors.outofCountryPeriodValue &&
                          touched.outofCountryPeriodValue
                            ? true
                            : false
                        }
                        name="outofCountryPeriodValue"
                        value={values.outofCountryPeriodValue}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* Button */}
                  <Grid item xs={12}>
                    <Button
                      onClick={close}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Cerrar
                    </Button>
                    <Button
                      onClick={() => {
                        if (!isValid) {
                          setIsValidForm(true);
                        }
                      }}
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Guardar"}
                    </Button>
                  </Grid>
                  <ConfirmationDialog
                    maxWidth="sm"
                    open={isValidForm}
                    title={"Error"}
                    description={"Llenar todos los requisitos en rojo"}
                    buttons={
                      <Button onClick={() => setIsValidForm(false)}>Ok</Button>
                    }
                  />
                  <ConfirmationDialog
                    maxWidth="sm"
                    open={openDialog}
                    title={dialogTitle}
                    description={dialogDesc}
                    buttons={dialogButton}
                  />
                  <LoadingModal
                    isLoading={isSaving}
                    loadingMessage="Guardando"
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
}
export default CloseFamilyModal;
