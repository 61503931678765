import { PaletteColorOptions, createTheme, styled } from "@mui/material/styles";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import React from "react";
import { LinkProps } from "@mui/material/Link";
import { TableCell, TableRow, tableCellClasses } from "@mui/material";
import { Padding } from "@mui/icons-material";

export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;

  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module "@mui/material/styles" {
  interface CustomPalette {
    customGrey: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    customGrey: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });

export const globalTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#b5aeae",
            color: "#00156b",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          ".Mui-selected": {
            backgroundColor: "#FE6B1B !important",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiTextField: {
      defaultProps: {
        style: {
          // backgroundColor: "rgb(245, 245, 245)",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        style: {
          // backgroundColor: "rgb(232, 241, 250)",
          // backgroundColor: "rgb(245, 245, 245)",
        },
      },
    },
  },
  typography: {
    fontFamily: "gotham",
    fontSize: 15,
    button: {
      textTransform: "uppercase",
    },
    // h4: {
    //   fontFamily: "GothamBlack",
    // },
    // h5: {
    //   fontFamily: "GothamMedium",
    //   marginBottom: "5px !important",
    //   fontSize: 30,
    // },
  },
  palette: {
    primary: {
      main: "#00166B",
    },
    secondary: {
      main: "#FE6B1B",
      contrastText: "#FFF",
    },
    customGrey: createColor("#9e9e9e"),
  },
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00166b", //theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
