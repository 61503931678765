import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { ApplicationState } from "../../models/ApplicationState";
import LoadingModal from "../../components/LoadingModal";
import ErrorText from "../../components/ErrorText";
import CandidateService from "../../services/CandidateService";
import AuthService from "../../services/AuthService";
import { login } from "../../store/appStateReducer";
import AppSelect from "../../components/AppSelect";
import UtilsService from "../../services/UtilsService";
import WelcomeCall from "../../models/WelcomeCall";
import { GetDateDDMMYYYYY } from "../../Utils";

interface WelcomeInterviewModalProps {
  onClose: () => void;
}

interface IWelcomeInterviewForm {
  dateHour: string;
}

const validationSchema = Yup.object().shape({
  dateHour: Yup.string().required("Requerido"),
});

function WelcomeInterviewModal({ onClose }: WelcomeInterviewModalProps) {
  const [initialValues, setInitialValues] = useState<IWelcomeInterviewForm>({
    dateHour: "",
  });

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [welcomeCalls, setWellcomeCalls] = useState<WelcomeCall[]>([]);

  const dispatch = useAppDispatch();

  async function submit(values: IWelcomeInterviewForm) {
    try {
      setIsSaving(true);

      await CandidateService.addEditWelcomeInterview(values.dateHour);

      setIsSaving(false);

      setOpenDialog(true);
      setDialogTitle("Gracias");
      setDialogDesc("Gracias por agendar esta entrevista de bienvenida");
      setDialogButton(
        <Button
          onClick={async () => {
            setIsSaving(true);

            var applicationState: ApplicationState | null =
              await AuthService.loadUserInfo();

            setOpenDialog(false);
            setIsSaving(false);
            onClose();

            await dispatch(login({ applicationState: applicationState! }));
          }}
        >
          Ok
        </Button>
      );
    } catch (error: any) {
      console.log(error);
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    const calls = await UtilsService.getWelcomeCalls();
    setWellcomeCalls(calls);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);
  return (
    <Box>
      {isLoading ? (
        <Box display="block" margin="auto" textAlign="center">
          <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
          <Typography variant="body1">Cargando...</Typography>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {(props: FormikProps<IWelcomeInterviewForm>) => {
            const {
              touched,
              errors,
              isSubmitting,
              handleChange,
              values,
              setFieldValue,
            } = props;

            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: "5px" }}>
                      Fecha y Hora
                    </Typography>
                    <AppSelect
                      name="dateHour"
                      error={errors.dateHour && touched.dateHour ? true : false}
                      fullWidth
                      value={values.dateHour}
                      onChange={handleChange}
                    >
                      {welcomeCalls?.map((el, index) => (
                        <MenuItem key={index} value={el.welcomeCallCandidateID}>
                          {GetDateDDMMYYYYY(new Date(el.welcomeCallDate))}{" "}
                          {el.welcomeCallHour}
                        </MenuItem>
                      ))}
                    </AppSelect>
                    {errors.dateHour && touched.dateHour ? (
                      <ErrorText text={errors.dateHour} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      onClick={onClose}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Cerrar
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Guardar"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}

      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}

export default WelcomeInterviewModal;
