import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Input,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CandidatePaymentAccount from "../../models/CandidatePaymentAccount";
import { StyledTableCell, StyledTableRow } from "../../theme/GlobalTheme";
import { GetDateDDMMYYYYY, currencyFormatter } from "../../Utils";
import CandidateService from "../../services/CandidateService";
import AppTextField from "../../components/AppTextField";
import PaymentDetail from "./PaymentDetail";
import LoadingModal from "../../components/LoadingModal";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PaymentSelected from "../../models/PaymentSelected";
import PaymentReference from "../../models/PaymentReference";
import AppSelect from "../../components/AppSelect";
import IOption from "../../interfaces/IOption";
import CandidatePendingPayment from "../../models/CandidatePendingPayments";

function PaymentScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pendingPayments, setPendingPayments] = useState<
    CandidatePendingPayment[]
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [isDetailPage, setIsDetailPage] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [paymentReference, setPaymentReference] = useState<PaymentReference>({
    token: "",
    referenceId: "",
    dollarRate: 0,
    totalAmount: 0,
  });
  const [exchangeRates, setExchangeRates] = useState<{
    [key: string]: number;
  }>();
  const [exchageRateId, setExchangeRateId] = useState<number>(1);

  async function initialData() {
    const pending = (await CandidateService.getPendingPayments()).filter(
      (el) => el.amount - el.amountPaid != 0
    );
    const rate: { [key: string]: number } = {};
    setIsLoading(false);
    setPendingPayments(pending);

    pending.forEach((el) => {
      if (rate[el.currency] == undefined) {
        rate[el.currency] = el.exchangeRateId;
      }
    });
    if (Object.keys(rate).length > 1) {
      setExchangeRateId(2);
    }
    if (Object.keys(rate).length == 1) {
      setExchangeRateId(rate[Object.keys(rate!)[0]]);
    }
    setExchangeRates(rate);
  }
  useEffect(() => {
    initialData();
  }, []);

  function goBack() {
    setIsDetailPage(!isDetailPage);
    setIsLoading(true);
    initialData();
  }

  function updateTotalAmount() {
    var calculatedAmount = 0;
    pendingPayments.map((el) => {
      if (el.isChecked) {
        calculatedAmount += el.amountToPay;
      }
      return el;
    });

    setTotalAmount(calculatedAmount);
  }

  async function submit() {
    try {
      const paymentSelected: PaymentSelected = {
        exchangeRateId: exchageRateId,
        pendingPayments: pendingPayments.filter((el) => el.isChecked),
      };
      setIsSaving(true);
      const reference = await CandidateService.getPaymentReference(
        paymentSelected
      );
      console.log(reference);
      setIsSaving(false);
      setPaymentReference(reference);
      setIsDetailPage(true);
    } catch {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Pagos TC
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Pagos TC
      </Typography> */}
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : !isDetailPage ? (
        <Box>
          {exchangeRates != undefined &&
          Object.keys(exchangeRates).length > 0 ? (
            <AppSelect
              fullWidth
              value={exchageRateId}
              onChange={(event, child) => {
                setExchangeRateId(Number(event.target.value));
              }}
            >
              {Object.keys(exchangeRates).map((el, index) => (
                <MenuItem key={index} value={exchangeRates[el]}>
                  {el}
                </MenuItem>
              ))}
            </AppSelect>
          ) : null}
          <TableContainer sx={{ mb: 2 }} component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">Concepto</StyledTableCell>
                  <StyledTableCell align="left">Monto</StyledTableCell>
                  <StyledTableCell align="left">Monto a Pagar</StyledTableCell>
                  <StyledTableCell align="left">Monto Pagado</StyledTableCell>
                  <StyledTableCell align="left">Fecha Límite</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingPayments
                  .filter((el) => el.exchangeRateId == exchageRateId)
                  .filter((el) => el.availableForCreditCard)
                  .map((el, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <StyledTableCell align="left" component="th" scope="row">
                        {el.amount != el.amountPaid ? (
                          <Checkbox
                            color="primary"
                            checked={el.isChecked ?? false}
                            onChange={(e) => {
                              const newList = [...pendingPayments];
                              const payment = newList.find(
                                (ele) =>
                                  ele.candidateAmountToPayID ==
                                  el.candidateAmountToPayID
                              );
                              payment!.isChecked = e.target.checked;
                              setPendingPayments(newList);
                              updateTotalAmount();
                            }}
                          />
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell align="left" component="th" scope="row">
                        {el.amountDescription}
                      </StyledTableCell>
                      <StyledTableCell align="left" component="th" scope="row">
                        {el.currency} {currencyFormatter.format(el.amount)}
                      </StyledTableCell>
                      <StyledTableCell align="left" component="th" scope="row">
                        <AppTextField
                          style={{
                            width: 100,
                          }}
                          inputProps={{
                            max: el.pendingAmount,
                          }}
                          disabled={!el.isChecked}
                          value={el.amountToPay}
                          type="number"
                          onChange={(e) => {
                            var value: number = 0;
                            if (
                              e.target.value.trim() != "" &&
                              Number(e.target.value) > 0
                            ) {
                              value = Number(e.target.value);
                              if (Number(e.target.value) > el.pendingAmount) {
                                value = el.pendingAmount;
                              }
                            }

                            const newList = [...pendingPayments];
                            const payment = newList.find(
                              (ele) =>
                                ele.candidateAmountToPayID ==
                                el.candidateAmountToPayID
                            );
                            payment!.amountToPay = value;
                            setPendingPayments(newList);

                            updateTotalAmount();
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {currencyFormatter.format(el.amountPaid)}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {GetDateDDMMYYYYY(new Date(el.limitDate))}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box textAlign={"right"}>
            <Typography sx={{ fontSize: 30 }}>
              {" "}
              Total a pagar: {currencyFormatter.format(totalAmount)}
            </Typography>
            <Button
              disabled={
                pendingPayments.filter(
                  (el) => el.isChecked && el.amountToPay > 0
                ).length == 0
              }
              variant="contained"
              color="secondary"
              onClick={submit}
            >
              Siguiente
            </Button>
          </Box>
        </Box>
      ) : (
        <PaymentDetail
          paymentReference={paymentReference}
          back={goBack}
          payments={pendingPayments.filter((el) => el.isChecked)}
        />
      )}
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
    </Box>
  );
}
export default PaymentScreen;
