import {
    AxiosError,
    AxiosInstance,
    // AxiosRequestConfig, // Change to InternalAxiosRequestConfig
    InternalAxiosRequestConfig,
    AxiosResponse,

  } from "axios";

  import axios from "axios";
import { getCookie } from "../Utils";
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;


  const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const { method, url } = config;
    var userToken = getCookie("userToken");

    if (userToken && userToken.length > 0) {
      config.headers.Authorization = `Bearer ${userToken}`
    }


    // Set Headers Here
    // Check Authentication Here
    // Set Loading Start Here
    config.headers["API-KEY"] = process.env.REACT_APP_API_KEY

    return config;
  };

  const onResponse = (response: AxiosResponse): AxiosResponse => {
    const { method, url } = response.config;
    const { status } = response;
    // Set Loading End Here
    // Handle Response Data Here
    // Error Handling When Return Success with Error Code Here

    return response;
  };

  const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    const { statusText, status, data } = error.response as AxiosResponse ?? {};
    if (error && error.response && status === 401) {
      if(data == undefined || data == ""){
        window.location.href = "/login";
      }
    }
    return Promise.reject(error)
  }

  export const setupInterceptors = (instance: AxiosInstance): AxiosInstance => {
    instance.interceptors.request.use(onRequest, onResponseError);
    instance.interceptors.response.use(onResponse, onResponseError);

    return instance;
  };
