import { ChangeEvent, useEffect, useState } from "react";
import CandidateOtherCloseFamily from "../../models/CandidateOtherCloseFamily";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import AppSelect from "../../components/AppSelect";
import FamilyRelationship from "../../models/FamilyRelationship";
import { handleInputChangeToUppercase, phoneFormatter } from "../../Utils";
import MigratoryState from "../../models/MigratoryState";
import { Country } from "../../models/Country";

import utilsService from "../../services/UtilsService";
import LoadingModal from "../../components/LoadingModal";
import candidateService from "../../services/CandidateService";
import IOption from "../../interfaces/IOption";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Requerido"),
  lastName: Yup.string().required("Requerido"),
  outofCountryUSAFamilyRelationshipId: Yup.string().required("Requerido"),
  phonePrincipal: Yup.string().required("Requerido"),
  outofCountryPeriodValue: Yup.string().required("Requerido"),
  outofCountryMigratoryStateId: Yup.string().required("Requerido"),
  outofCountryCountryID: Yup.string().required("Requerido"),
  outofCountryStateText: Yup.string().required("Requerido"),
  outofCountryCityText: Yup.string().required("Requerido"),
  pagaraSuPrograma: Yup.string().required("Requerido"),
  outofCountryAddress: Yup.string().required("Requerido"),
});

interface ICandidateOtherFamilyModal {
  name: string;
  secondName: string;
  lastName: string;
  secondLastName: string;
  outofCountryUSAFamilyRelationshipId: string;
  phonePrincipal: string;
  outofCountryPeriodValue: string;
  outofCountryMigratoryStateId: string;
  outofCountryCountryID: string;
  outofCountryStateText: string;
  outofCountryCityText: string;
  outofCountryZipCode: string;
  pagaraSuPrograma: string;
  outofCountryAddress: string;
}

interface CandidateOtherFamilyModalProps {
  close: () => void;
  otherFamily: CandidateOtherCloseFamily;
  onSave: () => void;
}

function CandidateOtherFamilyModal({
  close,
  otherFamily,
  onSave,
}: CandidateOtherFamilyModalProps) {
  const [initialValues, setInitialValues] =
    useState<ICandidateOtherFamilyModal>({
      name: "",
      secondName: "",
      lastName: "",
      secondLastName: "",
      outofCountryUSAFamilyRelationshipId: "",
      phonePrincipal: "",
      outofCountryPeriodValue: "",
      outofCountryMigratoryStateId: "",
      outofCountryCountryID: "",
      outofCountryStateText: "",
      outofCountryCityText: "",
      outofCountryZipCode: "",
      pagaraSuPrograma: "",
      outofCountryAddress: "",
    });
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [familyRelationships, setFamilyRelationships] = useState<
    FamilyRelationship[]
  >([]);
  const [migratoryStates, setMigratoryStates] = useState<MigratoryState[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function submit(values: ICandidateOtherFamilyModal) {
    const family: CandidateOtherCloseFamily = {
      ...values,
      candidateRelatedID: otherFamily.candidateRelatedID,
      outofCountryUSAFamilyRelationshipId: Number(
        values.outofCountryUSAFamilyRelationshipId
      ),
      outofCountryPeriodValue: values.outofCountryPeriodValue.toString(),
      outofCountryMigratoryStateId: Number(values.outofCountryMigratoryStateId),
      outofCountryCountryID: Number(values.outofCountryCountryID),
    };

    try {
      setIsSaving(true);
      await candidateService.saveCandidateOtherCloseFamily(family);
      setIsSaving(false);
      onSave();
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  async function initialData() {
    const country = await utilsService.getCountries();
    const relationships = await utilsService.getFamilyRelationships();
    const migratory = await utilsService.getMigratoryStates();

    setInitialValues({
      ...otherFamily,
      secondName: otherFamily.secondName ?? "",
      secondLastName: otherFamily.secondLastName ?? "",
      outofCountryUSAFamilyRelationshipId:
        otherFamily.outofCountryUSAFamilyRelationshipId! > 0
          ? otherFamily.outofCountryUSAFamilyRelationshipId!.toString()
          : "",
      outofCountryPeriodValue: otherFamily.outofCountryPeriodValue ?? "",
      outofCountryMigratoryStateId:
        otherFamily.outofCountryMigratoryStateId! > 0
          ? otherFamily.outofCountryMigratoryStateId!.toString()
          : "",
      outofCountryCountryID:
        otherFamily.outofCountryCountryID! > 0
          ? otherFamily.outofCountryCountryID!.toString()
          : "",
      outofCountryStateText: otherFamily.outofCountryStateText ?? "",
      outofCountryCityText: otherFamily.outofCountryCityText ?? "",
      outofCountryZipCode: otherFamily.outofCountryZipCode ?? "",
      pagaraSuPrograma: otherFamily.pagaraSuPrograma ?? "NO",
      outofCountryAddress: otherFamily.outofCountryAddress ?? "",
    });
    setIsLoading(false);
    setCountries(country);
    setFamilyRelationships(relationships);
    setMigratoryStates(migratory);
  }
  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Box display="block" margin="auto" textAlign="center">
          <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
          <Typography variant="body1">Cargando...</Typography>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {(props: FormikProps<ICandidateOtherFamilyModal>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Grid container spacing={1}>
                  {/* Primer Nombre */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Primer nombre" required />
                    <AppTextField
                      error={errors.name && touched.name ? true : false}
                      name="name"
                      value={values.name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Segundo Nombre */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Segundo nombre" />
                    <AppTextField
                      name="secondName"
                      value={values.secondName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Primer apellido */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Primer apellido" required />
                    <AppTextField
                      error={errors.lastName && touched.lastName ? true : false}
                      name="lastName"
                      value={values.lastName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Segundo apellido */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Segundo apellido" />
                    <AppTextField
                      name="secondLastName"
                      value={values.secondLastName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Parentezco */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Parentesco" required />
                    <AppSelect
                      name="outofCountryUSAFamilyRelationshipId"
                      error={
                        errors.outofCountryUSAFamilyRelationshipId &&
                        touched.outofCountryUSAFamilyRelationshipId
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.outofCountryUSAFamilyRelationshipId}
                      onChange={handleChange}
                    >
                      {familyRelationships.map((el) => (
                        <MenuItem
                          key={el.familyRelationshipId}
                          value={el.familyRelationshipId}
                        >
                          {el.name}
                        </MenuItem>
                      ))}
                    </AppSelect>
                  </Grid>

                  {/* Numero de celular */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Número celular" required />
                    <AppTextField
                      error={
                        errors.phonePrincipal && touched.phonePrincipal
                          ? true
                          : false
                      }
                      name="phonePrincipal"
                      value={values.phonePrincipal}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const phoneRegex = /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                        const { value } = event.target;
                        if (phoneRegex.test(value)) {
                          const formattedPhone = phoneFormatter(value);
                          setFieldValue("phonePrincipal", formattedPhone);
                        }
                      }}
                    />
                  </Grid>
                  {/* Tiempo en el exterior */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph
                      title="Tiempo residiendo el el exterior"
                      required
                    />
                    <AppTextField
                      // type="number"
                      error={
                        errors.outofCountryPeriodValue &&
                        touched.outofCountryPeriodValue
                          ? true
                          : false
                      }
                      name="outofCountryPeriodValue"
                      value={values.outofCountryPeriodValue}
                      onChange={handleChange}
                    />
                  </Grid>
                  {/* Status migratorio */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Estatus migratorio" required />
                    <AppSelect
                      name="outofCountryMigratoryStateId"
                      error={
                        errors.outofCountryMigratoryStateId &&
                        touched.outofCountryMigratoryStateId
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.outofCountryMigratoryStateId}
                      onChange={handleChange}
                    >
                      {migratoryStates.map((el) => (
                        <MenuItem
                          key={el.migratoryStateId}
                          value={el.migratoryStateId.toString()}
                        >
                          {el.name}
                        </MenuItem>
                      ))}
                    </AppSelect>
                  </Grid>

                  {/* Direcion Completa */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Dirección completa" required />
                    <AppTextField
                      name="outofCountryAddress"
                      error={
                        errors.outofCountryAddress &&
                        touched.outofCountryAddress
                          ? true
                          : false
                      }
                      value={values.outofCountryAddress}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>

                  {/* Country */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="País" required />
                    <AppSelect
                      name="outofCountryCountryID"
                      error={
                        errors.outofCountryCountryID &&
                        touched.outofCountryCountryID
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.outofCountryCountryID}
                      onChange={handleChange}
                      isSearchable={true}
                      options={countries.map((el) => {
                        var option: IOption = {
                          label: el.name!,
                          value: el.countryId.toString(),
                        };
                        return option;
                      })}
                    >
                      {/* {countries.map((el) => (
                        <MenuItem
                          key={el.countryId}
                          value={el.countryId.toString()}
                        >
                          {el.name}
                        </MenuItem>
                      ))} */}
                    </AppSelect>
                  </Grid>

                  {/* Estado */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Provincia o estado" required />
                    <AppTextField
                      error={
                        errors.outofCountryStateText &&
                        touched.outofCountryStateText
                          ? true
                          : false
                      }
                      name="outofCountryStateText"
                      value={values.outofCountryStateText}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>

                  {/* Ciudad */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Ciudad o Municipio" required />
                    <AppTextField
                      error={
                        errors.outofCountryCityText &&
                        touched.outofCountryCityText
                          ? true
                          : false
                      }
                      name="outofCountryCityText"
                      value={values.outofCountryCityText}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>

                  {/* Codigo Postal */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="Código postal " />
                    <AppTextField
                      name="outofCountryZipCode"
                      value={values.outofCountryZipCode}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  {/* Pagara programa */}
                  <Grid item xs={12}>
                    <Paragraph
                      title="¿Esta persona pagará tu programa?"
                      required
                    />
                    <AppSelect
                      name="pagaraSuPrograma"
                      error={
                        errors.pagaraSuPrograma && touched.pagaraSuPrograma
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.pagaraSuPrograma}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>

                  {/* Buttons */}
                  <Grid item xs={12}>
                    <Button
                      onClick={close}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Cerrar
                    </Button>
                    <Button
                      onClick={() => {
                        if (!isValid) {
                          setIsValidForm(true);
                        }
                      }}
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Guardar"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}

      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default CandidateOtherFamilyModal;
