import { Box, Button } from "@mui/material";
import { ApplicationState } from "../models/ApplicationState";
import { useAppSelector } from "../store/store";
import { useContext } from "react";
import { CandidateApplicationContext } from "../screens/CandidateApplicationScreen";

interface StepNumberProps {
  currentStep: number;
  isActive: boolean;
}

function StepNumber({ currentStep, isActive }: StepNumberProps) {
  const appState: ApplicationState = useAppSelector((state) => state.appState);
  const { step, setStep } = useContext(CandidateApplicationContext);
  return appState.isAdmin ? (
    <Box
      onClick={() => {
        setStep(currentStep);
      }}
      bgcolor={isActive ? "secondary.main" : "primary.main"}
      sx={{
        height: "30px",
        width: "30px",
        borderRadius: "60px",
        //backgroundColor: "#00166B",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      {currentStep}
    </Box>
  ) : (
    <Box
      bgcolor={isActive ? "secondary.main" : "primary.main"}
      sx={{
        height: "30px",
        width: "30px",
        borderRadius: "60px",
        //backgroundColor: "#00166B",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      {currentStep}
    </Box>
  );
}

export default StepNumber;
