import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ResetPasswordComponent from "../components/ResetPasswordComponent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { logout } from "../store/appStateReducer";
import { useAppDispatch } from "../store/store";
import { setBodyColor } from "../Utils";
import ConfirmationDialog from "../components/ConfirmationDialog";
import CandidateApplicationScreen from "./CandidateApplicationScreen";

function ProfileScreen() {
  const [changePassport, setChangePassport] = useState<boolean>(false);
  var dispatch = useAppDispatch();
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Perfil
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Perfil
      </Typography> */}
      <ListItem sx={{ mb: 2 }} disablePadding>
        <ListItemButton
          sx={{ backgroundColor: "#0000000a" }}
          onClick={() => {
            setChangePassport(true);
          }}
        >
          <ListItemText primary="Cambiar contraseña" />
        </ListItemButton>
      </ListItem>

      <CandidateApplicationScreen />
      <Dialog fullWidth={true} maxWidth="md" open={changePassport}>
        <DialogTitle>Cambiar Contraseña</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setChangePassport(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ResetPasswordComponent
            onChanged={() => {
              setChangePassport(false);
            }}
          />
        </DialogContent>
      </Dialog>

      {/* <Fab
        onClick={() => {
          setOpenDialog(true);
          setDialogTitle("Confirmar");
          setDialogDesc("¿Estas seguro de que deseas salir?");
          setDialogButton([
            <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>,
            <Button
              onClick={() => {
                setOpenDialog(false);
                dispatch(logout());
                setBodyColor("#00166B");
                document.body.classList.add("body-custom-bg");
              }}
            >
              Ok
            </Button>,
          ]);
        }}
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
        color="primary"
        aria-label="add"
      >
        <ExitToAppIcon />
      </Fab> */}
      {/* <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      /> */}
    </Box>
  );
}

export default ProfileScreen;
