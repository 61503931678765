import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { ApplicationState } from "../../models/ApplicationState";
import { GetDateYYMMYYYYHHmm, State, currencyFormatter } from "../../Utils";
import { useEffect, useState } from "react";
import personajeAvionPose2 from "../../assets/images/personaje-avion-pose2.png";
import InterviewModal from "./InterviewModal";
import { changeInterviewDate, login } from "../../store/appStateReducer";
import CandidateInterviewUpdate from "../../models/CandidateInterviewUpdate";

import ResetPasswordScreen from "../ResetPasswordScreen";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LoadingModal from "../../components/LoadingModal";
import AuthService from "../../services/AuthService";
import WelcomeInterviewModal from "./WelcomeInterviewModal";
import CandidateEventTicket from "../../models/CandidateEventTicket";
import CandidateService from "../../services/CandidateService";
import AppSpinner from "../../components/AppSpinner";
import EventTicketModal from "./EventTicketModal";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";

function DashboardScreen() {
  const appState: ApplicationState = useAppSelector((state) => state.appState);
  const [editInterview, setEditInterview] = useState<boolean>(false);
  const [openWelcomInterview, setOpenWelcomInterview] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [eventLoading, setEventLoading] = useState<boolean>(true);
  const [eventTickets, setEventTickets] = useState<CandidateEventTicket[]>([]);
  const [eventModalOpen, setEventModalOpen] = useState<boolean>(false);
  const [eventTicket, setEventTicket] = useState<CandidateEventTicket>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();

  var dispatch = useAppDispatch();
  var navigate = useNavigate();
  async function initialLoad() {
    const tickets = await CandidateService.getCandidateEventTickets();
    setEventTickets(tickets);
    setEventLoading(false);
  }

  function denyEventTicket(et: CandidateEventTicket) {
    setDialogTitle("Confirmar");
    setDialogDesc(
      "¿Estás seguro de que deseas rechazar estas boletas? Una vez rechazadas, no podrás realizar cambios ni ingresar a este evento exclusivo."
    );
    setOpenDialog(true);
    setDialogButton([
      <Button
        variant="contained"
        color="customGrey"
        onClick={() => {
          setOpenDialog(false);
        }}
      >
        Cancelar
      </Button>,
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          setOpenDialog(false);
          setIsLoading(true);
          try {
            await CandidateService.acceptDenyAventTicket(et!.eventoBoletoID, 0);
            setIsLoading(true);
            setEventLoading(true);
            await initialLoad();
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
            setOpenDialog(true);
            setDialogTitle("Error");
            setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
            setDialogButton(
              <Button onClick={() => setOpenDialog(false)}>Ok</Button>
            );
          }
        }}
      >
        Rechazar
      </Button>,
    ]);
  }

  useEffect(() => {
    initialLoad();
  }, []);

  return (
    <Box>
      <TopSection />

      {appState.mustChangePassword ? (
        <ResetPasswordScreen />
      ) : (
        <Grid container>
          {eventLoading ? (
            <Grid item xs={12} mb={2}>
              <AppSpinner />
            </Grid>
          ) : (
            <CandidateEventTickets />
          )}

          <Grid sx={{ mb: 2 }} item xs={12}>
            {appState.candidateStatusMessage != "" ? (
              <Alert style={{ fontSize: 18 }} severity="warning">
                {appState.candidateStatusMessage}
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={4} order={{ xs: 2, lg: 1 }}>
            <img
              alt="Ace Baby"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "500px",
                objectFit: "contain",
                transform: "scaleX(-1)",
              }}
              src={personajeAvionPose2}
            />
          </Grid>

          {appState.applicationStateId == 62 ||
          appState.applicationStateId == 25 ||
          appState.editForm ? (
            <CandidateInterview />
          ) : null}

          {!appState.editForm &&
          appState.applicationStateId > State.Declinado &&
          appState.applicationStateId != State.PendienteConfirmacion ? (
            <SummarySection />
          ) : null}

          {appState.applicationStateId == State.CompletedProgram ||
          appState.applicationStateId == State.CompletedProgramReturned ||
          appState.applicationStateId == State.Retire ||
          appState.applicationStateId == 0 ? (
            <NoActiveCandidate />
          ) : null}
        </Grid>
      )}
      <LoadingModal isLoading={isLoading} loadingMessage="Actualizando" />
    </Box>
  );

  function CandidateEventTickets() {
    return (
      <Grid sx={{ mb: 2 }} item xs={12}>
        {eventTickets.length > 0 ? (
          <Box>
            <Typography variant="h5">Próximos Eventos</Typography>

            <List>
              {eventTickets.map((el, index) => (
                <ListItem
                  sx={{ mb: 2, display: "block" }}
                  key={index}
                  disablePadding
                >
                  <img
                    style={{
                      maxHeight: 500,
                      maxWidth: "100%",
                      display: "block",
                      marginBottom: 10,
                    }}
                    alt="Evento"
                    src={el.soldout ? el.soldoutBanner : el.imagenURLWeb}
                  />
                  {el.soldout ? (
                    <div className="mt-2">
                      <Alert sx={{ mb: 2 }} severity="info">
                        {el.soldoutText}
                      </Alert>
                      <Button
                        variant="contained"
                        href={el.soldoutForm}
                        target="_blank"
                      >
                        Inscribirte
                      </Button>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Button
                        color="secondary"
                        onClick={(e) => {
                          setEventTicket(el);
                          setEventModalOpen(true);
                        }}
                        variant="contained"
                      >
                        Aceptar Event
                      </Button>
                      <Button
                        sx={{ ml: 2 }}
                        color="error"
                        onClick={(e) => {
                          denyEventTicket(el);
                        }}
                        variant="contained"
                      >
                        Rechazar
                      </Button>
                    </div>
                  )}
                </ListItem>
              ))}
            </List>
            <EventTicketModal
              savedChanges={async () => {
                setEventLoading(true);
                setEventModalOpen(false);
                setDialogTitle("Gracias");
                setDialogDesc(
                  "Muchas gracias por su participación en este evento. Por favor, revise su estado de cuenta y 'eventos' para obtener su boleta digital en forma de QR. Recuerde presentarla el día del evento para entrar. ¡Te esperamos!"
                );
                setDialogButton(
                  <Button
                    onClick={() => {
                      setOpenDialog(false);
                      navigate("/events");
                    }}
                  >
                    Ok
                  </Button>
                );
                setOpenDialog(true);
                await initialLoad();
              }}
              eventTicket={eventTicket}
              eventModalOpen={eventModalOpen}
              setEventModalOpen={(isOpen: boolean) => {
                setEventModalOpen(isOpen);
              }}
            />
          </Box>
        ) : null}

        <ConfirmationDialog
          maxWidth="sm"
          open={openDialog}
          title={dialogTitle}
          description={dialogDesc}
          buttons={dialogButton}
        />
      </Grid>
    );
  }

  function NoActiveCandidate() {
    return (
      <Grid my="auto" item xs={12} lg={8} order={{ xs: 1, lg: 2 }}>
        <Grid container justifyContent="center">
          <Grid item lg={10} xs={12}>
            <Box borderRadius={4} p={3} color="white" bgcolor="primary.main">
              <Typography sx={{ mb: 2 }}>
                Estimado aplicante, en este momento no posee una solicitud
                activa en nuestros programas.
              </Typography>
              <Typography sx={{ mb: 2 }}>
                Te invitamos a ir a la opción de solicitudes y hacer clic en el
                botón re aplicar para que puedas nuevamente participar en uno de
                nuestros programas y continuar siendo parte de esta familia Ace
                internationa
              </Typography>
              <Typography>
                Atentamente
                <br /> Equipo Ace International
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function SummarySection() {
    return (
      <Grid
        rowSpacing={2}
        columnSpacing={2}
        item
        xs={12}
        lg={8}
        order={{ xs: 1, lg: 2 }}
      >
        {appState.welcomeCallNeeded &&
        appState.welcomeCallDateTime == null &&
        !appState.welcomeCallCompleted ? (
          <WelcomeCallSection />
        ) : null}
        <Grid columnSpacing={2} rowSpacing={2} container>
          <Grid item xs={12}>
            <Typography variant="h5">Garantes</Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box
              color="white"
              borderRadius={2}
              height={100}
              sx={{ backgroundColor: "#26C6DB", display: "flex" }}
            >
              <Box
                p={2}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <PersonIcon sx={{ fontSize: "40px" }} />
                <Box
                  textAlign="right"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography sx={{ fontSize: "19px" }}>
                    {appState.guarantorStatus != null &&
                      appState?.guarantorStatus.toUpperCase()}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Estatus De La Garantia
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box
              borderRadius={2}
              height={100}
              sx={{ backgroundColor: "#66bb6a", display: "flex" }}
            >
              <Box
                color="white"
                p={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <CheckCircleIcon sx={{ fontSize: "40px" }} />
                <Box
                  textAlign="right"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography sx={{ fontSize: "25px" }}>
                    {" "}
                    {appState?.guarantorPercentage != null &&
                      appState?.guarantorPercentage}
                    %
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Porcentaje Aprobado
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Balance</Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box
              color="white"
              borderRadius={2}
              height={100}
              sx={{ backgroundColor: "#e17055", display: "flex" }}
            >
              <Box
                p={2}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <MonetizationOnIcon sx={{ fontSize: "40px" }} />
                <Box
                  textAlign="right"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography sx={{ fontSize: "25px" }}>
                    {appState.pendingAmountPesos != null &&
                      currencyFormatter.format(appState?.pendingAmountPesos)}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Total adeudado en pesos
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box
              borderRadius={2}
              height={100}
              sx={{ backgroundColor: "#74b9ff", display: "flex" }}
            >
              <Box
                color="white"
                p={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <MonetizationOnIcon sx={{ fontSize: "40px" }} />
                <Box
                  textAlign="right"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography sx={{ fontSize: "25px" }}>
                    {appState.pendingAmountDollar != null &&
                      currencyFormatter.format(appState?.pendingAmountDollar)}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Total adeudado en dólares
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function CandidateInterview() {
    return (
      <Grid my="auto" item xs={12} lg={8} order={{ xs: 1, lg: 2 }}>
        <Box borderRadius={4} p={3} color="white" bgcolor="primary.main">
          <Typography textAlign="center" mb={2} variant="h5">
            Detalles de tu entrevista
          </Typography>
          {appState.interviewStatus == null ||
          appState.interviewStatus == "" ? (
            <Box mb={2} textAlign="center">
              <Typography textAlign="center">
                Estimado aplicante, por favor agregar una entrevista.
              </Typography>
              <Box>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      setIsNew(true);
                      setEditInterview(true);
                    }}
                    color="secondary"
                    variant="contained"
                    sx={{ bgcolor: "secondary.main", mt: "10px" }}
                  >
                    Agregar Entrevista
                  </Button>
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box>
              {appState.applicationStateId == 62 ? (
                <Box mb={2} display="flex">
                  <Typography>
                    Estimado aplicante, te indicamos que tu perfil está
                    pendiente de confirmación por parte de nuestro personal. Una
                    vez agotemos este proceso recibirás un correo y una
                    notificación con los siguientes paso a seguir.
                  </Typography>
                </Box>
              ) : null}

              <Box mb={2} display="flex">
                <Typography mr={1} variant="body1">
                  Fecha/Hora:{" "}
                </Typography>
                <Typography>
                  {GetDateYYMMYYYYHHmm(new Date(appState.interviewDate!))}
                </Typography>
              </Box>
              <Box mb={2} display="flex">
                <Typography mr={1} variant="body1">
                  Lugar:
                </Typography>
                <Typography>{appState.interviewPlaceAddress}</Typography>
              </Box>
              <Box mb={1} display="flex">
                <Typography mr={1} variant="body1">
                  Modalidad:
                </Typography>
                <Typography>{appState.interviewType}</Typography>
              </Box>
              <Box>
                <Typography mr={1} variant="body1">
                  Atentamente
                </Typography>
                <Typography mr={1} variant="body1">
                  Equipo Ace International
                </Typography>
              </Box>
              {appState.applicationStateId == 23 ||
              appState.applicationStateId == 62 ? (
                <Box>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setIsNew(false);
                        setEditInterview(true);
                      }}
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main", mt: "10px" }}
                    >
                      Editar
                    </Button>
                  </Grid>
                </Box>
              ) : null}
            </Box>
          )}

          <Dialog fullWidth={true} maxWidth="sm" open={editInterview}>
            <DialogTitle>Editar Entrevista</DialogTitle>
            <DialogContent>
              <InterviewModal
                isNew={isNew}
                onSave={async (date, hour) => {
                  const interviewUpdate: CandidateInterviewUpdate = {
                    date: date,
                    hour: hour,
                  };

                  if (isNew) {
                    setIsLoading(true);
                    var applicationState: ApplicationState | null =
                      await AuthService.loadUserInfo();

                    dispatch(login({ applicationState: applicationState! }));
                    setIsLoading(false);
                  } else {
                    dispatch(changeInterviewDate(interviewUpdate));
                  }

                  setEditInterview(false);
                }}
                onClose={() => {
                  setEditInterview(false);
                }}
              />
            </DialogContent>
          </Dialog>
        </Box>
      </Grid>
    );
  }

  function WelcomeCallSection() {
    return (
      <Grid sx={{ mb: 5 }} item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Box borderRadius={4} p={3} color="white" bgcolor="primary.main">
              <Typography textAlign="center" mb={2} variant="h5">
                ¡Programa tu llamada de bienvenida!
              </Typography>
              <Typography sx={{ mb: 2 }}>
                Como parte del protocolo de aceptación con nosotros, es
                necesario programar tu llamada de bienvenida al programa. Esta
                llamada es esencial para brindarte toda la información
                importante y responder a cualquier pregunta que puedas tener.
              </Typography>
              <Box>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      setOpenWelcomInterview(true);
                    }}
                    color="secondary"
                    variant="contained"
                    sx={{ bgcolor: "secondary.main", mt: "10px" }}
                  >
                    AGENDAR LLAMADA
                  </Button>
                </Grid>
              </Box>

              <Dialog
                sx={{
                  "& .MuiDialog-paper": {
                    transform: "translateY(-100px)",
                  },
                }}
                fullWidth={true}
                maxWidth="md"
                open={openWelcomInterview}
              >
                <DialogTitle>¡Programa tu llamada de bienvenida!</DialogTitle>
                <DialogContent>
                  <WelcomeInterviewModal
                    onClose={() => {
                      setOpenWelcomInterview(false);
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function TopSection() {
    return (
      <Box textAlign="center" mb={3}>
        <Box
          sx={{
            mb: 1,
            textAlign: "center",
            padding: "0px !important",
          }}
        >
          <Box
            component="span"
            sx={{
              display: "inline-block",
              mr: 1,
              fontWeight: "bold",
              fontSize: 45,
            }}
          >
            ¡Bienvenido (a)
          </Box>
          <Box
            sx={{
              display: "inline-block",
              mr: 1,
              fontSize: 45,
              fontWeight: "bold",
              color: "#FE6B1B",
            }}
            component="span"
          >
            Ace Traveler!
          </Box>
        </Box>
        <Box
          component="p"
          sx={{
            display: "inline-block",
            my: 1,
            fontWeight: "bold",
            fontSize: 40,
          }}
        >
          {appState.fullName}
        </Box>
      </Box>
    );
  }
}
export default DashboardScreen;
