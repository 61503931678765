import { Box, CircularProgress, Modal, Typography } from "@mui/material";

interface LoadingModalProps {
  isLoading: boolean;
  loadingMessage: string;
}

function LoadingModal({ isLoading, loadingMessage }: LoadingModalProps) {
  return (
    <Modal
      open={isLoading}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Box
          bgcolor="white"
          alignItems="center"
          display="flex"
          justifyContent="center"
          width={120}
          height={120}
          borderRadius="10px"
          position="absolute"
          top="50%"
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography
              variant="body1"
              style={{ color: "black", marginBottom: "5px" }}
            >
              {loadingMessage}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
export default LoadingModal;
