import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import ConfirmationDialog from "../components/ConfirmationDialog";
import logo from "../assets/images/ace_logo_big.png";
import logoBlue from "../assets/images/ace-logo-blue.jpg";
import ErrorText from "../components/ErrorText";
import AppTextField from "../components/AppTextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import authService from "../services/AuthService";
import LoadingModal from "../components/LoadingModal";
import { useNavigate } from "react-router-dom";
import Paragraph from "../components/Paragraph";
import ReCAPTCHA from "react-google-recaptcha";

interface IForgotPassword {
  email: string;
  token: string;
}

const validationScheme = Yup.object().shape({
  email: Yup.string().required("Requerido"),
});

function ForgotPasswordScreen() {
  const [initialValues, setInitialValues] = useState<IForgotPassword>({
    email: "",
    token: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [reToken, setReToken] = useState<string | null>(null);

  const navigate = useNavigate();

  async function submit(values: IForgotPassword) {
    if (reToken == null) {
      setIsSaving(false);
      setDialogTitle("Lo Sentimos");
      setDialogDesc("Debes de verificar que no eres un robot.");
      setOpenDialog(true);
      setDialogButton(
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Ok
        </Button>
      );
      return;
    }

    try {
      setIsSaving(true);
      await authService.forgotPassword(values.email, reToken);
      setIsSaving(false);
      setDialogTitle("Enviado");
      setDialogDesc(
        "Se ha enviado un correo a su email para resetear su contraseña"
      );
      setOpenDialog(true);
      setDialogButton(
        <Button
          onClick={() => {
            setOpenDialog(false);
            navigate("/login");
          }}
        >
          Ok
        </Button>
      );
    } catch (error: any) {
      if (error.response.status == 403) {
        setIsSaving(false);
        setOpenDialog(true);
        setDialogTitle("Error");
        setDialogDesc(error.response.data);
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
        return;
      }
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Container>
      <Box display="inline-block" sx={{ mt: 3 }}>
        <Link href="/login" sx={{ display: "flex" }}>
          <ArrowBackIosIcon sx={{ color: "white" }} />
          <Typography sx={{ color: "white" }}>Login</Typography>
        </Link>
      </Box>
      <Box
        // sx={{
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "center",
        //   justifyContent: "center",
        //   marginTop: "20px",
        // }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          maxWidth: { lg: "570px", xs: "100%" },
          margin: "auto",
          width: "100%",
          backgroundColor: "white",
          padding: 2,
          borderRadius: 5,
          position: { lg: "absolute" },
          top: { lg: "50%" },
          left: { lg: "50%" },
          transform: { lg: "translate(-50%,-50%)" },
        }}
      >
        <Box sx={{ mb: 5 }}>
          <img style={{ width: "100%", maxWidth: "400px" }} src={logoBlue} />
        </Box>

        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationScheme}
          validateOnMount={true}
        >
          {(props: FormikProps<IForgotPassword>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "450px",
                  width: "100%",
                }}
              >
                {/* <Typography
                  variant="body1"
                  style={{ color: "white", marginBottom: "5px" }}
                >
                  Email
                </Typography> */}
                <Paragraph title="Email" />
                <AppTextField
                  error={errors.email && touched.email ? true : false}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <ErrorText text={errors.email} />
                ) : null}
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPCHA_KEY!}
                  onChange={(token: string | null) => {
                    setReToken(token);
                  }}
                />

                <Button
                  fullWidth
                  disabled={isSubmitting}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ bgcolor: "secondary.main", mt: 2 }}
                >
                  {isSubmitting ? <>Guardando...</> : "Guardar"}
                </Button>
              </Form>
            );
          }}
        </Formik>
        <ConfirmationDialog
          maxWidth="sm"
          open={isValidForm}
          title={"Error"}
          description={"Llenar todos los requisitos en rojo"}
          buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
        />
        <ConfirmationDialog
          maxWidth="sm"
          open={openDialog}
          title={dialogTitle}
          description={dialogDesc}
          buttons={dialogButton}
        />
        <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
      </Box>
    </Container>
  );
}

export default ForgotPasswordScreen;
