import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import Paragraph from "../../components/Paragraph";
import AppSelect from "../../components/AppSelect";
import AppTextField from "../../components/AppTextField";
import utilsService from "../../services/UtilsService";
import University from "../../models/University";
import UniversityCareer from "../../models/UniversityCareer";
import UniversityPeriodType from "../../models/UniversityPeriodType";
import CandidateEducation from "../../models/CandidateEducation";
import candidateService from "../../services/CandidateService";
import { CandidateEducationInterruption } from "../../models/CandidateEducationInterruption";
import EducationInterruptionModal from "./EducationInterruptionModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  GetDateDDMMYYYYY,
  GetDateYYYYMMDD,
  handleInputChangeToUppercase,
} from "../../Utils";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import { CandidateApplicationContext } from "../../screens/CandidateApplicationScreen";
import IOption from "../../interfaces/IOption";
import { StyledTableCell, StyledTableRow } from "../../theme/GlobalTheme";
import { ApplicationState } from "../../models/ApplicationState";
import { useAppSelector } from "../../store/store";
interface ICandidateEducationForm {
  universityId: string;
  enclosure: string;
  universityCareerId: string;
  startCareerDate: string;
  universityPeriodTypeId: string;
  universityPeriodNumber: string;
  quantSignaturesInCurrentPeriod: string;
  quantSignaturesInPreviousPeriod: string;
  fYear: string;
  academicIndex: string;
  careerReason: string;
  plansWhenFinishCareer: string;
  interrupStudiesFlag: string;
  changeCareerFlag: string;
  changeCareerJustification: string | null;
  changeCareerPreviousCareerId: string | null;
  changeUniversityFlag: string;
  changeUniversityPreviousUniversity: string | null;
  changeUniversityPreviousUniversityId: string | null;
  isCurrentlyStudying: string;
  isCurrentlyStudyingExplain: string;
  isGraduated: string | null;
  graduatedYear: string | null;

  creditosActual: string | null;
  creditosAnterior: string | null;
  creditosProximos: string | null;
  quantSignaturesInNextPeriod: string | null;
  quantAsigApprovedInAllCareer: string | null;
}

const candidateEducationFormValidationScheme = Yup.object().shape({
  universityId: Yup.string().required("Requerido"),
  enclosure: Yup.string().required("Requerido"),
  universityCareerId: Yup.string().required("Requerido"),
  startCareerDate: Yup.string().required("Requerido"),
  universityPeriodTypeId: Yup.string().required("Requerido"),
  universityPeriodNumber: Yup.string().required("Requerido"),
  quantSignaturesInCurrentPeriod: Yup.string().required("Requerido"),
  quantSignaturesInPreviousPeriod: Yup.string().required("Requerido"),
  creditosActual: Yup.string().required("Requerido"),
  creditosAnterior: Yup.string().required("Requerido"),
  creditosProximos: Yup.string().required("Requerido"),
  quantSignaturesInNextPeriod: Yup.string().required("Requerido"),
  quantAsigApprovedInAllCareer: Yup.string().required("Requerido"),
  fYear: Yup.string().required("Requerido"),
  academicIndex: Yup.string().required("Requerido"),
  careerReason: Yup.string().required("Requerido"),
  plansWhenFinishCareer: Yup.string().required("Requerido"),
  interrupStudiesFlag: Yup.string().required("Requerido"),
  changeCareerFlag: Yup.string().required("Requerido"),
  changeCareerJustification: Yup.string().when(
    "changeCareerFlag",
    (changeCareerFlag: string | string[], schema) => {
      if (changeCareerFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  changeCareerPreviousCareerId: Yup.string().when(
    "changeCareerFlag",
    (changeCareerFlag: string | string[], schema) => {
      if (changeCareerFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  changeUniversityFlag: Yup.string().required("Requerido"),
  changeUniversityPreviousUniversity: Yup.string().when(
    "changeUniversityFlag",
    (changeUniversityFlag: string | string[], schema) => {
      if (changeUniversityFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  changeUniversityPreviousUniversityId: Yup.string().when(
    "changeUniversityFlag",
    (changeUniversityFlag: string | string[], schema) => {
      if (changeUniversityFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

const otherValidationScheme = Yup.object().shape({
  isCurrentlyStudyingExplain: Yup.string().required("Requerido"),
  isGraduated: Yup.string().required("Requerido"),
  universityCareerId: Yup.string().when(
    "isGraduated",
    (isGraduated: string | string[], schema) => {
      if (isGraduated == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  universityId: Yup.string().when(
    "isGraduated",
    (isGraduated: string | string[], schema) => {
      if (isGraduated == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  graduatedYear: Yup.string().when(
    "isGraduated",
    (isGraduated: string | string[], schema) => {
      if (isGraduated == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

function CandidateEducationForm() {
  const [initialValues, setInitialValues] = useState<ICandidateEducationForm>({
    universityId: "",
    enclosure: "",
    universityCareerId: "",
    startCareerDate: "",
    universityPeriodTypeId: "",
    universityPeriodNumber: "",
    quantSignaturesInCurrentPeriod: "",
    quantSignaturesInPreviousPeriod: "",
    fYear: "",
    academicIndex: "",
    careerReason: "",
    plansWhenFinishCareer: "",
    interrupStudiesFlag: "NO",
    changeCareerFlag: "",
    changeCareerJustification: "",
    changeCareerPreviousCareerId: "",
    changeUniversityFlag: "",
    changeUniversityPreviousUniversity: "",
    changeUniversityPreviousUniversityId: "",
    isCurrentlyStudying: "",
    isCurrentlyStudyingExplain: "",
    isGraduated: "",
    graduatedYear: "",
    creditosActual: "",
    creditosAnterior: "",
    creditosProximos: "",
    quantSignaturesInNextPeriod: "",
    quantAsigApprovedInAllCareer: "",
  });
  const [isCurrentlyStudying, setIsCurrentlyStudying] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [universities, setUniversities] = useState<University[]>([]);
  const [universityCareers, setUniversityCareers] = useState<
    UniversityCareer[]
  >([]);
  const [universityPeriodTypes, setUniversityPeriodTypes] = useState<
    UniversityPeriodType[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [interruptions, setInterruptions] = useState<
    CandidateEducationInterruption[]
  >([]);
  const [interruntionModal, setInterruptionModal] = useState<boolean>(false);
  const [educationInterruption, setEducationInterruption] =
    useState<CandidateEducationInterruption>({
      dateFrom: "",
      dateTo: "",
      justification: "",
      candidateEducationInterruptionID: 0,
    });
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const { step, setStep } = useContext(CandidateApplicationContext);
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  async function otherSubmit(values: ICandidateEducationForm) {
    const candidateEducation: CandidateEducation = {
      ...values,
      universityId: Number(values.universityId),
      universityCareerId: Number(values.universityCareerId),
      universityPeriodTypeId: Number(values.universityPeriodTypeId),
      universityPeriodNumber: null,
      quantSignaturesInCurrentPeriod: null,
      quantSignaturesInPreviousPeriod: null,
      creditosActual: null,
      creditosAnterior: null,
      creditosProximos: null,
      quantSignaturesInNextPeriod: null,
      quantAsigApprovedInAllCareer: null,
      academicIndex: values.academicIndex.toString(),
      interrupStudiesFlag: values.interrupStudiesFlag == "SI",
      fYear: values.fYear != "" ? Number(values.fYear) : null,

      changeCareerFlag: values.changeCareerFlag == "SI",
      changeCareerJustification: values.changeCareerJustification,
      changeCareerPreviousCareerId:
        values.changeCareerPreviousCareerId != ""
          ? Number(values.changeCareerPreviousCareerId)
          : null,
      changeUniversityFlag: values.changeUniversityFlag == "SI",
      changeUniversityPreviousUniversity:
        values.changeUniversityPreviousUniversity,
      changeUniversityPreviousUniversityId:
        values.changeUniversityPreviousUniversityId != ""
          ? Number(values.changeUniversityPreviousUniversityId)
          : null,
      isCurrentlyStudying: isCurrentlyStudying,
      isCurrentlyStudyingExplain: values.isCurrentlyStudyingExplain,
      isGraduated: values.isGraduated == "SI",
      graduatedYear:
        values.graduatedYear != "" ? Number(values.graduatedYear) : null,
    };

    try {
      setIsSaving(true);
      const education: CandidateEducation =
        await candidateService.saveCandidataEducation(candidateEducation);
      setIsSaving(false);
      setStep(step + 1);
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function submit(values: ICandidateEducationForm) {
    if (
      (appState.applicationStateId != 23 && !appState.isAdmin) ||
      window.location.href.includes("profile")
    ) {
      setStep(step + 1);
      return;
    }
    const candidateEducation: CandidateEducation = {
      ...values,
      universityId:
        values.universityId != "" ? Number(values.universityId) : null,
      universityCareerId:
        values.universityCareerId != ""
          ? Number(values.universityCareerId)
          : null,
      universityPeriodTypeId:
        values.universityPeriodTypeId != ""
          ? Number(values.universityPeriodTypeId)
          : null,
      universityPeriodNumber:
        values.universityPeriodNumber != ""
          ? Number(values.universityPeriodNumber)
          : 0,
      quantSignaturesInCurrentPeriod:
        values.quantSignaturesInCurrentPeriod != ""
          ? Number(values.quantSignaturesInCurrentPeriod)
          : 0,
      quantSignaturesInPreviousPeriod:
        values.quantSignaturesInPreviousPeriod != ""
          ? Number(values.quantSignaturesInPreviousPeriod)
          : 0,
      creditosActual:
        values.creditosActual != "" ? Number(values.creditosActual) : 0,
      creditosAnterior:
        values.creditosAnterior != "" ? Number(values.creditosAnterior) : 0,
      creditosProximos:
        values.creditosProximos != "" ? Number(values.creditosProximos) : 0,
      quantSignaturesInNextPeriod:
        values.quantSignaturesInNextPeriod != ""
          ? Number(values.quantSignaturesInNextPeriod)
          : 0,
      quantAsigApprovedInAllCareer:
        values.quantAsigApprovedInAllCareer != ""
          ? Number(values.quantAsigApprovedInAllCareer)
          : 0,
      academicIndex: values.academicIndex.toString(),
      interrupStudiesFlag: values.interrupStudiesFlag == "SI",
      fYear: values.fYear != "" ? Number(values.fYear) : null,

      changeCareerFlag: values.changeCareerFlag == "SI",
      changeCareerJustification: values.changeCareerJustification,
      changeCareerPreviousCareerId:
        values.changeCareerPreviousCareerId != ""
          ? Number(values.changeCareerPreviousCareerId)
          : null,
      changeUniversityFlag: values.changeUniversityFlag == "SI",
      changeUniversityPreviousUniversity:
        values.changeUniversityPreviousUniversity,
      changeUniversityPreviousUniversityId:
        values.changeUniversityPreviousUniversityId != ""
          ? Number(values.changeUniversityPreviousUniversityId)
          : null,
      isCurrentlyStudying: isCurrentlyStudying,
      isCurrentlyStudyingExplain: null,
      isGraduated: false,
      graduatedYear: null,
    };

    if (values.interrupStudiesFlag == "SI" && interruptions.length == 0) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Por favor agregar al menos una interripcion de estudio");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    try {
      setIsSaving(true);
      const education: CandidateEducation =
        await candidateService.saveCandidataEducation(candidateEducation);
      setIsSaving(false);
      setStep(step + 1);
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    const units = await utilsService.getUniversities();
    const careers = await utilsService.getUniversityCareers();
    const types = await utilsService.getUniversityPeriodTypes();
    const interrups =
      await candidateService.getCandidateEducationInterruptions();
    const candidateEducation: CandidateEducation =
      await candidateService.getCandidateEducation();

    setInitialValues({
      universityId:
        candidateEducation.universityId != null
          ? candidateEducation.universityId.toString()
          : "",
      enclosure:
        candidateEducation.enclosure != null
          ? candidateEducation.enclosure.toString()
          : "",
      universityCareerId:
        candidateEducation.universityCareerId != null
          ? candidateEducation.universityCareerId.toString()
          : "",
      startCareerDate:
        candidateEducation.startCareerDate != null &&
        candidateEducation.startCareerDate != ""
          ? candidateEducation.startCareerDate
          : "",
      universityPeriodTypeId:
        candidateEducation.universityPeriodTypeId != null
          ? candidateEducation.universityPeriodTypeId.toString()
          : "",
      universityPeriodNumber:
        candidateEducation.universityPeriodNumber != null
          ? candidateEducation.universityPeriodNumber.toString()
          : "",
      quantSignaturesInCurrentPeriod:
        candidateEducation.quantSignaturesInCurrentPeriod != null
          ? candidateEducation.quantSignaturesInCurrentPeriod.toString()
          : "",
      quantSignaturesInPreviousPeriod:
        candidateEducation.quantSignaturesInPreviousPeriod != null
          ? candidateEducation.quantSignaturesInPreviousPeriod.toString()
          : "",
      fYear:
        candidateEducation.fYear != null
          ? candidateEducation.fYear.toString()
          : "",
      academicIndex:
        candidateEducation.academicIndex != null
          ? candidateEducation.academicIndex.toString()
          : "",
      careerReason:
        candidateEducation.careerReason != null
          ? candidateEducation.careerReason.toString()
          : "",
      plansWhenFinishCareer:
        candidateEducation.plansWhenFinishCareer != null
          ? candidateEducation.plansWhenFinishCareer.toString()
          : "",
      interrupStudiesFlag: candidateEducation.interrupStudiesFlag ? "SI" : "NO",
      changeCareerFlag: candidateEducation.changeCareerFlag ? "SI" : "NO",
      changeCareerJustification:
        candidateEducation.changeCareerJustification != null
          ? candidateEducation.changeCareerJustification.toString()
          : "",
      changeCareerPreviousCareerId:
        candidateEducation.changeCareerPreviousCareerId != null
          ? candidateEducation.changeCareerPreviousCareerId.toString()
          : "",
      changeUniversityFlag: candidateEducation.changeUniversityFlag
        ? "SI"
        : "NO",
      changeUniversityPreviousUniversity:
        candidateEducation.changeUniversityPreviousUniversity != null
          ? candidateEducation.changeUniversityPreviousUniversity
          : "",
      changeUniversityPreviousUniversityId:
        candidateEducation.changeUniversityPreviousUniversityId != null
          ? candidateEducation.changeUniversityPreviousUniversityId.toString()
          : "",
      isCurrentlyStudying: candidateEducation.isCurrentlyStudying ? "SI" : "NO",
      isCurrentlyStudyingExplain:
        candidateEducation.isCurrentlyStudyingExplain ?? "",
      isGraduated: candidateEducation.isGraduated ? "SI" : "NO",
      graduatedYear:
        candidateEducation.graduatedYear != null
          ? candidateEducation.graduatedYear.toString()
          : "",
      creditosActual:
        candidateEducation.creditosActual != null
          ? candidateEducation.creditosActual.toString()
          : "",
      creditosAnterior:
        candidateEducation.creditosAnterior != null
          ? candidateEducation.creditosAnterior.toString()
          : "",
      creditosProximos:
        candidateEducation.creditosProximos != null
          ? candidateEducation.creditosProximos.toString()
          : "",
      quantSignaturesInNextPeriod:
        candidateEducation.quantSignaturesInNextPeriod != null
          ? candidateEducation.quantSignaturesInNextPeriod.toString()
          : "",
      quantAsigApprovedInAllCareer:
        candidateEducation.quantAsigApprovedInAllCareer != null
          ? candidateEducation.quantAsigApprovedInAllCareer.toString()
          : "",
    });

    setIsCurrentlyStudying(candidateEducation.isCurrentlyStudying ?? false);

    if (
      candidateEducation.isCurrentlyStudying == null ||
      candidateEducation.isCurrentlyStudying == false
    ) {
      if (
        candidateEducation.enclosure != null &&
        candidateEducation.enclosure != ""
      ) {
        setIsCurrentlyStudying(true);
      }
    }

    setInterruptions(interrups);
    setUniversityPeriodTypes(types);
    setUniversityCareers(careers);
    setUniversities(units);
    setIsLoading(false);
  }
  useEffect(() => {
    //setIsLoading(false);
    initialData();
  }, []);

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box textAlign="center">
            <Typography
              display="inline-block"
              sx={{
                mb: 3,
                backgroundColor: "#00166B",
                color: "white",
                padding: "10px 30px",
                borderRadius: "10px",
                textTransform: "uppercase",
              }}
              variant="h4"
            >
              DATOS UNIVERSITARIOS
            </Typography>
          </Box>
          {/* <Typography sx={{ mb: 3 }} variant="h4">
            DATOS UNIVERSITARIOS
          </Typography> */}
          <Grid container spacing={1} mb={2}>
            <Box
              bgcolor="primary"
              sx={{
                width: "100%",
                bgcolor: "primary.main",
              }}
            >
              <Typography sx={{ color: "white", px: 2, py: 1 }}>
                Generales de la Universidad y la carrera
              </Typography>
            </Box>
            <Grid item xs={12}>
              <Paragraph title="¿Usted está estudiando actualmente?" required />
              <AppSelect
                fullWidth
                value={isCurrentlyStudying ? "SI" : "NO"}
                onChange={(e) => {
                  setIsCurrentlyStudying(e.target.value == "SI");
                }}
              >
                <MenuItem value="SI">SI</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
              </AppSelect>
            </Grid>
          </Grid>

          {!isCurrentlyStudying ? (
            <Formik
              initialValues={initialValues}
              validationSchema={
                !window.location.href.includes("profile")
                  ? otherValidationScheme
                  : null
              }
              onSubmit={otherSubmit}
              validateOnMount={true}
            >
              {(props: FormikProps<ICandidateEducationForm>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                  setFieldValue,
                  isValid,
                } = props;

                return (
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Paragraph title="Explique por qué" required />
                        <AppTextField
                          error={
                            errors.isCurrentlyStudyingExplain &&
                            touched.isCurrentlyStudyingExplain
                              ? true
                              : false
                          }
                          name="isCurrentlyStudyingExplain"
                          value={values.isCurrentlyStudyingExplain}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Es graduado de alguna carrera universitaria o técnico superior?"
                          required
                        />
                        <AppSelect
                          name="isGraduated"
                          fullWidth
                          error={
                            errors.isGraduated && touched.isGraduated
                              ? true
                              : false
                          }
                          value={values.isGraduated}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Universidad*/}
                      {values.isGraduated == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph title="Universidad" required />
                          <AppSelect
                            name="universityId"
                            error={
                              errors.universityId && touched.universityId
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.universityId}
                            onChange={handleChange}
                            isSearchable={true}
                            options={universities.map((el) => {
                              var option: IOption = {
                                label: el.name!,
                                value: el.universityId.toString(),
                              };
                              return option;
                            })}
                          >
                            {/* {universities.map((el) => (
                        <MenuItem key={el.universityId} value={el.universityId}>
                          {el.name}
                        </MenuItem>
                      ))} */}
                          </AppSelect>
                        </Grid>
                      ) : null}
                      {/* Career*/}
                      {values.isGraduated == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph title="Carrera" required />
                          <AppSelect
                            name="universityCareerId"
                            error={
                              errors.universityCareerId &&
                              touched.universityCareerId
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.universityCareerId}
                            onChange={handleChange}
                            isSearchable={true}
                            options={universityCareers.map((el) => {
                              var option: IOption = {
                                label: el.name!,
                                value: el.universityCareerId.toString(),
                              };
                              return option;
                            })}
                          >
                            {/* {universityCareers.map((el) => (
                        <MenuItem
                          key={el.universityCareerId}
                          value={el.universityCareerId}
                        >
                          {el.name}
                        </MenuItem>
                      ))} */}
                          </AppSelect>
                        </Grid>
                      ) : null}
                      {values.isGraduated == "SI" ? (
                        <Grid item xs={12}>
                          <Paragraph title="Año de graduación" required />
                          <AppTextField
                            type="number"
                            error={
                              errors.graduatedYear && touched.graduatedYear
                                ? true
                                : false
                            }
                            name="graduatedYear"
                            value={values.graduatedYear}
                            onChange={handleChange}
                          />
                        </Grid>
                      ) : null}

                      {/* BUTTON */}
                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            setStep(step - 1);
                          }}
                          color="customGrey"
                          variant="contained"
                          sx={{ mr: "10px", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          onClick={() => {
                            if (!isValid) {
                              setIsValidForm(true);
                            }
                          }}
                          disabled={isSubmitting}
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ bgcolor: "secondary.main" }}
                        >
                          {isSubmitting ? <>Guardando...</> : "Siguiente"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={submit}
              validationSchema={candidateEducationFormValidationScheme}
              validateOnMount={true}
            >
              {(props: FormikProps<ICandidateEducationForm>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                  setFieldValue,
                  isValid,
                } = props;

                return (
                  <Form>
                    <Grid container spacing={1}>
                      {/* Universidad*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Universidad" required />
                        <AppSelect
                          name="universityId"
                          error={
                            errors.universityId && touched.universityId
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.universityId}
                          onChange={handleChange}
                          isSearchable={true}
                          options={universities.map((el) => {
                            var option: IOption = {
                              label: el.name!,
                              value: el.universityId.toString(),
                            };
                            return option;
                          })}
                        >
                          {/* {universities.map((el) => (
                        <MenuItem key={el.universityId} value={el.universityId}>
                          {el.name}
                        </MenuItem>
                      ))} */}
                        </AppSelect>
                      </Grid>
                      {/* Recinto*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Recinto" required />
                        <AppTextField
                          error={
                            errors.enclosure && touched.enclosure ? true : false
                          }
                          name="enclosure"
                          value={values.enclosure}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>

                      {/* Career*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Carrera" required />
                        <AppSelect
                          name="universityCareerId"
                          error={
                            errors.universityCareerId &&
                            touched.universityCareerId
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.universityCareerId}
                          onChange={handleChange}
                          isSearchable={true}
                          options={universityCareers.map((el) => {
                            var option: IOption = {
                              label: el.name!,
                              value: el.universityCareerId.toString(),
                            };
                            return option;
                          })}
                        >
                          {/* {universityCareers.map((el) => (
                        <MenuItem
                          key={el.universityCareerId}
                          value={el.universityCareerId}
                        >
                          {el.name}
                        </MenuItem>
                      ))} */}
                        </AppSelect>
                      </Grid>
                      {/* Fecha*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Fecha inicio carrera" required />
                        <AppTextField
                          type="date"
                          error={
                            errors.startCareerDate && touched.startCareerDate
                              ? true
                              : false
                          }
                          name="startCareerDate"
                          value={values.startCareerDate}
                          onChange={handleChange}
                        />
                      </Grid>
                      {/* Periodo */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Tipo período universidad" required />
                        <AppSelect
                          name="universityPeriodTypeId"
                          error={
                            errors.universityPeriodTypeId &&
                            touched.universityPeriodTypeId
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.universityPeriodTypeId}
                          onChange={handleChange}
                        >
                          {universityPeriodTypes.map((el) => (
                            <MenuItem
                              key={el.universityPeriodTypeId}
                              value={el.universityPeriodTypeId}
                            >
                              {el.name}
                            </MenuItem>
                          ))}
                        </AppSelect>
                      </Grid>

                      {/* Ciclo Cursados*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Cantidad de ciclos cursados"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={
                            errors.universityPeriodNumber &&
                            touched.universityPeriodNumber
                              ? true
                              : false
                          }
                          name="universityPeriodNumber"
                          value={values.universityPeriodNumber}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* Credito periodo atual*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Cantidad de créditos en el periodo actual"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={
                            errors.creditosActual && touched.creditosActual
                              ? true
                              : false
                          }
                          name="creditosActual"
                          value={values.creditosActual}
                          onChange={handleChange}
                        />
                      </Grid>
                      {/* Credito anterior */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Cantidad de créditos en el periodo anterior"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={
                            errors.creditosAnterior && touched.creditosAnterior
                              ? true
                              : false
                          }
                          name="creditosAnterior"
                          value={values.creditosAnterior}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* Credito anterior */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Cantidad de créditos en el próximo periodo"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={
                            errors.creditosProximos && touched.creditosProximos
                              ? true
                              : false
                          }
                          name="creditosProximos"
                          value={values.creditosProximos}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* Cantidad de materias cursando*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Cantidad de materias cursando en el período actual"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={
                            errors.quantSignaturesInCurrentPeriod &&
                            touched.quantSignaturesInCurrentPeriod
                              ? true
                              : false
                          }
                          name="quantSignaturesInCurrentPeriod"
                          value={values.quantSignaturesInCurrentPeriod}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* No. Materias cursadas período anterior*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Cantidad de materias cursadas y aprobadas en el periodo anterior"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={
                            errors.quantSignaturesInPreviousPeriod &&
                            touched.quantSignaturesInPreviousPeriod
                              ? true
                              : false
                          }
                          name="quantSignaturesInPreviousPeriod"
                          value={values.quantSignaturesInPreviousPeriod}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* cantidad de materia next*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Cantidad de materias a cursar en el siguiente periodo"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={
                            errors.quantSignaturesInNextPeriod &&
                            touched.quantSignaturesInNextPeriod
                              ? true
                              : false
                          }
                          name="quantSignaturesInNextPeriod"
                          value={values.quantSignaturesInNextPeriod}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* cantidad de materia next*/}
                      <Grid item lg={6} xs={12}>
                        <Paragraph
                          title="Cantidad de materias cursadas y aprobadas en toda tu carrera"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={
                            errors.quantAsigApprovedInAllCareer &&
                            touched.quantAsigApprovedInAllCareer
                              ? true
                              : false
                          }
                          name="quantAsigApprovedInAllCareer"
                          value={values.quantAsigApprovedInAllCareer}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* Índice académico general*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Índice académico general" required />
                        <AppTextField
                          type="number"
                          error={
                            errors.academicIndex && touched.academicIndex
                              ? true
                              : false
                          }
                          name="academicIndex"
                          value={values.academicIndex}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* Año que espera terminar la carrera*/}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="Año que espera terminar la carrera"
                          required
                        />
                        <AppTextField
                          type="number"
                          error={errors.fYear && touched.fYear ? true : false}
                          name="fYear"
                          value={values.fYear}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* elegiste esta carrera*/}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Por qué elegiste esta carrera?"
                          required
                        />
                        <AppTextField
                          error={
                            errors.careerReason && touched.careerReason
                              ? true
                              : false
                          }
                          name="careerReason"
                          value={values.careerReason}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>

                      {/* Año que espera terminar la carrera*/}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Cuáles son tus planes al finalizar esta carrera?"
                          required
                        />
                        <AppTextField
                          error={
                            errors.plansWhenFinishCareer &&
                            touched.plansWhenFinishCareer
                              ? true
                              : false
                          }
                          name="plansWhenFinishCareer"
                          value={values.plansWhenFinishCareer}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                        />
                      </Grid>

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography sx={{ color: "white", px: 2, py: 1 }}>
                          Cambios en los estudios universitarios
                        </Typography>
                      </Box>

                      {/* Interruption flag */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Has interrumpido tus estudios universitarios?"
                          required
                        />
                        <AppSelect
                          name="interrupStudiesFlag"
                          error={
                            errors.interrupStudiesFlag &&
                            touched.interrupStudiesFlag
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.interrupStudiesFlag}
                          onChange={handleChange}
                        >
                          <MenuItem value="NO">NO</MenuItem>
                          <MenuItem value="SI">SI</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Interruption table */}

                      {values.interrupStudiesFlag == "SI" ? (
                        <Grid item xs={12}>
                          {appState.applicationStateId == 23 ||
                          appState.isAdmin ? (
                            <Button
                              sx={{ mb: 2 }}
                              variant="contained"
                              onClick={() => {
                                setEducationInterruption({
                                  dateFrom: "",
                                  dateTo: "",
                                  justification: "",
                                  candidateEducationInterruptionID: 0,
                                });
                                setInterruptionModal(true);
                              }}
                            >
                              Agregar
                            </Button>
                          ) : null}

                          <TableContainer component={Paper}>
                            <Table
                              //sx={{ minWidth: 650 }}
                              size="small"
                              aria-label="a dense table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell align="left">
                                    Fecha desde
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Fecha hasta
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Explique por qué
                                  </StyledTableCell>
                                  <StyledTableCell align="left"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {interruptions.map((el) => (
                                  <StyledTableRow
                                    key={el.candidateEducationInterruptionID}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <StyledTableCell
                                      align="left"
                                      component="th"
                                      scope="row"
                                    >
                                      {GetDateDDMMYYYYY(new Date(el.dateFrom!))}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {GetDateDDMMYYYYY(new Date(el.dateTo!))}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {el.justification}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {appState.applicationStateId == 23 ||
                                      appState.isAdmin ? (
                                        <Box>
                                          <IconButton
                                            sx={{ mr: 1 }}
                                            color="error"
                                            onClick={async () => {
                                              try {
                                                await candidateService.deleteCandidateEducationInterruption(
                                                  el.candidateEducationInterruptionID
                                                );
                                                const interrups =
                                                  await candidateService.getCandidateEducationInterruptions();
                                                setInterruptions(interrups);
                                              } catch {
                                                setOpenDialog(true);
                                                setDialogTitle("Error");
                                                setDialogDesc(
                                                  "Ha ocurrido un error. Por favor intente mas tarde"
                                                );
                                                setDialogButton(
                                                  <Button
                                                    onClick={() =>
                                                      setOpenDialog(false)
                                                    }
                                                  >
                                                    Ok
                                                  </Button>
                                                );
                                              }
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                          <IconButton
                                            color="success"
                                            onClick={() => {
                                              const obj: CandidateEducationInterruption =
                                                {
                                                  ...el,
                                                  dateFrom: GetDateYYYYMMDD(
                                                    new Date(el.dateFrom!)
                                                  ),
                                                  dateTo: GetDateYYYYMMDD(
                                                    new Date(el.dateTo!)
                                                  ),
                                                };
                                              setEducationInterruption(obj);
                                              setInterruptionModal(true);
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Box>
                                      ) : null}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      ) : null}

                      {/* Change career flag */}
                      <Grid item xs={12}>
                        <Paragraph title="¿Has cambiado de carrera?" required />
                        <AppSelect
                          name="changeCareerFlag"
                          error={
                            errors.changeCareerFlag && touched.changeCareerFlag
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.changeCareerFlag}
                          onChange={handleChange}
                        >
                          <MenuItem value="NO">NO</MenuItem>
                          <MenuItem value="SI">SI</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Change career justification */}
                      {values.changeCareerFlag == "SI" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph title="Explique por qué" required />
                          <AppTextField
                            error={
                              errors.changeCareerJustification &&
                              touched.changeCareerJustification
                                ? true
                                : false
                            }
                            name="changeCareerJustification"
                            value={values.changeCareerJustification}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                          />
                        </Grid>
                      ) : null}
                      {/* Change career id */}
                      {values.changeCareerFlag == "SI" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph title="Carrera anterior" required />
                          <AppSelect
                            name="changeCareerPreviousCareerId"
                            error={
                              errors.changeCareerPreviousCareerId &&
                              touched.changeCareerPreviousCareerId
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.changeCareerPreviousCareerId}
                            onChange={handleChange}
                            isSearchable={true}
                            options={universityCareers.map((el) => {
                              var option: IOption = {
                                label: el.name!,
                                value: el.universityCareerId.toString(),
                              };
                              return option;
                            })}
                          >
                            {/* {universityCareers.map((el) => (
                          <MenuItem
                            key={el.universityCareerId}
                            value={el.universityCareerId}
                          >
                            {el.name}
                          </MenuItem>
                        ))} */}
                          </AppSelect>
                        </Grid>
                      ) : null}

                      {/* Change university */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Has cambiado de universidad?"
                          required
                        />
                        <AppSelect
                          name="changeUniversityFlag"
                          error={
                            errors.changeUniversityFlag &&
                            touched.changeUniversityFlag
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.changeUniversityFlag}
                          onChange={handleChange}
                        >
                          <MenuItem value="NO">NO</MenuItem>
                          <MenuItem value="SI">SI</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Change university justification */}
                      {values.changeUniversityFlag == "SI" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph title="Explique por qué" required />
                          <AppTextField
                            error={
                              errors.changeUniversityPreviousUniversity &&
                              touched.changeUniversityPreviousUniversity
                                ? true
                                : false
                            }
                            name="changeUniversityPreviousUniversity"
                            value={values.changeUniversityPreviousUniversity}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                          />
                        </Grid>
                      ) : null}
                      {/* Change university id */}
                      {values.changeUniversityFlag == "SI" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph title="Universidad anterior" required />
                          <AppSelect
                            name="changeUniversityPreviousUniversityId"
                            error={
                              errors.changeUniversityPreviousUniversityId &&
                              touched.changeUniversityPreviousUniversityId
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.changeUniversityPreviousUniversityId}
                            onChange={handleChange}
                            isSearchable={true}
                            options={universities.map((el) => {
                              var option: IOption = {
                                label: el.name!,
                                value: el.universityId.toString(),
                              };
                              return option;
                            })}
                          >
                            {/* {universities.map((el) => (
                          <MenuItem
                            key={el.universityId}
                            value={el.universityId}
                          >
                            {el.name}
                          </MenuItem>
                        ))} */}
                          </AppSelect>
                        </Grid>
                      ) : null}

                      {/* BUTTON */}
                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            setStep(step - 1);
                          }}
                          color="customGrey"
                          variant="contained"
                          sx={{ mr: "10px", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          onClick={() => {
                            if (!isValid) {
                              setIsValidForm(true);
                            }
                          }}
                          disabled={isSubmitting}
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ bgcolor: "secondary.main" }}
                        >
                          {isSubmitting ? <>Guardando...</> : "Siguiente"}
                        </Button>
                      </Grid>

                      {/* Creditos Actual*/}
                      {/* <Grid item lg={3} xs={12}>
              <Paragraph title="Fecha inicio carrera*" required />
              <AppTextField
                type="number"
                error={
                  errors.creditosActual && touched.creditosActual
                    ? true
                    : false
                }
                name="creditosActual"
                value={values.creditosActual}
                onChange={handleChange}
              />
            </Grid> */}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Box>
      )}

      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
      <Dialog fullWidth={true} maxWidth="md" open={interruntionModal}>
        <DialogTitle>Estudios Interrumpido</DialogTitle>
        <DialogContent>
          <EducationInterruptionModal
            interruption={educationInterruption}
            onSave={async (interruption: CandidateEducationInterruption) => {
              const interrups =
                await candidateService.getCandidateEducationInterruptions();
              setInterruptions(interrups);
              setInterruptionModal(false);
            }}
            close={() => {
              setInterruptionModal(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default CandidateEducationForm;
