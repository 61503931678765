import { useEffect, useState } from "react";
import CandidatePaymentAccount from "../../models/CandidatePaymentAccount";
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../theme/GlobalTheme";
import { GetDateDDMMYYYYY, currencyFormatter } from "../../Utils";
import PaymentReference from "../../models/PaymentReference";
import { useNavigate } from "react-router-dom";
import CandidatePendingPayment from "../../models/CandidatePendingPayments";

interface PaymentDetailProps {
  payments: CandidatePendingPayment[];
  paymentReference: PaymentReference;
  back: () => void;
}

function PaymentDetail({
  payments,
  back,
  paymentReference,
}: PaymentDetailProps) {
  const [paymentAccounts, setPaymentAccounts] =
    useState<CandidatePendingPayment[]>(payments);

  const navigate = useNavigate();

  function popup() {
    // const popupUrl =
    //   "http://aceapitest.sdqsistemas.info/Payment?token=" +
    //   paymentReference.token;

    // // Define the width and height of the popup window.
    // const popupWidth = 600;
    // const popupHeight = 400;

    // // Calculate the left and top positions to center the popup window.
    // const left = (window.innerWidth - popupWidth) / 2;
    // const top = (window.innerHeight - popupHeight) / 2;

    // // Open the popup window.
    // const popupWindow = window.open(
    //   popupUrl,
    //   "Payment",
    //   `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    // );

    // // Focus the popup window (optional).

    // var timer = setInterval(() => {
    //   if (popupWindow?.closed) {
    //     clearInterval(timer);
    //     back();
    //   }
    // }, 1000);

    // if (popupWindow) {
    //   popupWindow.focus();
    // }
    // window.location.href =
    //   "https://aceapitest.sdqsistemas.info/Payment?token=" +
    //   paymentReference.token;

    window.location.href =
      "https://apiv2.aceenlinea.com/Payment?token=" + paymentReference.token;
  }

  return (
    <Box>
      <Grid sx={{ mb: 1 }} container>
        <Grid item xs={6} lg={3}>
          <Typography variant="h5">Referencia</Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {paymentReference.referenceId.toUpperCase()}
          </Typography>
        </Grid>
        {paymentReference.dollarRate > 1 ? (
          <Grid item xs={6} lg={3}>
            <Typography variant="h5">Tasa del Dolar</Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {currencyFormatter.format(paymentReference.dollarRate)}
            </Typography>
          </Grid>
        ) : null}

        <Grid item xs={6} lg={3}>
          <Typography variant="h5">Total</Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {currencyFormatter.format(paymentReference.totalAmount)}
          </Typography>
        </Grid>

        <Grid item xs={6} lg={3}>
          <Typography variant="h5">Fecha</Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {GetDateDDMMYYYYY(new Date())}
          </Typography>
        </Grid>
        {paymentReference.dollarRate > 1 ? (
          <Grid item xs={12} my={3}>
            <Alert style={{ fontSize: 18 }} severity="warning">
              LOS PAGOS EN DOLARES SE COBRA EN PESOS DOMINICANOS Y SE CONVIERTEN
              A UNA TASA DE 1 USD = RD$
              {currencyFormatter.format(paymentReference.dollarRate)}
            </Alert>
          </Grid>
        ) : null}
      </Grid>

      <TableContainer sx={{ mb: 2 }} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Descripcion</StyledTableCell>
              <StyledTableCell align="left">Monto Pendiente</StyledTableCell>
              <StyledTableCell align="left">Monto a Pagar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentAccounts
              .filter((el) => el.isChecked)
              .map((el, index) => (
                <StyledTableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <StyledTableCell align="left" component="th" scope="row">
                    {el.amountDescription}
                  </StyledTableCell>
                  <StyledTableCell align="left" component="th" scope="row">
                    {el.currency}{" "}
                    {currencyFormatter.format(el.amount - el.amountPaid)}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {currencyFormatter.format(el.amountToPay)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Button
          sx={{ mr: 2 }}
          disabled={paymentAccounts.filter((el) => el.isChecked).length == 0}
          variant="contained"
          color="customGrey"
          onClick={() => {
            back();
          }}
        >
          Atras
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            popup();
          }}
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
}
export default PaymentDetail;
