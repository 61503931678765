import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { ChangeEvent, useState } from "react";
import * as Yup from "yup";
import CandidateSponsor from "../../models/CandidateGuarantor";
import Paragraph from "../../components/Paragraph";
import AppSelect from "../../components/AppSelect";
import { GenericItem } from "../../models/GenericItem";
import AppTextField from "../../components/AppTextField";
import LoadingModal from "../../components/LoadingModal";
import candidateService from "../../services/CandidateService";

interface SponsorFileModalProps {
  onSave: () => void;
  onClose: () => void;
  candidateSponsor: CandidateSponsor;
  documentTypes: GenericItem[];
}

interface ISponsorFileModal {
  doctTypeGenericID: string;
}

const validationSchema = Yup.object().shape({
  doctTypeGenericID: Yup.string().required("Requerido"),
});

function GuarantorFileModal({
  onClose,
  candidateSponsor,
  documentTypes,
  onSave,
}: SponsorFileModalProps) {
  const [initialValues, setInitialValues] = useState<ISponsorFileModal>({
    doctTypeGenericID: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function submit(values: ISponsorFileModal) {
    try {
      if (file == null) {
        alert("Por favor agregar un archivo.");
        return;
      }
      setIsSaving(true);
      await candidateService.saveCandidateGuarantorFile(
        Number(values.doctTypeGenericID),
        candidateSponsor.realGuarantorID,
        file
      );
      setIsSaving(false);
      onSave();
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {(props: FormikProps<ISponsorFileModal>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            isValid,
          } = props;

          return (
            <Form>
              <Typography sx={{ mb: 3 }} variant="h6">
                {candidateSponsor.guarantorName}{" "}
                {candidateSponsor.guarantorLastName}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paragraph title="Tipo de documento" required />
                  <AppSelect
                    name="doctTypeGenericID"
                    error={
                      errors.doctTypeGenericID && touched.doctTypeGenericID
                        ? true
                        : false
                    }
                    fullWidth
                    value={values.doctTypeGenericID}
                    onChange={handleChange}
                  >
                    {documentTypes.map((el) => (
                      <MenuItem key={el.genericID} value={el.genericID}>
                        {el.genericDescription}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                <Grid item xs={12}>
                  <Paragraph title="Archivo" required />
                  <AppTextField
                    inputProps={{ accept: "image/*, .doc, .docx, .pdf" }}
                    name="file"
                    type="file"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files != null) {
                        const f = event.target.files[0];
                        setFile(f);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={onClose}
                    color="customGrey"
                    variant="contained"
                    sx={{ mr: "10px", color: "white" }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ bgcolor: "secondary.main" }}
                  >
                    {isSubmitting ? <>Guardando...</> : "Guardar"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default GuarantorFileModal;
