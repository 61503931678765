import { Typography } from "@mui/material";
import "../GeneralStyle.css";

interface ErrorTextProps {
  text: string;
}

function ErrorText({ text }: ErrorTextProps) {
  return (
    <Typography className="errorColor" variant="body1" sx={{ mb: "5px" }}>
      {text}
    </Typography>
  );
}
export default ErrorText;
