import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

interface Paragraph {
  title: string;
  styles?: React.CSSProperties | undefined;
  required?: boolean;
  variant?: Variant;
}

function Paragraph({ title, styles, required, variant = "body1" }: Paragraph) {
  return (
    <div style={{ display: "flex" }}>
      <Typography
        variant={variant}
        style={{ marginBottom: "5px", fontWeight: "bold", ...styles }}
      >
        {title}
      </Typography>
      {required ? (
        // <Typography
        //   component="span"
        //   sx={{ ml: 0.5, color: "red", fontWeight: "bold" }}
        // >
        //   *
        // </Typography>
        <span
          style={{
            display: "inline-block",
            marginLeft: "5px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          *
        </span>
      ) : null}
    </div>
  );
}
export default Paragraph;
