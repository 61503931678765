import { useEffect, useState } from "react";
import CandidateService from "../services/CandidateService";
import CandidateMissingItem from "../models/CandidateMissingItem";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import QrCodeIcon from "@mui/icons-material/QrCode";
import LoadingModal from "../components/LoadingModal";
import ConfirmationDialog from "../components/ConfirmationDialog";
import CandidateQRPassportInfo from "../models/CandidatePassportQRInfo";
import { GetDateYYMMYYYYHHmm } from "../Utils";
import { useAppSelector } from "../store/store";

function QRCodeScreen() {
  const [missingItems, setMissingItems] = useState<CandidateMissingItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [qrCode, setQRCode] = useState<string>("");
  const [passportInfo, setPassportInfo] = useState<CandidateQRPassportInfo>();
  var appState = useAppSelector((state) => state.appState);

  async function initialData() {
    const items = await CandidateService.getCandidateMissingItem();
    const can = await CandidateService.getCandidate();
    const info = await CandidateService.getPassportQRCodeInfo();

    setMissingItems(items);
    setIsLoading(false);
    setQRCode(can.qrFileName);
    setPassportInfo(info);
  }

  async function generateQRCode() {
    setIsSaving(true);
    try {
      await CandidateService.generatePassportQRCode();

      const items = await CandidateService.getCandidateMissingItem();
      const can = await CandidateService.getCandidate();
      const info = await CandidateService.getPassportQRCodeInfo();

      setMissingItems(items);
      setQRCode(can.qrFileName);
      setPassportInfo(info);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Generar QR
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Generar QR
      </Typography> */}

      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          {qrCode != null && qrCode != "" ? (
            <Box textAlign="center">
              <img
                alt="QR CODE"
                style={{
                  height: "100%",
                  maxHeight: "400px",
                  objectFit: "contain",
                  display: "block",
                  margin: "auto",
                }}
                src={`${process.env.REACT_APP_API_BASEURL}/Documents/qrcode?candidateId=${appState.candidateId}`}
              />

              {passportInfo != undefined &&
              passportInfo.deliveredDate != null &&
              passportInfo.deliveredDate != "" ? (
                <Box>
                  <Typography sx={{ mb: 2 }} variant="h5">
                    Datos de la entrega de tu pasaporte
                  </Typography>
                  <Typography variant="h5">
                    {passportInfo.deliveredDescription}
                    {GetDateYYMMYYYYHHmm(new Date(passportInfo.deliveredDate))}
                  </Typography>
                </Box>
              ) : passportInfo?.deliveryDate != null &&
                passportInfo?.deliveryDate != "" ? (
                <Box>
                  <Typography sx={{ mb: 2 }} variant="h5">
                    Datos de la entrega de tu pasaporte
                  </Typography>
                  <Typography variant="h5">
                    {passportInfo?.deliveryDescription}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography sx={{ mb: 2 }} variant="h5">
                    Datos de la entrega de tu pasaporte
                  </Typography>
                  <Typography variant="h6">
                    Fecha de entrega: PENDIENTE
                  </Typography>
                  <Typography variant="h6">
                    Hora de entrega: PENDIENTE
                  </Typography>
                  <Typography variant="h6">
                    Lugar de entregar: PENDIENTE
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              <TableContainer sx={{ mb: 2 }} component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Id</StyledTableCell>
                      <StyledTableCell align="left">
                        Descripción
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {missingItems.length == 0 && (
                      <StyledTableRow>
                        <StyledTableCell
                          sx={{ textAlign: "center" }}
                          colSpan={3}
                        >
                          No data disponible
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {missingItems.map((el, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <StyledTableCell align="left">
                          {el.itemNumber}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {el.itemDescription}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {missingItems.length == 0 ? (
                <Button
                  onClick={generateQRCode}
                  startIcon={<QrCodeIcon />}
                  variant="contained"
                  sx={{
                    fontSize: 30,
                  }}
                >
                  Generar Codigo QR
                </Button>
              ) : null}
            </Box>
          )}
        </Box>
      )}
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default QRCodeScreen;
