import {
  Box,
  Container,
  Typography,
  Link,
  Grid,
  Button,
  MenuItem,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import logo from "../assets/images/ace_logo_big.png";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import AppTextField from "../components/AppTextField";
import ErrorText from "../components/ErrorText";
import * as Yup from "yup";
import AppSelect from "../components/AppSelect";
import IRegisterForm from "../interfaces/IRegisterForm";
import authService from "../services/AuthService";
import { ChangeEvent, LegacyRef, useEffect, useState } from "react";
import utilsService from "../services/UtilsService";
import Subsidiary from "../models/Subsidiary";
import { GenericItem } from "../models/GenericItem";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import InterviewDate from "../models/InterviewDate";
import ValidHourForDate from "../models/ValidHourForDate";
import { cedulaFormatter, phoneFormatter } from "../Utils";
import LoadingModal from "../components/LoadingModal";
import personajeAvionPose2 from "../assets/images/personaje-avion-pose2.png";
import AceLogoBlue from "../assets/images/ace-logo-blue.jpg";
import Paragraph from "../components/Paragraph";
import ReCAPTCHA from "react-google-recaptcha";

const registerValidationScheme = Yup.object().shape({
  firstName: Yup.string().required("Requerido"),
  firstLastName: Yup.string().required("Requerido"),
  email: Yup.string().email().required("Email requerido"),
  confirmEmail: Yup.string().email().required("Email requerido"),
  whatsapp: Yup.string().required("Requerido"),
  otherPhone: Yup.string().required("Requerido"),
  branch: Yup.string().required("Requerido"),
  type: Yup.string().required("Requerido"),
  programType: Yup.string().required("Requerido"),
  password: Yup.string().min(6).required("Requerido"),
  confirmPassword: Yup.string().min(6).required("Requerido"),
  date: Yup.string().required("Requerido"),
  hour: Yup.string().required("Requerido"),
  dateOfBirth: Yup.string().required("Requerido"),
  documentId: Yup.string().required("Requerido"),
  //genre: Yup.string().required("Requerido"),
});

var initialValues: IRegisterForm = {
  firstName: "",
  secondName: "",
  secondLastName: "",
  firstLastName: "",
  email: "",
  confirmEmail: "",
  whatsapp: "",
  otherPhone: "",
  branch: "",
  type: "",
  programType: "",
  password: "",
  confirmPassword: "",
  hour: "",
  date: "",
  documentId: "",
  dateOfBirth: "",
  token: "",
  //genre: "",
  //residentPhoneNumber: "",
};

function RegisterScreen() {
  var [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>();
  var [genericItem, setGenericItem] = useState<GenericItem[]>();
  var [interviewDates, setInterviewDates] = useState<InterviewDate[]>();
  var [validHourForDate, setValidHourForDate] = useState<ValidHourForDate[]>();
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState<string | JSX.Element>("");
  var [dialogButton, setDialogButton] = useState<JSX.Element | undefined>();
  var [isLoading, setIsLoading] = useState<boolean>(false);
  var [modalTitle, setModalTitle] = useState<string>("Guardando...");
  const [reToken, setReToken] = useState<string | null>();
  const [recaptchaKey, setRecaptchaKey] = useState<number>(0);
  const navigate = useNavigate();

  async function getSubsidiaries() {
    var subs = await utilsService.getSubsidiaries();
    setSubsidiaries(subs);
  }
  async function getGenericItems() {
    var gen = await utilsService.getGenericItems("CITA_RAZON");
    setGenericItem(gen);
  }

  async function getInterviewDates() {
    var dates = await utilsService.getInterviewDate();
    setInterviewDates(dates);
  }
  async function getValidHourForDate(date: string) {
    var validHours = await utilsService.getValidHourForDate(date);
    setValidHourForDate(validHours);
  }

  async function submit(
    values: IRegisterForm,
    { setSubmitting }: FormikHelpers<IRegisterForm>
  ) {
    if (reToken == null) {
      setSubmitting(false);
      setDialogTitle("Lo Sentimos");
      setDialogDesc("Debes de verificar que no eres un robot.");
      setOpenDialog(true);
      setDialogButton(
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Ok
        </Button>
      );
      return;
    }

    values.token = reToken;

    if (values.password != values.confirmPassword) {
      setDialogTitle("Advertencia");
      setDialogDesc("Las contraseñas deben ser iguales");
      setOpenDialog(true);
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    if (values.email != values.confirmEmail) {
      setDialogTitle("Advertencia");
      setDialogDesc("Los emails deben de ser iguales");
      setOpenDialog(true);
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    var date: Date = new Date();
    var dob: Date = new Date(values.dateOfBirth);
    var minAge: Date = new Date(
      date.getFullYear() - 16,
      date.getMonth(),
      date.getDate()
    );

    if (dob > minAge) {
      setSubmitting(false);
      setDialogTitle("Lo Sentimos");
      setDialogDesc("Debes de ser mayor de edad para aplicar.");
      setOpenDialog(true);
      setDialogButton(
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Ok
        </Button>
      );
      return;
    }

    setSubmitting(true);
    setIsLoading(true);
    try {
      var isSuccess: boolean = await authService.register(values);

      if (isSuccess) {
        setIsLoading(false);
        setSubmitting(false);
        setDialogTitle("¡Bienvenido (a) Ace Traveler!");
        setDialogDesc(
          <Box component="span">
            Queremos informarte que hemos recibido exitosamente tu solicitud de{" "}
            <Box component="span" sx={{ fontWeight: "bold" }}>
              APLICACIÓN.
            </Box>{" "}
            Para{" "}
            <Box component="span" sx={{ fontWeight: "bold" }}>
              ACTIVAR TU CUENTA
            </Box>
            , por favor ingresar a tu correo electrónico y hacer clic en el
            botón de{" "}
            <Box component="span" sx={{ fontWeight: "bold" }}>
              ACTIVAR CUENTA.
            </Box>
            <Box
              component="span"
              sx={{ mt: 1, mb: 1, fontWeight: "bold", display: "block" }}
            >
              ¡Bienvenido/a a nuestra familia!
            </Box>
            <Box sx={{ display: "block" }} component="span">
              Atentamente,
            </Box>
            <Box component="span">El equipo de Ace International.</Box>
          </Box>
        );
        setOpenDialog(true);
        setDialogButton(
          <Button
            onClick={() => {
              setOpenDialog(false);
              navigate("/login");
            }}
          >
            Ok
          </Button>
        );
      }
    } catch (error: any) {
      if (error.response.status == 409) {
        setIsLoading(false);
        setSubmitting(false);
        setDialogTitle("Lo Sentimos");
        setDialogDesc(error.response.data);
        setOpenDialog(true);
        setDialogButton(
          <Button
            onClick={() => {
              setOpenDialog(false);
              setRecaptchaKey(recaptchaKey + 1);
            }}
          >
            Ok
          </Button>
        );
      } else {
        setIsLoading(false);
        setSubmitting(false);
        setDialogTitle("Lo Sentimos");
        setDialogDesc(
          "No pudimos subir su solicitud. Por favor intente mas tarde"
        );
        setOpenDialog(true);
        setDialogButton(
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            Ok
          </Button>
        );
      }
    }
  }

  useEffect(() => {
    getGenericItems();
    getSubsidiaries();
    getInterviewDates();
  }, []);

  return (
    <Box>
      {/* <Grid container> */}
      {/* <Box sx={{ mb: "40px" }}>
        <img
          style={{
            width: "100%",
            maxWidth: "400px",
            margin: "auto",
            display: "block",
          }}
          src={logo}
        />
      </Box> */}

      {/* </Grid> */}
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={registerValidationScheme}
      >
        {(props: FormikProps<IRegisterForm>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
          } = props;

          return (
            <Form>
              <Grid sx={{ maxWidth: "1200px", margin: "auto" }} container>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Box
                    display="inline-block"
                    sx={{ mt: 3, maxWidth: "1200px" }}
                  >
                    <Link href="/login" sx={{ display: "flex" }}>
                      <ArrowBackIosIcon sx={{ color: "white" }} />
                      <Typography sx={{ color: "white" }}>Login</Typography>
                    </Link>
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    display: { xs: "none", lg: "block" },
                    background: "#00166B",
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                  }}
                  position="relative"
                  item
                  xs={12}
                  lg={5}
                >
                  <Box
                    sx={{
                      width: "100%",
                      position: "absolute",
                      top: "40px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "690px",
                        objectFit: "contain",
                      }}
                      src={personajeAvionPose2}
                    />
                  </Box>
                </Grid>

                {/* <Grid
                  sx={{ display: { xs: "block", lg: "none" } }}
                  position="relative"
                  item
                  xs={12}
                  lg={6}
                >
                  <Box sx={{ mb: "40px" }}>
                    <img
                      style={{
                        width: "100%",
                        maxWidth: "400px",
                        margin: "auto",
                        display: "block",
                      }}
                      src={logo}
                    />
                  </Box>
                </Grid> */}

                <Grid
                  sx={{
                    background: "white",
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                    borderTopLeftRadius: { xs: 20, lg: 0 },
                    borderBottomLeftRadius: { xs: 20, lg: 0 },
                  }}
                  item
                  xs={12}
                  lg={7}
                >
                  <Grid sx={{ padding: 2 }} container spacing={1}>
                    {/* <Grid xs={12} item sx={{ textAlign: "center", mb: 2 }}>
                      <Typography variant="h3">Nueva Aplicaciòn</Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Box>
                        <img
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                            margin: "auto",
                            display: "block",
                          }}
                          src={AceLogoBlue}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      sx={{
                        mb: 1,
                        textAlign: "center",
                        padding: "0px !important",
                      }}
                      item
                      xs={12}
                    >
                      <Box
                        component="span"
                        sx={{
                          display: "inline-block",
                          mr: 1,
                          fontWeight: "300",
                          fontSize: 35,
                        }}
                      >
                        ¡Bienvenido (a)
                      </Box>
                      <Box
                        sx={{
                          display: "inline-block",
                          mr: 1,
                          fontSize: 35,
                          fontWeight: "bold",
                          color: "#FE6B1B",
                        }}
                        component="span"
                      >
                        Ace Traveler!
                      </Box>
                    </Grid>
                    {/* First  Name */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Primer nombre" required />

                      <AppTextField
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        error={
                          errors.firstName && touched.firstName ? true : false
                        }
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.firstName && touched.firstName ? (
                        <ErrorText text={errors.firstName} />
                      ) : null}
                    </Grid>
                    {/* Second  Name */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Segundo nombre" />
                      <AppTextField
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name="secondName"
                        value={values.secondName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* First Last Name */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title=" Primer apellido" required />
                      <AppTextField
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        error={
                          errors.firstLastName && touched.firstLastName
                            ? true
                            : false
                        }
                        name="firstLastName"
                        value={values.firstLastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.firstLastName && touched.firstLastName ? (
                        <ErrorText text={errors.firstLastName} />
                      ) : null}
                    </Grid>

                    {/* Second Last Name */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Segundo apellido" />
                      <AppTextField
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name="secondLastName"
                        value={values.secondLastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Date of birth */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Fecha de nacimiento" required />
                      <AppTextField
                        error={
                          errors.dateOfBirth && touched.dateOfBirth
                            ? true
                            : false
                        }
                        type="date"
                        name="dateOfBirth"
                        value={values.dateOfBirth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.dateOfBirth && touched.dateOfBirth ? (
                        <ErrorText text={errors.dateOfBirth} />
                      ) : null}
                    </Grid>
                    {/* Document Id */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Cédula" required />
                      <AppTextField
                        error={
                          errors.documentId && touched.documentId ? true : false
                        }
                        name="documentId"
                        value={values.documentId}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const cedulaRegex =
                            /^(\d{0,3})-?(\d{0,7})-?(\d{0,1})$/;
                          const { value } = event.target;
                          if (cedulaRegex.test(value)) {
                            const formattedPhone = cedulaFormatter(value);
                            setFieldValue("documentId", formattedPhone);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.documentId && touched.documentId ? (
                        <ErrorText text={errors.documentId} />
                      ) : null}
                    </Grid>

                    {/* Other phone */}
                    {/* <Grid item md={6} xs={12}>
                      <Typography
                        variant="body1"
                        style={{  marginBottom: "5px" }}
                      >
                        Teléfono residencial
                      </Typography>
                      <AppTextField
                        name="residentPhoneNumber"
                        value={values.residentPhoneNumber}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue(
                              "residentPhoneNumber",
                              formattedPhone
                            );
                          }
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid> */}

                    {/* Whatsapp */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title=" WhatsApp" required />
                      <AppTextField
                        error={
                          errors.whatsapp && touched.whatsapp ? true : false
                        }
                        name="whatsapp"
                        value={values.whatsapp}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue("whatsapp", formattedPhone);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.whatsapp && touched.whatsapp ? (
                        <ErrorText text={errors.whatsapp} />
                      ) : null}
                    </Grid>

                    {/* Ceular */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Número celular" required />
                      <AppTextField
                        error={
                          errors.otherPhone && touched.otherPhone ? true : false
                        }
                        name="otherPhone"
                        value={values.otherPhone}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue("otherPhone", formattedPhone);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.otherPhone && touched.otherPhone ? (
                        <ErrorText text={errors.otherPhone} />
                      ) : null}
                    </Grid>

                    {/* Asunto */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Tipo aplicación" required />
                      <AppSelect
                        name="programType"
                        error={
                          errors.programType && touched.programType
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.programType}
                        onChange={handleChange}
                      >
                        {genericItem?.map((el) => (
                          <MenuItem key={el.genericID} value={el.genericID}>
                            {el.genericDescription}
                          </MenuItem>
                        ))}
                      </AppSelect>
                      {errors.programType && touched.programType ? (
                        <ErrorText text={errors.programType} />
                      ) : null}
                    </Grid>

                    {/* Genero */}
                    {/* <Grid item md={6} xs={12}>
                      <Typography
                        variant="body1"
                        style={{  marginBottom: "5px" }}
                      >
                        Género
                      </Typography>
                      <AppSelect
                        name="genre"
                        error={errors.genre && touched.genre ? true : false}
                        fullWidth
                        value={values.genre}
                        onChange={handleChange}
                      >
                        <MenuItem value="M">Masculino</MenuItem>
                        <MenuItem value="F">Femenino</MenuItem>
                      </AppSelect>
                      {errors.genre && touched.genre ? (
                        <ErrorText text={errors.genre} />
                      ) : null}
                    </Grid> */}

                    {/* Sucursal */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Sucursal" required />
                      <AppSelect
                        name="branch"
                        error={errors.branch && touched.branch ? true : false}
                        fullWidth
                        value={values.branch}
                        onChange={handleChange}
                      >
                        {subsidiaries?.map((el) => (
                          <MenuItem
                            key={el.subsidiaryId}
                            value={el.subsidiaryId}
                          >
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                      {errors.branch && touched.branch ? (
                        <ErrorText text={errors.branch} />
                      ) : null}
                    </Grid>

                    {/* Email */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Email" required />
                      <AppTextField
                        error={errors.email && touched.email ? true : false}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <ErrorText text={errors.email} />
                      ) : null}
                    </Grid>

                    {/* Confirm Email */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Confirmar email" required />
                      <AppTextField
                        error={
                          errors.confirmEmail && touched.confirmEmail
                            ? true
                            : false
                        }
                        name="confirmEmail"
                        value={values.confirmEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.confirmEmail && touched.confirmEmail ? (
                        <ErrorText text={errors.confirmEmail} />
                      ) : null}
                    </Grid>

                    {/* Password */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Contraseña" required />
                      <AppTextField
                        type="password"
                        error={
                          errors.password && touched.password ? true : false
                        }
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.password && touched.password ? (
                        <ErrorText text={errors.password} />
                      ) : null}
                    </Grid>

                    {/* Confirn Password */}
                    <Grid item md={6} xs={12}>
                      <Paragraph title="Confirmar contraseña" required />
                      <AppTextField
                        type="password"
                        error={
                          errors.confirmPassword && touched.confirmPassword
                            ? true
                            : false
                        }
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <ErrorText text={errors.confirmPassword} />
                      ) : null}
                    </Grid>

                    {/* Fecha */}
                    <Grid item md={4} xs={12}>
                      <Paragraph title="Fecha de cita" required />
                      <AppSelect
                        name="date"
                        error={errors.date && touched.date ? true : false}
                        fullWidth
                        value={values.date}
                        onChange={(event, child) => {
                          setFieldValue("date", event.target.value);
                          getValidHourForDate(event.target.value);
                          setFieldValue("hour", "");
                        }}
                      >
                        {interviewDates?.map((el) => (
                          <MenuItem key={el.currentDate} value={el.currentDate}>
                            {el.currentDateString}
                          </MenuItem>
                        ))}
                      </AppSelect>
                      {errors.date && touched.date ? (
                        <ErrorText text={errors.date} />
                      ) : null}
                    </Grid>

                    {/* Hora */}
                    <Grid item md={4} xs={12}>
                      <Paragraph title="Hora" required />
                      <AppSelect
                        name="hour"
                        error={errors.hour && touched.hour ? true : false}
                        fullWidth
                        value={values.hour}
                        onChange={handleChange}
                      >
                        {validHourForDate?.map((el) => (
                          <MenuItem
                            key={el.interviewTime}
                            value={el.interviewTime}
                          >
                            {el.interviewTime}
                          </MenuItem>
                        ))}
                      </AppSelect>
                      {errors.hour && touched.hour ? (
                        <ErrorText text={errors.hour} />
                      ) : null}
                    </Grid>
                    {/* Modalidad*/}
                    <Grid item md={4} xs={12}>
                      <Paragraph title="Modo entrevista" required />
                      <AppSelect
                        name="type"
                        error={errors.type && touched.type ? true : false}
                        fullWidth
                        value={values.type}
                        onChange={handleChange}
                      >
                        <MenuItem value="VIRTUAL">VIRTUAL</MenuItem>
                        <MenuItem value="PRESENCIAL">PRESENCIAL</MenuItem>
                      </AppSelect>
                      {errors.type && touched.type ? (
                        <ErrorText text={errors.type} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <ReCAPTCHA
                        key={recaptchaKey}
                        //ref={(r) => setCaptchaRef(r)}
                        sitekey={process.env.REACT_APP_RECAPCHA_KEY!}
                        onChange={(token: string | null) => {
                          setReToken(token);
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        onClick={() => {
                          navigate("/login");
                        }}
                        color="customGrey"
                        variant="contained"
                        sx={{ color: "white" }}
                      >
                        Cancelar
                      </Button>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        disabled={isSubmitting}
                        fullWidth
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{ bgcolor: "secondary.main" }}
                      >
                        {isSubmitting ? <>Guardando...</> : "Crear cuenta"}
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={0}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />

      <LoadingModal isLoading={isLoading} loadingMessage={modalTitle} />
    </Box>
  );
}

export default RegisterScreen;
