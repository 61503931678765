import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import CandidateEventTicket from "../../models/CandidateEventTicket";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import CandidateService from "../../services/CandidateService";

interface EventTicketModalProps {
  eventTicket: CandidateEventTicket | undefined;
  eventModalOpen: boolean;
  setEventModalOpen: (isOpen: boolean) => void;
  savedChanges: () => void;
}

export default function EventTicketModal({
  eventTicket,
  eventModalOpen,
  setEventModalOpen,
  savedChanges,
}: EventTicketModalProps) {
  const [eventQuantity, setEventQuantity] = useState<string>(
    eventTicket ? eventTicket.candidadSugerida.toString() : ""
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState<string | JSX.Element>("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isTermChecked, setIsTermChecked] = useState<boolean>(false);

  function acceptEventTicket() {
    // if (!eventQuantity) {
    //   setDialogTitle("");
    //   setDialogDesc("Por favor añadir una cantidad o rechazar");
    //   setOpenDialog(true);
    //   setDialogButton(
    //     <Button
    //       onClick={() => {
    //         setOpenDialog(false);
    //       }}
    //     >
    //       Ok
    //     </Button>
    //   );
    //   return;
    // }

    if (!isTermChecked) {
      setDialogTitle("");
      setDialogDesc("Por favor aceptar los términos y condiciones");
      setOpenDialog(true);
      setDialogButton(
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Ok
        </Button>
      );
      return;
    }

    setDialogTitle("Confirmar");
    // setDialogDesc(
    //   `¿Estas seguro de que deseas reservar ${eventQuantity} boletas?`
    // );
    setDialogDesc(
      `¿Estás seguro de que deseas reservar una boleta? Recuerda que al aceptar el evento, aceptas los términos y condiciones del mismo. ¡Asiste con tu T-shirt del programa!`
    );
    setOpenDialog(true);
    setDialogButton([
      <Button
        variant="contained"
        color="customGrey"
        onClick={() => {
          setOpenDialog(false);
        }}
      >
        Cancelar
      </Button>,
      <Button
        variant="contained"
        color="secondary"
        onClick={async () => {
          setOpenDialog(false);
          setIsSaving(true);
          try {
            await CandidateService.acceptDenyAventTicket(
              eventTicket!.eventoBoletoID,
              1
            );
            setIsSaving(false);
            savedChanges();
          } catch (error) {
            setIsSaving(false);
            setOpenDialog(true);
            setDialogTitle("Error");
            setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
            setDialogButton(
              <Button onClick={() => setOpenDialog(false)}>Ok</Button>
            );
          }
        }}
      >
        Aceptar
      </Button>,
    ]);
  }

  function denyEventTicket() {
    setDialogTitle("Confirmar");
    setDialogDesc(
      "¿Estas seguro de que deseas rechazar estas boletas? una vez rechazada no se prodra hacer cambios. "
    );
    setOpenDialog(true);
    setDialogButton([
      <Button
        variant="contained"
        color="customGrey"
        onClick={() => {
          setOpenDialog(false);
        }}
      >
        Cancelar
      </Button>,
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          setOpenDialog(false);
          setIsSaving(true);
          try {
            await CandidateService.acceptDenyAventTicket(
              eventTicket!.eventoBoletoID,
              0
            );
            setIsSaving(false);
            savedChanges();
          } catch (error) {
            setIsSaving(false);
            setOpenDialog(true);
            setDialogTitle("Error");
            setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
            setDialogButton(
              <Button onClick={() => setOpenDialog(false)}>Ok</Button>
            );
          }
        }}
      >
        Rechazar
      </Button>,
    ]);
  }

  return (
    <Box>
      <Dialog fullWidth={true} maxWidth="sm" open={eventModalOpen}>
        <DialogTitle>{eventTicket?.eventoNombre}</DialogTitle>
        <DialogContent>
          <Box>
            <Box display="flex" flexDirection="column">
              <Box mb={2} alignItems="end">
                {/* <Box mr={2}>
                  <Paragraph title="Boletas" required />
                  <AppTextField
                    style={{
                      margin: 0,
                      marginBottom: 10,
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    type="number"
                    error={!eventQuantity ? true : false}
                    value={eventQuantity}
                    onChange={(e) => {
                      if (e.target.value && Number(e.target.value) < 0) {
                        e.target.value = "0";
                      }
                      setEventQuantity(e.target.value);
                    }}
                  />
                </Box> */}
                <Box color="secondary" mb={2}>
                  <Checkbox
                    checked={isTermChecked}
                    sx={{ padding: 0 }}
                    color="primary"
                    onChange={(e) => {
                      setIsTermChecked(e.target.checked);
                    }}
                  />
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setDialogTitle("Términos y condiciones");
                      setDialogDesc(
                        <div
                          dangerouslySetInnerHTML={{
                            __html: eventTicket!.terminosCondiciones!,
                          }}
                        ></div>
                      );
                      setOpenDialog(true);
                      setDialogButton(
                        <Button
                          onClick={() => {
                            setOpenDialog(false);
                          }}
                        >
                          Ok
                        </Button>
                      );
                    }}
                  >
                    Términos y condiciones
                  </Button>
                </Box>
                <Button
                  onClick={() => {
                    acceptEventTicket();
                  }}
                  color="secondary"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Aceptar
                </Button>
              </Box>
              <Box display="flex" justifyContent="end">
                <Button
                  onClick={() => {
                    setEventModalOpen(false);
                  }}
                  color="customGrey"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Cerrar
                </Button>
                {/* <Button
                  onClick={() => {
                    denyEventTicket();
                  }}
                  color="error"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Rechazar
                </Button> */}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
