import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import AppSelect from "../../components/AppSelect";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import { StyledTableCell, StyledTableRow } from "../../theme/GlobalTheme";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CandidateOtherFamilyModal from "./CandidateOtherFamilyModal";
import candidateService from "../../services/CandidateService";
import CandidateOtherCloseFamily from "../../models/CandidateOtherCloseFamily";
import { Country } from "../../models/Country";
import FamilyRelationship from "../../models/FamilyRelationship";
import utilsService from "../../services/UtilsService";
import { CandidateOtherCloseFamilyInformation } from "../../models/CandidateOtherCloseFamilyInformation";
import CandidateOtherRefenreceModal from "./CandidateOtherReferenceModal";
import CandidateReference from "../../models/CandidateReference";
import { CandidateApplicationContext } from "../../screens/CandidateApplicationScreen";
import { ApplicationState } from "../../models/ApplicationState";
import { useAppSelector } from "../../store/store";

interface ICandidateEducationForm {
  haveChildren: string;
  childrensQuantity: string;
  hasBrothers: string;
  brothersQuantity: string;
  hasOutOfCountryFamily: string;
}

const validationSchema = Yup.object().shape({});

function CandidateOtherFamilyForm() {
  const [initialValues, setInitialValues] = useState<ICandidateEducationForm>({
    haveChildren: "NO",
    hasBrothers: "",
    childrensQuantity: "",
    brothersQuantity: "",
    hasOutOfCountryFamily: "",
  });
  const [candidateReferences, setCandidateReferences] = useState<
    CandidateReference[]
  >([]);
  const [candidateReference, setCandidateReference] =
    useState<CandidateReference>({
      candidateRelatedID: 0,
      name: "",
      secondName: "",
      lastName: "",
      secondLastName: "",
      relationText: "",
      email: "",
      phonePrincipal: "",
      phoneSecondary: "",
      countryOfResidence: 0,
    });
  const [otherFamilyModal, setOtherFamilyModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [otherReferenceModal, setOtherReferenceModal] =
    useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [candidateOtherFamily, setCandidateOtherFamily] =
    useState<CandidateOtherCloseFamily>({
      candidateRelatedID: 0,
      name: "",
      secondName: "",
      lastName: "",
      secondLastName: "",
      outofCountryUSAFamilyRelationshipId: 0,
      phonePrincipal: "",
      outofCountryPeriodValue: "",
      outofCountryMigratoryStateId: 0,
      outofCountryCountryID: 0,
      outofCountryStateText: "",
      outofCountryCityText: "",
      outofCountryZipCode: "",
      pagaraSuPrograma: "",
      outofCountryAddress: "",
    });
  const [otherFamilies, setOtherFamilies] = useState<
    CandidateOtherCloseFamily[]
  >([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [familyRelationships, setFamilyRelationships] = useState<
    FamilyRelationship[]
  >([]);
  const { step, setStep } = useContext(CandidateApplicationContext);
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  async function initialData() {
    const families = await candidateService.getCandidateOtherFamilies("OTHERC");
    const country = await utilsService.getCountries();
    const relationships = await utilsService.getFamilyRelationships();
    const info = await candidateService.getCandidateOtherFamilyInformation();
    const references = await candidateService.getCandidateReferences("REF");

    setInitialValues({
      brothersQuantity: info.brothersQuantity!.toString(),
      childrensQuantity: info.childrensQuantity!.toString(),
      hasBrothers: info.hasBrothers ? "SI" : "NO",
      haveChildren: info.hasChildren ? "SI" : "NO",
      hasOutOfCountryFamily: families.length > 0 ? "SI" : "NO",
    });

    setCountries(country);
    setFamilyRelationships(relationships);
    setOtherFamilies(families);
    setIsLoading(false);
    setCandidateReferences(references);
  }

  async function submit(values: ICandidateEducationForm) {
    if (
      (appState.applicationStateId != 23 && !appState.isAdmin) ||
      window.location.href.includes("profile")
    ) {
      setStep(step + 1);
      return;
    }
    const info: CandidateOtherCloseFamilyInformation = {
      hasBrothers: values.hasBrothers == "SI",
      hasChildren: values.hasBrothers == "SI",
      brothersQuantity:
        values.brothersQuantity != "" ? Number(values.brothersQuantity) : 0,
      childrensQuantity:
        values.childrensQuantity != "" ? Number(values.childrensQuantity) : 0,
    };

    if (values.hasOutOfCountryFamily == "SI" && otherFamilies.length == 0) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Por favor agregar familiares en el exterior");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    if (candidateReferences.length < 2) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Debes agregar al menos dos referencias");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    try {
      setIsSaving(true);
      await candidateService.saveCandidateCloseOtherFamilyInformation(info);
      setIsSaving(false);
      setStep(step + 1);
    } catch (error: any) {
      console.log(error);
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={
            !window.location.href.includes("profile") ? validationSchema : null
          }
          validateOnMount={true}
        >
          {(props: FormikProps<ICandidateEducationForm>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Box textAlign="center">
                  <Typography
                    display="inline-block"
                    sx={{
                      mb: 3,
                      backgroundColor: "#00166B",
                      color: "white",
                      padding: "10px 30px",
                      borderRadius: "10px",
                      textTransform: "uppercase",
                    }}
                    variant="h4"
                  >
                    INFORMACIÓN DE OTROS FAMILIARES Y RELACIONADOS
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  {/* <Typography sx={{ mb: 3 }} variant="h4">
                    INFORMACIÓN DE OTROS FAMILIARES Y RELACIONADOS
                  </Typography> */}
                  {/* Has children */}
                  <Grid item xs={12} lg={values.haveChildren == "SI" ? 6 : 12}>
                    <Paragraph title="¿Tienes hijos?" required />
                    <AppSelect
                      name="haveChildren"
                      error={
                        errors.haveChildren && touched.haveChildren
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.haveChildren}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Children quantity */}
                  {values.haveChildren == "SI" ? (
                    <Grid item lg={6} xs={12}>
                      <Paragraph title="Cantidad de hijos" required />
                      <AppTextField
                        type="number"
                        error={
                          errors.childrensQuantity && touched.childrensQuantity
                            ? true
                            : false
                        }
                        name="childrensQuantity"
                        value={values.childrensQuantity}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : null}

                  {/* Has Brother */}
                  <Grid item xs={12} lg={values.hasBrothers == "SI" ? 6 : 12}>
                    <Paragraph title="¿Tienes hermanos? " required />
                    <AppSelect
                      name="hasBrothers"
                      error={
                        errors.hasBrothers && touched.hasBrothers ? true : false
                      }
                      fullWidth
                      value={values.hasBrothers}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Brother quantity */}
                  {values.hasBrothers == "SI" ? (
                    <Grid item lg={6} xs={12}>
                      <Paragraph
                        title="Cantidad de hermanos en el país"
                        required
                      />
                      <AppTextField
                        type="number"
                        error={
                          errors.brothersQuantity && touched.brothersQuantity
                            ? true
                            : false
                        }
                        name="brothersQuantity"
                        value={values.brothersQuantity}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : null}

                  {/* Family out of the country */}
                  <Grid item xs={12}>
                    <Paragraph
                      title="¿Tienes familiares directos viviendo en el exterior?"
                      required
                    />
                    <AppSelect
                      name="hasOutOfCountryFamily"
                      error={
                        errors.hasOutOfCountryFamily &&
                        touched.hasOutOfCountryFamily
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.hasOutOfCountryFamily}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Out of the country Family */}
                  {values.hasOutOfCountryFamily == "SI" ? (
                    <Grid item xs={12}>
                      {appState.applicationStateId == 23 || appState.isAdmin ? (
                        <Button
                          sx={{ mb: 2 }}
                          variant="contained"
                          onClick={() => {
                            setCandidateOtherFamily({
                              candidateRelatedID: 0,
                              name: "",
                              secondName: "",
                              lastName: "",
                              secondLastName: "",
                              outofCountryUSAFamilyRelationshipId: 0,
                              phonePrincipal: "",
                              outofCountryPeriodValue: "",
                              outofCountryMigratoryStateId: 0,
                              outofCountryCountryID: 0,
                              outofCountryStateText: "",
                              outofCountryCityText: "",
                              outofCountryZipCode: "",
                              pagaraSuPrograma: "",
                              outofCountryAddress: "",
                            });
                            setOtherFamilyModal(true);
                          }}
                        >
                          Agregar
                        </Button>
                      ) : null}

                      <TableContainer component={Paper}>
                        <Table
                          //sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                Nombre
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                Parentesco
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                País
                              </StyledTableCell>
                              <StyledTableCell align="left"></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {otherFamilies.map((el) => (
                              <StyledTableRow
                                key={el.candidateRelatedID}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell align="left">
                                  {el.name} {el.lastName}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  {
                                    familyRelationships.filter(
                                      (e) =>
                                        e.familyRelationshipId ==
                                        el.outofCountryUSAFamilyRelationshipId?.toString()
                                    )[0].name
                                  }
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  {
                                    countries.filter(
                                      (e) =>
                                        e.countryId == el.outofCountryCountryID
                                    )[0].name
                                  }
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  {!window.location.href.includes("profile") ? (
                                    <div>
                                      <IconButton
                                        sx={{ mr: 1 }}
                                        color="error"
                                        onClick={async () => {
                                          try {
                                            await candidateService.deleteCandidateRelated(
                                              el.candidateRelatedID
                                            );
                                            const families =
                                              await candidateService.getCandidateOtherFamilies(
                                                "OTHERC"
                                              );
                                            setOtherFamilies(families);
                                          } catch {
                                            setOpenDialog(true);
                                            setDialogTitle("Error");
                                            setDialogDesc(
                                              "Ha ocurrido un error. Por favor intente mas tarde"
                                            );
                                            setDialogButton(
                                              <Button
                                                onClick={() =>
                                                  setOpenDialog(false)
                                                }
                                              >
                                                Ok
                                              </Button>
                                            );
                                          }
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                      <IconButton
                                        color="success"
                                        onClick={() => {
                                          setCandidateOtherFamily(el);
                                          setOtherFamilyModal(true);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </div>
                                  ) : null}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Box
                      bgcolor="primary"
                      sx={{
                        width: "100%",
                        bgcolor: "primary.main",
                      }}
                    >
                      <Typography sx={{ color: "white", px: 2, py: 1 }}>
                        Referencias personales
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Alert severity="info">
                      Favor agregar al menos dos (2) referencias personales
                    </Alert>
                  </Grid>

                  <Grid item xs={12}>
                    {appState.applicationStateId == 23 || appState.isAdmin ? (
                      <Button
                        sx={{ mb: 2 }}
                        variant="contained"
                        onClick={() => {
                          setCandidateReference({
                            candidateRelatedID: 0,
                            name: "",
                            secondName: "",
                            lastName: "",
                            secondLastName: "",
                            relationText: "",
                            email: "",
                            phonePrincipal: "",
                            phoneSecondary: "",
                            countryOfResidence: 0,
                          });
                          setOtherReferenceModal(true);
                        }}
                      >
                        Agregar
                      </Button>
                    ) : null}

                    <TableContainer component={Paper}>
                      <Table
                        //sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Nombre
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Parentesco
                            </StyledTableCell>

                            <StyledTableCell align="left"></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {candidateReferences.map((el) => (
                            <StyledTableRow
                              key={el.candidateRelatedID}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell align="left">
                                {el.name} {el.lastName}
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {el.relationText}
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {appState.applicationStateId == 23 ||
                                appState.isAdmin ? (
                                  <Box>
                                    <IconButton
                                      sx={{ mr: 1 }}
                                      color="error"
                                      onClick={async () => {
                                        try {
                                          await candidateService.deleteCandidateRelated(
                                            el.candidateRelatedID
                                          );

                                          const references =
                                            await candidateService.getCandidateReferences(
                                              "REF"
                                            );
                                          setCandidateReferences(references);
                                        } catch {
                                          setOpenDialog(true);
                                          setDialogTitle("Error");
                                          setDialogDesc(
                                            "Ha ocurrido un error. Por favor intente mas tarde"
                                          );
                                          setDialogButton(
                                            <Button
                                              onClick={() =>
                                                setOpenDialog(false)
                                              }
                                            >
                                              Ok
                                            </Button>
                                          );
                                        }
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                      color="success"
                                      onClick={() => {
                                        setCandidateReference(el);
                                        setOtherReferenceModal(true);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Box>
                                ) : null}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setStep(step - 1);
                      }}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Atras
                    </Button>
                    <Button
                      onClick={() => {
                        if (!isValid) {
                          setIsValidForm(true);
                        }
                      }}
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Siguiente"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}

      <Dialog fullWidth={true} maxWidth="md" open={otherFamilyModal}>
        <DialogTitle>Familia en el exterior</DialogTitle>
        <DialogContent>
          <CandidateOtherFamilyModal
            otherFamily={candidateOtherFamily}
            onSave={async () => {
              setOtherFamilyModal(false);
              const families = await candidateService.getCandidateOtherFamilies(
                "OTHERC"
              );
              setOtherFamilies(families);
            }}
            close={() => {
              setOtherFamilyModal(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog fullWidth={true} maxWidth="md" open={otherReferenceModal}>
        <DialogTitle>Referencias</DialogTitle>
        <DialogContent>
          <CandidateOtherRefenreceModal
            countries={countries}
            onSave={async () => {
              const references = await candidateService.getCandidateReferences(
                "REF"
              );
              setCandidateReferences(references);
              setOtherReferenceModal(false);
            }}
            candidataReference={candidateReference}
            close={() => {
              setOtherReferenceModal(false);
            }}
            familyRelationships={familyRelationships}
          />
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}

export default CandidateOtherFamilyForm;
