import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { ChangeEvent, useEffect, useState } from "react";
import LoadingModal from "../components/LoadingModal";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Paragraph from "../components/Paragraph";
import AppTextField from "../components/AppTextField";
import {
  cedulaFormatter,
  handleInputChangeToUppercase,
  phoneFormatter,
} from "../Utils";
import AppSelect from "../components/AppSelect";
import CivilStatus from "../models/CivilStatus";
import UtilsService from "../services/UtilsService";
import IOption from "../interfaces/IOption";
import { Country } from "../models/Country";
import CandidateService from "../services/CandidateService";
import { ApplicationState } from "../models/ApplicationState";
import { useAppDispatch, useAppSelector } from "../store/store";
import AuthService from "../services/AuthService";
import { login } from "../store/appStateReducer";

interface IDS160 {
  apellidos: string;
  nombres: string;
  otron_sino: string;
  otron_apellidos: string;
  otron_nombres: string;
  sexo: string;
  estado_civil: string;
  pareja_nombre_completo: string;
  pareja_fecha_nacimiento: string;
  pareja_direccion: string;
  pareja_telefono: string;
  nacimiento_fecha: string;
  nacimiento_ciudad: string;
  nacimiento_provincia: string;
  nacimiento_pais: string;
  nacionalidad: string;
  otra_nacionalidad_sino: string;
  otra_nacionalidad_valor: string;
  otra_residencia: string;
  otra_residencia_valor: string;
  cedula: string;
  QuienPaga: string;
  QuienPagaA: string;
  QuienPagaN: string;
  QuienPagaNT: string;
  QuienPagaD: string;
  QuienPagaP: string;
  usa_travel_sino: string;
  usa_travel_date1: string;
  usa_travel_days1: string;
  usa_travel_date2: string;
  usa_travel_days2: string;
  usa_travel_days3: string;
  usa_travel_date3: string;
  usa_visa: string;
  usa_visadate: string;
  usa_visanumber: string;
  usv_visasmae: string;
  usa_visacountry: string;
  usa_visa_perdida: string;
  us_visa_perdida_anho: string;
  usa_visa_perdida_motivo: string;
  usa_visa_cancelada: string;
  usa_visa_cancelada_razon: string;
  VISANO: string;
  VISANODETALLE: string;
  us_visamigrante: string;
  usa_visamigrante_rexplique: string;
  dir_linea1: string;
  dir_linea2: string;
  dir_ciudad: string;
  dir_provincia: string;
  dir_codigopostal: string;
  dir_pais: string;
  tel_primario: string;
  tel_secundario: string;
  tel_trabajo: string;
  tel_adicional: string;
  tel_adicional_tel: string;
  email: string;
  email_otro_sino: string;
  email_otro_valor: string;
  medio_sociales_sino: string;
  medio_social_proveedor: string;
  medio_social_id: string;
  medio_proporcionar: string;
  medio_proporcionar_respuesta: string;
  pasaporte_tipo: string;
  pasaporte_numero: string;
  pasaporte_country: string;
  pasaporte_city: string;
  pasaporte_provincia: string;
  pasaporte_fecha_emision: string;
  pasaporte_fecha_end: string;
  pasaporte_peridido: string;
  pasaporte_perdido_numero: string;
  pasaporte_perdido_country: string;
  pasaporte_perido_explicacion: string;
  padre_apellido: string;
  padre_nombre: string;
  padre_email: string;
  padre_fecha_nacimiento: string;
  padre_usa: string;
  padre_usa_status: string;
  madre_apellido: string;
  madre_nombre: string;
  madre_email: string;
  madre_fecha_nacimiento: string;
  madre_usa: string;
  madre_usa_status: string;
  FES180: string;
  DES190: string;
  DES191: string;
  empleo_sino: string;
  empleo_empleador: string;
  empleo_dir_linea1: string;
  empleo_dir_linea2: string;
  empleo_ciudad: string;
  empleo_provincia: string;
  empleo_zonapostal: string;
  empleo_pais: string;
  empleo_titulo: string;
  empleo_apellido_sup: string;
  empleo_nombre_sup: string;
  empleo_numerotel: string;
  empleo_fecha_desde: string;
  empleo_fecha_hasta: string;
  empleo_funciones: string;
  edu_sino: string;
  edu_institucion: string;
  edu_dir_linea1: string;
  edu_ciudad: string;
  edu_fe: string;
  edu_car: string;
  idiomas: string;
  ref1Nombre: string;
  ref1Dir: string;
  ref1Tel: string;
  ref2Nombre: string;
  ref2Dir: string;
  ref2Tel: string;
  ps142: string;
  ps143: string;
  ps144: string;
  ps145: string;
  ps146: string;
  ps147: string;
  ps148: string;
  ps149: string;
  ps150: string;
  ps151: string;
  ps152: string;
  ps153: string;
  ps154: string;
  ps155: string;
  ps156: string;
  ps157: string;
  ps158: string;
  ps159: string;
  ps160: string;
  ps161: string;
  ps162: string;
  ps163: string;
  ps164: string;
  ps165: string;
  ps166: string;
  ps167: string;
  ps168: string;
  ps169: string;
  ps170: string;
  ps171: string;
  ps172: string;
  ps173: string;
  ps174: string;
}
const validationSchema = Yup.object().shape({
  apellidos: Yup.string().required("Requerido"),
  nombres: Yup.string().required("Requerido"),
  otron_sino: Yup.string().required("Requerido"),
  nacimiento_fecha: Yup.string().required("Requerido"),
  nacimiento_ciudad: Yup.string().required("Requerido"),
  nacimiento_provincia: Yup.string().required("Requerido"),
  nacimiento_pais: Yup.string().required("Requerido"),
  otron_apellidos: Yup.string().when(
    "otron_sino",
    (otron_sino: string | string[], schema) => {
      if (otron_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  otron_nombres: Yup.string().when(
    "otron_sino",
    (otron_sino: string | string[], schema) => {
      if (otron_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  sexo: Yup.string().required("Requerido"),
  estado_civil: Yup.string().required("Requerido"),

  pareja_nombre_completo: Yup.string().when(
    "estado_civil",
    (estado_civil: string | string[], schema) => {
      if (estado_civil == "2" || estado_civil == "5") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  pareja_fecha_nacimiento: Yup.string().when(
    "estado_civil",
    (estado_civil: string | string[], schema) => {
      if (estado_civil == "2" || estado_civil == "5") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  pareja_direccion: Yup.string().when(
    "estado_civil",
    (estado_civil: string | string[], schema) => {
      if (estado_civil == "2" || estado_civil == "5") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  pareja_telefono: Yup.string().when(
    "estado_civil",
    (estado_civil: string | string[], schema) => {
      if (estado_civil == "2" || estado_civil == "5") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  nacionalidad: Yup.string().required("Requerido"),
  otra_nacionalidad_sino: Yup.string().required("Requerido"),
  otra_nacionalidad_valor: Yup.string().when(
    "otra_nacionalidad_sino",
    (otra_nacionalidad_sino: string | string[], schema) => {
      if (otra_nacionalidad_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  otra_residencia: Yup.string().required("Requerido"),
  otra_residencia_valor: Yup.string().when(
    "otra_residencia",
    (otra_residencia: string | string[], schema) => {
      if (otra_residencia == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  cedula: Yup.string().required("Requerido"),
  QuienPaga: Yup.string().required("Requerido"),
  QuienPagaA: Yup.string().when(
    "QuienPaga",
    (QuienPaga: string | string[], schema) => {
      if (QuienPaga == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  QuienPagaN: Yup.string().when(
    "QuienPaga",
    (QuienPaga: string | string[], schema) => {
      if (QuienPaga == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  QuienPagaNT: Yup.string().when(
    "QuienPaga",
    (QuienPaga: string | string[], schema) => {
      if (QuienPaga == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  QuienPagaD: Yup.string().when(
    "QuienPaga",
    (QuienPaga: string | string[], schema) => {
      if (QuienPaga == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  QuienPagaP: Yup.string().when(
    "QuienPaga",
    (QuienPaga: string | string[], schema) => {
      if (QuienPaga == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_travel_sino: Yup.string().required("Requerido"),
  usa_travel_date1: Yup.string().when(
    "usa_travel_sino",
    (usa_travel_sino: string | string[], schema) => {
      if (usa_travel_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_travel_days1: Yup.string().when(
    "usa_travel_sino",
    (usa_travel_sino: string | string[], schema) => {
      if (usa_travel_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_visa: Yup.string().required("Requerido"),
  usa_visadate: Yup.string().when(
    "usa_visa",
    (usa_visa: string | string[], schema) => {
      if (usa_visa == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_visanumber: Yup.string().when(
    "usa_visa",
    (usa_visa: string | string[], schema) => {
      if (usa_visa == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usv_visasmae: Yup.string().when(
    "usa_visa",
    (usa_visa: string | string[], schema) => {
      if (usa_visa == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_visacountry: Yup.string().when(
    "usa_visa",
    (usa_visa: string | string[], schema) => {
      if (usa_visa == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_visa_perdida: Yup.string().when(
    "usa_visa",
    (usa_visa: string | string[], schema) => {
      if (usa_visa == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  us_visa_perdida_anho: Yup.string().when(
    ["usa_visa", "usa_visa_perdida"],
    (usa_visa: string | string[], schema) => {
      if (usa_visa[0] == "SI" && usa_visa[1] == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_visa_perdida_motivo: Yup.string().when(
    ["usa_visa", "usa_visa_perdida"],
    (usa_visa: string | string[], schema) => {
      if (usa_visa[0] == "SI" && usa_visa[1] == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_visa_cancelada: Yup.string().when(
    "usa_visa",
    (usa_visa: string | string[], schema) => {
      if (usa_visa == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  usa_visa_cancelada_razon: Yup.string().when(
    ["usa_visa", "usa_visa_cancelada"],
    (usa_visa: string | string[], schema) => {
      if (usa_visa[0] == "SI" && usa_visa[1] == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  VISANO: Yup.string().required("Requerido"),
  VISANODETALLE: Yup.string().when(
    "VISANO",
    (VISANO: string | string[], schema) => {
      if (VISANO == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  us_visamigrante: Yup.string().required("Requerido"),
  usa_visamigrante_rexplique: Yup.string().when(
    "us_visamigrante",
    (us_visamigrante: string | string[], schema) => {
      if (us_visamigrante == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  dir_linea1: Yup.string().required("Requerido"),
  dir_ciudad: Yup.string().required("Requerido"),
  dir_provincia: Yup.string().required("Requerido"),
  dir_pais: Yup.string().required("Requerido"),
  tel_primario: Yup.string().required("Requerido"),
  tel_adicional: Yup.string().required("Requerido"),
  tel_adicional_tel: Yup.string().when(
    "tel_adicional",
    (tel_adicional: string | string[], schema) => {
      if (tel_adicional == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  email: Yup.string().required("Requerido"),
  email_otro_sino: Yup.string().required("Requerido"),
  email_otro_valor: Yup.string().when(
    "email_otro_sino",
    (email_otro_sino: string | string[], schema) => {
      if (email_otro_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  medio_sociales_sino: Yup.string().required("Requerido"),
  medio_social_proveedor: Yup.string().when(
    "medio_sociales_sino",
    (medio_sociales_sino: string | string[], schema) => {
      if (medio_sociales_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  medio_social_id: Yup.string().when(
    "medio_sociales_sino",
    (medio_sociales_sino: string | string[], schema) => {
      if (medio_sociales_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  medio_proporcionar: Yup.string().required("Requerido"),
  medio_proporcionar_respuesta: Yup.string().when(
    "medio_proporcionar",
    (medio_proporcionar: string | string[], schema) => {
      if (medio_proporcionar == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  pasaporte_tipo: Yup.string().required("Requerido"),
  pasaporte_numero: Yup.string().required("Requerido"),
  pasaporte_country: Yup.string().required("Requerido"),
  pasaporte_city: Yup.string().required("Requerido"),
  pasaporte_fecha_emision: Yup.string().required("Requerido"),
  pasaporte_fecha_end: Yup.string().required("Requerido"),
  pasaporte_peridido: Yup.string().required("Requerido"),
  pasaporte_perdido_numero: Yup.string().when(
    "pasaporte_peridido",
    (pasaporte_peridido: string | string[], schema) => {
      if (pasaporte_peridido == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  pasaporte_perdido_country: Yup.string().when(
    "pasaporte_peridido",
    (pasaporte_peridido: string | string[], schema) => {
      if (pasaporte_peridido == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  pasaporte_perido_explicacion: Yup.string().when(
    "pasaporte_peridido",
    (pasaporte_peridido: string | string[], schema) => {
      if (pasaporte_peridido == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  padre_apellido: Yup.string().required("Requerido"),
  padre_nombre: Yup.string().required("Requerido"),
  padre_usa: Yup.string().required("Requerido"),
  padre_usa_status: Yup.string().when(
    "padre_usa",
    (padre_usa: string | string[], schema) => {
      if (padre_usa == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  madre_apellido: Yup.string().required("Requerido"),
  madre_nombre: Yup.string().required("Requerido"),
  madre_usa: Yup.string().required("Requerido"),
  madre_usa_status: Yup.string().when(
    "madre_usa",
    (madre_usa: string | string[], schema) => {
      if (madre_usa == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  FES180: Yup.string().required("Requerido"),
  DES190: Yup.string().when("FES180", (FES180: string | string[], schema) => {
    if (FES180 == "SI") {
      return schema.required("Requerido");
    }
    return schema;
  }),
  DES191: Yup.string().when("FES180", (FES180: string | string[], schema) => {
    if (FES180 == "SI") {
      return schema.required("Requerido");
    }
    return schema;
  }),
  empleo_sino: Yup.string().required("Requerido"),
  empleo_empleador: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_dir_linea1: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_ciudad: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_provincia: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_pais: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_titulo: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_apellido_sup: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_nombre_sup: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_numerotel: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_fecha_desde: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  empleo_funciones: Yup.string().when(
    "empleo_sino",
    (empleo_sino: string | string[], schema) => {
      if (empleo_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  edu_sino: Yup.string().required("Requerido"),
  edu_institucion: Yup.string().when(
    "edu_sino",
    (edu_sino: string | string[], schema) => {
      if (edu_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  edu_dir_linea1: Yup.string().when(
    "edu_sino",
    (edu_sino: string | string[], schema) => {
      if (edu_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  edu_ciudad: Yup.string().when(
    "edu_sino",
    (edu_sino: string | string[], schema) => {
      if (edu_sino == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  idiomas: Yup.string().required("Requerido"),
  ref1Nombre: Yup.string().required("Requerido"),
  ref1Dir: Yup.string().required("Requerido"),
  ref1Tel: Yup.string().required("Requerido"),
  ref2Nombre: Yup.string().required("Requerido"),
  ref2Dir: Yup.string().required("Requerido"),
  ref2Tel: Yup.string().required("Requerido"),
  ps142: Yup.string().required("Requerido"),
  ps143: Yup.string().required("Requerido"),
  ps144: Yup.string().required("Requerido"),
  ps145: Yup.string().required("Requerido"),
  ps146: Yup.string().required("Requerido"),
  ps147: Yup.string().required("Requerido"),
  ps148: Yup.string().required("Requerido"),
  ps149: Yup.string().required("Requerido"),
  ps150: Yup.string().required("Requerido"),
  ps151: Yup.string().required("Requerido"),
  ps152: Yup.string().required("Requerido"),
  ps153: Yup.string().required("Requerido"),
  ps154: Yup.string().required("Requerido"),
  ps155: Yup.string().required("Requerido"),
  ps156: Yup.string().required("Requerido"),
  ps157: Yup.string().required("Requerido"),
  ps158: Yup.string().required("Requerido"),
  ps159: Yup.string().required("Requerido"),
  ps160: Yup.string().required("Requerido"),
  ps161: Yup.string().required("Requerido"),
  ps162: Yup.string().required("Requerido"),
  ps163: Yup.string().required("Requerido"),
  ps164: Yup.string().required("Requerido"),
  ps165: Yup.string().required("Requerido"),
  ps166: Yup.string().required("Requerido"),
  ps167: Yup.string().required("Requerido"),
  ps168: Yup.string().required("Requerido"),
  ps169: Yup.string().required("Requerido"),
  ps170: Yup.string().required("Requerido"),
  ps171: Yup.string().required("Requerido"),
  ps172: Yup.string().required("Requerido"),
  ps173: Yup.string().required("Requerido"),
  ps174: Yup.string().required("Requerido"),
});

function DS160Screen() {
  const [initialValues, setInitialValues] = useState<IDS160>({
    apellidos: "",
    nombres: "",
    otron_sino: "",
    otron_apellidos: "",
    otron_nombres: "",
    sexo: "",
    estado_civil: "",
    pareja_nombre_completo: "",
    pareja_fecha_nacimiento: "",
    pareja_direccion: "",
    pareja_telefono: "",
    nacimiento_fecha: "",
    nacimiento_ciudad: "",
    nacimiento_provincia: "",
    nacimiento_pais: "",
    nacionalidad: "",
    otra_nacionalidad_sino: "",
    otra_nacionalidad_valor: "",
    otra_residencia: "",
    otra_residencia_valor: "",
    cedula: "",
    QuienPaga: "",
    QuienPagaA: "",
    QuienPagaN: "",
    QuienPagaNT: "",
    QuienPagaD: "",
    QuienPagaP: "",
    usa_travel_sino: "",
    usa_travel_date1: "",
    usa_travel_days1: "",
    usa_travel_date2: "",
    usa_travel_days2: "",
    usa_travel_days3: "",
    usa_travel_date3: "",
    usa_visa: "",
    usa_visadate: "",
    usa_visanumber: "",
    usv_visasmae: "",
    usa_visacountry: "",
    usa_visa_perdida: "",
    us_visa_perdida_anho: "",
    usa_visa_perdida_motivo: "",
    usa_visa_cancelada: "",
    usa_visa_cancelada_razon: "",
    VISANO: "",
    VISANODETALLE: "",
    us_visamigrante: "",
    usa_visamigrante_rexplique: "",
    dir_linea1: "",
    dir_linea2: "",
    dir_ciudad: "",
    dir_provincia: "",
    dir_codigopostal: "",
    dir_pais: "",
    tel_primario: "",
    tel_secundario: "",
    tel_trabajo: "",
    tel_adicional: "",
    tel_adicional_tel: "",
    email: "",
    email_otro_sino: "",
    email_otro_valor: "",
    medio_sociales_sino: "",
    medio_social_proveedor: "",
    medio_social_id: "",
    medio_proporcionar: "",
    medio_proporcionar_respuesta: "",
    pasaporte_tipo: "",
    pasaporte_numero: "",
    pasaporte_country: "",
    pasaporte_city: "",
    pasaporte_provincia: "",
    pasaporte_fecha_emision: "",
    pasaporte_fecha_end: "",
    pasaporte_peridido: "",
    pasaporte_perdido_numero: "",
    pasaporte_perdido_country: "",
    pasaporte_perido_explicacion: "",
    padre_apellido: "",
    padre_nombre: "",
    padre_email: "",
    padre_fecha_nacimiento: "",
    padre_usa: "",
    padre_usa_status: "",
    madre_apellido: "",
    madre_nombre: "",
    madre_email: "",
    madre_fecha_nacimiento: "",
    madre_usa: "",
    madre_usa_status: "",
    FES180: "",
    DES190: "",
    DES191: "",
    empleo_sino: "",
    empleo_empleador: "",
    empleo_dir_linea1: "",
    empleo_dir_linea2: "",
    empleo_ciudad: "",
    empleo_provincia: "",
    empleo_zonapostal: "",
    empleo_pais: "",
    empleo_titulo: "",
    empleo_apellido_sup: "",
    empleo_nombre_sup: "",
    empleo_numerotel: "",
    empleo_fecha_desde: "",
    empleo_fecha_hasta: "",
    empleo_funciones: "",
    edu_sino: "",
    edu_institucion: "",
    edu_dir_linea1: "",
    edu_ciudad: "",
    edu_fe: "",
    edu_car: "",
    idiomas: "",
    ref1Nombre: "",
    ref1Dir: "",
    ref1Tel: "",
    ref2Nombre: "",
    ref2Dir: "",
    ref2Tel: "",
    ps142: "",
    ps143: "",
    ps144: "",
    ps145: "",
    ps146: "",
    ps147: "",
    ps148: "",
    ps149: "",
    ps150: "",
    ps151: "",
    ps152: "",
    ps153: "",
    ps154: "",
    ps155: "",
    ps156: "",
    ps157: "",
    ps158: "",
    ps159: "",
    ps160: "",
    ps161: "",
    ps162: "",
    ps163: "",
    ps164: "",
    ps165: "",
    ps166: "",
    ps167: "",
    ps168: "",
    ps169: "",
    ps170: "",
    ps171: "",
    ps172: "",
    ps173: "",
    ps174: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [civilStatus, setCivilStatus] = useState<CivilStatus[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const migrationStatus: string[] = [
    "No se",
    "Otro",
    "Indocumentado",
    "No inmigrante",
    "Residente Permanente",
    "Ciudadano",
  ];
  const appState: ApplicationState = useAppSelector((state) => state.appState);
  var dispatch = useAppDispatch();

  async function initialData() {
    var civilStatu = await UtilsService.getCivilStatus();
    const country = await UtilsService.getCountries();
    const formDefinition = await CandidateService.getDS160();

    function getDataValue(code: string): string {
      var ret =
        formDefinition.find((el) => el.controlCode == code)
          ?.valueForCandidate ?? "";

      return ret;
    }

    setCountries(country);
    setCivilStatus(civilStatu);

    setInitialValues({
      apellidos: getDataValue("apellidos"),
      nombres: getDataValue("nombres"),
      otron_sino: getDataValue("otron_sino"),
      otron_apellidos: getDataValue("otron_apellidos"),
      otron_nombres: getDataValue("otron_nombres"),
      sexo: getDataValue("sexo"),
      estado_civil: getDataValue("estado_civil"),
      pareja_nombre_completo: getDataValue("pareja_nombre_completo"),
      pareja_fecha_nacimiento: getDataValue("pareja_fecha_nacimiento"),
      pareja_direccion: getDataValue("pareja_direccion"),
      pareja_telefono: getDataValue("pareja_telefono"),
      nacimiento_fecha: getDataValue("nacimiento_fecha"),
      nacimiento_ciudad: getDataValue("nacimiento_ciudad"),
      nacimiento_provincia: getDataValue("nacimiento_provincia"),
      nacimiento_pais: getDataValue("nacimiento_pais"),
      nacionalidad: getDataValue("nacionalidad"),
      otra_nacionalidad_sino: getDataValue("otra_nacionalidad_sino"),
      otra_nacionalidad_valor: getDataValue("otra_nacionalidad_valor"),
      otra_residencia: getDataValue("otra_residencia"),
      otra_residencia_valor: getDataValue("otra_residencia_valor"),
      cedula: getDataValue("cedula"),
      QuienPaga: getDataValue("QuienPaga"),
      QuienPagaA: getDataValue("QuienPagaA"),
      QuienPagaN: getDataValue("QuienPagaN"),
      QuienPagaNT: getDataValue("QuienPagaNT"),
      QuienPagaD: getDataValue("QuienPagaD"),
      QuienPagaP: getDataValue("QuienPagaP"),
      usa_travel_sino: getDataValue("usa_travel_sino"),
      usa_travel_date1: getDataValue("usa_travel_date1"),
      usa_travel_days1: getDataValue("usa_travel_days1"),
      usa_travel_date2: getDataValue("usa_travel_date2"),
      usa_travel_days2: getDataValue("usa_travel_days2"),
      usa_travel_days3: getDataValue("usa_travel_days3"),
      usa_travel_date3: getDataValue("usa_travel_date3"),
      usa_visa: getDataValue("usa_visa"),
      usa_visadate: getDataValue("usa_visadate"),
      usa_visanumber: getDataValue("usa_visanumber"),
      usv_visasmae: getDataValue("usv_visasmae"),
      usa_visacountry: getDataValue("usa_visacountry"),
      usa_visa_perdida: getDataValue("usa_visa_perdida"),
      us_visa_perdida_anho: getDataValue("us_visa_perdida_anho"),
      usa_visa_perdida_motivo: getDataValue("usa_visa_perdida_motivo"),
      usa_visa_cancelada: getDataValue("usa_visa_cancelada"),
      usa_visa_cancelada_razon: getDataValue("usa_visa_cancelada_razon"),
      VISANO: getDataValue("VISANO"),
      VISANODETALLE: getDataValue("VISANODETALLE"),
      us_visamigrante: getDataValue("us_visamigrante"),
      usa_visamigrante_rexplique: getDataValue("usa_visamigrante_rexplique"),
      dir_linea1: getDataValue("dir_linea1"),
      dir_linea2: getDataValue("dir_linea2"),
      dir_ciudad: getDataValue("dir_ciudad"),
      dir_provincia: getDataValue("dir_provincia"),
      dir_codigopostal: getDataValue("dir_codigopostal"),
      dir_pais: getDataValue("dir_pais"),
      tel_primario: getDataValue("tel_primario"),
      tel_secundario: getDataValue("tel_secundario"),
      tel_trabajo: getDataValue("tel_trabajo"),
      tel_adicional: getDataValue("tel_adicional"),
      tel_adicional_tel: getDataValue("tel_adicional_tel"),
      email: getDataValue("email"),
      email_otro_sino: getDataValue("email_otro_sino"),
      email_otro_valor: getDataValue("email_otro_valor"),
      medio_sociales_sino: getDataValue("medio_sociales_sino"),
      medio_social_proveedor: getDataValue("medio_social_proveedor"),
      medio_social_id: getDataValue("medio_social_id"),
      medio_proporcionar: getDataValue("medio_proporcionar"),
      medio_proporcionar_respuesta: getDataValue(
        "medio_proporcionar_respuesta"
      ),
      pasaporte_tipo: getDataValue("pasaporte_tipo"),
      pasaporte_numero: getDataValue("pasaporte_numero"),
      pasaporte_country: getDataValue("pasaporte_country"),
      pasaporte_city: getDataValue("pasaporte_city"),
      pasaporte_provincia: getDataValue("pasaporte_provincia"),
      pasaporte_fecha_emision: getDataValue("pasaporte_fecha-emision"),
      pasaporte_fecha_end: getDataValue("pasaporte_fecha-end"),
      pasaporte_peridido: getDataValue("pasaporte_peridido"),
      pasaporte_perdido_numero: getDataValue("pasaporte_perdido_numero"),
      pasaporte_perdido_country: getDataValue("pasaporte_perdido_country"),
      pasaporte_perido_explicacion: getDataValue(
        "pasaporte_perido_explicacion"
      ),
      padre_apellido: getDataValue("padre_apellido"),
      padre_nombre: getDataValue("padre_nombre"),
      padre_email: getDataValue("padre_email"),
      padre_fecha_nacimiento: getDataValue("padre_fecha_nacimiento"),
      padre_usa: getDataValue("padre_usa"),
      padre_usa_status: getDataValue("padre_usa_status"),
      madre_apellido: getDataValue("madre_apellido"),
      madre_nombre: getDataValue("madre_nombre"),
      madre_email: getDataValue("madre_email"),
      madre_fecha_nacimiento: getDataValue("madre_fecha_nacimiento"),
      madre_usa: getDataValue("madre_usa"),
      madre_usa_status: getDataValue("madre_usa_status"),
      FES180: getDataValue("FES180"),
      DES190: getDataValue("DES190"),
      DES191: getDataValue("DES191"),
      empleo_sino: getDataValue("empleo_sino"),
      empleo_empleador: getDataValue("empleo_empleador"),
      empleo_dir_linea1: getDataValue("empleo_dir_linea1"),
      empleo_dir_linea2: getDataValue("empleo_dir_linea2"),
      empleo_ciudad: getDataValue("empleo_ciudad"),
      empleo_provincia: getDataValue("empleo_provincia"),
      empleo_zonapostal: getDataValue("empleo_zonapostal"),
      empleo_pais: getDataValue("empleo_pais"),
      empleo_titulo: getDataValue("empleo_titulo"),
      empleo_apellido_sup: getDataValue("empleo_apellido_sup"),
      empleo_nombre_sup: getDataValue("empleo_nombre_sup"),
      empleo_numerotel: getDataValue("empleo_numerotel"),
      empleo_fecha_desde: getDataValue("empleo_fecha_desde"),
      empleo_fecha_hasta: getDataValue("empleo_fecha_hasta"),
      empleo_funciones: getDataValue("empleo_funciones"),
      edu_sino: getDataValue("edu_sino"),
      edu_institucion: getDataValue("edu_institucion"),
      edu_dir_linea1: getDataValue("edu_dir_linea1"),
      edu_ciudad: getDataValue("edu_ciudad"),
      edu_fe: getDataValue("edu_fe"),
      edu_car: getDataValue("edu_car"),
      idiomas: getDataValue("idiomas"),
      ref1Nombre: getDataValue("ref1Nombre"),
      ref1Dir: getDataValue("ref1Dir"),
      ref1Tel: getDataValue("ref1Tel"),
      ref2Nombre: getDataValue("ref2Nombre"),
      ref2Dir: getDataValue("ref2Dir"),
      ref2Tel: getDataValue("ref2Tel"),
      ps142: getDataValue("ps142"),
      ps143: getDataValue("ps143"),
      ps144: getDataValue("ps144"),
      ps145: getDataValue("ps145"),
      ps146: getDataValue("ps146"),
      ps147: getDataValue("ps147"),
      ps148: getDataValue("ps148"),
      ps149: getDataValue("ps149"),
      ps150: getDataValue("ps150"),
      ps151: getDataValue("ps151"),
      ps152: getDataValue("ps152"),
      ps153: getDataValue("ps153"),
      ps154: getDataValue("ps154"),
      ps155: getDataValue("ps155"),
      ps156: getDataValue("ps156"),
      ps157: getDataValue("ps157"),
      ps158: getDataValue("ps158"),
      ps159: getDataValue("ps159"),
      ps160: getDataValue("ps160"),
      ps161: getDataValue("ps161"),
      ps162: getDataValue("ps162"),
      ps163: getDataValue("ps163"),
      ps164: getDataValue("ps164"),
      ps165: getDataValue("ps165"),
      ps166: getDataValue("ps166"),
      ps167: getDataValue("ps167"),
      ps168: getDataValue("ps168"),
      ps169: getDataValue("ps169"),
      ps170: getDataValue("ps170"),
      ps171: getDataValue("ps171"),
      ps172: getDataValue("ps172"),
      ps173: getDataValue("ps173"),
      ps174: getDataValue("ps174"),
    });
    setIsLoading(false);
  }
  async function submit(values: IDS160) {
    try {
      setIsSaving(true);
      if (values.otron_sino == "NO") {
        values.otron_apellidos = "";
        values.otron_nombres = "";
      }
      if (values.estado_civil != "2" && values.estado_civil != "5") {
        values.pareja_nombre_completo = "";
        values.pareja_fecha_nacimiento = "";
        values.pareja_direccion = "";
        values.pareja_telefono = "";
      }

      if (values.otra_nacionalidad_sino == "NO") {
        values.otra_nacionalidad_valor = "";
      }

      if (values.otra_residencia == "NO") {
        values.otra_residencia_valor = "";
      }

      if (values.QuienPaga == "SI") {
        values.QuienPagaA = "";
        values.QuienPagaD = "";
        values.QuienPagaN = "";
        values.QuienPagaNT = "";
        values.QuienPagaP = "";
      }

      if (values.usa_travel_sino == "NO") {
        values.usa_travel_date1 = "";
        values.usa_travel_date2 = "";
        values.usa_travel_date3 = "";
        values.usa_travel_days1 = "";
        values.usa_travel_days2 = "";
        values.usa_travel_days3 = "";
      }

      if (values.usa_visa == "NO") {
        values.usa_visadate = "";
        values.usa_visanumber = "";
        values.usv_visasmae = "";
        values.usa_visacountry = "";
        values.usa_visa_perdida = "";
        values.us_visa_perdida_anho = "";
        values.usa_visa_perdida_motivo = "";
        values.usa_visa_cancelada = "";
        values.usa_visa_cancelada_razon = "";
      }

      if (values.usa_visa_perdida == "NO") {
        values.us_visa_perdida_anho = "";
        values.usa_visa_perdida_motivo = "";
      }

      if (values.usa_visa_cancelada == "NO") {
        values.usa_visa_cancelada_razon = "";
      }
      if (values.VISANO == "NO") {
        values.VISANODETALLE = "";
      }
      if (values.us_visamigrante == "NO") {
        values.usa_visamigrante_rexplique = "";
      }

      if (values.tel_adicional == "NO") {
        values.tel_adicional_tel = "";
      }

      if (values.email_otro_sino == "NO") {
        values.email_otro_valor = "";
      }

      if (values.medio_sociales_sino == "NO") {
        values.medio_social_proveedor = "";
        values.medio_social_id = "";
      }

      if (values.medio_proporcionar == "NO") {
        values.medio_proporcionar_respuesta = "";
      }
      if (values.pasaporte_peridido == "NO") {
        values.pasaporte_perdido_numero = "";
        values.pasaporte_perido_explicacion = "";
        values.pasaporte_perdido_country = "";
      }

      if (values.FES180 == "NO") {
        values.DES190 = "";
        values.DES191 = "";
      }

      if (values.empleo_sino == "NO") {
        values.empleo_titulo = "";
        values.empleo_apellido_sup = "";
        values.empleo_nombre_sup = "";
        values.empleo_ciudad = "";
        values.empleo_dir_linea1 = "";
        values.empleo_dir_linea2 = "";
        values.empleo_empleador = "";
        values.empleo_fecha_desde = "";
        values.empleo_fecha_hasta = "";
        values.empleo_funciones = "";
        values.empleo_numerotel = "";
        values.empleo_pais = "";
        values.empleo_provincia = "";
        values.empleo_zonapostal = "";
      }

      if (values.edu_sino == "NO") {
        values.edu_car = "";
        values.edu_ciudad = "";
        values.edu_dir_linea1 = "";
        values.edu_fe = "";
        values.edu_institucion = "";
      }

      const obj: { [key: string]: any } = {};
      for (const [key, value] of Object.entries(values)) {
        obj[key] = value;
      }

      obj["pasaporte_fecha-emision"] = values.pasaporte_fecha_emision;
      obj["pasaporte_fecha-end"] = values.pasaporte_fecha_end;

      await CandidateService.saveDS160(encodeURIComponent(JSON.stringify(obj)));

      var applicationState: ApplicationState | null =
        await AuthService.loadUserInfo();

      if (applicationState != null) {
        dispatch(login({ applicationState: applicationState }));
      }

      setIsSaving(false);

      setOpenDialog(true);
      setDialogTitle("Guardado");
      setDialogDesc("Su formulario ha sido guardado y enviado.");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          DS 160
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        DS 160
      </Typography> */}

      {
        isLoading ? (
          <Box textAlign="center">
            <Box display="inline-block" textAlign="center">
              <CircularProgress
                color="secondary"
                size={50}
                sx={{ mr: "10px" }}
              />
              <Typography variant="body1">Cargando...</Typography>
            </Box>
          </Box>
        ) : (
          // appState.dS160FileUrl != null && appState.dS160FileUrl.length > 0 ? (
          //   <iframe
          //     src={appState.dS160FileUrl}
          //     style={{ width: "100%", height: "100vh" }}
          //   ></iframe>
          // ) : (
          <Box>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={submit}
              validationSchema={validationSchema}
              validateOnMount={true}
            >
              {(props: FormikProps<IDS160>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                  setFieldValue,
                  isValid,
                } = props;

                return (
                  <Form>
                    <Grid container spacing={1}>
                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Informaciòn Personal 1
                        </Typography>
                      </Box>
                      {/* Nombres */}
                      <Grid item lg={6} xs={12}>
                        <Paragraph title="Nombres" required />
                        <AppTextField
                          error={
                            errors.nombres && touched.nombres ? true : false
                          }
                          name="nombres"
                          value={values.nombres}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      {/* Apellidos */}
                      <Grid item lg={6} xs={12}>
                        <Paragraph title="Apellidos" required />
                        <AppTextField
                          error={
                            errors.apellidos && touched.apellidos ? true : false
                          }
                          name="apellidos"
                          value={values.apellidos}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      {/* Otro nombres */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Alguna vez ha usado otros nombres (es decir, soltera, religiosa, profesional, alias, etc.)?"
                          required
                        />
                        <AppSelect
                          name="otron_sino"
                          error={
                            errors.otron_sino && touched.otron_sino
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.otron_sino}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {values.otron_sino == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph
                            title="Otros apellidos utilizados (religiosa, profesional, alias, etc.) "
                            required
                          />
                          <AppTextField
                            error={
                              errors.otron_apellidos && touched.otron_apellidos
                                ? true
                                : false
                            }
                            name="otron_apellidos"
                            value={values.otron_apellidos}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {values.otron_sino == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph
                            title="Otros nombres dados son usados "
                            required
                          />
                          <AppTextField
                            error={
                              errors.otron_nombres && touched.otron_nombres
                                ? true
                                : false
                            }
                            name="otron_nombres"
                            value={values.otron_nombres}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Sexo */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Sexo" required />
                        <AppSelect
                          name="sexo"
                          error={errors.sexo && touched.sexo ? true : false}
                          fullWidth
                          value={values.sexo}
                          onChange={handleChange}
                        >
                          <MenuItem value="M">Masculino</MenuItem>
                          <MenuItem value="F">Femenino</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* Estado Civil */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Estado civil" required />
                        <AppSelect
                          name="estado_civil"
                          error={
                            errors.estado_civil && touched.estado_civil
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.estado_civil}
                          onChange={handleChange}
                        >
                          {civilStatus.map((el) => (
                            <MenuItem
                              key={el.civilStatusId}
                              value={el.civilStatusId}
                            >
                              {el.status}
                            </MenuItem>
                          ))}
                        </AppSelect>
                      </Grid>

                      {/* Pareja Nombre */}
                      {values.estado_civil == "2" ||
                      values.estado_civil == "5" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph
                            title="Nombre completo de la pareja"
                            required
                          />
                          <AppTextField
                            error={
                              errors.pareja_nombre_completo &&
                              touched.pareja_nombre_completo
                                ? true
                                : false
                            }
                            name="pareja_nombre_completo"
                            value={values.pareja_nombre_completo}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}
                      {/* Fecha de nacimiento pareja*/}
                      {values.estado_civil == "2" ||
                      values.estado_civil == "5" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph
                            title="Fecha nacimiento de la pareja"
                            required
                          />
                          <AppTextField
                            type="date"
                            error={
                              errors.pareja_fecha_nacimiento &&
                              touched.pareja_fecha_nacimiento
                                ? true
                                : false
                            }
                            name="pareja_fecha_nacimiento"
                            value={values.pareja_fecha_nacimiento}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Pareja direccion */}
                      {values.estado_civil == "2" ||
                      values.estado_civil == "5" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph
                            title="Direccion actual de la pareja"
                            required
                          />
                          <AppTextField
                            error={
                              errors.pareja_direccion &&
                              touched.pareja_direccion
                                ? true
                                : false
                            }
                            name="pareja_direccion"
                            value={values.pareja_direccion}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Pareja telefono */}
                      {values.estado_civil == "2" ||
                      values.estado_civil == "5" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph title="Telefono de la pareja" required />
                          <AppTextField
                            error={
                              errors.pareja_telefono && touched.pareja_telefono
                                ? true
                                : false
                            }
                            name="pareja_telefono"
                            value={values.pareja_telefono}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const phoneRegex =
                                /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                              const { value } = event.target;
                              if (phoneRegex.test(value)) {
                                const formattedPhone = phoneFormatter(value);
                                setFieldValue(
                                  "pareja_telefono",
                                  formattedPhone
                                );
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Fecha y lugar de nacimiento
                        </Typography>
                      </Box>
                      {/* Fecha */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Fecha" required />
                        <AppTextField
                          type="date"
                          error={
                            errors.nacimiento_fecha && touched.nacimiento_fecha
                              ? true
                              : false
                          }
                          name="nacimiento_fecha"
                          value={values.nacimiento_fecha}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      {/* Ciudad */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Ciudad" required />
                        <AppTextField
                          error={
                            errors.nacimiento_ciudad &&
                            touched.nacimiento_ciudad
                              ? true
                              : false
                          }
                          name="nacimiento_ciudad"
                          value={values.nacimiento_ciudad}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      {/* Provincia */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="Provincia/estado" required />
                        <AppTextField
                          error={
                            errors.nacimiento_provincia &&
                            touched.nacimiento_provincia
                              ? true
                              : false
                          }
                          name="nacimiento_provincia"
                          value={values.nacimiento_provincia}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      {/* Pais */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph title="País / región" required />
                        <AppTextField
                          error={
                            errors.nacimiento_pais && touched.nacimiento_pais
                              ? true
                              : false
                          }
                          name="nacimiento_pais"
                          value={values.nacimiento_pais}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography sx={{ color: "white", px: 2, py: 1 }}>
                          Informaciòn personal 2
                        </Typography>
                      </Box>

                      {/* Nacionalidad */}
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="País / Región de origen (Nacionalidad)"
                          required
                        />
                        <AppTextField
                          error={
                            errors.nacionalidad && touched.nacionalidad
                              ? true
                              : false
                          }
                          name="nacionalidad"
                          value={values.nacionalidad}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Otro Nacionalidad */}
                      <Grid item xs={12} lg={8}>
                        <Paragraph
                          title="¿Tiene o ha tenido alguna nacionalidad que no sea la indicada anteriormente en la nacionalidad?"
                          required
                        />
                        <AppSelect
                          name="otra_nacionalidad_sino"
                          error={
                            errors.otra_nacionalidad_sino &&
                            touched.otra_nacionalidad_sino
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.otra_nacionalidad_sino}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Otro Nacionalidad  Pais*/}
                      {values.otra_nacionalidad_sino == "SI" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph
                            title="Otro país / región de origen (nacionalidad)"
                            required
                          />
                          <AppSelect
                            name="otra_nacionalidad_valor"
                            error={
                              errors.otra_nacionalidad_valor &&
                              touched.otra_nacionalidad_valor
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.otra_nacionalidad_valor}
                            onChange={handleChange}
                            isSearchable={true}
                            options={countries.map((el) => {
                              var option: IOption = {
                                label: el.name,
                                value: el.countryId.toString(),
                              };
                              return option;
                            })}
                          >
                            {" "}
                          </AppSelect>
                        </Grid>
                      ) : null}

                      {/* Otro Recidencia */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Es usted un residente permanente de un país / región que no sea su país / región de origen (nacionalidad) indicado anteriormente?"
                          required
                        />
                        <AppSelect
                          name="otra_residencia"
                          error={
                            errors.otra_residencia && touched.otra_residencia
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.otra_residencia}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* Otro Recidencia Pais*/}

                      {values.otra_residencia == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph
                            title="Otro país / región residente permanente"
                            required
                          />
                          <AppSelect
                            name="otra_residencia_valor"
                            error={
                              errors.otra_residencia_valor &&
                              touched.otra_residencia_valor
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.otra_residencia_valor}
                            onChange={handleChange}
                            isSearchable={true}
                            options={countries.map((el) => {
                              var option: IOption = {
                                label: el.name,
                                value: el.countryId.toString(),
                              };
                              return option;
                            })}
                          >
                            {" "}
                          </AppSelect>
                        </Grid>
                      ) : null}

                      {/* Cedula */}
                      <Grid item lg={6} xs={12}>
                        <Paragraph
                          title="Número de identificación nacional (CEDULA)"
                          required
                        />
                        <AppTextField
                          error={errors.cedula && touched.cedula ? true : false}
                          name="cedula"
                          value={values.cedula}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const cedulaRegex =
                              /^(\d{0,3})-?(\d{0,7})-?(\d{0,1})$/;
                            const { value } = event.target;
                            if (cedulaRegex.test(value)) {
                              const formattedPhone = cedulaFormatter(value);
                              setFieldValue("cedula", formattedPhone);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Informaciòn sobre el viaje
                        </Typography>
                      </Box>

                      {/* Pagara programa */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Usted va a pagar su programa? En caso de ser otra persona coloque NO."
                          required
                        />
                        <AppSelect
                          name="QuienPaga"
                          error={
                            errors.QuienPaga && touched.QuienPaga ? true : false
                          }
                          fullWidth
                          value={values.QuienPaga}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* Pagara programa apellido */}
                      {values.QuienPaga == "NO" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph
                            title="Apellidos de esa persona"
                            required
                          />
                          <AppTextField
                            error={
                              errors.QuienPagaA && touched.QuienPagaA
                                ? true
                                : false
                            }
                            name="QuienPagaA"
                            value={values.QuienPagaA}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Pagara programa nombre */}
                      {values.QuienPaga == "NO" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph title="Nombres de esa persona" required />
                          <AppTextField
                            error={
                              errors.QuienPagaN && touched.QuienPagaN
                                ? true
                                : false
                            }
                            name="QuienPagaN"
                            value={values.QuienPagaN}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Pagara programa telefono */}
                      {values.QuienPaga == "NO" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph
                            title="Número de teléfono de esa persona"
                            required
                          />
                          <AppTextField
                            error={
                              errors.QuienPagaNT && touched.QuienPagaNT
                                ? true
                                : false
                            }
                            name="QuienPagaNT"
                            value={values.QuienPagaNT}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const phoneRegex =
                                /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                              const { value } = event.target;
                              if (phoneRegex.test(value)) {
                                const formattedPhone = phoneFormatter(value);
                                setFieldValue("QuienPagaNT", formattedPhone);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Pagara programa direccion */}
                      {values.QuienPaga == "NO" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph
                            title="Dirección donde vive esa persona"
                            required
                          />
                          <AppTextField
                            error={
                              errors.QuienPagaD && touched.QuienPagaD
                                ? true
                                : false
                            }
                            name="QuienPagaD"
                            value={values.QuienPagaD}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Pagara programa parentesco */}
                      {values.QuienPaga == "NO" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph
                            title="Parentesco de esa persona con usted"
                            required
                          />
                          <AppTextField
                            error={
                              errors.QuienPagaP && touched.QuienPagaP
                                ? true
                                : false
                            }
                            name="QuienPagaP"
                            value={values.QuienPagaP}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Informaciòn previa al viaje
                        </Typography>
                      </Box>

                      {/* has estado en los Estados Unidos */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Alguna vez has estado en los Estados Unidos?"
                          required
                        />
                        <AppSelect
                          name="usa_travel_sino"
                          error={
                            errors.usa_travel_sino && touched.usa_travel_sino
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.usa_travel_sino}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Viaje 1 */}
                      {values.usa_travel_sino == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph
                            title="Fecha 1er viaje cuando llego a estados unidos"
                            required
                          />
                          <AppTextField
                            type="date"
                            error={
                              errors.usa_travel_date1 &&
                              touched.usa_travel_date1
                                ? true
                                : false
                            }
                            name="usa_travel_date1"
                            value={values.usa_travel_date1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {values.usa_travel_sino == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph
                            title="Duración de su estadía en el 1er viaje"
                            required
                          />
                          <AppTextField
                            error={
                              errors.usa_travel_days1 &&
                              touched.usa_travel_days1
                                ? true
                                : false
                            }
                            name="usa_travel_days1"
                            value={values.usa_travel_days1}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}
                      {/* Viaje 2 */}
                      {values.usa_travel_sino == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph title="Fecha 2do viaje cuando llego a estados unidos" />
                          <AppTextField
                            type="date"
                            error={
                              errors.usa_travel_date2 &&
                              touched.usa_travel_date2
                                ? true
                                : false
                            }
                            name="usa_travel_date2"
                            value={values.usa_travel_date2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {values.usa_travel_sino == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph title="Duración de su estadía en el 2do viaje" />
                          <AppTextField
                            error={
                              errors.usa_travel_days2 &&
                              touched.usa_travel_days2
                                ? true
                                : false
                            }
                            name="usa_travel_days2"
                            value={values.usa_travel_days2}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}
                      {/* Viaje 3 */}
                      {values.usa_travel_sino == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph title="Fecha 3er viaje cuando llego a estados unidos" />
                          <AppTextField
                            type="date"
                            error={
                              errors.usa_travel_date3 &&
                              touched.usa_travel_date3
                                ? true
                                : false
                            }
                            name="usa_travel_date3"
                            value={values.usa_travel_date3}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {values.usa_travel_sino == "SI" ? (
                        <Grid item lg={6} xs={12}>
                          <Paragraph title="Duración de su estadía en el 3er viaje" />
                          <AppTextField
                            error={
                              errors.usa_travel_days3 &&
                              touched.usa_travel_days3
                                ? true
                                : false
                            }
                            name="usa_travel_days3"
                            value={values.usa_travel_days3}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Visa emitida */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Alguna vez ha emitido una visa de EE.UU.?"
                          required
                        />
                        <AppSelect
                          name="usa_visa"
                          error={
                            errors.usa_visa && touched.usa_visa ? true : false
                          }
                          fullWidth
                          value={values.usa_visa}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Visa emitida fecha*/}
                      {values.usa_visa == "SI" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph
                            title="Fecha de emisión de la última visa"
                            required
                          />
                          <AppTextField
                            type="date"
                            error={
                              errors.usa_visadate && touched.usa_visadate
                                ? true
                                : false
                            }
                            name="usa_visadate"
                            value={values.usa_visadate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Visa  numero*/}
                      {values.usa_visa == "SI" ? (
                        <Grid item lg={4} xs={12}>
                          <Paragraph title="Numero de la visa" required />
                          <AppTextField
                            error={
                              errors.usa_visanumber && touched.usa_visanumber
                                ? true
                                : false
                            }
                            name="usa_visanumber"
                            value={values.usa_visanumber}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}
                      {/* mismo tipo de  visa*/}
                      {values.usa_visa == "SI" ? (
                        <Grid item xs={12} lg={4}>
                          <Paragraph
                            title="¿Esta aplicando para el mismo tipo de visa?"
                            required
                          />
                          <AppSelect
                            name="usv_visasmae"
                            error={
                              errors.usv_visasmae && touched.usv_visasmae
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.usv_visasmae}
                            onChange={handleChange}
                          >
                            <MenuItem value="SI">SI</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                          </AppSelect>
                        </Grid>
                      ) : null}
                      {/* mismo pais*/}
                      {values.usa_visa == "SI" ? (
                        <Grid item xs={12}>
                          <Paragraph
                            title="¿Está solicitando en el mismo país o ubicación donde se emitió la visa anterior, y este país o ubicación es su lugar de residencia principal?"
                            required
                          />
                          <AppSelect
                            name="usa_visacountry"
                            error={
                              errors.usa_visacountry && touched.usa_visacountry
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.usa_visacountry}
                            onChange={handleChange}
                          >
                            <MenuItem value="SI">SI</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                          </AppSelect>
                        </Grid>
                      ) : null}
                      {/* visa perdida*/}
                      {values.usa_visa == "SI" ? (
                        <Grid item xs={12}>
                          <Paragraph
                            title="¿Alguna vez se ha perdido o le han robado su visa estadounidense?"
                            required
                          />
                          <AppSelect
                            name="usa_visa_perdida"
                            error={
                              errors.usa_visa_perdida &&
                              touched.usa_visa_perdida
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.usa_visa_perdida}
                            onChange={handleChange}
                          >
                            <MenuItem value="SI">SI</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                          </AppSelect>
                        </Grid>
                      ) : null}
                      {/* Visa perdida año */}
                      {values.usa_visa == "SI" &&
                      values.usa_visa_perdida == "SI" ? (
                        <Grid item xs={12} lg={6}>
                          <Paragraph
                            title="Escriba el año cuando perdió la visa"
                            required
                          />
                          <AppTextField
                            error={
                              errors.us_visa_perdida_anho &&
                              touched.us_visa_perdida_anho
                                ? true
                                : false
                            }
                            name="us_visa_perdida_anho"
                            value={values.us_visa_perdida_anho}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}
                      {/* Visa perdida motivo */}
                      {values.usa_visa == "SI" &&
                      values.usa_visa_perdida == "SI" ? (
                        <Grid item xs={12} lg={6}>
                          <Paragraph
                            title="Explique motivo de pérdida de la visa"
                            required
                          />
                          <AppTextField
                            error={
                              errors.usa_visa_perdida_motivo &&
                              touched.usa_visa_perdida_motivo
                                ? true
                                : false
                            }
                            name="usa_visa_perdida_motivo"
                            value={values.usa_visa_perdida_motivo}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Visa cancelada */}
                      {values.usa_visa == "SI" ? (
                        <Grid item xs={12}>
                          <Paragraph
                            title="¿Alguna vez se ha cancelado o revocado su visa estadounidense?"
                            required
                          />
                          <AppSelect
                            name="usa_visa_cancelada"
                            error={
                              errors.usa_visa_cancelada &&
                              touched.usa_visa_cancelada
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.usa_visa_cancelada}
                            onChange={handleChange}
                          >
                            <MenuItem value="SI">SI</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                          </AppSelect>
                        </Grid>
                      ) : null}
                      {/* Visa cancelada detail */}
                      {values.usa_visa == "SI" &&
                      values.usa_visa_cancelada == "SI" ? (
                        <Grid item xs={12}>
                          <Paragraph
                            title="Explique motivo de la cancelación o la revocación"
                            required
                          />
                          <AppTextField
                            error={
                              errors.usa_visa_cancelada_razon &&
                              touched.usa_visa_cancelada_razon
                                ? true
                                : false
                            }
                            name="usa_visa_cancelada_razon"
                            value={values.usa_visa_cancelada_razon}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* Visa negada */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Alguna vez te han negado una visa americana?"
                          required
                        />
                        <AppSelect
                          name="VISANO"
                          error={errors.VISANO && touched.VISANO ? true : false}
                          fullWidth
                          value={values.VISANO}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* visa negada tipo */}
                      {values.VISANO == "SI" ? (
                        <Grid item xs={12}>
                          <Paragraph title="¿Qué tipo de visa?" required />
                          <AppTextField
                            error={
                              errors.VISANODETALLE && touched.VISANODETALLE
                                ? true
                                : false
                            }
                            name="VISANODETALLE"
                            value={values.VISANODETALLE}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* servicio migratorios */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Alguien ha presentado alguna vez una petición de inmigrante en su nombre ante los Servicios de Ciudadanía e Inmigración de los Estados Unidos?"
                          required
                        />
                        <AppSelect
                          name="us_visamigrante"
                          error={
                            errors.us_visamigrante && touched.us_visamigrante
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.us_visamigrante}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* servicio migratorio explain */}
                      {values.us_visamigrante == "SI" ? (
                        <Grid item xs={12}>
                          <Paragraph title="Explique" required />
                          <AppTextField
                            error={
                              errors.usa_visamigrante_rexplique &&
                              touched.usa_visamigrante_rexplique
                                ? true
                                : false
                            }
                            name="usa_visamigrante_rexplique"
                            value={values.usa_visamigrante_rexplique}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Dirección e información del teléfono
                        </Typography>
                      </Box>
                      {/* direccion 1*/}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Dirección 1" required />
                        <AppTextField
                          error={
                            errors.dir_linea1 && touched.dir_linea1
                              ? true
                              : false
                          }
                          name="dir_linea1"
                          value={values.dir_linea1}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      {/* direccion 2 */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Dirección 2" />
                        <AppTextField
                          error={
                            errors.dir_linea2 && touched.dir_linea2
                              ? true
                              : false
                          }
                          name="dir_linea2"
                          value={values.dir_linea2}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/*  Ciudad*/}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Ciudad" required />
                        <AppTextField
                          error={
                            errors.dir_ciudad && touched.dir_ciudad
                              ? true
                              : false
                          }
                          name="dir_ciudad"
                          value={values.dir_ciudad}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/*  Provincia */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Provincia del estado" required />
                        <AppTextField
                          error={
                            errors.dir_provincia && touched.dir_provincia
                              ? true
                              : false
                          }
                          name="dir_provincia"
                          value={values.dir_provincia}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/*  codigo postal */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Zona postal / Código postal" />
                        <AppTextField
                          error={
                            errors.dir_codigopostal && touched.dir_codigopostal
                              ? true
                              : false
                          }
                          name="dir_codigopostal"
                          value={values.dir_codigopostal}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/*  Provincia */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="País / Región" required />
                        <AppTextField
                          error={
                            errors.dir_pais && touched.dir_pais ? true : false
                          }
                          name="dir_pais"
                          value={values.dir_pais}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/*  Telefono primario */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph
                          title="Número de teléfono primario"
                          required
                        />
                        <AppTextField
                          error={
                            errors.tel_primario && touched.tel_primario
                              ? true
                              : false
                          }
                          name="tel_primario"
                          value={values.tel_primario}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const phoneRegex =
                              /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                            const { value } = event.target;
                            if (phoneRegex.test(value)) {
                              const formattedPhone = phoneFormatter(value);
                              setFieldValue("tel_primario", formattedPhone);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/*  Telefono secundario */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Numero de telefono secundario" />
                        <AppTextField
                          error={
                            errors.tel_secundario && touched.tel_secundario
                              ? true
                              : false
                          }
                          name="tel_secundario"
                          value={values.tel_secundario}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const phoneRegex =
                              /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                            const { value } = event.target;
                            if (phoneRegex.test(value)) {
                              const formattedPhone = phoneFormatter(value);
                              setFieldValue("tel_secundario", formattedPhone);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/*  Telefono trabajo */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Número de teléfono del trabajo" />
                        <AppTextField
                          error={
                            errors.tel_trabajo && touched.tel_trabajo
                              ? true
                              : false
                          }
                          name="tel_trabajo"
                          value={values.tel_trabajo}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const phoneRegex =
                              /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                            const { value } = event.target;
                            if (phoneRegex.test(value)) {
                              const formattedPhone = phoneFormatter(value);
                              setFieldValue("tel_trabajo", formattedPhone);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* telefono adicional */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Ha usado otros números de teléfono en los últimos cinco años?"
                          required
                        />
                        <AppSelect
                          name="tel_adicional"
                          error={
                            errors.tel_adicional && touched.tel_adicional
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.tel_adicional}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* telefono adicionla detail */}
                      {values.tel_adicional == "SI" ? (
                        <Grid item xs={12} lg={4}>
                          <Paragraph
                            title="Número de teléfono adicional"
                            required
                          />
                          <AppTextField
                            error={
                              errors.tel_adicional_tel &&
                              touched.tel_adicional_tel
                                ? true
                                : false
                            }
                            name="tel_adicional_tel"
                            value={values.tel_adicional_tel}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const phoneRegex =
                                /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                              const { value } = event.target;
                              if (phoneRegex.test(value)) {
                                const formattedPhone = phoneFormatter(value);
                                setFieldValue(
                                  "tel_adicional_tel",
                                  formattedPhone
                                );
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}
                      {/* email */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph
                          title="Dirección de correo electrónico o email"
                          required
                        />
                        <AppTextField
                          error={errors.email && touched.email ? true : false}
                          name="email"
                          value={values.email}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* otro email */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Ha utilizado alguna otra dirección de correo electrónico en los últimos cinco años?"
                          required
                        />
                        <AppSelect
                          name="email_otro_sino"
                          error={
                            errors.email_otro_sino && touched.email_otro_sino
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.email_otro_sino}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* otro email vlaue */}
                      {values.email_otro_sino == "SI" ? (
                        <Grid item xs={12} lg={4}>
                          <Paragraph
                            title="Dirección de correo electrónico adicional "
                            required
                          />
                          <AppTextField
                            error={
                              errors.email_otro_valor &&
                              touched.email_otro_valor
                                ? true
                                : false
                            }
                            name="email_otro_valor"
                            value={values.email_otro_valor}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* redes sociales */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Tienes presencia en las redes sociales?"
                          required
                        />
                        <AppSelect
                          name="medio_sociales_sino"
                          error={
                            errors.medio_sociales_sino &&
                            touched.medio_sociales_sino
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.medio_sociales_sino}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* redes sociales  provedor*/}
                      {values.medio_sociales_sino == "SI" ? (
                        <Grid item xs={12} lg={4}>
                          <Paragraph
                            title="Proveedor / plataforma de redes sociales"
                            required
                          />
                          <AppTextField
                            error={
                              errors.medio_social_proveedor &&
                              touched.medio_social_proveedor
                                ? true
                                : false
                            }
                            name="medio_social_proveedor"
                            value={values.medio_social_proveedor}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}
                      {/* redes sociales  id*/}
                      {values.medio_sociales_sino == "SI" ? (
                        <Grid item xs={12} lg={4}>
                          <Paragraph
                            title="Identificador de redes sociales"
                            required
                          />
                          <AppTextField
                            error={
                              errors.medio_social_id && touched.medio_social_id
                                ? true
                                : false
                            }
                            name="medio_social_id"
                            value={values.medio_social_id}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* otro sitios web */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Desea proporcionar información sobre su presencia en otros sitios web o aplicaciones que haya utilizado en los últimos cinco años para crear o compartir contenido (fotos, videos, actualizaciones de estado, etc.)?"
                          required
                        />
                        <AppSelect
                          name="medio_proporcionar"
                          error={
                            errors.medio_proporcionar &&
                            touched.medio_proporcionar
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.medio_proporcionar}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* otro sitios web detalle*/}
                      {values.medio_proporcionar == "SI" ? (
                        <Grid item xs={12}>
                          <Paragraph title="Explicar" required />
                          <AppTextField
                            error={
                              errors.medio_proporcionar_respuesta &&
                              touched.medio_proporcionar_respuesta
                                ? true
                                : false
                            }
                            name="medio_proporcionar_respuesta"
                            value={values.medio_proporcionar_respuesta}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Información de pasaporte
                        </Typography>
                      </Box>
                      {/* pasaporte tipo */}
                      <Grid item xs={12} lg={6}>
                        <Paragraph
                          title="Pasaporte / Tipo de documento de viaje"
                          required
                        />
                        <AppTextField
                          error={
                            errors.pasaporte_tipo && touched.pasaporte_tipo
                              ? true
                              : false
                          }
                          name="pasaporte_tipo"
                          value={values.pasaporte_tipo}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      {/* pasaporte numero */}
                      <Grid item xs={12} lg={6}>
                        <Paragraph title="Número de pasaporte" required />
                        <AppTextField
                          error={
                            errors.pasaporte_numero && touched.pasaporte_numero
                              ? true
                              : false
                          }
                          name="pasaporte_numero"
                          value={values.pasaporte_numero}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* pasaporte country */}
                      <Grid item xs={12} lg={6}>
                        <Paragraph
                          title="País / autoridad que emitió el pasaporte / documento de viaje"
                          required
                        />
                        <AppTextField
                          error={
                            errors.pasaporte_country &&
                            touched.pasaporte_country
                              ? true
                              : false
                          }
                          name="pasaporte_country"
                          value={values.pasaporte_country}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* pasaporte ciudad */}
                      <Grid item xs={12} lg={6}>
                        <Paragraph title="Ciudad" required />
                        <AppTextField
                          error={
                            errors.pasaporte_city && touched.pasaporte_city
                              ? true
                              : false
                          }
                          name="pasaporte_city"
                          value={values.pasaporte_city}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* pasaporte provincia */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Estado / Provincia * Si aparece en el pasaporte" />
                        <AppTextField
                          error={
                            errors.pasaporte_provincia &&
                            touched.pasaporte_provincia
                              ? true
                              : false
                          }
                          name="pasaporte_provincia"
                          value={values.pasaporte_provincia}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* pasaporte emision */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Fecha de Emisión" required />
                        <AppTextField
                          type="date"
                          error={
                            errors.pasaporte_fecha_emision &&
                            touched.pasaporte_fecha_emision
                              ? true
                              : false
                          }
                          name="pasaporte_fecha_emision"
                          value={values.pasaporte_fecha_emision}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* pasaporte emision end */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Fecha de caducidad" required />
                        <AppTextField
                          type="date"
                          error={
                            errors.pasaporte_fecha_end &&
                            touched.pasaporte_fecha_end
                              ? true
                              : false
                          }
                          name="pasaporte_fecha_end"
                          value={values.pasaporte_fecha_end}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* pasaporte perdido */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Alguna vez has perdido el pasaporte o te lo han robado?"
                          required
                        />
                        <AppSelect
                          name="pasaporte_peridido"
                          error={
                            errors.pasaporte_peridido &&
                            touched.pasaporte_peridido
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.pasaporte_peridido}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {values.pasaporte_peridido == "SI" ? (
                        <Grid item xs={12}>
                          <Grid spacing={1} container>
                            {/* pasaporte numero */}
                            <Grid item xs={12} lg={6}>
                              <Paragraph
                                title="Número de pasaporte perdido / documento de viaje"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.pasaporte_perdido_numero &&
                                  touched.pasaporte_perdido_numero
                                    ? true
                                    : false
                                }
                                name="pasaporte_perdido_numero"
                                value={values.pasaporte_perdido_numero}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                            {/* pasaporte pais */}
                            <Grid item xs={12} lg={6}>
                              <Paragraph
                                title="País / autoridad que emitió el pasaporte perdido / documento de viaje"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.pasaporte_perdido_country &&
                                  touched.pasaporte_perdido_country
                                    ? true
                                    : false
                                }
                                name="pasaporte_perdido_country"
                                value={values.pasaporte_perdido_country}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                            {/* pasaporte detalle */}
                            <Grid item xs={12}>
                              <Paragraph
                                title="Explicación de lo ocurrido"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.pasaporte_perido_explicacion &&
                                  touched.pasaporte_perido_explicacion
                                    ? true
                                    : false
                                }
                                name="pasaporte_perido_explicacion"
                                value={values.pasaporte_perido_explicacion}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Información de parientes
                        </Typography>
                      </Box>

                      <Grid item xs={12}>
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              py: 1,
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Información del Padre
                          </Typography>
                        </Box>
                      </Grid>

                      {/* padre apellido */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Apellidos del padre" required />
                        <AppTextField
                          error={
                            errors.padre_apellido && touched.padre_apellido
                              ? true
                              : false
                          }
                          name="padre_apellido"
                          value={values.padre_apellido}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* padre nombre */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Nombres del padre" required />
                        <AppTextField
                          error={
                            errors.padre_nombre && touched.padre_apellido
                              ? true
                              : false
                          }
                          name="padre_nombre"
                          value={values.padre_nombre}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* padre email */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Correo electrónico padre" />
                        <AppTextField
                          error={
                            errors.padre_email && touched.padre_email
                              ? true
                              : false
                          }
                          name="padre_email"
                          value={values.padre_email}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* padre fecha de nacimiento */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Fecha de nacimiento" />
                        <AppTextField
                          type="date"
                          error={
                            errors.padre_fecha_nacimiento &&
                            touched.padre_fecha_nacimiento
                              ? true
                              : false
                          }
                          name="padre_fecha_nacimiento"
                          value={values.padre_fecha_nacimiento}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* padre usa */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph
                          title="¿Está tu padre en los Estados Unidos?"
                          required
                        />
                        <AppSelect
                          name="padre_usa"
                          error={
                            errors.padre_usa && touched.padre_usa ? true : false
                          }
                          fullWidth
                          value={values.padre_usa}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* padre usa migratory status */}
                      {values.padre_usa == "SI" ? (
                        <Grid item xs={12} lg={4}>
                          <Paragraph title="Estatus del padre" required />
                          <AppSelect
                            name="padre_usa_status"
                            error={
                              errors.padre_usa_status &&
                              touched.padre_usa_status
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.padre_usa_status}
                            onChange={handleChange}
                          >
                            {migrationStatus.map((el, index) => (
                              <MenuItem key={index} value={el}>
                                {el}
                              </MenuItem>
                            ))}
                          </AppSelect>
                        </Grid>
                      ) : null}

                      <Grid item xs={12}>
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              py: 1,
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Información de la Madre
                          </Typography>
                        </Box>
                      </Grid>

                      {/* madre apellido */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Apellidos de la madre" required />
                        <AppTextField
                          error={
                            errors.madre_apellido && touched.madre_apellido
                              ? true
                              : false
                          }
                          name="madre_apellido"
                          value={values.madre_apellido}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* madre nombre */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Nombres de la madre" required />
                        <AppTextField
                          error={
                            errors.madre_nombre && touched.madre_nombre
                              ? true
                              : false
                          }
                          name="madre_nombre"
                          value={values.madre_nombre}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* madre email */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Correo electrónico madre" />
                        <AppTextField
                          error={
                            errors.madre_email && touched.madre_email
                              ? true
                              : false
                          }
                          name="madre_email"
                          value={values.madre_email}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* madre fecha de nacimiento */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Fecha de nacimiento" />
                        <AppTextField
                          type="date"
                          error={
                            errors.madre_fecha_nacimiento &&
                            touched.madre_fecha_nacimiento
                              ? true
                              : false
                          }
                          name="madre_fecha_nacimiento"
                          value={values.madre_fecha_nacimiento}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* madre usa */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph
                          title="¿Está tu madre en los Estados Unidos?"
                          required
                        />
                        <AppSelect
                          name="madre_usa"
                          error={
                            errors.madre_usa && touched.madre_usa ? true : false
                          }
                          fullWidth
                          value={values.madre_usa}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* madre usa migratory status */}
                      {values.madre_usa == "SI" ? (
                        <Grid item xs={12} lg={4}>
                          <Paragraph title="Estatus de la madre" required />
                          <AppSelect
                            name="madre_usa_status"
                            error={
                              errors.madre_usa_status &&
                              touched.madre_usa_status
                                ? true
                                : false
                            }
                            fullWidth
                            value={values.madre_usa_status}
                            onChange={handleChange}
                          >
                            {migrationStatus.map((el, index) => (
                              <MenuItem key={index} value={el}>
                                {el}
                              </MenuItem>
                            ))}
                          </AppSelect>
                        </Grid>
                      ) : null}

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Familiares en Estados Unidos
                        </Typography>
                      </Box>
                      {/* familiares en usa */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Tienes familiares en Estados Unidos?  "
                          required
                        />
                        <AppSelect
                          name="FES180"
                          error={errors.FES180 && touched.FES180 ? true : false}
                          fullWidth
                          value={values.FES180}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* familiare usa tipo */}
                      {values.FES180 == "SI" ? (
                        <Grid item xs={12} lg={6}>
                          <Paragraph
                            title="Especificar tipo de familiar que vive en Estados Unidos"
                            required
                          />
                          <AppTextField
                            error={
                              errors.DES190 && touched.DES190 ? true : false
                            }
                            name="DES190"
                            value={values.DES190}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : null}

                      {/* familiar migratorio estatus */}
                      {values.FES180 == "SI" ? (
                        <Grid item xs={12} lg={6}>
                          <Paragraph
                            title="Estatus migratorio del familiar"
                            required
                          />
                          <AppSelect
                            name="DES191"
                            error={
                              errors.DES191 && touched.DES191 ? true : false
                            }
                            fullWidth
                            value={values.DES191}
                            onChange={handleChange}
                          >
                            {migrationStatus.map((el, index) => (
                              <MenuItem key={index} value={el}>
                                {el}
                              </MenuItem>
                            ))}
                          </AppSelect>
                        </Grid>
                      ) : null}

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Información sobre trabajo/educación/capacitación
                        </Typography>
                      </Box>

                      {/* trabajo */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Estaba usted empleado anteriormente?"
                          required
                        />
                        <AppSelect
                          name="empleo_sino"
                          error={
                            errors.empleo_sino && touched.empleo_sino
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.empleo_sino}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {values.empleo_sino == "SI" ? (
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            {/* Empleo titulo */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Título profesional" required />
                              <AppTextField
                                error={
                                  errors.empleo_titulo && touched.empleo_titulo
                                    ? true
                                    : false
                                }
                                name="empleo_titulo"
                                value={values.empleo_titulo}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* Nombre */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph
                                title="Nombre del empleador"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.empleo_empleador &&
                                  touched.empleo_empleador
                                    ? true
                                    : false
                                }
                                name="empleo_empleador"
                                value={values.empleo_empleador}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                            {/* Direccion 1 */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph
                                title="Dirección del empleador (línea 1)"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.empleo_dir_linea1 &&
                                  touched.empleo_dir_linea1
                                    ? true
                                    : false
                                }
                                name="empleo_dir_linea1"
                                value={values.empleo_dir_linea1}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                            {/* Direccion 2 */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Dirección del empleador (línea 2)" />
                              <AppTextField
                                error={
                                  errors.empleo_dir_linea2 &&
                                  touched.empleo_dir_linea2
                                    ? true
                                    : false
                                }
                                name="empleo_dir_linea2"
                                value={values.empleo_dir_linea2}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* Ciudad */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Ciudad" required />
                              <AppTextField
                                error={
                                  errors.empleo_ciudad && touched.empleo_ciudad
                                    ? true
                                    : false
                                }
                                name="empleo_ciudad"
                                value={values.empleo_ciudad}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                            {/* Provincia */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph
                                title="Provincia del estado"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.empleo_provincia &&
                                  touched.empleo_provincia
                                    ? true
                                    : false
                                }
                                name="empleo_provincia"
                                value={values.empleo_provincia}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* postal code */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Zona postal / Código postal" />
                              <AppTextField
                                error={
                                  errors.empleo_zonapostal &&
                                  touched.empleo_zonapostal
                                    ? true
                                    : false
                                }
                                name="empleo_zonapostal"
                                value={values.empleo_zonapostal}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                            {/* pais */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Pais/Región" required />
                              <AppTextField
                                error={
                                  errors.empleo_pais && touched.empleo_pais
                                    ? true
                                    : false
                                }
                                name="empleo_pais"
                                value={values.empleo_pais}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* Nombre supervisor */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph
                                title="El nombre del Supervisor"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.empleo_nombre_sup &&
                                  touched.empleo_nombre_sup
                                    ? true
                                    : false
                                }
                                name="empleo_nombre_sup"
                                value={values.empleo_nombre_sup}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* Apellido supervisor */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph
                                title="Apellido del Supervisor"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.empleo_apellido_sup &&
                                  touched.empleo_apellido_sup
                                    ? true
                                    : false
                                }
                                name="empleo_apellido_sup"
                                value={values.empleo_apellido_sup}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* Numero de telefono supervisor */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Número de teléfono" required />
                              <AppTextField
                                error={
                                  errors.empleo_numerotel &&
                                  touched.empleo_numerotel
                                    ? true
                                    : false
                                }
                                name="empleo_numerotel"
                                value={values.empleo_numerotel}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const phoneRegex =
                                    /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                                  const { value } = event.target;
                                  if (phoneRegex.test(value)) {
                                    const formattedPhone =
                                      phoneFormatter(value);
                                    setFieldValue(
                                      "empleo_numerotel",
                                      formattedPhone
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* fecha desde */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph
                                title="Fecha de empleo desde"
                                required
                              />
                              <AppTextField
                                type="date"
                                error={
                                  errors.empleo_fecha_desde &&
                                  touched.empleo_fecha_desde
                                    ? true
                                    : false
                                }
                                name="empleo_fecha_desde"
                                value={values.empleo_fecha_desde}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* fecha hasta */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Fecha hasta" />
                              <AppTextField
                                type="date"
                                error={
                                  errors.empleo_fecha_hasta &&
                                  touched.empleo_fecha_hasta
                                    ? true
                                    : false
                                }
                                name="empleo_fecha_hasta"
                                value={values.empleo_fecha_hasta}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* empleo funcionalidad */}
                            <Grid item xs={8}>
                              <Paragraph
                                title="Describa brevemente sus funciones"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.empleo_funciones &&
                                  touched.empleo_funciones
                                    ? true
                                    : false
                                }
                                name="empleo_funciones"
                                value={values.empleo_funciones}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}

                      {/* Educacion */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Has asistido a alguna institución educativa de nivel secundario o superior?"
                          required
                        />
                        <AppSelect
                          name="edu_sino"
                          error={
                            errors.edu_sino && touched.edu_sino ? true : false
                          }
                          fullWidth
                          value={values.edu_sino}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {values.edu_sino == "SI" ? (
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            {/* nombre institucion */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph
                                title="Nombre de la institución"
                                required
                              />
                              <AppTextField
                                error={
                                  errors.edu_institucion &&
                                  touched.edu_institucion
                                    ? true
                                    : false
                                }
                                name="edu_institucion"
                                value={values.edu_institucion}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* direccion institucion */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Dirección" required />
                              <AppTextField
                                error={
                                  errors.edu_dir_linea1 &&
                                  touched.edu_dir_linea1
                                    ? true
                                    : false
                                }
                                name="edu_dir_linea1"
                                value={values.edu_dir_linea1}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* direccion institucion */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Ciudad" required />
                              <AppTextField
                                error={
                                  errors.edu_ciudad && touched.edu_ciudad
                                    ? true
                                    : false
                                }
                                name="edu_ciudad"
                                value={values.edu_ciudad}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* fecha institucion */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Fecha que iniciaste la universidad" />
                              <AppTextField
                                type="date"
                                error={
                                  errors.edu_fe && touched.edu_fe ? true : false
                                }
                                name="edu_fe"
                                value={values.edu_fe}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {/* carrera institucion */}
                            <Grid item xs={12} lg={4}>
                              <Paragraph title="Carrera que estudias" />
                              <AppTextField
                                error={
                                  errors.edu_car && touched.edu_car
                                    ? true
                                    : false
                                }
                                name="edu_car"
                                value={values.edu_car}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleInputChangeToUppercase(
                                    event,
                                    setFieldValue
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}

                      {/* Idiomas */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph
                          title="Proporcione una lista de idiomas que habla"
                          required
                        />
                        <AppTextField
                          error={
                            errors.idiomas && touched.idiomas ? true : false
                          }
                          name="idiomas"
                          value={values.idiomas}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Referencias Personales
                        </Typography>
                      </Box>

                      {/* Ref 1 nombre */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Ref. Personal 1 Nombres" required />
                        <AppTextField
                          error={
                            errors.ref1Nombre && touched.ref1Nombre
                              ? true
                              : false
                          }
                          name="ref1Nombre"
                          value={values.ref1Nombre}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Ref 1 direccion */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Ref. Personal 1 direccion" required />
                        <AppTextField
                          error={
                            errors.ref1Dir && touched.ref1Dir ? true : false
                          }
                          name="ref1Dir"
                          value={values.ref1Dir}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Ref 1 telefono */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Ref. Personal 1 telefono" required />
                        <AppTextField
                          error={
                            errors.ref1Tel && touched.ref1Tel ? true : false
                          }
                          name="ref1Tel"
                          value={values.ref1Tel}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const phoneRegex =
                              /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                            const { value } = event.target;
                            if (phoneRegex.test(value)) {
                              const formattedPhone = phoneFormatter(value);
                              setFieldValue("ref1Tel", formattedPhone);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Ref 2 nombre */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Ref. Personal 2 Nombres" required />
                        <AppTextField
                          error={
                            errors.ref2Nombre && touched.ref2Nombre
                              ? true
                              : false
                          }
                          name="ref2Nombre"
                          value={values.ref2Nombre}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Ref 2 direccion */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Ref. Personal 2 direccion" required />
                        <AppTextField
                          error={
                            errors.ref2Dir && touched.ref2Dir ? true : false
                          }
                          name="ref2Dir"
                          value={values.ref2Dir}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Ref 2 telefono */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Ref. Personal 2 telefono" required />
                        <AppTextField
                          error={
                            errors.ref2Tel && touched.ref2Tel ? true : false
                          }
                          name="ref2Tel"
                          value={values.ref2Tel}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const phoneRegex =
                              /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                            const { value } = event.target;
                            if (phoneRegex.test(value)) {
                              const formattedPhone = phoneFormatter(value);
                              setFieldValue("ref2Tel", formattedPhone);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", px: 2, py: 1, fontSize: 20 }}
                        >
                          Preguntas de Seguridad
                        </Typography>
                      </Box>

                      {/* PS 1 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 1 ¿Pertenece a un clan o a una tribu?"
                          required
                        />
                        <AppSelect
                          name="ps142"
                          error={errors.ps142 && touched.ps142 ? true : false}
                          fullWidth
                          value={values.ps142}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 2 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 2 ¿Ha viajado a algún país / región en los últimos cinco años?"
                          required
                        />
                        <AppSelect
                          name="ps143"
                          error={errors.ps143 && touched.ps143 ? true : false}
                          fullWidth
                          value={values.ps143}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 3 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 3 ¿Ha pertenecido, contribuido o trabajado para alguna organización profesional, social o caritativa?"
                          required
                        />
                        <AppSelect
                          name="ps144"
                          error={errors.ps144 && touched.ps144 ? true : false}
                          fullWidth
                          value={values.ps144}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 4 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 4 ¿Tiene alguna habilidad o capacitación especializada, como armas de fuego, explosivos, experiencia nuclear, biológica o química?"
                          required
                        />
                        <AppSelect
                          name="ps145"
                          error={errors.ps145 && touched.ps145 ? true : false}
                          fullWidth
                          value={values.ps145}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 5 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 5 ¿Alguna vez ha servido en el ejército?"
                          required
                        />
                        <AppSelect
                          name="ps146"
                          error={errors.ps146 && touched.ps146 ? true : false}
                          fullWidth
                          value={values.ps146}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 6 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 6 ¿Alguna vez ha servido, ha sido miembro o ha estado involucrado con una unidad paramilitar, unidad de vigilancia, grupo rebelde, grupo guerrillero u organización insurgente?"
                          required
                        />
                        <AppSelect
                          name="ps147"
                          error={errors.ps147 && touched.ps147 ? true : false}
                          fullWidth
                          value={values.ps147}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 7 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 7 ¿Tiene una enfermedad transmisible de importancia para la salud pública? (Las enfermedades transmisibles de importancia pública incluyen chancroide, gonorrea, granuloma inguinal, lepra infecciosa, linfogranuloma venéreo, sífilis en estadio infeccioso, tuberculosis activa y otras enfermedades según lo determine el Departamento de Salud y Servicios Humanos)."
                          required
                        />
                        <AppSelect
                          name="ps148"
                          error={errors.ps148 && touched.ps148 ? true : false}
                          fullWidth
                          value={values.ps148}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 8 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 8 ¿Tiene un trastorno mental o físico que plantea o es probable que represente una amenaza para la seguridad o el bienestar de usted u otros?"
                          required
                        />
                        <AppSelect
                          name="ps149"
                          error={errors.ps149 && touched.ps149 ? true : false}
                          fullWidth
                          value={values.ps149}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 9 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 9 ¿Eres o has sido alguna vez un drogadicto o adicto?"
                          required
                        />
                        <AppSelect
                          name="ps150"
                          error={errors.ps150 && touched.ps150 ? true : false}
                          fullWidth
                          value={values.ps150}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 10 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 10 ¿Alguna vez ha sido arrestado o condenado por algún delito o delito, a pesar de ser objeto de perdón, amnistía u otra acción similar?"
                          required
                        />
                        <AppSelect
                          name="ps151"
                          error={errors.ps151 && touched.ps151 ? true : false}
                          fullWidth
                          value={values.ps151}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 11 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 11 ¿Alguna vez ha violado, o ha participado en una conspiración para violar, alguna ley relacionada con sustancias controladas?"
                          required
                        />
                        <AppSelect
                          name="ps152"
                          error={errors.ps152 && touched.ps152 ? true : false}
                          fullWidth
                          value={values.ps152}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 12 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 12 ¿Vienes a los Estados Unidos para ejercer la prostitución o el vicio comercializado ilegalmente o has estado involucrado en la prostitución o en la adquisición de prostitutas en los últimos 10 años?"
                          required
                        />
                        <AppSelect
                          name="ps153"
                          error={errors.ps153 && touched.ps153 ? true : false}
                          fullWidth
                          value={values.ps153}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 13 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 13 ¿Alguna vez ha estado involucrado o busca involucrarse en lavado de dinero?"
                          required
                        />
                        <AppSelect
                          name="ps154"
                          error={errors.ps154 && touched.ps154 ? true : false}
                          fullWidth
                          value={values.ps154}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 14 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 14 ¿Alguna vez ha cometido o conspirado para cometer un delito de trata de personas en los Estados Unidos o fuera de los Estados Unidos?"
                          required
                        />
                        <AppSelect
                          name="ps155"
                          error={errors.ps155 && touched.ps155 ? true : false}
                          fullWidth
                          value={values.ps155}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 15 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 15 ¿Alguna vez ha ayudado, instigado, asistido o coludido a sabiendas con una persona que ha cometido o conspirado para cometer un delito grave de trata de personas en los Estados Unidos o fuera de los Estados Unidos?"
                          required
                        />
                        <AppSelect
                          name="ps156"
                          error={errors.ps156 && touched.ps156 ? true : false}
                          fullWidth
                          value={values.ps156}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 16 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 16 ¿Es usted el cónyuge, el hijo o la hija de una persona que se ha comprometido o conspiró para cometer un delito de trata de personas en los Estados Unidos o fuera de los Estados Unidos y lo ha beneficiado a sabiendas de las actividades de trata en los últimos cinco años?"
                          required
                        />
                        <AppSelect
                          name="ps157"
                          error={errors.ps157 && touched.ps157 ? true : false}
                          fullWidth
                          value={values.ps157}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 17 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 17 ¿Busca participar en espionaje, sabotaje, violaciones del control de exportaciones o cualquier otra actividad ilegal mientras está en los Estados Unidos?"
                          required
                        />
                        <AppSelect
                          name="ps158"
                          error={errors.ps158 && touched.ps158 ? true : false}
                          fullWidth
                          value={values.ps158}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 18 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 18 ¿Busca participar en actividades terroristas mientras está en los Estados Unidos o alguna vez ha participado en actividades terroristas?"
                          required
                        />
                        <AppSelect
                          name="ps159"
                          error={errors.ps159 && touched.ps159 ? true : false}
                          fullWidth
                          value={values.ps159}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 19 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 19 ¿Alguna vez o tiene la intención de proporcionar asistencia financiera u otro tipo de apoyo a terroristas u organizaciones terroristas?"
                          required
                        />
                        <AppSelect
                          name="ps160"
                          error={errors.ps160 && touched.ps160 ? true : false}
                          fullWidth
                          value={values.ps160}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 20 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 20 ¿Eres miembro o representante de una organización terrorista?"
                          required
                        />
                        <AppSelect
                          name="ps161"
                          error={errors.ps161 && touched.ps161 ? true : false}
                          fullWidth
                          value={values.ps161}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 21 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 21 ¿Es usted el cónyuge, el hijo o la hija de una persona que ha participado en actividades terroristas, incluida la prestación de asistencia financiera u otro tipo de apoyo a terroristas u organizaciones terroristas, en los últimos cinco años?"
                          required
                        />
                        <AppSelect
                          name="ps162"
                          error={errors.ps162 && touched.ps162 ? true : false}
                          fullWidth
                          value={values.ps162}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 22 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 22 ¿Alguna vez ha ordenado, incitado, comprometido, asistido o de otra manera participado en genocidio?"
                          required
                        />
                        <AppSelect
                          name="ps163"
                          error={errors.ps163 && touched.ps163 ? true : false}
                          fullWidth
                          value={values.ps163}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 23 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 23 ¿Alguna vez ha cometido, ordenado, incitado, asistido o participado en torturas?"
                          required
                        />
                        <AppSelect
                          name="ps164"
                          error={errors.ps164 && touched.ps164 ? true : false}
                          fullWidth
                          value={values.ps164}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 24 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 24 ¿Ha cometido, ordenado, incitado, asistido o participado en ejecuciones extrajudiciales, asesinatos políticos u otros actos de violencia?"
                          required
                        />
                        <AppSelect
                          name="ps165"
                          error={errors.ps165 && touched.ps165 ? true : false}
                          fullWidth
                          value={values.ps165}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 25 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 25 ¿Alguna vez ha participado en el reclutamiento o el uso de niños soldados?"
                          required
                        />
                        <AppSelect
                          name="ps166"
                          error={errors.ps166 && touched.ps166 ? true : false}
                          fullWidth
                          value={values.ps166}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 26 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 26 ¿Alguna vez, mientras se desempeñaba como un go, ha estado directamente involucrado en el trasplante coercitivo de órganos humanos o tejido corporal?"
                          required
                        />
                        <AppSelect
                          name="ps167"
                          error={errors.ps167 && touched.ps167 ? true : false}
                          fullWidth
                          value={values.ps167}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 27 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 27 Vernment oficial, ¿ha sido responsable o ha llevado a cabo directamente, en cualquier momento, violaciones particularmente graves de la libertad religiosa?"
                          required
                        />
                        <AppSelect
                          name="ps168"
                          error={errors.ps168 && touched.ps168 ? true : false}
                          fullWidth
                          value={values.ps168}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 28 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 28 ¿Alguna vez ha estado directamente involucrado en el establecimiento o la aplicación de controles de población obligando a una mujer a someterse a un aborto contra su libre elección o a un hombre o una mujer a someterse a una esterilización contra su libre albedrío?"
                          required
                        />
                        <AppSelect
                          name="ps169"
                          error={errors.ps169 && touched.ps169 ? true : false}
                          fullWidth
                          value={values.ps169}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 29 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 29 ¿Alguna vez ha tratado de obtener o ayudar a otros a obtener una visa, ingresar a los Estados Unidos o cualquier otro beneficio de inmigración de los Estados Unidos por fraude o tergiversación intencional u otros medios ilegales?"
                          required
                        />
                        <AppSelect
                          name="ps170"
                          error={errors.ps170 && touched.ps170 ? true : false}
                          fullWidth
                          value={values.ps170}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 30 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 30 ¿Alguna vez ha sido expulsado o deportado de algún país?"
                          required
                        />
                        <AppSelect
                          name="ps171"
                          error={errors.ps171 && touched.ps171 ? true : false}
                          fullWidth
                          value={values.ps171}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* PS 31 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 31 ¿Alguna vez ha retenido la custodia de un niño ciudadano de EE. UU. Fuera de los Estados Unidos de una persona a quien un tribunal de EE. UU. Le otorgó la custodia legal?"
                          required
                        />
                        <AppSelect
                          name="ps172"
                          error={errors.ps172 && touched.ps172 ? true : false}
                          fullWidth
                          value={values.ps172}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 32 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 32 ¿Ha votado en los Estados Unidos en violación de alguna ley o regulación?"
                          required
                        />
                        <AppSelect
                          name="ps173"
                          error={errors.ps173 && touched.ps173 ? true : false}
                          fullWidth
                          value={values.ps173}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* PS 33 */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="NO. 33 ¿Alguna vez ha renunciado a la ciudadanía de los Estados Unidos para evitar los impuestos?"
                          required
                        />
                        <AppSelect
                          name="ps174"
                          error={errors.ps174 && touched.ps174 ? true : false}
                          fullWidth
                          value={values.ps174}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                      {appState.dS160FileUrl != null &&
                      appState.dS160FileUrl.length > 0 ? null : (
                        <Grid item xs={12}>
                          <Button
                            onClick={() => {
                              if (!isValid) {
                                setIsValidForm(true);
                              }
                            }}
                            disabled={isSubmitting}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            sx={{ bgcolor: "secondary.main", mt: "10px" }}
                          >
                            {isSubmitting ? <>Guardando...</> : "Guardar"}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        )
        // )}
      }
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}

export default DS160Screen;
