import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { ChangeEvent, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import { cedulaFormatter } from "../../Utils";
import AppSelect from "../../components/AppSelect";
import Province from "../../models/Province";
import City from "../../models/City";
import utilsService from "../../services/UtilsService";
import { CandidateSponsorAdd } from "../../models/CandidateSponsorAdd";
import LoadingModal from "../../components/LoadingModal";
import candidateService from "../../services/CandidateService";

interface ISponsorModal {
  guarantorID: string;
  guarantorName: string;
  guarantorLastName: string;
  guarantorOccupation: string;
  civilStatus: string;
  genero: string;
  guarantorAddress: string;
  sector: string;
  provinceId: string;
  cityId: string;
}
interface SponsorModalProp {
  provinces: Province[];
  onClose: () => void;
  onSave: () => void;
}

const validationSchema = Yup.object().shape({
  guarantorID: Yup.string().required(),
  guarantorName: Yup.string().required(),
  guarantorLastName: Yup.string().required(),
  guarantorOccupation: Yup.string().required(),
  civilStatus: Yup.string().required(),
  genero: Yup.string().required(),
  guarantorAddress: Yup.string().required(),
  sector: Yup.string().required(),
  provinceId: Yup.string().required(),
  cityId: Yup.string().required(),
});
function GuarantorModal({ provinces, onClose, onSave }: SponsorModalProp) {
  const [initialValues, setInitialValues] = useState<ISponsorModal>({
    guarantorID: "",
    guarantorName: "",
    guarantorLastName: "",
    guarantorOccupation: "",
    civilStatus: "",
    genero: "",
    guarantorAddress: "",
    sector: "",
    provinceId: "",
    cityId: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [cities, setCities] = useState<City[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function submit(values: ISponsorModal) {
    if (file == null) {
      alert("Por favor agregar un archivo");
      return;
    }
    const candidateSponsorAdd: CandidateSponsorAdd = {
      ...values,
      provinceId: Number(values.provinceId),
      cityId: Number(values.cityId),
    };

    try {
      setIsSaving(true);
      await candidateService.saveCandidateGuarantor(candidateSponsorAdd, file);
      setIsSaving(false);
      onSave();
    } catch (error: any) {
      if (error.response.status == 403) {
        setIsSaving(false);
        setOpenDialog(true);
        setDialogTitle("Error");
        setDialogDesc(error.response.data);
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
        return;
      }
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {(props: FormikProps<ISponsorModal>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            isValid,
          } = props;

          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Cedula" required />
                  <AppTextField
                    error={
                      errors.guarantorID && touched.guarantorID ? true : false
                    }
                    inputProps={{ maxLength: 12 }}
                    name="guarantorID"
                    value={values.guarantorID}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const cedulaRegex = /^(\d{0,3})-?(\d{0,7})-?(\d{0,1})$/;
                      const { value } = event.target;
                      if (cedulaRegex.test(value)) {
                        const formattedPhone = cedulaFormatter(value);
                        setFieldValue("guarantorID", formattedPhone);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Nombres" required />
                  <AppTextField
                    error={
                      errors.guarantorName && touched.guarantorName
                        ? true
                        : false
                    }
                    name="guarantorName"
                    value={values.guarantorName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Apellidos" required />
                  <AppTextField
                    error={
                      errors.guarantorLastName && touched.guarantorLastName
                        ? true
                        : false
                    }
                    name="guarantorLastName"
                    value={values.guarantorLastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Ocupación" required />
                  <AppTextField
                    error={
                      errors.guarantorOccupation && touched.guarantorOccupation
                        ? true
                        : false
                    }
                    name="guarantorOccupation"
                    value={values.guarantorOccupation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Estado Civil" required />
                  <AppSelect
                    name="civilStatus"
                    error={
                      errors.civilStatus && touched.civilStatus ? true : false
                    }
                    fullWidth
                    value={values.civilStatus}
                    onChange={handleChange}
                  >
                    <MenuItem value="Soltero(a)">Soltero(a)</MenuItem>

                    <MenuItem value="Casado(a)">Casado(a)</MenuItem>
                  </AppSelect>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Genero" required />
                  <AppSelect
                    name="genero"
                    error={errors.genero && touched.genero ? true : false}
                    fullWidth
                    value={values.genero}
                    onChange={handleChange}
                  >
                    <MenuItem value="F">Femenino</MenuItem>
                    <MenuItem value="M">Masculino</MenuItem>
                  </AppSelect>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Dirección" required />
                  <AppTextField
                    error={
                      errors.guarantorAddress && touched.guarantorAddress
                        ? true
                        : false
                    }
                    name="guarantorAddress"
                    value={values.guarantorAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Sector" required />
                  <AppTextField
                    error={errors.sector && touched.sector ? true : false}
                    name="sector"
                    value={values.sector}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Provincia" required />
                  <AppSelect
                    name="provinceId"
                    error={
                      errors.provinceId && touched.provinceId ? true : false
                    }
                    fullWidth
                    value={values.provinceId}
                    onChange={async (event, child) => {
                      setFieldValue("provinceId", event.target.value);
                      var newCities = await utilsService.getCities(
                        event.target.value
                      );
                      setFieldValue("cityId", "");
                      setCities(newCities);
                    }}
                  >
                    {provinces.map((el) => (
                      <MenuItem key={el.provinceId} value={el.provinceId}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Paragraph title="Municipio" required />
                  <AppSelect
                    name="cityId"
                    error={errors.cityId && touched.cityId ? true : false}
                    fullWidth
                    value={values.cityId}
                    onChange={handleChange}
                  >
                    {cities.map((el) => (
                      <MenuItem key={el.cityId} value={el.cityId}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Grid>
                <Grid item xs={12}>
                  <Paragraph title="Archivo" required />
                  <AppTextField
                    inputProps={{ accept: "image/*, .doc, .docx, .pdf" }}
                    name="file"
                    type="file"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files != null) {
                        const f = event.target.files[0];
                        setFile(f);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={onClose}
                    color="customGrey"
                    variant="contained"
                    sx={{ mr: "10px", color: "white" }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    onClick={() => {
                      if (!isValid) {
                        setIsValidForm(true);
                      }
                    }}
                    disabled={isSubmitting}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ bgcolor: "secondary.main" }}
                  >
                    {isSubmitting ? <>Guardando...</> : "Guardar"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}

export default GuarantorModal;
