import { Navigate, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import authService from "../services/AuthService";
import { ApplicationState } from "../models/ApplicationState";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/store";
import { login, logout } from "../store/appStateReducer";
import LoadingScreen from "../screens/LoadingScreen";
import { setBodyColor } from "../Utils";

function ProtectedRoute() {
  var isAuthenticated = useAppSelector((state) => state.appState.isSignedIn);
  var appState = useAppSelector((state) => state.appState);
  var dispatch = useAppDispatch();
  var navigate = useNavigate();
  var [isLoading, setIsLoading] = useState(true);
  var [isError, setIsError] = useState(false);

  async function init() {
    try {
      if (!isAuthenticated && authService.hasToken()) {
        var state: ApplicationState | null = await authService.loadUserInfo();
        // setIsLoading(false);
        setBodyColor("#FFFFFF");
        await dispatch(login({ applicationState: state! }));
        setIsLoading(false);
        document.body.classList.remove("body-custom-bg");

        // if (state != null) {
        //   state.isSignedIn = true;
        //   await dispatch(login({ applicationState: state }));
        //   setIsLoading(false);
        // }
      } else {
        if (appState.mustChangePassword) {
          navigate("/");
          setBodyColor("#FFFFFF");
          document.body.classList.remove("body-custom-bg");
        }
        if (isAuthenticated && !authService.hasToken()) {
          dispatch(logout());
          setBodyColor("#00166B");
          document.body.classList.add("body-custom-bg");

          //window.location.href = "/login";
          // window.location.href = "/login";
        }
      }
    } catch (error: any) {
      setIsError(true);
      window.location.href = "/error";
      setBodyColor("#00166B");
      document.body.classList.add("body-custom-bg");
      //dispatch(logout());
    }

    //  else {
    //   console.log("User does not have token");
    //   dispatch(logout());
    //   // if (isAuthenticated) {
    //   //   setIsLoading(false);
    //   // } else {

    //   // }
    // }
  }

  useEffect(() => {
    init();
  }, [isAuthenticated, authService.hasToken()]);

  return (
    <>
      {isError ? (
        <Navigate to="/error" replace />
      ) : !authService.hasToken() ? (
        <Navigate to="/login" replace />
      ) : !isAuthenticated ? (
        isLoading ? (
          <LoadingScreen />
        ) : (
          <Outlet />
        )
      ) : (
        <Outlet />
      )}
    </>
    // <>
    //   {!authService.hasToken() ? (
    //     <Navigate to="/login" replace />
    //   ) : isLoading ? (
    //     <LoadingScreen />
    //   ) : isAuthenticated ? (
    //     <Outlet />
    //   ) : (
    //     <Navigate to="/login" replace />
    //   )}
    // </>
  );

  // return isAuthenticated ? (
  //   children
  // ) : isLoading ? (
  //   <LoadingScreen />
  // ) : (
  //   <Navigate to="/login" replace />
  // );
}
export default ProtectedRoute;
