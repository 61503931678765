import ConfirmationDialog from "../components/ConfirmationDialog";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import AppTextField from "../components/AppTextField";
import LoadingModal from "../components/LoadingModal";
import authService from "../services/AuthService";
import ResetPassword from "../models/ResetPassword";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../store/store";
import { Alert, Box, Button, Typography } from "@mui/material";
import { ApplicationState } from "../models/ApplicationState";
import { login } from "../store/appStateReducer";
import ErrorText from "../components/ErrorText";

interface IResetPassword {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const validationScheme = Yup.object().shape({
  oldPassword: Yup.string().required("Requerido"),
  password: Yup.string()
    .min(6, (obj) => {
      const length = obj.value.length;
      return `Constraseña debe ser mas de 6 caracteres`;
    })
    .required("Requerido"),
  confirmPassword: Yup.string().min(6).required("Requerido"),
});

function ResetPasswordScreen() {
  const [editInterview, setEditInterview] = useState<boolean>(false);
  useEffect(() => {}, []);
  var dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IResetPassword>({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  async function submit(values: IResetPassword) {
    if (values.confirmPassword != values.password) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("La nueva contraseña deben de ser iguales");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }
    try {
      setIsSaving(true);
      const reset: ResetPassword = {
        oldPassword: values.oldPassword,
        password: values.password,
      };
      await authService.resetPassword(reset);
      setIsSaving(false);
      var applicationState: ApplicationState | null =
        await authService.loadUserInfo();

      if (applicationState != null) {
        await dispatch(login({ applicationState: applicationState }));
      }
    } catch (error: any) {
      if (error.response.status == 403) {
        setIsSaving(false);
        setOpenDialog(true);
        setDialogTitle("Error");
        setDialogDesc("La vieja contraseña no coincide");
        setDialogButton(
          <Button onClick={() => setOpenDialog(false)}>Ok</Button>
        );
        return;
      }

      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationScheme}
        validateOnMount={true}
      >
        {(props: FormikProps<IResetPassword>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            isValid,
          } = props;

          return (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "450px",
                width: "100%",
              }}
            >
              <Alert sx={{ width: "100%", mb: 3 }} severity="info">
                Debe reiniciar contraseña para ver contenido
              </Alert>
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                Contraseña anterior
              </Typography>
              <AppTextField
                type="password"
                error={errors.oldPassword && touched.oldPassword ? true : false}
                name="oldPassword"
                value={values.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.oldPassword && touched.oldPassword ? (
                <ErrorText text={errors.oldPassword} />
              ) : null}

              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                Contraseña
              </Typography>
              <AppTextField
                type="password"
                error={errors.password && touched.password ? true : false}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password ? (
                <ErrorText text={errors.password} />
              ) : null}

              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                Confirmar Contraseña
              </Typography>
              <AppTextField
                type="password"
                error={
                  errors.confirmPassword && touched.confirmPassword
                    ? true
                    : false
                }
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.confirmPassword && touched.confirmPassword ? (
                <ErrorText text={errors.confirmPassword} />
              ) : null}

              <Button
                onClick={() => {
                  if (!isValid) {
                    setIsValidForm(true);
                  }
                }}
                fullWidth
                disabled={isSubmitting}
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ bgcolor: "secondary.main", mt: 2 }}
              >
                {isSubmitting ? <>Guardando...</> : "Guardar"}
              </Button>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}

export default ResetPasswordScreen;
