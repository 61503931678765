import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import AppTextField from "../../components/AppTextField";
import Paragraph from "../../components/Paragraph";
import AppSelect from "../../components/AppSelect";
import * as Yup from "yup";
import candidateService from "../../services/CandidateService";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import utilsService from "../../services/UtilsService";
import Subsidiary from "../../models/Subsidiary";
import { Country } from "../../models/Country";
import Province from "../../models/Province";
import City from "../../models/City";
import TShirtSize from "../../models/TShirtSize";
import CivilStatus from "../../models/CivilStatus";
import ProgramType from "../../models/ProgramType";
import {
  GetDateYYYYMMDD,
  cedulaFormatter,
  handleInputChangeToUppercase,
  phoneFormatter,
} from "../../Utils";
import FamilyRelationship from "../../models/FamilyRelationship";
import GeneralInformation from "../../models/GeneralInformation";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import { CandidateApplicationContext } from "../../screens/CandidateApplicationScreen";
import { GenericItem } from "../../models/GenericItem";
import IOption from "../../interfaces/IOption";
import { useAppSelector } from "../../store/store";
import { ApplicationState } from "../../models/ApplicationState";

interface ICandidateBasicInfoForm {
  firstName: string;
  secondName?: string;
  firstLastName: string;
  secondLastName?: string;
  programTypeId: string;
  birthday: string;
  documentId?: string;
  documentTypeId?: string;
  genre: string;
  countryId: string;
  subsidiaryId: string;
  residencialPhone: string;
  cellphone: string;
  altCellphoneWhatsapp: string;
  address: string;
  neighborhood?: string;
  homeNo: string;
  provinceId: string;
  cityId: string;
  birthPlace: string;
  tShirtSizeId: string;
  civilStatusId: string;
  coupleFullName: string;
  coupleBirthDay: string;
  coupleNationalityId: string;
  coupleLiveTogetherFlag: string;
  coupleDirection: string;
  bloodType: string;
  facebookProfileUrl?: string;
  instagramProfileUrl?: string;
  emergencyContactFullName: string;
  emergencyContactRelationShipId: string;
  emergencyPhoneContact: string;
  haveOtherNationality: string;
  otherNationalityCountryId: string;
  tiktokProfileUrl: string;
  followUsSocialMediaFlag: string;
  followUsSocialMediaExplain: string;
  emergencyEmailAddress: string;
  recidencial: string;
}

const candidateBasicInfoFormValidationScheme = Yup.object().shape({
  firstName: Yup.string().required("Requerido"),
  firstLastName: Yup.string().required("Requerido"),
  programTypeId: Yup.string().required("Requerido"),
  birthday: Yup.string().required("Requerido"),
  subsidiaryId: Yup.string().required("Requerido"),
  genre: Yup.string().required("Requerido"),
  countryId: Yup.string().required("Requerido"),
  haveOtherNationality: Yup.string().required("Requerido"),
  otherNationalityCountryId: Yup.string().when(
    "haveOtherNationality",
    (haveOtherNationality: string | string[], schema) => {
      if (haveOtherNationality == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  cellphone: Yup.string().required("Requerido"),
  altCellphoneWhatsapp: Yup.string().required("Requerido"),
  address: Yup.string().required("Requerido"),
  homeNo: Yup.string().required("Requerido"),
  provinceId: Yup.string().required("Requerido"),
  cityId: Yup.string().required("Requerido"),
  birthPlace: Yup.string().required("Requerido"),
  tShirtSizeId: Yup.string().required("Requerido"),
  civilStatusId: Yup.string().required("Requerido"),
  bloodType: Yup.string().required("Requerido"),
  followUsSocialMediaFlag: Yup.string().required("Requerido"),
  emergencyContactFullName: Yup.string().required("Requerido"),
  emergencyContactRelationShipId: Yup.string().required("Requerido"),
  emergencyPhoneContact: Yup.string().required("Requerido"),
  coupleFullName: Yup.string().when(
    "civilStatusId",
    (civilStatusId: string | string[], schema) => {
      if (civilStatusId == "2" || civilStatusId == "5") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  coupleBirthDay: Yup.string().when(
    "civilStatusId",
    (civilStatusId: string | string[], schema) => {
      if (civilStatusId == "2" || civilStatusId == "5") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  coupleNationalityId: Yup.string().when(
    "civilStatusId",
    (civilStatusId: string | string[], schema) => {
      if (civilStatusId == "2" || civilStatusId == "5") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  coupleLiveTogetherFlag: Yup.string().when(
    "civilStatusId",
    (civilStatusId: string | string[], schema) => {
      if (civilStatusId == "2" || civilStatusId == "5") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),

  coupleDirection: Yup.string().when(
    ["coupleLiveTogetherFlag", "civilStatusId"],
    (coupleLiveTogetherFlag: string | string[], schema) => {
      if (
        coupleLiveTogetherFlag[0] == "NO" &&
        (coupleLiveTogetherFlag[1] == "2" || coupleLiveTogetherFlag[1] == "5")
      ) {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

function CandidateBasicInfoForm() {
  const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>();
  const [initialValues, setInitialValues] = useState<ICandidateBasicInfoForm>({
    firstName: "",
    secondName: "",
    firstLastName: "",
    secondLastName: "",
    programTypeId: "",
    birthday: "",
    documentId: "",
    documentTypeId: "",
    subsidiaryId: "",
    genre: "",
    countryId: "",
    residencialPhone: "",
    cellphone: "",
    altCellphoneWhatsapp: "",
    address: "",
    neighborhood: "",
    homeNo: "",
    provinceId: "",
    cityId: "",
    birthPlace: "",
    tShirtSizeId: "",
    civilStatusId: "",
    coupleFullName: "",
    coupleBirthDay: "",
    coupleNationalityId: "",
    coupleLiveTogetherFlag: "",
    coupleDirection: "",
    bloodType: "",
    facebookProfileUrl: "",
    instagramProfileUrl: "",
    tiktokProfileUrl: "",
    emergencyContactFullName: "",
    emergencyContactRelationShipId: "",
    emergencyPhoneContact: "",
    haveOtherNationality: "",
    otherNationalityCountryId: "",
    followUsSocialMediaFlag: "",
    followUsSocialMediaExplain: "",
    emergencyEmailAddress: "",
    recidencial: "",
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [tshirtSizes, setTShirtSizes] = useState<TShirtSize[]>([]);
  const [civilStatus, setCivilStatus] = useState<CivilStatus[]>([]);
  const [programTypes, setProgramTypes] = useState<ProgramType[]>([]);
  const [bloodTypes, setBloodTypes] = useState<GenericItem[]>([]);
  const [familyRelationships, setFamilyRelationships] = useState<
    FamilyRelationship[]
  >([]);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();

  const { step, setStep } = useContext(CandidateApplicationContext);
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  async function submit(values: ICandidateBasicInfoForm) {
    if (
      (appState.applicationStateId != 23 && !appState.isAdmin) ||
      window.location.href.includes("profile")
    ) {
      setStep(step + 1);
      return;
    }
    const generalInformation: GeneralInformation = {
      ...values,
      coupleFullName:
        values.coupleFullName != "" ? values.coupleFullName : null,
      coupleBirthDay:
        values.coupleBirthDay != "" ? values.coupleBirthDay : null,
      coupleNationalityId:
        values.coupleNationalityId != "" ? values.coupleNationalityId : null,
      // New Fields
      haveOtherNationality: values.haveOtherNationality == "SI",
      followUsSocialMediaFlag: values.followUsSocialMediaFlag == "SI",
      otherNationalityCountryId:
        values.otherNationalityCountryId != ""
          ? values.otherNationalityCountryId
          : null,
    };

    setIsSaving(true);
    try {
      await candidateService.saveGeneralInformation(generalInformation);
      setIsSaving(false);
      setStep(step + 1);
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    var subs = await utilsService.getSubsidiaries();
    var countries: Country[] = await utilsService.getCountries();
    var province: Province[] = await utilsService.getProvinces();
    var city: City[] = [];
    var tShirts = await utilsService.getTShirtSize();
    var civilStatu = await utilsService.getCivilStatus();
    var programType = await utilsService.getProgramTypes();
    var generic = await utilsService.getGenericItems("TIPO_SANGRE");
    var families = await utilsService.getFamilyRelationships();
    var generalInformation = await candidateService.getGeneralInformation();

    if (generalInformation.cityId != null && generalInformation.cityId != "") {
      city = await utilsService.getCities(generalInformation.provinceId);
      setCities(city);
    }

    setCountries(countries);
    setSubsidiaries(subs);

    setInitialValues({
      firstName: generalInformation.firstName,
      secondName: generalInformation.secondName,
      firstLastName: generalInformation.firstLastName,
      secondLastName: generalInformation.secondLastName,
      programTypeId: generalInformation.programTypeId ?? "",
      countryId: generalInformation.countryId ?? "",
      birthday: GetDateYYYYMMDD(new Date(generalInformation.birthday)),
      documentId: generalInformation.documentId,
      documentTypeId:
        Number(generalInformation.documentTypeId) != 0
          ? generalInformation.subsidiaryId
          : "",
      subsidiaryId:
        Number(generalInformation.subsidiaryId) != 0
          ? generalInformation.subsidiaryId
          : "",
      genre: generalInformation.genre,
      residencialPhone: generalInformation.residencialPhone ?? "",
      cellphone: generalInformation.cellphone,
      altCellphoneWhatsapp: generalInformation.altCellphoneWhatsapp ?? "",
      address: generalInformation.address,
      neighborhood: generalInformation.neighborhood ?? "",
      homeNo: generalInformation.homeNo ?? "",
      emergencyContactFullName: generalInformation.emergencyContactFullName,
      emergencyContactRelationShipId:
        generalInformation.emergencyContactRelationShipId != null
          ? generalInformation.emergencyContactRelationShipId
          : "",
      emergencyPhoneContact: generalInformation.emergencyPhoneContact,
      provinceId:
        Number(generalInformation.provinceId) != 0
          ? generalInformation.provinceId
          : "",
      cityId:
        Number(generalInformation.cityId) != 0 ? generalInformation.cityId : "",
      birthPlace: generalInformation.birthPlace,
      tShirtSizeId:
        Number(generalInformation.tShirtSizeId) != 0
          ? generalInformation.tShirtSizeId
          : "",
      civilStatusId:
        Number(generalInformation.civilStatusId) != 0
          ? generalInformation.civilStatusId
          : "",
      coupleFullName: generalInformation.coupleFullName ?? "",
      coupleBirthDay:
        generalInformation.coupleBirthDay != null
          ? GetDateYYYYMMDD(new Date(generalInformation.coupleBirthDay))
          : "",
      coupleNationalityId: generalInformation.coupleNationalityId ?? "",
      coupleLiveTogetherFlag: generalInformation.coupleLiveTogetherFlag ?? "NO",
      coupleDirection: generalInformation.coupleDirection ?? "",
      bloodType:
        generalInformation.bloodType != null
          ? generalInformation.bloodType
          : "",
      facebookProfileUrl:
        generalInformation.facebookProfileUrl != null
          ? generalInformation.facebookProfileUrl
          : "",
      instagramProfileUrl:
        generalInformation.instagramProfileUrl != null
          ? generalInformation.instagramProfileUrl
          : "",

      haveOtherNationality:
        generalInformation.haveOtherNationality != null &&
        generalInformation.haveOtherNationality == true
          ? "SI"
          : "NO",
      otherNationalityCountryId:
        generalInformation.otherNationalityCountryId ?? "",
      tiktokProfileUrl: generalInformation.tiktokProfileUrl ?? "",
      followUsSocialMediaFlag:
        generalInformation.followUsSocialMediaFlag != null &&
        generalInformation.followUsSocialMediaFlag == true
          ? "SI"
          : "NO",
      followUsSocialMediaExplain:
        generalInformation.followUsSocialMediaExplain ?? "",
      emergencyEmailAddress: generalInformation.emergencyEmailAddress ?? "",
      recidencial: generalInformation.recidencial ?? "",
    });

    setProvinces(province);
    setCities(city);

    setProgramTypes(programType);
    setCivilStatus(civilStatu);
    setTShirtSizes(tShirts);
    setBloodTypes(generic);
    setFamilyRelationships(families);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          {/* <Typography sx={{ mb: 3 }} variant="h4">
            INFORMACIÒN GENERAL
          </Typography> */}
          <Box textAlign="center">
            <Typography
              display="inline-block"
              sx={{
                mb: 3,
                backgroundColor: "#00166B",
                color: "white",
                padding: "10px 30px",
                borderRadius: "10px",
                textTransform: "uppercase",
              }}
              variant="h4"
            >
              Información general
            </Typography>
          </Box>

          <Formik
            validateOnMount={true}
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={
              !window.location.href.includes("profile")
                ? candidateBasicInfoFormValidationScheme
                : undefined
            }
          >
            {(props: FormikProps<ICandidateBasicInfoForm>) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                setFieldValue,
                isValid,
              } = props;

              return (
                <Form>
                  <Grid container spacing={1}>
                    <Box
                      bgcolor="primary"
                      sx={{
                        width: "100%",
                        bgcolor: "primary.main",
                      }}
                    >
                      <Typography sx={{ color: "white", px: 2, py: 1 }}>
                        Datos generales
                      </Typography>
                    </Box>
                    {/* First  Name */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Primer nombre" required />
                      <AppTextField
                        error={
                          errors.firstName && touched.firstName ? true : false
                        }
                        name="firstName"
                        value={values.firstName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* Second Name */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Segundo nombre" />
                      <AppTextField
                        name="secondName"
                        value={values.secondName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* First Last Name */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Primer apellido" required />
                      <AppTextField
                        error={
                          errors.firstLastName && touched.firstLastName
                            ? true
                            : false
                        }
                        name="firstLastName"
                        value={values.firstLastName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* Second Last Name */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Segundo apellido" />
                      <AppTextField
                        name="secondLastName"
                        value={values.secondLastName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Cedula */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Cédula" />
                      <AppTextField
                        error={
                          errors.documentId && touched.documentId ? true : false
                        }
                        disabled={appState.isAdmin ? false : true}
                        name="documentId"
                        value={values.documentId}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const cedulaRegex =
                            /^(\d{0,3})-?(\d{0,7})-?(\d{0,1})$/;
                          const { value } = event.target;
                          if (cedulaRegex.test(value)) {
                            const formattedPhone = cedulaFormatter(value);
                            setFieldValue("documentId", formattedPhone);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* Fecha de nacimiento */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Fecha de nacimiento" required />
                      <AppTextField
                        type="date"
                        error={
                          errors.birthday && touched.birthday ? true : false
                        }
                        name="birthday"
                        value={values.birthday}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* COUNTRY */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="País Nacimiento" required />
                      <AppSelect
                        name="countryId"
                        error={
                          errors.countryId && touched.countryId ? true : false
                        }
                        fullWidth
                        value={values.countryId}
                        onChange={handleChange}
                        isSearchable={true}
                        options={countries.map((el) => {
                          var option: IOption = {
                            label: el.name,
                            value: el.countryId.toString(),
                          };
                          return option;
                        })}
                      >
                        {/* {countries.map((country) => (
                          <MenuItem
                            key={country.countryId}
                            value={country.countryId}
                          >
                            {country.name}
                          </MenuItem>
                        ))} */}
                      </AppSelect>
                    </Grid>
                    {/* Ciudad de nacimiento */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Ciudad de nacimiento" required />
                      <AppTextField
                        error={
                          errors.birthPlace && touched.birthPlace ? true : false
                        }
                        name="birthPlace"
                        value={values.birthPlace}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* Program Type */}
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Programa a Aplicar" required />
                      <AppSelect
                        name="programTypeId"
                        error={
                          errors.programTypeId && touched.programTypeId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.programTypeId}
                        onChange={handleChange}
                      >
                        {programTypes.map((el) => (
                          <MenuItem
                            key={el.programTypeID}
                            value={el.programTypeID}
                          >
                            {el.programTypeName}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                    {/* Other Naionality */}
                    <Grid item lg={4} xs={12}>
                      <Paragraph
                        title="¿Posees alguna otra nacionalidad?"
                        required
                      />
                      <AppSelect
                        name="haveOtherNationality"
                        error={
                          errors.haveOtherNationality &&
                          touched.haveOtherNationality
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.haveOtherNationality}
                        onChange={handleChange}
                      >
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="SI">SI</MenuItem>
                      </AppSelect>
                    </Grid>
                    {/* Other Naionality Country */}
                    {values.haveOtherNationality == "SI" ? (
                      <Grid item lg={4} xs={12}>
                        <Paragraph
                          title="País de la otra nacionalidad"
                          required
                        />
                        <AppSelect
                          name="otherNationalityCountryId"
                          error={
                            errors.otherNationalityCountryId &&
                            touched.otherNationalityCountryId
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.otherNationalityCountryId}
                          onChange={handleChange}
                          isSearchable={true}
                          options={countries.map((el) => {
                            var option: IOption = {
                              label: el.name,
                              value: el.countryId.toString(),
                            };
                            return option;
                          })}
                        >
                          {/* {countries.map((el) => (
                            <MenuItem key={el.countryId} value={el.countryId}>
                              {el.name}
                            </MenuItem>
                          ))} */}
                        </AppSelect>
                      </Grid>
                    ) : null}

                    {/* Genre */}
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Género" required />
                      <AppSelect
                        name="genre"
                        error={errors.genre && touched.genre ? true : false}
                        fullWidth
                        value={values.genre}
                        onChange={handleChange}
                      >
                        <MenuItem value="M">Masculino</MenuItem>
                        <MenuItem value="F">Femenino</MenuItem>
                      </AppSelect>
                    </Grid>
                    {/* Subsidiary */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Sucursal" required />
                      <AppSelect
                        name="subsidiaryId"
                        error={
                          errors.subsidiaryId && touched.subsidiaryId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.subsidiaryId}
                        onChange={handleChange}
                      >
                        {subsidiaries?.map((sub) => (
                          <MenuItem
                            key={sub.subsidiaryId}
                            value={sub.subsidiaryId}
                          >
                            {sub.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>

                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Correo electrónico" />
                      <AppTextField disabled value={appState.email} />
                    </Grid>

                    {/* Teléfono residencial */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Teléfono residencial" />
                      <AppTextField
                        name="residencialPhone"
                        value={values.residencialPhone}
                        onBlur={handleBlur}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue("residencialPhone", formattedPhone);
                          }
                        }}
                      />
                    </Grid>
                    {/* Número celular */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Número celular" required />
                      <AppTextField
                        error={
                          errors.cellphone && touched.cellphone ? true : false
                        }
                        name="cellphone"
                        value={values.cellphone}
                        onBlur={handleBlur}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue("cellphone", formattedPhone);
                          }
                        }}
                      />
                    </Grid>
                    {/* Número WhatsApp */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Número de WhatsApp" required />
                      <AppTextField
                        name="altCellphoneWhatsapp"
                        value={values.altCellphoneWhatsapp}
                        error={
                          errors.altCellphoneWhatsapp &&
                          touched.altCellphoneWhatsapp
                            ? true
                            : false
                        }
                        onBlur={handleBlur}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue(
                              "altCellphoneWhatsapp",
                              formattedPhone
                            );
                          }
                        }}
                      />
                    </Grid>

                    {/*BloodType */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Tipo de sangre" required />
                      <AppSelect
                        name="bloodType"
                        error={
                          errors.bloodType && touched.bloodType ? true : false
                        }
                        fullWidth
                        value={values.bloodType}
                        onChange={handleChange}
                      >
                        {bloodTypes?.map((sub) => (
                          <MenuItem
                            key={sub.genericCode}
                            value={sub.genericCode}
                          >
                            {sub.genericDescription}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ width: "100%", bgcolor: "primary.main" }}>
                        <Typography sx={{ color: "white", px: 2, py: 1 }}>
                          Información sobre la dirección actual de residencia
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Nombre de Calle */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Nombre de Calle" required />
                      <AppTextField
                        error={errors.address && touched.address ? true : false}
                        name="address"
                        value={values.address}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* Barrio / Sector */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Barrio / Sector" />
                      <AppTextField
                        name="neighborhood"
                        value={values.neighborhood}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* No.Casa*/}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="No.Casa / Apartamento" required />
                      <AppTextField
                        error={errors.homeNo && touched.homeNo ? true : false}
                        name="homeNo"
                        value={values.homeNo}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/*Residencial*/}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Residencial" />
                      <AppTextField
                        name="recidencial"
                        value={values.recidencial}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Provincia*/}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Provincia" required />
                      <AppSelect
                        name="provinceId"
                        error={
                          errors.provinceId && touched.provinceId ? true : false
                        }
                        fullWidth
                        value={values.provinceId}
                        onChange={async (event, child) => {
                          setFieldValue("provinceId", event.target.value);
                          var newCities = await utilsService.getCities(
                            event.target.value
                          );
                          setFieldValue("cityId", "");
                          setCities(newCities);
                        }}
                      >
                        {provinces.map((el) => (
                          <MenuItem key={el.provinceId} value={el.provinceId}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                    {/* Municipio*/}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Municipio" required />
                      <AppSelect
                        name="cityId"
                        error={errors.cityId && touched.cityId ? true : false}
                        fullWidth
                        value={values.cityId}
                        onChange={handleChange}
                      >
                        {cities.map((el) => (
                          <MenuItem key={el.cityId + el.name} value={el.cityId}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>

                    {/* Civil Status */}

                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Estado Civil" required />
                      <AppSelect
                        name="civilStatusId"
                        error={
                          errors.civilStatusId && touched.civilStatusId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.civilStatusId}
                        onChange={handleChange}
                      >
                        {civilStatus.map((el) => (
                          <MenuItem
                            key={el.civilStatusId}
                            value={el.civilStatusId}
                          >
                            {el.status}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>

                    {/* Pareja nombre */}
                    {values.civilStatusId == "2" ||
                    values.civilStatusId == "5" ? (
                      <Grid item lg={3} xs={12}>
                        <Paragraph
                          title="Nombre completo de la pareja"
                          required
                        />
                        <AppTextField
                          error={
                            errors.coupleFullName && touched.coupleFullName
                              ? true
                              : false
                          }
                          name="coupleFullName"
                          value={values.coupleFullName}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                    ) : null}

                    {/* Pareja fecha de nacimiento */}
                    {values.civilStatusId == "2" ||
                    values.civilStatusId == "5" ? (
                      <Grid item lg={3} xs={12}>
                        <Paragraph
                          title="Fecha de nacimiento de la pareja"
                          required
                        />
                        <AppTextField
                          type="date"
                          error={
                            errors.coupleBirthDay && touched.coupleBirthDay
                              ? true
                              : false
                          }
                          name="coupleBirthDay"
                          value={values.coupleBirthDay}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    ) : null}

                    {/* Pareja nacionalidad */}
                    {values.civilStatusId == "2" ||
                    values.civilStatusId == "5" ? (
                      <Grid item lg={3} xs={12}>
                        <Paragraph title="Nacionalidad de la pareja" required />
                        <AppSelect
                          name="coupleNationalityId"
                          error={
                            errors.coupleNationalityId &&
                            touched.coupleNationalityId
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.coupleNationalityId}
                          onChange={handleChange}
                        >
                          {countries.map((country) => (
                            <MenuItem
                              key={country.countryId}
                              value={country.countryId}
                            >
                              {country.name}
                            </MenuItem>
                          ))}
                        </AppSelect>
                      </Grid>
                    ) : null}

                    {/* Viven juntos */}
                    {values.civilStatusId == "2" ||
                    values.civilStatusId == "5" ? (
                      <Grid item lg={3} xs={12}>
                        <Paragraph title="¿Viven juntos?" required />
                        <AppSelect
                          name="coupleLiveTogetherFlag"
                          error={
                            errors.coupleLiveTogetherFlag &&
                            touched.coupleLiveTogetherFlag
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.coupleLiveTogetherFlag}
                          onChange={handleChange}
                        >
                          <MenuItem value="SI">SI</MenuItem>
                          <MenuItem value="NO">NO</MenuItem>
                        </AppSelect>
                      </Grid>
                    ) : null}

                    {/* Direccion */}
                    {(values.civilStatusId == "2" ||
                      values.civilStatusId == "5") &&
                    values.coupleLiveTogetherFlag == "NO" ? (
                      <Grid item lg={3} xs={12}>
                        <Paragraph title="Dirección de la pareja" required />
                        <AppTextField
                          error={
                            errors.coupleDirection && touched.coupleDirection
                              ? true
                              : false
                          }
                          name="coupleDirection"
                          value={values.coupleDirection}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Box sx={{ width: "100%", bgcolor: "primary.main" }}>
                        <Typography sx={{ color: "white", px: 2, py: 1 }}>
                          Redes sociales
                        </Typography>
                      </Box>
                    </Grid>

                    {/* TshirtSize */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Tamaño TShirt" required />
                      <AppSelect
                        name="tShirtSizeId"
                        error={
                          errors.tShirtSizeId && touched.tShirtSizeId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.tShirtSizeId}
                        onChange={handleChange}
                      >
                        {tshirtSizes.map((el) => (
                          <MenuItem
                            key={el.tShirtSizeId}
                            value={el.tShirtSizeId}
                          >
                            {el.size}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>

                    {/* Facebook*/}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Facebook" />
                      <AppTextField
                        name="facebookProfileUrl"
                        value={values.facebookProfileUrl}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Instagram*/}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Instagram" />
                      <AppTextField
                        name="instagramProfileUrl"
                        value={values.instagramProfileUrl}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* TikTiok*/}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Tik Tok" />
                      <AppTextField
                        name="tiktokProfileUrl"
                        value={values.tiktokProfileUrl}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* Follow us*/}
                    <Grid item lg={4} xs={12}>
                      <Paragraph
                        title="¿Usted nos sigue en las redes sociales?"
                        required
                      />
                      <AppSelect
                        name="followUsSocialMediaFlag"
                        error={
                          errors.followUsSocialMediaFlag &&
                          touched.followUsSocialMediaFlag
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.followUsSocialMediaFlag}
                        onChange={handleChange}
                      >
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="SI">SI</MenuItem>
                      </AppSelect>
                    </Grid>

                    {/* Follow us explain */}
                    {values.followUsSocialMediaFlag == "NO" ? (
                      <Grid item lg={8} xs={12}>
                        <Paragraph title="¿Por qué no nos sigues?" />
                        <AppTextField
                          name="followUsSocialMediaExplain"
                          value={values.followUsSocialMediaExplain}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChangeToUppercase(event, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Box sx={{ width: "100%", bgcolor: "primary.main" }}>
                        <Typography sx={{ color: "white", px: 2, py: 1 }}>
                          Contacto en caso de una emergencia
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Nombre completo */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Nombre completo" required />
                      <AppTextField
                        error={
                          errors.emergencyContactFullName &&
                          touched.emergencyContactFullName
                            ? true
                            : false
                        }
                        name="emergencyContactFullName"
                        value={values.emergencyContactFullName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Parentesco con usted */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Parentesco con usted" required />
                      <AppSelect
                        name="emergencyContactRelationShipId"
                        error={
                          errors.emergencyContactRelationShipId &&
                          touched.emergencyContactRelationShipId
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.emergencyContactRelationShipId}
                        onChange={handleChange}
                      >
                        {familyRelationships.map((el) => (
                          <MenuItem
                            key={el.familyRelationshipId}
                            value={el.familyRelationshipId}
                          >
                            {el.name}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>

                    {/* Teléfono de contacto */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Teléfono de contacto" required />
                      <AppTextField
                        error={
                          errors.emergencyPhoneContact &&
                          touched.emergencyPhoneContact
                            ? true
                            : false
                        }
                        name="emergencyPhoneContact"
                        value={values.emergencyPhoneContact}
                        onBlur={handleBlur}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue(
                              "emergencyPhoneContact",
                              formattedPhone
                            );
                          }
                        }}
                      />
                    </Grid>

                    {/* Nombre completo */}
                    <Grid item lg={3} xs={12}>
                      <Paragraph title="Email" />
                      <AppTextField
                        name="emergencyEmailAddress"
                        value={values.emergencyEmailAddress}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* BUTTON */}
                    <Grid item xs={12}>
                      <Button
                        disabled={isSubmitting}
                        onClick={() => {
                          if (!isValid) {
                            setIsValidForm(true);
                          }
                        }}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{ bgcolor: "secondary.main", mt: "10px" }}
                      >
                        {isSubmitting ? <>Guardando...</> : "Siguiente"}
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={0}></Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />

      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default CandidateBasicInfoForm;
