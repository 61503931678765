import { Form, Formik, FormikProps } from "formik";
import CandidateCountryVisit from "../../models/CandidateCountryVisit";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Button, Grid, MenuItem } from "@mui/material";
import Paragraph from "../../components/Paragraph";
import AppTextField from "../../components/AppTextField";
import { Country } from "../../models/Country";
import AppSelect from "../../components/AppSelect";
import candidateService from "../../services/CandidateService";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import { handleInputChangeToUppercase } from "../../Utils";
import IOption from "../../interfaces/IOption";

interface CandidateCountryVisitProps {
  close: () => void;
  candidateCountryVisit: CandidateCountryVisit;
  onSave: (visit: CandidateCountryVisit) => void;
  countries: Country[];
}

const candidateCountryVisitValidationScheme = Yup.object().shape({
  dateFrom: Yup.string().required("Requerido"),
  dateTo: Yup.string().required("Requerido"),
  tripMotivation: Yup.string().required("Requerido"),
  countryID: Yup.string().required("Requerido"),
});

function CandidateCountryVisitModal({
  close,
  candidateCountryVisit,
  onSave,
  countries,
}: CandidateCountryVisitProps) {
  const [initialValues, setInitialValues] = useState<CandidateCountryVisit>(
    candidateCountryVisit
  );
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function submit(values: CandidateCountryVisit) {
    const visit: CandidateCountryVisit = {
      ...values,
    };
    setIsSaving(true);
    try {
      const returnVisa = await candidateService.saveCandidateCountryVisit(
        visit
      );

      onSave(returnVisa);
      setIsSaving(false);
    } catch {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={candidateCountryVisitValidationScheme}
    >
      {(props: FormikProps<CandidateCountryVisit>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          setFieldValue,
        } = props;

        return (
          <Form>
            <Grid container spacing={1}>
              {/* Tienes pasaporte*/}
              <Grid item lg={6} xs={12}>
                <Paragraph title="País donde estuvo" required />
                <AppSelect
                  name="countryID"
                  error={errors.countryID && touched.countryID ? true : false}
                  fullWidth
                  value={values.countryID}
                  onChange={handleChange}
                  isSearchable={true}
                  options={countries.map((el) => {
                    var option: IOption = {
                      label: el.name,
                      value: el.countryId.toString(),
                    };
                    return option;
                  })}
                >
                  {/* {countries.map((el) => (
                    <MenuItem key={el.countryId} value={el.countryId}>
                      {el.name}
                    </MenuItem>
                  ))} */}
                </AppSelect>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Paragraph title="Fecha desde" required />
                <AppTextField
                  type="date"
                  error={errors.dateFrom && touched.dateFrom ? true : false}
                  name="dateFrom"
                  value={values.dateFrom}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <Paragraph title="Fecha de hasta" required />
                <AppTextField
                  type="date"
                  error={errors.dateTo && touched.dateTo ? true : false}
                  name="dateTo"
                  value={values.dateTo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <Paragraph title="Motivo del viaje" required />
                <AppTextField
                  error={
                    errors.tripMotivation && touched.tripMotivation
                      ? true
                      : false
                  }
                  name="tripMotivation"
                  value={values.tripMotivation}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeToUppercase(event, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={close}
                  color="customGrey"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Cerrar
                </Button>
                <Button
                  onClick={() => {
                    if (!isValid) {
                      setIsValidForm(true);
                    }
                  }}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ bgcolor: "secondary.main" }}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>

            <ConfirmationDialog
              maxWidth="sm"
              open={isValidForm}
              title={"Error"}
              description={"Llenar todos los requisitos en rojo"}
              buttons={
                <Button onClick={() => setIsValidForm(false)}>Ok</Button>
              }
            />
            <ConfirmationDialog
              maxWidth="sm"
              open={openDialog}
              title={dialogTitle}
              description={dialogDesc}
              buttons={dialogButton}
            />
            <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
          </Form>
        );
      }}
    </Formik>
  );
}
export default CandidateCountryVisitModal;
