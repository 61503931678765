import axios from "axios";
import { UtilsEndPoints } from "../Settings/EndPoints";
import Subsidiary from "../models/Subsidiary";
import InterviewDate from "../models/InterviewDate";
import ValidHourForDate from "../models/ValidHourForDate";
import { Country } from "../models/Country";
import Province from "../models/Province";
import City from "../models/City";
import TShirtSize from "../models/TShirtSize";
import CivilStatus from "../models/CivilStatus";
import ProgramType from "../models/ProgramType";
import FamilyRelationship from "../models/FamilyRelationship";
import University from "../models/University";
import UniversityCareer from "../models/UniversityCareer";
import UniversityPeriodType from "../models/UniversityPeriodType";
import VisaType from "../models/VisaType";
import MigratoryState from "../models/MigratoryState";
import FoundAboutUs from "../models/FoundAboutUs";
import LanguageLevel from "../models/LanguageLevel";
import BankAccount from "../models/BankAccount";
import { GenericItem } from "../models/GenericItem";
import { Sponsor } from "../models/Sponsor";
import WorkCompany from "../models/WorkCompany";
import WorkPosition from "../models/WorkPosition";
import WelcomeCall from "../models/WelcomeCall";
class UtilsService
{
    async getGenericItems(type: string): Promise<GenericItem[]>{
        var ret: GenericItem[] = []
        var resp = await axios.get(UtilsEndPoints.GenericItems + `?type=${type}`)
        ret = resp.data;
        return ret;
    }
    async getSubsidiaries(): Promise<Subsidiary[]>{
        var ret:Subsidiary[] = []
        var resp = await axios.get(UtilsEndPoints.GetSubsidiaries)
        ret = resp.data
        return ret;
    }

    async getInterviewDate(): Promise<InterviewDate[]>
    {
        var ret: InterviewDate[] = [];
        var resp = await axios.get(UtilsEndPoints.GetInterviewDate);
        ret = resp.data
        return ret;

    }
    async getValidHourForDate(date: string, candidateId: number = 0): Promise<ValidHourForDate[]>
    {
        var ret: ValidHourForDate[] = [];
        var resp = await axios.get(UtilsEndPoints.GetHoursForDate + `?date=${date}&candidateId=${candidateId}`);
        ret = resp.data
        return ret;
    }
    async getCountries(): Promise<Country[]>
    {
        var ret: Country[] = [];
        var resp = await axios.get(UtilsEndPoints.GetCountries);
        ret = resp.data
        return ret;
    }
    async getProvinces(): Promise<Province[]>
    {
        var ret: Province[] = [];
        var resp = await axios.get(UtilsEndPoints.GetProvince);
        ret = resp.data
        return ret;
    }

    async getCities(provinceId: string): Promise<City[]>
    {
        var ret: City[] = [];
        var resp = await axios.get(UtilsEndPoints.GetCities + `?provinceId=${provinceId}`);
        ret = resp.data
        return ret;
    }

    async getTShirtSize(): Promise<TShirtSize[]>
    {
        var ret: TShirtSize[] = [];
        var resp = await axios.get(UtilsEndPoints.GetTShirtSize );
        ret = resp.data
        return ret;
    }

    async getCivilStatus(): Promise<CivilStatus[]>
    {
        var ret: CivilStatus[] = [];
        var resp = await axios.get(UtilsEndPoints.GetCivilStatus );
        ret = resp.data
        return ret;
    }

    async getProgramTypes(): Promise<ProgramType[]>
    {
        var ret: ProgramType[] = [];
        var resp = await axios.get(UtilsEndPoints.GetProgramTypes );
        ret = resp.data
        return ret;
    }
    // async getGenerics(genericType: string): Promise<Generic[]>
    // {
    //     var ret: Generic[] = [];
    //     var resp = await axios.get(UtilsEndPoints.GetGenerics + `?genericType=${genericType}` );
    //     ret = resp.data
    //     return ret;
    // }
    async getFamilyRelationships(): Promise<FamilyRelationship[]>
    {
        var ret: FamilyRelationship[] = [];
        var resp = await axios.get(UtilsEndPoints.GetFamilyRelationships);
        ret = resp.data
        return ret;
    }

    async getUniversities() : Promise<University[]> {
        var ret: University[] = [];
        var resp = await axios.get(UtilsEndPoints.GetUniversities);
        ret = resp.data
        return ret;
    }

    async getUniversityCareers() : Promise<UniversityCareer[]> {
        var ret: UniversityCareer[] = [];
        var resp = await axios.get(UtilsEndPoints.GetUniversityCareers);
        ret = resp.data
        return ret;
    }
    async getUniversityPeriodTypes() : Promise<UniversityPeriodType[]> {
        var ret: UniversityPeriodType[] = [];
        var resp = await axios.get(UtilsEndPoints.GetUniversityPeriodTypes);
        ret = resp.data
        return ret;
    }
    async getVisaTypes(): Promise<VisaType[]>{
        var ret: VisaType[] = [];
        var resp = await axios.get(UtilsEndPoints.GetVisaTypes);
        ret = resp.data
        return ret;
    }
    async getMigratoryStates(): Promise<MigratoryState[]>{
        var ret: MigratoryState[] = [];
        var resp = await axios.get(UtilsEndPoints.GetMigratoryStates);
        ret = resp.data
        return ret;
    }
    async getFoundAboutUs(): Promise<FoundAboutUs[]>{
        var ret: FoundAboutUs[] = [];
        var resp = await axios.get(UtilsEndPoints.GetFoundAboutUs);
        ret = resp.data
        return ret;
    }

    async getLanguageLevels(): Promise<LanguageLevel[]>{
        var ret: LanguageLevel[] = [];
        var resp = await axios.get(UtilsEndPoints.GetLanguageLevels);
        ret = resp.data
        return ret;
    }
    async getBankAccounts(): Promise<BankAccount[]>{
        var ret: BankAccount[] = [];
        var resp = await axios.get(UtilsEndPoints.GetBankAccounts);
        ret = resp.data
        return ret;
    }

    async getSponsors(): Promise<Sponsor[]>{
        var ret: Sponsor[] = [];
        var resp = await axios.get(UtilsEndPoints.GetSponsors);
        ret = resp.data
        return ret;
    }

    async getWorkCompanies(): Promise<WorkCompany[]>{
        var ret: WorkCompany[] = [];
        var resp = await axios.get(UtilsEndPoints.GetWorkCompanies);
        ret = resp.data
        return ret;
    }

    async getWorkPositions(): Promise<WorkPosition[]>{
        var ret: WorkPosition[] = [];
        var resp = await axios.get(UtilsEndPoints.GetWorkPositions);
        ret = resp.data
        return ret;
    }
    async getWelcomeCalls(): Promise<WelcomeCall[]>{
        var ret: WelcomeCall[] = [];
        var resp = await axios.get(UtilsEndPoints.GetWelcomeCalls);
        ret = resp.data
        return ret;
    }
}



export default new UtilsService();