

export function getCookie(name: string): string | null {
	const nameLenPlus = (name.length + 1);
	const params = new URLSearchParams(window.location.search);
	let token = params.get('userToken')

	if(!token){
		token  = params.get('userToken')
		if(token == null || token.length == 0){
			token = null
		}
	}

	if(token == null || token.length == 0){
		token = document.cookie
		.split(';')
		.map(c => c.trim())
		.filter(cookie => {
			return cookie.substring(0, nameLenPlus) === `${name}=`;
		})
		.map(cookie => {
			return decodeURIComponent(cookie.substring(nameLenPlus));
		})[0] || null
	}



	// if(!token){

	// 	token  = params.get('userToken')
	// 	if(token == null || token.length == 0){
	// 		token = null
	// 	}
	// }

	return token;
}

export function GetDateYYMMYYYYHHmm(date:Date ): string{

	var dt = new Date(date)
	var mm = dt.getMonth() + 1;
	var dd = dt.getDate();
	var year = dt.getFullYear();
	var hour = dt.getHours();
	var minutes = dt.getMinutes();
	var time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
	return `${dd}/${mm}/${year} ${time}`;
}

export function GetDateYYYYMMDD(date:Date ): string{

	var dt = new Date(date)
	var mm = dt.getMonth() + 1;
	var dd = dt.getDate();
	var year = dt.getFullYear();

	return `${year}-${mm < 10 ? "0" : ""}${mm}-${dd < 10 ? "0" : ""}${dd}`
}


export function GetDateDDMMYYYYY(date:Date ): string{

	var dt = new Date(date)
	var mm = dt.getMonth() + 1;
	var dd = dt.getDate();
	var year = dt.getFullYear();

	return `${dd < 10 ? "0" : ""}${dd}/${mm < 10 ? "0" : ""}${mm}/${year}`
}

export const handleInputChangeToUppercase = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const uppercaseValue = event.target.value.toUpperCase();
    setFieldValue(event.target.name, uppercaseValue);
  };



export function phoneFormatter(value: string): string{
	const phoneRegex = /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;

	return value.replace(
		phoneRegex,
		(_, group1, group2, group3) => {
		  if (value.length == 4) {
			return `${group1}-${group2}`;
		  }
		  return group1 && group2 && group3
			? `${group1}-${group2}-${group3}`
			: value;
		}
	  )
}

export function cedulaFormatter(value: string): string{
	const cedulaRegex = /^(\d{0,3})-?(\d{0,7})-?(\d{0,1})$/;

	return value.replace(
		cedulaRegex,
		(_, group1, group2, group3) => {
		  if (value.length == 4) {
			return `${group1}-${group2}`;
		  }
		  return group1 && group2 && group3
			? `${group1}-${group2}-${group3}`
			: value;
		}
	  )
}

export  function setBodyColor(color: string) {
    // document.body.style.background = color;


}
export const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

 export function generateTimeIntervals(): string[] {
    let times: string[] = [];
    let start = 8 * 60; // 8:00 AM in minutes
    let end = 16.5 * 60; // 4:30 PM in minutes
    let interval = 30; // 30 minutes

    function formatTime(minutes: number) {
        let h:number = Math.floor(minutes / 60);
        let m:number | string = minutes % 60;
        let ampm = h >= 12 ? 'PM' : 'AM';
        h = h % 12;
        h = h ? h : 12; // the hour '0' should be '12'
        m = m < 10 ? '0' + m : m;
        return h + ':' + m + ' ' + ampm;
    }

    // Generate intervals from 8:00 AM to 12:00 PM
    for (let i = start; i < 12 * 60; i += interval) {
        times.push(formatTime(i));
    }

    // Generate intervals from 2:00 PM to 4:30 PM
    for (let i = 14 * 60; i <= end; i += interval) {
        times.push(formatTime(i));
    }

    return times;
}



export enum State{
	Pendientedeactivacion = 22,
	PendienteConfirmacion = 62,
	PendientedeRegistro = 23,
	Registrado_PendientePagoEvaluacion = 24,
	Pendienteevaluacion = 25,
	Declinado = 26,
	Aceptado_pendienteprograma = 27,
	ContratoProgramafirmado = 28,
	Pendienteempleo = 29,
	EmpleoSeleccionado = 30,
	PendienteCitaVac = 31,
	PendienteCitaConsular = 32,
	CitaConfirmada = 33,
	VisadoDeclinado = 34,
	PendienteContratoGarantias = 35,
	ContratoGFirmadopendienteconfirmacionviaje = 36,
	Viajeconfirmado = 37,
	PendienteDescargo = 38,
	ProgramaCompletado = 39,
	PendienteAprobacion = 40,
	PendingTripInfo = 48,
	PendingPassportDelivery = 49,
	PassportDelivered = 50,
	CompletedProgram = 51,
	CompletedProgramReturned = 57,
	Retire = 43
}

