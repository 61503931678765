import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Subsidiary from "../../models/Subsidiary";
import InterviewDate from "../../models/InterviewDate";
import ValidHourForDate from "../../models/ValidHourForDate";
import utilsService from "../../services/UtilsService";
import { useAppSelector } from "../../store/store";
import { ApplicationState } from "../../models/ApplicationState";
import { GetDateDDMMYYYYY, GetDateYYYYMMDD } from "../../Utils";
import AppSelect from "../../components/AppSelect";
import candidateService from "../../services/CandidateService";
import LoadingModal from "../../components/LoadingModal";
import ErrorText from "../../components/ErrorText";
import Paragraph from "../../components/Paragraph";

interface InterviewModalProps {
  onClose: () => void;
  onSave: (date: string, hour: string) => void;
  isNew?: boolean;
}
interface IInterviewModal {
  interviewDate: string;
  interviewHour: string;
  interviewType?: string;
}

function InterviewModal({
  onClose,
  onSave,
  isNew = false,
}: InterviewModalProps) {
  const appState: ApplicationState = useAppSelector((state) => state.appState);
  const [initialValues, setInitialValues] = useState<IInterviewModal>({
    interviewDate: "",
    interviewHour: "",
    interviewType: "",
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  var [interviewDates, setInterviewDates] = useState<InterviewDate[]>();
  var [validHourForDate, setValidHourForDate] = useState<ValidHourForDate[]>();

  const validationSchema = Yup.object().shape({
    interviewDate: Yup.string().required("Requerido"),
    interviewHour: Yup.string().required("Requerido"),
    interviewType: isNew ? Yup.string().required("Requerido") : Yup.string(),
  });

  async function submit(values: IInterviewModal) {
    try {
      setIsSaving(true);
      await candidateService.saveCandidateInterview({
        date: values.interviewDate,
        hour: values.interviewHour,
        interviewType: values.interviewType,
      });
      setIsSaving(false);
      onSave(values.interviewDate, values.interviewHour);
    } catch (errror: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    const dates = await utilsService.getInterviewDate();

    const hours = await utilsService.getValidHourForDate(
      GetDateYYYYMMDD(new Date(appState.interviewDate!))
    );

    setInterviewDates(dates);
    setValidHourForDate(hours);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);
  return (
    <Box>
      {isLoading ? (
        <Box display="block" margin="auto" textAlign="center">
          <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
          <Typography variant="body1">Cargando...</Typography>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {(props: FormikProps<IInterviewModal>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Grid container spacing={1}>
                  {isNew ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "5px" }}
                      >
                        Modo entrevista
                      </Typography>
                      <AppSelect
                        name="interviewType"
                        error={
                          errors.interviewType && touched.interviewType
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.interviewType}
                        onChange={handleChange}
                      >
                        <MenuItem value="VIRTUAL">VIRTUAL</MenuItem>
                        <MenuItem value="PRESENCIAL">PRESENCIAL</MenuItem>
                      </AppSelect>
                      {errors.interviewType && touched.interviewType ? (
                        <ErrorText text={errors.interviewType} />
                      ) : null}
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: "5px" }}>
                      Fecha de cita
                    </Typography>
                    <AppSelect
                      name="date"
                      error={
                        errors.interviewDate && touched.interviewDate
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.interviewDate}
                      onChange={async (event, child) => {
                        setFieldValue("interviewDate", event.target.value);
                        const hours = await utilsService.getValidHourForDate(
                          GetDateYYYYMMDD(new Date(event.target.value))
                        );
                        setValidHourForDate(hours);
                        setFieldValue("interviewHour", "");
                      }}
                    >
                      {interviewDates?.map((el) => (
                        <MenuItem key={el.currentDate} value={el.currentDate}>
                          {el.currentDateString}
                        </MenuItem>
                      ))}
                    </AppSelect>
                    {errors.interviewDate && touched.interviewDate ? (
                      <ErrorText text={errors.interviewDate} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: "5px" }}>
                      Hora
                    </Typography>
                    <AppSelect
                      name="interviewHour"
                      error={
                        errors.interviewHour && touched.interviewHour
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.interviewHour}
                      onChange={handleChange}
                    >
                      {validHourForDate?.map((el) => (
                        <MenuItem
                          key={el.interviewTime}
                          value={el.interviewTime}
                        >
                          {el.interviewTime}
                        </MenuItem>
                      ))}
                    </AppSelect>
                    {errors.interviewHour && touched.interviewHour ? (
                      <ErrorText text={errors.interviewHour} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={onClose}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Cerrar
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Guardar"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}

export default InterviewModal;
