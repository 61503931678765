import {
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import IOption from "../interfaces/IOption";
import { useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ApplicationState } from "../models/ApplicationState";
import { useAppSelector } from "../store/store";

interface AppSelectProps {
  value?: any;
  onChange?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
  label?: string;
  children?: React.ReactNode;
  fullWidth?: boolean | undefined;
  error?: boolean | undefined;
  name?: string | undefined;
  disabled?: boolean;
  options?: IOption[];
  isSearchable?: boolean;
  renderValue?: (value: any) => React.ReactNode;
}

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

function AppSelect({
  value,
  onChange,
  label,
  children,
  fullWidth,
  error,
  name,
  disabled,
  options = [],
  isSearchable,
  renderValue,
}: AppSelectProps) {
  const [searchText, setSearchText] = useState("");

  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option.label, searchText)),
    [searchText]
  );

  const appState: ApplicationState = useAppSelector((state) => state.appState);

  return (
    <Select
      MenuProps={{
        autoFocus: isSearchable ? false : undefined,
        style: { maxHeight: "400px" },
      }}
      disabled={
        (disabled == undefined &&
          appState.applicationStateId != 24 &&
          appState.applicationStateId != 23 &&
          !appState.isAdmin &&
          (window.location.href.includes("candidateapplication") ||
            window.location.href.includes("profile"))) ||
        (window.location.href.includes("ds160") &&
          appState.dS160FileUrl != null &&
          appState.dS160FileUrl.length > 0)
          ? true
          : disabled
      }
      sx={{
        marginBottom: "5px",
        bgcolor: "white",
        borderRadius: "4px",
        "& .MuiSelect-select": {
          padding: "8.5px 14px",
        },
      }}
      name={name}
      error={error}
      value={value}
      onChange={onChange}
      fullWidth={fullWidth}
      label={label}
      renderValue={renderValue}
      onClose={() => setSearchText("")}
    >
      {isSearchable ? (
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
      ) : null}

      {isSearchable
        ? displayedOptions.map((el, index) => (
            <MenuItem key={index} value={el.value}>
              {el.label}
            </MenuItem>
          ))
        : children}
    </Select>
  );
}

export default AppSelect;
