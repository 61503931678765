import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

export default function AppSpinner() {
  return (
    <Box textAlign="center">
      <Box display="inline-block" textAlign="center">
        <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
        <Typography variant="body1">Cargando...</Typography>
      </Box>
    </Box>
  );
}
