import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";
import processing from "../assets/lottie/processing.json";
import done from "../assets/lottie/done_animation.json";
import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import authService from "../services/AuthService";
import ConfirmationDialog from "../components/ConfirmationDialog";

function ActivationScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState<boolean>(false);
  const code = searchParams.get("activationCode");
  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  const navigate = useNavigate();

  let isCalled: boolean = false;

  async function activateAccount() {
    if (!isCalled) {
      if (code != null) {
        isCalled = true;
        try {
          var isSuccess: boolean = await authService.activateAccount(code);
          if (isSuccess) {
            setIsActive(true);
            setTimeout(() => {
              window.location.href = "/login";
            }, 6000);
          }
        } catch (error) {
          setOpenDialog(true);
          setDialogTitle("Lo Sentimos");
          setDialogDesc("Ha ocurrido un error procesando su cuenta.");
        }
      }
    }
  }

  useEffect(() => {
    activateAccount();
  }, []);

  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {!isActive ? (
        <Box textAlign="center">
          <Typography
            variant="h4"
            style={{ color: "white", marginTop: "20px" }}
          >
            NO CERRAR LA PÁGINA.
          </Typography>
          <Typography
            variant="h4"
            style={{ color: "white", marginTop: "20px" }}
          >
            Tu cuenta está siendo activada, por favor espere unos segundos. .
          </Typography>
          <Lottie
            style={{ maxWidth: 500, width: "100%", display: "inline-block" }}
            animationData={processing}
            loop={true}
          />
        </Box>
      ) : (
        <Box textAlign="center">
          {" "}
          <Typography
            variant="h3"
            style={{ color: "white", marginTop: "20px" }}
          >
            Cuenta Activada
          </Typography>
          <Lottie
            style={{ maxWidth: 500, width: "100%", display: "inline-block" }}
            animationData={done}
            loop={false}
          />
          <Button
            onClick={() => {
              navigate("/login");
            }}
            color="secondary"
            variant="contained"
          >
            Entrar
          </Button>
        </Box>
      )}

      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={
          <Button
            onClick={() => {
              setOpenDialog(false);
              window.location.href = "/login";
            }}
          >
            Ok
          </Button>
        }
      />
    </Container>
  );
}

export default ActivationScreen;
