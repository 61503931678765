import { ChangeEvent, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Sponsor } from "../../models/Sponsor";
import WorkCompany from "../../models/WorkCompany";
import WorkPosition from "../../models/WorkPosition";
import { Form, Formik, FormikProps } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingModal from "../../components/LoadingModal";
import Paragraph from "../../components/Paragraph";
import AppSelect from "../../components/AppSelect";
import IOption from "../../interfaces/IOption";
import AppTextField from "../../components/AppTextField";
import { handleInputChangeToUppercase } from "../../Utils";
import utilsService from "../../services/UtilsService";
import { CandidateApplicationContext } from "../../screens/CandidateApplicationScreen";
import CandidateService from "../../services/CandidateService";
import CandidateRepeatingOtherInformation from "../../models/CandidateRepeatingOtherInformacion";
import { ApplicationState } from "../../models/ApplicationState";
import AuthService from "../../services/AuthService";
import { appState, login } from "../../store/appStateReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";

interface ICandidateOtherInformation {
  repeatingSponsor: string;
  repeatingJobDescription: string;
  repeatingPosition: string;
  repeatingSameJob: string;
  repeatingSameJobJustification: string;
  repeatingSamePosition: string;
  repeatingSamePositionJustificacion: string;
  repeatingGarantee: string;
  repeatingParticipationCount: string;
  repeating12CreditDecember: string;
  repeating12CreditDecemberJustification: string;
  repeating12CreditJanuary: string;
  repeating12CreditJanuaryJustification: string;
  repeatingMedica: string;
  userPicture?: string;
  scheduleURL?: string;
}
const validationSchema = Yup.object().shape({
  repeatingSponsor: Yup.string().required("Requerido"),
  repeatingJobDescription: Yup.string().required("Requerido"),
  repeatingPosition: Yup.string().required("Requerido"),
  repeatingSameJob: Yup.string().required("Requerido"),
  repeatingSameJobJustification: Yup.string().when(
    "repeatingSameJob",
    (repeatingSameJob: string | string[], schema) => {
      if (repeatingSameJob == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  repeatingSamePosition: Yup.string().required("Requerido"),
  repeatingSamePositionJustificacion: Yup.string().when(
    "repeatingSamePosition",
    (repeatingSamePosition: string | string[], schema) => {
      if (repeatingSamePosition == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  repeating12CreditDecember: Yup.string().required("Requerido"),
  repeating12CreditDecemberJustification: Yup.string().when(
    "repeating12CreditDecember",
    (repeating12CreditDecember: string | string[], schema) => {
      if (repeating12CreditDecember == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  repeating12CreditJanuary: Yup.string().required("Requerido"),
  repeating12CreditJanuaryJustification: Yup.string().when(
    "repeating12CreditJanuary",
    (repeating12CreditJanuary: string | string[], schema) => {
      if (repeating12CreditJanuary == "NO") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

function CandidateRepeatOtherInformation() {
  const [initialValues, setInitialValues] =
    useState<ICandidateOtherInformation>({
      repeatingSponsor: "",
      repeatingJobDescription: "",
      repeatingPosition: "",
      repeatingSameJob: "NO",
      repeatingSameJobJustification: "",
      repeatingSamePosition: "NO",
      repeatingSamePositionJustificacion: "",
      repeatingGarantee: "",
      repeatingParticipationCount: "",
      repeating12CreditDecember: "NO",
      repeating12CreditDecemberJustification: "",
      repeating12CreditJanuary: "NO",
      repeating12CreditJanuaryJustification: "",
      repeatingMedica: "",
      userPicture: "",
      scheduleURL: "",
    });
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [workCompanies, setWorkCompanies] = useState<WorkCompany[]>([]);
  const [workPositions, setWorkPositions] = useState<WorkPosition[]>([]);
  const [fileUploadHorario, setFileUploadHorario] = useState<File | null>(null);
  const [fileUploadFoto, setFileUploadFoto] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const { step, setStep } = useContext(CandidateApplicationContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  async function submit(values: ICandidateOtherInformation) {
    if (
      (appState.applicationStateId != 23 && !appState.isAdmin) ||
      window.location.href.includes("profile")
    ) {
      navigate("/");
      return;
    }
    try {
      setIsSaving(true);
      const info: CandidateRepeatingOtherInformation = {
        ...values,
        repeatingSameJob: values.repeatingSameJob == "SI",
        repeatingGarantee: values.repeatingGarantee == "SI",
        repeating12CreditDecember: values.repeating12CreditDecember == "SI",
        repeating12CreditJanuary: values.repeating12CreditJanuary == "SI",
      };

      if (
        values.scheduleURL != null &&
        values.scheduleURL != "" &&
        values.userPicture != null &&
        values.userPicture != ""
      ) {
      } else {
        if (fileUploadHorario == null) {
          setIsSaving(false);
          setOpenDialog(true);
          setDialogTitle("Error");
          setDialogDesc("Debe subir el horario");
          setDialogButton(
            <Button onClick={() => setOpenDialog(false)}>Ok</Button>
          );
          return;
        }

        if (fileUploadFoto == null) {
          setIsSaving(false);
          setOpenDialog(true);
          setDialogTitle("Error");
          setDialogDesc("Debe subir una foto actualizada");
          setDialogButton(
            <Button onClick={() => setOpenDialog(false)}>Ok</Button>
          );
          return;
        }
      }

      await CandidateService.saveCandidateRepeatingOtherInformation(
        info,
        fileUploadHorario,
        fileUploadFoto
      );

      if (appState.isAdmin) {
        setIsSaving(false);
        setOpenDialog(true);
        setDialogTitle("CAMBIOS REALIZADOS HAN SIDO GUARDADOS");
        setDialogDesc(
          "Los cambios realizados en los datos de este aplicante han sido guardados exitósamente. Puede continuar con el proceso de evaluación del estudiante."
        );
        setDialogButton(
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            Ok
          </Button>
        );
      } else {
        var applicationState: ApplicationState | null =
          await AuthService.loadUserInfo();

        await dispatch(login({ applicationState: applicationState! }));

        setIsSaving(false);

        setOpenDialog(true);
        setDialogTitle("TUS DATOS HAN SIDO REGISTRADOS EXITOSAMENTE");
        setDialogDesc(
          "¡Registro exitoso! Puedes revisar los detalles de tu entrevista haciendo clic en Inicio. Tu cuenta está pendiente de pago, recuerda que puedes hacerlo mediante depósito, transferencia, efectivo o tarjeta en línea.\n\n Agradecemos tu interés en unirte a la familia ACE INTERNATIONAL. ¡Bienvenido(a)!"
        );
        setDialogButton(
          <Button
            onClick={() => {
              setOpenDialog(false);
              navigate("/");
            }}
          >
            Ok
          </Button>
        );
      }
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  async function initialData() {
    const sponsor = await utilsService.getSponsors();
    const company = await utilsService.getWorkCompanies();
    const position = await utilsService.getWorkPositions();
    const info = await CandidateService.getCandidateRepeatingOtherInfo();
    const newVal: ICandidateOtherInformation = {
      repeatingSponsor: isNumeric(info.repeatingSponsor)
        ? sponsor.find((c) => c.sponsorID.toString() == info.repeatingSponsor)
            ?.sponsorName ?? info.repeatingSponsor
        : info.repeatingSponsor,
      // info.repeatingSponsor != null ? info.repeatingSponsor : "",
      repeatingJobDescription: isNumeric(info.repeatingJobDescription)
        ? company.find(
            (c) => c.workCompanyID.toString() == info.repeatingJobDescription
          )?.workCompanyName ?? info.repeatingJobDescription
        : info.repeatingJobDescription, //info.repeatingJobDescription ?? "",
      repeatingPosition: isNumeric(info.repeatingPosition)
        ? position.find(
            (c) => c.workPositionID.toString() == info.repeatingPosition
          )?.workPositionName ?? info.repeatingPosition
        : info.repeatingPosition, //info.repeatingPosition ?? "",
      repeatingSameJobJustification: info.repeatingSameJobJustification ?? "",
      repeatingSamePosition: info.repeatingSamePosition ?? "",
      repeatingSamePositionJustificacion:
        info.repeatingSamePositionJustificacion ?? "",
      repeatingParticipationCount: info.repeatingParticipationCount ?? "",
      repeating12CreditDecemberJustification:
        info.repeating12CreditDecemberJustification ?? "",
      repeating12CreditJanuaryJustification:
        info.repeating12CreditJanuaryJustification ?? "",
      repeatingMedica: info.repeatingMedica ?? "",
      repeatingSameJob: info.repeatingSameJob ? "SI" : "NO",
      repeatingGarantee: info.repeatingGarantee ? "SI" : "NO",
      repeating12CreditDecember: info.repeating12CreditDecember ? "SI" : "NO",
      repeating12CreditJanuary: info.repeating12CreditJanuary ? "SI" : "NO",
      userPicture: info.userPicture,
      scheduleURL: info.scheduleURL,
    };

    setInitialValues(newVal);
    // setInitialValues({
    //   ...info,
    //   repeatingSameJob: info.repeatingSameJob ? "SI" : "NO",
    //   repeatingGarantee: info.repeatingGarantee ? "SI" : "NO",
    //   repeating12CreditDecember: info.repeating12CreditDecember ? "SI" : "NO",
    //   repeating12CreditJanuary: info.repeating12CreditJanuary ? "SI" : "NO",
    // });

    setSponsors(sponsor);
    setWorkCompanies(company);
    setWorkPositions(position);
    setIsLoading(false);
  }

  function isNumeric(val: unknown): val is string | number {
    return (
      !isNaN(Number(Number.parseFloat(String(val)))) && isFinite(Number(val))
    );
  }

  useEffect(() => {
    initialData();
  }, []);
  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={
            !window.location.href.includes("profile") ? validationSchema : null
          }
          validateOnMount={true}
        >
          {(props: FormikProps<ICandidateOtherInformation>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Box textAlign="center">
                  <Typography
                    display="inline-block"
                    sx={{
                      mb: 3,
                      backgroundColor: "#00166B",
                      color: "white",
                      padding: "10px 30px",
                      borderRadius: "10px",
                      textTransform: "uppercase",
                    }}
                    variant="h4"
                  >
                    OTRAS INFORMACIONES REQUERIDAS
                  </Typography>
                </Box>
                {/* <Typography sx={{ mb: 3 }} variant="h4">
                  OTRAS INFORMACIONES REQUERIDAS
                </Typography> */}
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid spacing={1} container>
                      <Box
                        bgcolor="primary"
                        sx={{
                          width: "100%",
                          bgcolor: "primary.main",
                        }}
                      >
                        <Typography sx={{ color: "white", px: 2, py: 1 }}>
                          Datos para los repitentes
                        </Typography>
                      </Box>
                      {/* Sponsor */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Sponsor" required />
                        <AppSelect
                          name="repeatingSponsor"
                          error={
                            errors.repeatingSponsor && touched.repeatingSponsor
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.repeatingSponsor}
                          onChange={handleChange}
                          isSearchable={true}
                          options={sponsors.map((el) => {
                            var option: IOption = {
                              label: el.sponsorName,
                              value: el.sponsorName.toString(),
                            };
                            return option;
                          })}
                        />
                      </Grid>
                      {/* Work Company */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Plaza actual" required />
                        <AppSelect
                          name="repeatingJobDescription"
                          error={
                            errors.repeatingJobDescription &&
                            touched.repeatingJobDescription
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.repeatingJobDescription}
                          onChange={handleChange}
                          isSearchable={true}
                          options={workCompanies.map((el) => {
                            var option: IOption = {
                              label: el.workCompanyName,
                              value: el.workCompanyName.toString(),
                            };
                            return option;
                          })}
                        />
                      </Grid>
                      {/* Work Position */}
                      <Grid item xs={12} lg={4}>
                        <Paragraph title="Posiciòn actual" required />
                        <AppSelect
                          name="repeatingPosition"
                          error={
                            errors.repeatingPosition &&
                            touched.repeatingPosition
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.repeatingPosition}
                          onChange={handleChange}
                          isSearchable={true}
                          options={workPositions.map((el) => {
                            var option: IOption = {
                              label: el.workPositionName,
                              value: el.workPositionName.toString(),
                            };
                            return option;
                          })}
                        />
                      </Grid>

                      {/* Same Job  */}
                      <Grid
                        item
                        lg={values.repeatingSameJob == "SI" ? 12 : 6}
                        xs={12}
                      >
                        <Paragraph
                          title="¿En caso de ser posible deseas repetir la misma plaza?"
                          required
                        />
                        <AppSelect
                          name="repeatingSameJob"
                          error={
                            errors.repeatingSameJob && touched.repeatingSameJob
                              ? true
                              : false
                          }
                          fullWidth
                          value={values?.repeatingSameJob}
                          onChange={handleChange}
                        >
                          <MenuItem value="NO">NO</MenuItem>
                          <MenuItem value="SI">SI</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Same Job justification  */}
                      {values.repeatingSameJob == "NO" ? (
                        <Grid item xs={12} lg={6}>
                          <Paragraph title="Justificaciòn" required />
                          <AppTextField
                            error={
                              errors.repeatingSameJobJustification &&
                              touched.repeatingSameJobJustification
                                ? true
                                : false
                            }
                            name="repeatingSameJobJustification"
                            value={values.repeatingSameJobJustification}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                          />
                        </Grid>
                      ) : null}
                      {/* Same position  */}
                      <Grid
                        item
                        lg={values.repeatingSamePosition == "SI" ? 12 : 6}
                        xs={12}
                      >
                        <Paragraph
                          title="¿En caso de ser posible deseas repetir la misma posiciòn?"
                          required
                        />
                        <AppSelect
                          name="repeatingSamePosition"
                          error={
                            errors.repeatingSamePosition &&
                            touched.repeatingSamePosition
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.repeatingSamePosition}
                          onChange={handleChange}
                        >
                          <MenuItem value="NO">NO</MenuItem>
                          <MenuItem value="SI">SI</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* Same position justification  */}
                      {values.repeatingSamePosition == "NO" ? (
                        <Grid item xs={12} lg={6}>
                          <Paragraph title="Justificaciòn" required />
                          <AppTextField
                            error={
                              errors.repeatingSamePositionJustificacion &&
                              touched.repeatingSamePositionJustificacion
                                ? true
                                : false
                            }
                            name="repeatingSamePositionJustificacion"
                            value={values.repeatingSamePositionJustificacion}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                          />
                        </Grid>
                      ) : null}
                      {/* Same Guarantor  */}
                      <Grid item lg={6} xs={12}>
                        <Paragraph
                          title="¿Utilizará mismos garantes?"
                          required
                        />
                        <AppSelect
                          name="repeatingGarantee"
                          error={
                            errors.repeatingGarantee &&
                            touched.repeatingGarantee
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.repeatingGarantee}
                          onChange={handleChange}
                        >
                          <MenuItem value="NO">NO</MenuItem>
                          <MenuItem value="SI">SI</MenuItem>
                        </AppSelect>
                      </Grid>

                      {/* participate count */}
                      <Grid item lg={6} xs={12}>
                        <Paragraph
                          title="¿Cuantas veces ha realizado el Summer Work?"
                          required
                        />
                        <AppSelect
                          name="repeatingParticipationCount"
                          error={
                            errors.repeatingParticipationCount &&
                            touched.repeatingParticipationCount
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.repeatingParticipationCount}
                          onChange={handleChange}
                        >
                          {[1, 2, 3, 4, 5, 6, 7].map((el) => (
                            <MenuItem key={el} value={el.toString()}>
                              {el}
                            </MenuItem>
                          ))}
                        </AppSelect>
                      </Grid>

                      {/* december  */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Estará usted cursando un mínimo de  12 créditos para el ciclo septiembre diciembre 2024?"
                          required
                        />
                        <AppSelect
                          name="repeating12CreditDecember"
                          error={
                            errors.repeating12CreditDecember &&
                            touched.repeating12CreditDecember
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.repeating12CreditDecember}
                          onChange={handleChange}
                        >
                          <MenuItem value="NO">NO</MenuItem>
                          <MenuItem value="SI">SI</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* december justificatino  */}
                      {values.repeating12CreditDecember == "NO" ? (
                        <Grid item xs={12}>
                          <Paragraph title="Justificaciòn" required />
                          <AppTextField
                            error={
                              errors.repeating12CreditDecemberJustification &&
                              touched.repeating12CreditDecemberJustification
                                ? true
                                : false
                            }
                            name="repeating12CreditDecemberJustification"
                            value={
                              values.repeating12CreditDecemberJustification
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                          />
                        </Grid>
                      ) : null}

                      {/* january  */}
                      <Grid item xs={12}>
                        <Paragraph
                          title="¿Estará usted cursando un mínimo de  12 créditos para el ciclo enero-mayo 2025?"
                          required
                        />
                        <AppSelect
                          name="repeating12CreditJanuary"
                          error={
                            errors.repeating12CreditJanuary &&
                            touched.repeating12CreditJanuary
                              ? true
                              : false
                          }
                          fullWidth
                          value={values.repeating12CreditJanuary}
                          onChange={handleChange}
                        >
                          <MenuItem value="NO">NO</MenuItem>
                          <MenuItem value="SI">SI</MenuItem>
                        </AppSelect>
                      </Grid>
                      {/* january justificatino  */}
                      {values.repeating12CreditJanuary == "NO" ? (
                        <Grid item xs={12}>
                          <Paragraph title="Justificaciòn" required />
                          <AppTextField
                            error={
                              errors.repeating12CreditJanuaryJustification &&
                              touched.repeating12CreditJanuaryJustification
                                ? true
                                : false
                            }
                            name="repeating12CreditJanuaryJustification"
                            value={values.repeating12CreditJanuaryJustification}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChangeToUppercase(event, setFieldValue)
                            }
                          />
                        </Grid>
                      ) : null}

                      <Grid item xs={12} lg={6}>
                        <Paragraph title="Horario de Clase 2024 " required />
                        <AppTextField
                          inputProps={{
                            accept: "image/*, .doc, .docx, .pdf",
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (event.target.files != null) {
                              const f = event.target.files[0];
                              setFileUploadHorario(f);
                            } else {
                              setFileUploadHorario(null);
                            }
                          }}
                          type="file"
                        />

                        {values.scheduleURL != null &&
                        values.scheduleURL != "" ? (
                          <Button target="_blank" href={values.scheduleURL}>
                            Ver Archivo
                          </Button>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <Paragraph title="Foto Actualizada" required />
                        <AppTextField
                          inputProps={{
                            accept: "image/*, .doc, .docx, .pdf",
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (event.target.files != null) {
                              const f = event.target.files[0];
                              setFileUploadFoto(f);
                            } else {
                              setFileUploadFoto(null);
                            }
                          }}
                          type="file"
                        />
                        {values.userPicture != null &&
                        values.userPicture != "" ? (
                          <Button target="_blank" href={values.userPicture}>
                            Ver Archivo
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setStep(step - 1);
                      }}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Atras
                    </Button>
                    <Button
                      onClick={() => {
                        console.log(errors);
                        if (!isValid) {
                          setIsValidForm(true);
                        }
                      }}
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Guardar"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />

      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
export default CandidateRepeatOtherInformation;
