import { ChangeEvent, useContext, useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import FoundAboutUs from "../../models/FoundAboutUs";
import utilsService from "../../services/UtilsService";
import Paragraph from "../../components/Paragraph";
import AppSelect from "../../components/AppSelect";
import AppTextField from "../../components/AppTextField";
import { StyledTableCell, StyledTableRow } from "../../theme/GlobalTheme";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CandidateLanguageModal from "./CandidateLanguageModal";
import LanguageLevel from "../../models/LanguageLevel";
import CandidateLanguageList from "../../models/CandidateLanguageList";
import candidateService from "../../services/CandidateService";
import CandidateLanguage from "../../models/CandidateLanguage";
import { handleInputChangeToUppercase, phoneFormatter } from "../../Utils";
import CandidateOtherInformation from "../../models/CandidateOtherInformation";
import LoadingModal from "../../components/LoadingModal";
import { ApplicationState } from "../../models/ApplicationState";
import authService from "../../services/AuthService";
import { appState, login } from "../../store/appStateReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { CandidateApplicationContext } from "../../screens/CandidateApplicationScreen";

interface ICandidateOtherInformation {
  foundOutAboutUsId: string;
  friendCompleteName: string;
  friendPhone: string;
  friendEmail: string;
  specifyOther: string;
  englishLearn: string;
  englishImmersionEnclosure: string;
  dedicatedTimeEnglishImmersion: string;
  ownHouseflag: string;
  canSwim: string;
  exceptToGetFromThisProgramDetail: string;
  healthConditionFlag: string;
  healthConditionDetail: string;
  isAllergic: string;
  alerjicoAQue: string;
  impedimentWorkWeekendFlag: string;
  impedimentWorkWeekendDetail: string;
  cualidades: string;
  otherSchool: string;
}
const validationSchema = Yup.object().shape({
  foundOutAboutUsId: Yup.string().required("Requerido"),
  friendCompleteName: Yup.string().when(
    "foundOutAboutUsId",
    (foundOutAboutUsId: string | string[], schema) => {
      if (foundOutAboutUsId == "4") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  // friendPhone: Yup.string().when(
  //   "foundOutAboutUsId",
  //   (foundOutAboutUsId: string | string[], schema) => {
  //     if (foundOutAboutUsId == "4") {
  //       return schema.required("Requerido");
  //     }
  //     return schema;
  //   }
  // ),
  // friendEmail: Yup.string().when(
  //   "foundOutAboutUsId",
  //   (foundOutAboutUsId: string | string[], schema) => {
  //     if (foundOutAboutUsId == "4") {
  //       return schema.required("Requerido");
  //     }
  //     return schema;
  //   }
  // ),
  specifyOther: Yup.string().when(
    "foundOutAboutUsId",
    (foundOutAboutUsId: string | string[], schema) => {
      if (foundOutAboutUsId == "6") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  englishLearn: Yup.string().required("Requerido"),
  englishImmersionEnclosure: Yup.string().when(
    "englishLearn",
    (englishLearn: string | string[], schema) => {
      if (englishLearn != "Autodidacta") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  dedicatedTimeEnglishImmersion: Yup.string().when(
    "englishLearn",
    (englishLearn: string | string[], schema) => {
      if (englishLearn != "Autodidacta") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  ownHouseflag: Yup.string().required("Requerido"),
  canSwim: Yup.string().required("Requerido"),
  exceptToGetFromThisProgramDetail: Yup.string().required("Requerido"),
  healthConditionFlag: Yup.string().required("Requerido"),
  healthConditionDetail: Yup.string().when(
    "healthConditionFlag",
    (healthConditionFlag: string | string[], schema) => {
      if (healthConditionFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  isAllergic: Yup.string().required("Requerido"),
  alerjicoAQue: Yup.string().when(
    "isAllergic",
    (isAllergic: string | string[], schema) => {
      if (isAllergic == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  impedimentWorkWeekendFlag: Yup.string().required("Requerido"),
  impedimentWorkWeekendDetail: Yup.string().when(
    "impedimentWorkWeekendFlag",
    (impedimentWorkWeekendFlag: string | string[], schema) => {
      if (impedimentWorkWeekendFlag == "SI") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
  cualidades: Yup.string().required("Requerido"),
  otherSchool: Yup.string().when(
    "englishImmersionEnclosure",
    (englishImmersionEnclosure: string | string[], schema) => {
      if (englishImmersionEnclosure == "Otros") {
        return schema.required("Requerido");
      }
      return schema;
    }
  ),
});

function CandidateOtherInformationForm() {
  const [initialValues, setInitialValues] =
    useState<ICandidateOtherInformation>({
      foundOutAboutUsId: "",
      friendCompleteName: "",
      friendPhone: "",
      friendEmail: "",
      specifyOther: "",
      englishLearn: "",
      englishImmersionEnclosure: "",
      dedicatedTimeEnglishImmersion: "",
      ownHouseflag: "",
      canSwim: "",
      exceptToGetFromThisProgramDetail: "",
      healthConditionFlag: "",
      healthConditionDetail: "",
      isAllergic: "",
      alerjicoAQue: "",
      impedimentWorkWeekendFlag: "",
      impedimentWorkWeekendDetail: "",
      cualidades: "",
      otherSchool: "",
    });
  const { step, setStep } = useContext(CandidateApplicationContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [foundAboutUs, setFoundAboutUs] = useState<FoundAboutUs[]>([]);
  const [languageModal, setLanguageModal] = useState<boolean>(false);
  const [languageLevels, setLanguangeLevels] = useState<LanguageLevel[]>([]);
  const [candidateLanguageList, setCandididateLanguageList] = useState<
    CandidateLanguageList[]
  >([]);
  const [candidateLanguages, setCandidateLanguages] = useState<
    CandidateLanguage[]
  >([]);
  const [candidateLanguage, setCandidateLanguage] = useState<CandidateLanguage>(
    {
      languageId: 2,
      learnLevel: 1,
      writeLevel: 1,
      readLevel: 1,
      candidateLanguageId: 0,
      languageName: "",
    }
  );
  const immersionSchools: string[] = [
    "CCEPJB Santo Domingo",
    "CCEPJB Santiago Apóstol",
    "Centro Educativo Crossover",
    "Centro Progresando Los Mina",
    "Centro Progresando Pedro Brand",
    "Centro Progresando Sabana Perdida",
    "Centro Tecnológico Comunitario de Boca Chica",
    "Centro Tecnológico Comunitario de La Victoria",
    "Centro Tecnológico Comunitario de Villa Mella",
    "Colegio Don Bosco",
    "Colegio Elvira de Mendoza",
    "Colegio Minetta Roques",
    "Colegio San Francisco de Asís",
    "ICDA - Dominico Americano Santo Domingo",
    "ICDA - Gregorio Hernández",
    "ICDA - San Martín de Porres",
    "Instituto Tecnológico de Aragón",
    "La Victoria - Parroquia San Antonio de Padua",
    "MEMPHIS, Centro de Idiomas y Tecnología",
    "Universidad Abierta para Adultos Recinto Zona Oriental (UAPA)",
    "Universidad Acción Pro Educación y Cultura (APEC)",
    "Universidad Autónoma de Santo Domingo (UASD)",
    "Universidad del Caribe (UNICARIBE)",
    "Universidad Nacional Evangelica Recinto Santo Domingo (UNEV)",
    "Felix Evaristo Mejia",
    "Liceo Prof. Pedro Aponte",
    "Centro Comunitario Tecnológico de Comendador",
    "Centro Cultural Perelló",
    "Centro Educativo Prof. Hilda Dotel Florian",
    "Centro Progresando, Azua",
    "Centro Tecnológico Comunitario de Nigua",
    "Centro Tecnologico Comunitario Tamayo",
    "Escuela Santa Baez de San Jose de Ocoa",
    "Instituto Politécnico de Loyola",
    "Liceo de Pedernales",
    "Liceo Humberto Recio",
    "Liceo Roman Baldorioty",
    "UASD - Bani",
    "UASD - Barahona",
    "UASD - Neyba",
    "UASD - San Cristóbal",
    "UASD - San Juan de la Maguana",
    "Universidad Católica Tecnológica de Barahona (UCATEBA)",
    "Urania Montás",
    "Centro de Inglés de Jimaní",
    "Centro de Ingles de Galvan",
    "Escuela Fe y Alegria, Villa Jaragua",
    "Instituto Politécnico de Haina",
    "Centro Comunal Ateneo Hermanas Mirabal",
    "Centro Comunitario de Tenares",
    "Centro Comunitario de Villa Tapia",
    "Centro de Inglés Maimón",
    "Centro Educativo Jean Piaget",
    "Centro Progresando de Samaná",
    "Centro Tecnológico Comunitario de Gaspar Hernández",
    "Centro Tecnológico Comunitario de Juan López",
    "Centro Tecnológico de San Victor",
    "Centro Tecnológico El Factor De Nagua",
    "ENCOM",
    "Escuela Basica Carmela Sheppard",
    "Instituto Superior de Agricultura (ISA)",
    "Instituto Tecnológico San Ignacio de Loyola ( ITESIL)",
    "Liceo Jorge Sterling Echavarría Mendoza",
    "Liceo Juan Pablo Duarte Monción",
    "Politecnico Felipe Soriano Bello",
    "UASD - Nagua",
    "UASD - San Francisco de Macorís",
    "UASD - Santiago",
    "UASD - Valverde Mao",
    "Universidad Abierta para Adultos Recinto Santiago (UAPA-Santiago)",
    "Universidad Agroforestal Fernando Arturo de Meriño",
    "Universidad Nacional Evangélica Recinto Santiago (UNEV-Santiago)",
    "Universidad Tecnológica del Cibao Oriental (UTECO)",
    "Centro Tecnológico Comunitario El Limón",
    "Centro Cultural de CienFuegos",
    "Centro De Ingles Monte Cristi",
    "UASD - Bonao",
    "Politecnico Santo Esteban Rivera de Las Terrenas",
    "Universidad Católica Tecnológica del Cibao (UCATECI)",
    "Centro de Formación Integral D Clase (CEFORMA)",
    "Instituto Politécnico Industrial Don Bosco",
    "Centro Progresando El Seibo",
    "Centro Comunitario Tecnológico de Peralvillo",
    "Centro Progresando de Yamasá",
    "Liceo San Rafael del Yuma",
    "UASD - Higuey",
    "UASD - San Pedro de Macorís",
    "Universidad Central del Este (UCE)",
    "Centro de Inglés Proyecto Esperanza",
    "Centro Comunitario de Nisibon",
    "ICDA - Dominico Americano (La Romana)",
    "Centro Tecnológico de Sabana de la Mar",
    "Centro Tecnológico Comunitario de Yamasá",
    "Centro de Crecimiento y Desarrollo Infantil (CREDI)",
    "Fundación Carlos Pérez Guante",
    "Escuela Técnico Vocacional Hato Mayor del Rey",
    "Liceo Matias Ramon Mella",
    "Otros",
  ];
  const appState: ApplicationState = useAppSelector((state) => state.appState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  async function initialData() {
    const aboutUs = await utilsService.getFoundAboutUs();
    const levels = await utilsService.getLanguageLevels();
    const languageList = await candidateService.getCandidateLanguageList();
    const candidateLanguages = await candidateService.getCandidateLanguages();
    const candidateOtherInfo =
      await candidateService.getCandidateOtherInformation();

    setInitialValues({
      ...candidateOtherInfo,
      healthConditionFlag: candidateOtherInfo.healthConditionFlag ? "SI" : "NO",
      isAllergic:
        candidateOtherInfo.alerjicoAQue != null &&
        candidateOtherInfo.alerjicoAQue != ""
          ? "SI"
          : "NO",
      impedimentWorkWeekendFlag: candidateOtherInfo.impedimentWorkWeekendFlag
        ? "SI"
        : "NO",
      ownHouseflag: candidateOtherInfo.ownHouseflag ? "SI" : "NO",
      foundOutAboutUsId:
        candidateOtherInfo.foundOutAboutUsId != 0
          ? candidateOtherInfo.foundOutAboutUsId.toString()
          : "",
      canSwim: candidateOtherInfo.canSwim ? "SI" : "NO",
      friendCompleteName: candidateOtherInfo.friendCompleteName ?? "",
      friendPhone: candidateOtherInfo.friendPhone ?? "",
      friendEmail: candidateOtherInfo.friendEmail ?? "",
      specifyOther: candidateOtherInfo.specifyOther ?? "",
      englishLearn: candidateOtherInfo.englishLearn ?? "",
      englishImmersionEnclosure:
        immersionSchools.find(
          (el) => el == candidateOtherInfo.englishImmersionEnclosure
        ) == undefined
          ? candidateOtherInfo.englishImmersionEnclosure != null &&
            candidateOtherInfo.englishImmersionEnclosure != ""
            ? "Otros"
            : ""
          : candidateOtherInfo.englishImmersionEnclosure ?? "",

      dedicatedTimeEnglishImmersion:
        candidateOtherInfo.dedicatedTimeEnglishImmersion ?? "",
      exceptToGetFromThisProgramDetail:
        candidateOtherInfo.exceptToGetFromThisProgramDetail ?? "",
      healthConditionDetail: candidateOtherInfo.healthConditionDetail ?? "",
      alerjicoAQue: candidateOtherInfo.alerjicoAQue ?? "",
      impedimentWorkWeekendDetail:
        candidateOtherInfo.impedimentWorkWeekendDetail ?? "",
      cualidades: candidateOtherInfo.cualidades ?? "",
      otherSchool: candidateOtherInfo.englishImmersionEnclosure ?? "",
    });
    setLanguangeLevels(levels);
    setFoundAboutUs(aboutUs);
    setCandididateLanguageList(languageList);
    setCandidateLanguages(candidateLanguages);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);

  async function submit(values: ICandidateOtherInformation) {
    if (
      (appState.applicationStateId != 23 && !appState.isAdmin) ||
      window.location.href.includes("profile")
    ) {
      if (appState.isRepeating) {
        setStep(step + 1);
      } else {
        navigate("/");
      }
      return;
    }
    const otherInfo: CandidateOtherInformation = {
      ...values,
      foundOutAboutUsId: Number(values.foundOutAboutUsId),
      isAllergic: values.isAllergic == "SI",
      healthConditionFlag: values.healthConditionFlag == "SI",
      impedimentWorkWeekendFlag: values.impedimentWorkWeekendFlag == "SI",
      dedicatedTimeEnglishImmersion:
        values.dedicatedTimeEnglishImmersion.toString(),
      ownHouseflag: values.ownHouseflag == "SI",
      englishImmersionEnclosure:
        immersionSchools.find((el) => el == values.englishImmersionEnclosure) ==
          undefined || values.englishImmersionEnclosure == "Otros"
          ? values.otherSchool
          : values.englishImmersionEnclosure,
    };

    if (candidateLanguages.length == 0) {
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Por favor agregar nivel de idioma");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
      return;
    }

    if (appState.isRepeating) {
      saveForm(otherInfo);
      return;
    }

    setOpenDialog(true);
    setDialogTitle("Confirmar");
    setDialogDesc(
      "Confirmo que todos los datos proporcionados son completamente veraces y entiendo que brindar información falsa puede tener consecuencias negativas para mi participación\n\n ¿ESTAS SEGURO QUE DESEAS COMPLETAR TU REGISTRO? Luego de confirmar no podrás realizar modificaciones a tus datos"
    );
    setDialogButton([
      <Button
        onClick={() => {
          setOpenDialog(false);
          return;
        }}
      >
        CANCELAR
      </Button>,
      <Button
        onClick={async () => {
          setOpenDialog(false);
          saveForm(otherInfo);
        }}
      >
        CONFIRMO LOS DATOS
      </Button>,
    ]);
  }

  async function saveForm(value: CandidateOtherInformation) {
    try {
      setIsSaving(true);
      await candidateService.saveCandidateOtherInformation(value);

      if (!appState.isRepeating) {
        if (!appState.isAdmin) {
          var applicationState: ApplicationState | null =
            await authService.loadUserInfo();

          await dispatch(login({ applicationState: applicationState! }));

          setIsSaving(false);

          setOpenDialog(true);
          setDialogTitle("TUS DATOS HAN SIDO REGISTRADOS EXITOSAMENTE");
          setDialogDesc(
            "¡Registro exitoso! Puedes revisar los detalles de tu entrevista haciendo clic en Inicio. Tu cuenta está pendiente de pago, recuerda que puedes hacerlo mediante depósito, transferencia, efectivo o tarjeta en línea.\n\n Agradecemos tu interés en unirte a la familia ACE INTERNATIONAL. ¡Bienvenido(a)!"
          );
          setDialogButton(
            <Button
              onClick={() => {
                setOpenDialog(false);
                navigate("/");
              }}
            >
              Ok
            </Button>
          );
        } else {
          setIsSaving(false);
          setOpenDialog(true);
          setDialogTitle("");
          setDialogDesc("Ha terminado con este candidato");
          setDialogButton(
            <Button
              onClick={() => {
                setOpenDialog(false);
                setStep(1);
              }}
            >
              Ok
            </Button>
          );
        }
      } else {
        setStep(step + 1);
      }
    } catch (error: any) {
      setIsSaving(false);
      setOpenDialog(true);
      setDialogTitle("Error");
      setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
      setDialogButton(<Button onClick={() => setOpenDialog(false)}>Ok</Button>);
    }
  }

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={
            !window.location.href.includes("profile")
              ? validationSchema
              : undefined
          }
          validateOnMount={true}
        >
          {(props: FormikProps<ICandidateOtherInformation>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
            } = props;

            return (
              <Form>
                <Box textAlign="center">
                  <Typography
                    display="inline-block"
                    sx={{
                      mb: 3,
                      backgroundColor: "#00166B",
                      color: "white",
                      padding: "10px 30px",
                      borderRadius: "10px",
                      textTransform: "uppercase",
                    }}
                    variant="h4"
                  >
                    OTRAS INFORMACIONES REQUERIDAS
                  </Typography>
                </Box>

                <Grid container spacing={1}>
                  <Box
                    bgcolor="primary"
                    sx={{
                      width: "100%",
                      bgcolor: "primary.main",
                    }}
                  >
                    <Typography sx={{ color: "white", px: 2, py: 1 }}>
                      Idiomas que habla el estudiante (Diferentes al español)
                    </Typography>
                  </Box>

                  <Grid item xs={12}>
                    {candidateLanguageList.length > 0 ? (
                      appState.applicationStateId == 23 || appState.isAdmin ? (
                        <Button
                          sx={{ mb: 2 }}
                          variant="contained"
                          onClick={() => {
                            setLanguageModal(true);
                          }}
                        >
                          Agregar
                        </Button>
                      ) : null
                    ) : null}

                    <TableContainer component={Paper}>
                      <Table
                        //sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Idioma
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Escribe
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Lectura
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Conversación
                            </StyledTableCell>
                            <StyledTableCell align="left"></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {candidateLanguages.map((el) => (
                            <StyledTableRow
                              key={el.candidateLanguageId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell align="left">
                                {el.languageName}
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {
                                  languageLevels.filter(
                                    (l) => l.languageLevelId == el.writeLevel
                                  )[0].name
                                }
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {
                                  languageLevels.filter(
                                    (l) => l.languageLevelId == el.readLevel
                                  )[0].name
                                }
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {
                                  languageLevels.filter(
                                    (l) => l.languageLevelId == el.learnLevel
                                  )[0].name
                                }
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {appState.applicationStateId == 23 ||
                                appState.isAdmin ? (
                                  <Box>
                                    <IconButton
                                      sx={{ mr: 1 }}
                                      color="error"
                                      onClick={async () => {
                                        try {
                                          await candidateService.deleteCandidateLanguage(
                                            el.candidateLanguageId
                                          );
                                          const languageList =
                                            await candidateService.getCandidateLanguageList();
                                          const candidateLanguages =
                                            await candidateService.getCandidateLanguages();

                                          setCandididateLanguageList(
                                            languageList
                                          );
                                          setCandidateLanguages(
                                            candidateLanguages
                                          );
                                        } catch {
                                          setOpenDialog(true);
                                          setDialogTitle("Error");
                                          setDialogDesc(
                                            "Ha ocurrido un error. Por favor intente mas tarde"
                                          );
                                          setDialogButton(
                                            <Button
                                              onClick={() =>
                                                setOpenDialog(false)
                                              }
                                            >
                                              Ok
                                            </Button>
                                          );
                                        }
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                      color="success"
                                      onClick={() => {
                                        setCandidateLanguage(el);
                                        setLanguageModal(true);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Box>
                                ) : null}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Box
                    bgcolor="primary"
                    sx={{
                      width: "100%",
                      bgcolor: "primary.main",
                    }}
                  >
                    <Typography sx={{ color: "white", px: 2, py: 1 }}>
                      Otras preguntas sobre tu aplicación
                    </Typography>
                  </Box>
                  {/* Found about us */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="¿Cómo se enteró de nosotros?" required />
                    <AppSelect
                      name="foundOutAboutUsId"
                      error={
                        errors.foundOutAboutUsId && touched.foundOutAboutUsId
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.foundOutAboutUsId}
                      onChange={handleChange}
                    >
                      {foundAboutUs.map((el) => (
                        <MenuItem
                          key={el.foundOutAboutUsId}
                          value={el.foundOutAboutUsId}
                        >
                          {el.name}
                        </MenuItem>
                      ))}
                    </AppSelect>
                  </Grid>
                  {/* Name */}
                  {values.foundOutAboutUsId == "4" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Nombre completo" required />
                      <AppTextField
                        error={
                          errors.friendCompleteName &&
                          touched.friendCompleteName
                            ? true
                            : false
                        }
                        name="friendCompleteName"
                        value={values.friendCompleteName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Friend Telephone */}
                  {values.foundOutAboutUsId == "4" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Teléfono" />
                      <AppTextField
                        name="friendPhone"
                        value={values.friendPhone}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue("friendPhone", formattedPhone);
                          }
                        }}
                      />
                    </Grid>
                  ) : null}
                  {/* Friend Email */}
                  {values.foundOutAboutUsId == "4" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Email" />
                      <AppTextField
                        name="friendEmail"
                        value={values.friendEmail}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : null}
                  {/* Specify */}
                  {values.foundOutAboutUsId == "6" ? (
                    <Grid item lg={4} xs={12}>
                      <Paragraph title="Favor especifique" required />
                      <AppTextField
                        error={
                          errors.specifyOther && touched.specifyOther
                            ? true
                            : false
                        }
                        name="specifyOther"
                        value={values.specifyOther}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Lean english  */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="¿Cómo aprendió hablar inglés?" required />
                    <AppSelect
                      name="englishLearn"
                      error={
                        errors.englishLearn && touched.englishLearn
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.englishLearn}
                      onChange={handleChange}
                    >
                      <MenuItem value="Ingles por Inmersión">
                        INGLES POR INMERSIÓN
                      </MenuItem>
                      <MenuItem value="Instituto/Colegio">
                        INSTITUTO/COLEGIO
                      </MenuItem>
                      <MenuItem value="Autodidacta">AUTODIDACTA</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Engles Place  */}
                  {values.englishLearn != "Autodidacta" ? (
                    <Grid item xs={12} lg={4}>
                      <Paragraph title="Recinto" required />
                      <AppSelect
                        name="englishImmersionEnclosure"
                        error={
                          errors.englishImmersionEnclosure &&
                          touched.englishImmersionEnclosure
                            ? true
                            : false
                        }
                        fullWidth
                        value={values.englishImmersionEnclosure}
                        onChange={handleChange}
                      >
                        {immersionSchools.map((el) => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))}
                      </AppSelect>
                    </Grid>
                  ) : null}

                  {values.englishImmersionEnclosure == "Otros" &&
                  values.englishLearn != "Autodidacta" ? (
                    <Grid item xs={12} lg={4}>
                      <Paragraph title="Nombre del Recinto" required />
                      <AppTextField
                        error={
                          errors.otherSchool && touched.otherSchool
                            ? true
                            : false
                        }
                        name="otherSchool"
                        value={values.otherSchool}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}
                  {/* Engles Year  */}
                  {values.englishLearn != "Autodidacta" ? (
                    <Grid item xs={12} lg={4}>
                      <Paragraph
                        title="Año en que realizó su estudio"
                        required
                      />
                      <AppTextField
                        type="number"
                        error={
                          errors.dedicatedTimeEnglishImmersion &&
                          touched.dedicatedTimeEnglishImmersion
                            ? true
                            : false
                        }
                        name="dedicatedTimeEnglishImmersion"
                        value={values.dedicatedTimeEnglishImmersion}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : null}

                  {/* Own House  */}
                  <Grid item lg={4} xs={12}>
                    <Paragraph title="¿Vives en casa propia?" required />
                    <AppSelect
                      name="ownHouseflag"
                      error={
                        errors.ownHouseflag && touched.ownHouseflag
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.ownHouseflag}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Can Swim  */}
                  <Grid item lg={12} xs={12}>
                    <Paragraph title="¿Sabes nadar?" required />
                    <AppSelect
                      name="canSwim"
                      error={errors.canSwim && touched.canSwim ? true : false}
                      fullWidth
                      value={values.canSwim}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Expect about program  */}
                  <Grid item xs={12}>
                    <Paragraph
                      title="¿Qué esperas obtener de este programa?"
                      required
                    />
                    <AppTextField
                      error={
                        errors.exceptToGetFromThisProgramDetail &&
                        touched.exceptToGetFromThisProgramDetail
                          ? true
                          : false
                      }
                      name="exceptToGetFromThisProgramDetail"
                      value={values.exceptToGetFromThisProgramDetail}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>

                  {/* Medical condition  */}
                  <Grid item lg={6} xs={12}>
                    <Paragraph
                      title="¿Tienes alguna condición médica? "
                      required
                    />
                    <AppSelect
                      name="healthConditionFlag"
                      error={
                        errors.healthConditionFlag &&
                        touched.healthConditionFlag
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.healthConditionFlag}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Medical detail  */}
                  {values.healthConditionFlag == "SI" ? (
                    <Grid item xs={12} lg={6}>
                      <Paragraph
                        title="Especifique su condición médica"
                        required
                      />
                      <AppTextField
                        error={
                          errors.healthConditionDetail &&
                          touched.healthConditionDetail
                            ? true
                            : false
                        }
                        name="healthConditionDetail"
                        value={values.healthConditionDetail}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* Allergic  */}
                  <Grid item lg={6} xs={12}>
                    <Paragraph title="¿Eres alérgico a algo?" required />
                    <AppSelect
                      name="isAllergic"
                      error={
                        errors.isAllergic && touched.isAllergic ? true : false
                      }
                      fullWidth
                      value={values.isAllergic}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* Allergic detail  */}
                  {values.isAllergic == "SI" ? (
                    <Grid item xs={12} lg={6}>
                      <Paragraph title="Especifique" required />
                      <AppTextField
                        error={
                          errors.alerjicoAQue && touched.alerjicoAQue
                            ? true
                            : false
                        }
                        name="alerjicoAQue"
                        value={values.alerjicoAQue}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  {/* trabajo fin de semana  */}
                  <Grid item lg={6} xs={12}>
                    <Paragraph
                      title="¿Tienes impedimiento para trabajar fines de semana?"
                      required
                    />
                    <AppSelect
                      name="impedimentWorkWeekendFlag"
                      error={
                        errors.impedimentWorkWeekendFlag &&
                        touched.impedimentWorkWeekendFlag
                          ? true
                          : false
                      }
                      fullWidth
                      value={values.impedimentWorkWeekendFlag}
                      onChange={handleChange}
                    >
                      <MenuItem value="NO">NO</MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                    </AppSelect>
                  </Grid>
                  {/* trabajo fin de semana detail  */}
                  {values.impedimentWorkWeekendFlag == "SI" ? (
                    <Grid item xs={12} lg={6}>
                      <Paragraph title="Favor especificar por qué" required />
                      <AppTextField
                        error={
                          errors.impedimentWorkWeekendDetail &&
                          touched.impedimentWorkWeekendDetail
                            ? true
                            : false
                        }
                        name="impedimentWorkWeekendDetail"
                        value={values.impedimentWorkWeekendDetail}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChangeToUppercase(event, setFieldValue)}
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Paragraph
                      title="Haga un breve resumen de qué lo hace un buen candidato para este programa"
                      required
                    />
                    <AppTextField
                      error={
                        errors.cualidades && touched.cualidades ? true : false
                      }
                      name="cualidades"
                      value={values.cualidades}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChangeToUppercase(event, setFieldValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setStep(step - 1);
                      }}
                      color="customGrey"
                      variant="contained"
                      sx={{ mr: "10px", color: "white" }}
                    >
                      Atras
                    </Button>
                    <Button
                      onClick={() => {
                        if (!isValid) {
                          setIsValidForm(true);
                        }
                      }}
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ bgcolor: "secondary.main" }}
                    >
                      {isSubmitting ? <>Guardando...</> : "Guardar"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      <ConfirmationDialog
        maxWidth="sm"
        open={isValidForm}
        title={"Error"}
        description={"Llenar todos los requisitos en rojo"}
        buttons={<Button onClick={() => setIsValidForm(false)}>Ok</Button>}
      />
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />

      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />

      <Dialog fullWidth={true} maxWidth="md" open={languageModal}>
        <DialogTitle>Idiomas</DialogTitle>
        <DialogContent>
          <CandidateLanguageModal
            candidateLanguage={candidateLanguage}
            candidateLanguageList={candidateLanguageList}
            languageLevels={languageLevels}
            onSave={async () => {
              const candidateLanguages =
                await candidateService.getCandidateLanguages();
              const languageList =
                await candidateService.getCandidateLanguageList();
              setCandididateLanguageList(languageList);
              setCandidateLanguages(candidateLanguages);
              setLanguageModal(false);
            }}
            close={() => {
              setLanguageModal(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default CandidateOtherInformationForm;
